import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-black rounded-md w-full h-auto p-2 sm:p-6 md:p-10 xl:p-6 2xl:p-10" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "flex-wrap md:flex justify-between text-sm md:text-lg text-black font-normal text-base mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["h-fit flex flex-col rounded-xl p-4 border-2 border-grey-19 select-none", {
      'border-green-4': _ctx.isSelected,
      'bg-grey-18 pointer-events-none': _ctx.disabled,
      'cursor-pointer': !_ctx.disabled,
    }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleParcel(_ctx.parcel.id)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: 
          require(`@/assets/images/parcel_size_${
            '' + (_ctx.parcel.size || 'null')
          }.png`)
        ,
        class: "h-full rounded-md w-auto"
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", null, "Parcel " + _toDisplayString(_ctx.parcelName), 1),
      _createElementVNode("div", null, _toDisplayString(_ctx.parcel.size) + "x" + _toDisplayString(_ctx.parcel.size), 1)
    ])
  ], 2))
}