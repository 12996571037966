
import { Vue, Options, Prop, Watch, Emit } from 'vue-property-decorator';
import StarIcon from '@/components/icons/star.vue';
import StickerIcon from '@/components/icons/sticker.vue';
import StreakIcon from '@/components/icons/streak.vue';
import CollapseIcon from '@/components/icons/collapse.vue';
import ExpandIcon from '@/components/icons/expand.vue';
import {
  ARBITRUM_CHAIN_ID,
  ARBITRUM_NETWORK_NAME,
  ETHEREUM_NETWORK_NAME,
  ETHEREUM_CHAIN_ID,
} from '@/config';

@Options({
  components: {
    StarIcon,
    StickerIcon,
    StreakIcon,
    CollapseIcon,
    ExpandIcon,
  },
})
export default class Category extends Vue {
  @Prop(String) readonly title!: string;
  @Prop(String) readonly subtitle!: string;
  @Prop(Number) readonly selected!: number;
  @Prop({ default: false }) readonly subcategories!: boolean;
  @Prop({ default: false }) readonly links!: boolean;
  @Prop({ default: false }) readonly showCollectionsSubcategories!: boolean;
  @Prop({ default: false }) readonly showAllCollections!: boolean;

  get collections() {
    return this.$store.state.launchpad.collections;
  }

  @Watch('collections', { deep: true })
  collections_watch() {
    this.collectionsSubcategoriesArray.forEach((subcategory) => {
      subcategory.links = this.collections[subcategory.name].map(
        (collection: any) => {
          return {
            id: collection.id,
            name: collection.name,
            subcategory: subcategory.name,
            active: false,
          };
        }
      );
    });
    this.allCollectionsArray = this.collections.all.map((collection: any) => {
      return {
        id: collection.id,
        name: collection.name,
        active: false,
        subcategory: 'all',
      };
    });
    if (this.collections.featured.length > 0) {
      this.collectionsSubcategoriesArray[0].links[0].active = true;
      this.$store.commit('setCurrentCollection', this.collections.featured[0]);
    }
  }

  changeCurrentCollection(newCollection: any): void {
    // clear all active links from both arrays
    this.collectionsSubcategoriesArray.forEach((subcategory) => {
      subcategory.links.forEach((link) => {
        link.active = false;
      });
    });
    this.allCollectionsArray.forEach((link) => {
      link.active = false;
    });

    // find the new current collection link and set it active
    if (newCollection.subcategory === 'all') {
      this.allCollectionsArray.find((link) => {
        if (link.id === newCollection.id) {
          link.active = true;
        }
      });
    } else {
      const newCategory = this.collectionsSubcategoriesArray.find(
        (subcategory) => subcategory.name === newCollection.subcategory
      );
      if (!newCategory) {
        return;
      }
      const newLink = newCategory.links.find(
        (link) => link.id === newCollection.id
      );
      if (!newLink) {
        return;
      }
      newLink.active = true;
    }

    // find the new current collection data and set it in the store
    const collection = this.collections[newCollection.subcategory].find(
      (collection: any) => collection.id === newCollection.id
    );
    this.$store.commit('setCurrentCollection', collection);
  }

  collectionsSubcategoriesArray = [
    {
      prepend: 'StarIcon',
      label: 'Featured',
      name: 'featured',
      open: true,
      links: [
        {
          id: '',
          name: 'Wolf Game',
          url: '#',
          active: false,
        },
        {
          name: 'Dystowarz',
          url: '#',
          active: true,
        },
        {
          name: 'Feudalz',
          url: '#',
          active: false,
        },
      ],
    },
    {
      prepend: 'StreakIcon',
      label: 'Hottest',
      name: 'hottest',
      open: false,
      links: [
        {
          name: 'Cyber Kongz',
          url: '#',
          active: false,
        },
        {
          name: 'Neo Tokyo Identities',
          url: '#',
          active: false,
        },
        {
          name: 'Ether Orcs Genesis',
          url: '#',
          active: false,
        },
      ],
    },
    {
      prepend: 'StickerIcon',
      label: 'Newest',
      name: 'newest',
      open: false,
      links: [
        {
          name: 'Cyber Kongz',
          url: '#',
          active: false,
        },
        {
          name: 'Neo Tokyo Identities',
          url: '#',
          active: false,
        },
        {
          name: 'Ether Orcs Genesis',
          url: '#',
          active: false,
        },
      ],
    },
  ];

  allCollectionsArray = [
    {
      id: '',
      name: 'Wolf Game',
      url: '#',
      active: false,
    },
    {
      name: 'Dystowarz',
      url: '#',
      active: false,
    },
    {
      name: 'Feudalz',
      url: '#',
      active: false,
    },
  ];

  categories = [
    {
      label: 'SIZE',
      open: true,
      links: [
        {
          name: 'All',
          value: -1,
          method: '',
          active: false,
        },
        {
          name: '1 x 1',
          value: 1,
          method: '',
          active: false,
        },
        {
          name: '3 x 3',
          value: 3,
          method: '',
          active: false,
        },
        {
          name: '6 x 6',
          value: 6,
          method: '',
          active: false,
        },
        {
          name: '12 x 12',
          value: 12,
          method: '',
          active: false,
        },
        {
          name: '24 x 24',
          value: 24,
          method: '',
          active: false,
        },
      ],
    },
    {
      label: 'TYPE',
      open: true,
      links: [
        {
          name: 'All',
          value: -1,
          method: '',
          active: false,
        },
        {
          name: 'Reserved',
          value: 1,
          method: '',
          active: false,
        },
        {
          name: 'Minted',
          value: 2,
          method: '',
          active: false,
        },
      ],
    },
    // {
    //   label: 'CHAIN',
    //   open: true,
    //   links: [
    //     { name: 'All', value: -1, method: '', active: false },
    //     {
    //       name: ETHEREUM_NETWORK_NAME,
    //       value: ETHEREUM_CHAIN_ID,
    //       method: '',
    //       active: false,
    //     },
    //     {
    //       name: ARBITRUM_NETWORK_NAME,
    //       value: ARBITRUM_CHAIN_ID,
    //       method: '',
    //       active: false,
    //     },
    //   ],
    // },
    {
      label: 'NEWEST',
      open: true,
      links: [
        {
          name: 'All',
          url: '#',
          active: false,
        },
        {
          name: 'For SA',
          url: '#',
          active: false,
        },
        {
          name: 'Peacemaker',
          url: '#',
          active: false,
        },
        {
          name: 'Worker',
          url: '#',
          active: false,
        },
      ],
    },
  ];

  @Emit('selected')
  selectedOption(option: any) {
    return option.value;
  }
}
