import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "h-full w-fit flex justify-start items-center space-x-2 mx-4" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category, id) => {
    return _withDirectives((_openBlock(), _createElementBlock("div", {
      key: id,
      class: "rounded-md relative bg-black-3 text-white w-fit h-11 mr-4 mt-4"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_font_awesome_icon, {
          icon: category.icon,
          class: "text-white h-6"
        }, null, 8, ["icon"]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.links.filter((link) =>
          _ctx.selected.includes(link.value)
        ), (link, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "h-full flex items-center px-2 select-none cursor-pointer font-medium text-sm sm:text-base whitespace-nowrap",
            onClick: ($event: any) => (_ctx.selectedOption(link.value))
          }, _toDisplayString(link.name), 9, _hoisted_2))
        }), 128))
      ]),
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
        class: "rounded-full bg-grey-12 w-6 h-6 flex items-center justify-center absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 cursor-pointer"
      }, [
        _createVNode(_component_font_awesome_icon, {
          icon: "fa-solid fa-x",
          class: "text-white h-3"
        })
      ])
    ])), [
      [_vShow, category.label === _ctx.title]
    ])
  }), 128))
}