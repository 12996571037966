import '@/utils/socket';
import 'tippy.js/dist/tippy.css';
import { createApp } from 'vue';
import VueTippy from 'vue-tippy';
import App from './App.vue';
import router from './router';
import store from './store';
import Vue3Toasity from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import 'typeface-montserrat';
import "@fontsource/poppins/400.css"; // Weight 400.
import "@fontsource/poppins/700.css"; // Weight 700 for bold text.
import "@fontsource/rajdhani/400.css";

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* import font awesome icon component */

/* import the icons you want to use */
// import { faExpand } from '@fortawesome/free-regular-svg-icons';
import {
  faArrowRight,
  faArrowsSplitUpAndLeft,
  faBars,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleExclamation,
  faCirclePlus,
  faCircleUser,
  faClock,
  faClockRotateLeft,
  faCubes,
  faExpand,
  faFireFlameCurved,
  faFolder,
  faGift,
  faGlobe,
  faHashtag,
  faLayerGroup,
  faLink,
  faLocationDot,
  faMagnifyingGlass,
  faPen,
  faPlay,
  faPlus,
  faRightFromBracket,
  faSquarePlus,
  faStar,
  faTriangleExclamation,
  faUserSecret,
  faX,
} from '@fortawesome/free-solid-svg-icons';

import {
  faDiscord,
  faTelegram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

import { faCopy } from '@fortawesome/free-regular-svg-icons';

library.add(
  faUserSecret,
  faExpand,
  faLayerGroup,
  faCubes,
  faX,
  faChevronDown,
  faChevronUp,
  faCirclePlus,
  faClock,
  faCircleExclamation,
  faArrowsSplitUpAndLeft,
  faArrowRight,
  faLocationDot,
  faCopy,
  faCircleUser,
  faRightFromBracket,
  faMagnifyingGlass,
  faChevronLeft,
  faChevronRight,
  faStar,
  faFireFlameCurved,
  faSquarePlus,
  faHashtag,
  faLink,
  faGlobe,
  faPen,
  faPlay,
  faFolder,
  faTwitter,
  faDiscord,
  faTelegram,
  faCheck,
  faClockRotateLeft,
  faGift,
  faTriangleExclamation,
  faBars,
  faPlus
);

import '@/assets/stylus/home.css';
import '@/assets/stylus/index.styl';
import '@/assets/stylus/main.css';
import Vue3TouchEvents from 'vue3-touch-events';
import BigNumber from 'bignumber.js';

// Almost never return exponential notation:
BigNumber.config({ EXPONENTIAL_AT: 1e+9 });

// import stylus

createApp(App)
  .use(store)
  .use(router)
  .use(Vue3Toasity, {})
  .use(VueTippy, {
    directive: 'tippy', // => v-tippy
    component: 'tippy', // => <tippy/>
    componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
    defaultProps: {
      placement: 'auto-end',
      allowHTML: true,
    },
  })
  .use(Vue3TouchEvents)
  .mount('#app');
