
import { Vue, Options, Watch } from 'vue-property-decorator';
import Navbar from '@/components/shared/navbar.vue';
import Modal from '@/components/shared/modals/index.vue';

@Options({
  components: {
    Navbar,
    Modal,
  },
})
export default class Basic extends Vue {
  logoClass = 'smaller';
  scroll = 0;

  get forceBigNavbarLogo(): boolean {
    return this.$store.state.app.forceBigNavbarLogo;
  }

  @Watch('forceBigNavbarLogo')
  onForceBigNavbarLogoChange(): void {
    this.logoClass = this.forceBigNavbarLogo ? 'bigger' : 'smallest';
  }

  get modal(): any {
    return this.$store.state.app.modal;
  }

  checkWidth(): void {
    if (window.innerWidth >= 1440 && this.scroll < 200) {
      this.logoClass = 'bigger';
    } else if (window.innerWidth < 1440 && this.scroll <= 1) {
      this.logoClass = 'smaller';
    }
  }

  mounted(): void {
    this.checkWidth();
    window.addEventListener('resize', this.checkWidth);

    const router = document.getElementById('router')!;
    router.addEventListener('scroll', (e) => {
      this.scroll = router.scrollTop;
      if (router.scrollTop <= 1) {
        this.logoClass = 'smaller';
      } else {
        this.logoClass = '';
      }

      if (router.scrollTop < 200 && window.innerWidth >= 1400) {
        this.logoClass = 'bigger';
      }
    });
  }
}
