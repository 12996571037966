
import { Vue } from 'vue-property-decorator';
import { formatEther } from '@/utils';

export default class estimateKeyPrice extends Vue {

  exitCallback: (() => void) | null = null;
  collectionAddress = '';
  numberOfSharesInput = 0;
  isBuy = true;

  estimatedPriceInDysto = '';
  errorOccurred = false;

  sharesSupply = 0;

  async beforeMount(): Promise<void> {
    this.exitCallback = this.$store.state.app.modal.payload.exitCallback;
    this.collectionAddress = this.$store.state.app.modal.payload.collectionAddress;

    this.sharesSupply = await this.$store.state.dystoWorldKeypadContract.methods.sharesSupply(this.collectionAddress).call();
  }

  toggle() {
    this.isBuy = !this.isBuy;
    this.estimatedPriceInDysto = "";
    this.errorOccurred = false;
  }

  close(): void {
    this.$store.commit('app/setModal', { component: '', payload: null });
    if (this.exitCallback) {
      this.exitCallback();
    }
  }

  validateInput() {
    this.estimatedPriceInDysto = '';
    this.errorOccurred = false;

    if (this.numberOfSharesInput < 0) {
      this.numberOfSharesInput = 0;
    }
  }

  canSellThisAmount() {
    return this.numberOfSharesInput >= 0 && this.numberOfSharesInput < this.sharesSupply;
  }

  async estimatePrice() {
    try {
      if (!this.canSellThisAmount() && !this.isBuy) {
        return;
      }

      const keypadContract = this.$store.state.dystoWorldKeypadContract;
      let priceInDysto;

      if (this.isBuy) {
        priceInDysto = await keypadContract.methods.getBuyPriceAfterFee(this.collectionAddress, this.numberOfSharesInput).call();
      } else {
        priceInDysto = await keypadContract.methods.getSellPriceAfterFee(this.collectionAddress, this.numberOfSharesInput).call();
      }

      this.estimatedPriceInDysto = formatEther(priceInDysto.toString());
    } catch (error) {
      console.log(error);
      this.errorOccurred = true;
    }
  }
}
