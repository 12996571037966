import {
  API_URL,
  ETHEREUM_CHAIN_ID,
  ETHEREUM_CHAIN_ID_HEX,
  ETHEREUM_NETWORK_NAME,
  LAUNCHPAD_SUPPORTED_CHAIN_IDS_HEX,
  MAX_CONFIRMATIONS,
  SUPPORTED_CHAIN_IDS,
  SUPPORTED_CHAIN_IDS_HEX,
} from '@/config';
import {
  DYSTO_WORLD_KEYPAD_CONTRACT_ABI,
  DYSTO_WORLD_LANDLORD_STAKING_CONTRACT_ABI,
  DYSTO_WORLD_PORTAL_CONTRACT_ABI,
  DYSTO_WORLD_PUBLIC_SALE_CONTRACT_ABI,
  DYSTO_WORLD_SPACES_CONTRACT_ABI,
  DYSTO_WORLD_TOKEN_ABI, DYSTO_WORLD_VESTING_CONTRACT_ABI,
  GENERIC_COLLECTION_ABI,
  GENERIC_ERC20_ABI,
} from '@/config/abi';
import { ADDRESS_ZERO, delay, formatEther } from '@/utils';
import {
  getChainNameByChainId,
  getNetworkByChainId,
  getNetworkConnectionParamsByChainId,
} from '@/utils/blockchain';
import { validateResponse } from '@/utils/functions/validateResponse';
import { Parcel } from '@/utils/interfaces';
import detectEthereumProvider from '@metamask/detect-provider';
import WalletConnectProvider from '@walletconnect/web3-provider';
import BigNumber from 'bignumber.js';
import { createStore } from 'vuex';
import Web3 from 'web3';
import { app } from './modules/app';
import {
  NFTInfo,
  NFTOptionInfo,
  PoolHistory,
  PoolReward,
  StakedNFTInfo,
} from './types/landlordStaking';
import {
  GenericAssetInfo,
  MountSpaceInfo,
  ParcelSpaceInfo,
  SpaceAssetInfo,
  SpaceInstance,
} from './types/spaces';
import eventBus from '@/eventBus';

// import { socket } from "../utils/socket";

// interceptor for all fetch requests to remove auth token if 401 and force sign again
const { fetch: originalFetch } = window;
window.fetch = async (...args: any[]) => {
  const [resource, config] = args;
  const response = await originalFetch(resource, config);
  if (response.status === 401) {
    localStorage.removeItem('auth_token');
  }
  return response;
};

const store = createStore({
  modules: {
    app,
  },
  state: {
    currentUrlPath: '',
    metamask: false,
    user: null,
    wallet: null,
    requestingToSign: false, // mutex for signing requests
    web3: null as unknown,
    chainId: 1,
    client: null,
    dystoWorldTokenContract: null,
    dystoWorldPortalContract: null,
    dystoWorldSpacesContract: null,
    dystoWorldLandlordStakingContract: null,
    dystoWorldPublicSaleContract: null,
    dystoWorldKeypadContract: null,
    dystoWorldVestingContract: null,
    usdtContractAddress: null,
    usdcContractAddress: null,
    busdContractAddress: null,
    token: null,
    keypadCollections: [],
    parcels: [] as Array<any>,
    filteredParcels: [] as Array<any>,
    launchpad: {
      mintingInProgress: false,
      mintingPhase: false,
      collectionSearchName: '',
      collections: {
        soldout: [],
        upcoming: [],
        featured: [],
        hottest: [],
        newest: [],
        all: [],
      },
      currentCollection: null,
    },
    keypad: {
      collectionSearchName: '',
      collections: [],
      currentCollection: null,
    },
    home: {
      topCollection: null as any,
      featured: [] as Array<any>,
    },
    landlordStaking: {
      poolRewardsSearchName: '',
      waitingForApi: false,
      waitingForOpenSeaApi: false,
      waitForConfirmationTrigger: false,
      hasApprovalForTransferOfParcels: false,
      stakedParcels: [] as Array<number>,
      unstakedParcels: [] as Array<number>,
      activePoolRewards: [] as Array<PoolReward>,
      pastPoolRewards: [] as Array<PoolReward>,
      currentPoolReward: null as PoolReward | null,
      stakedNFTs: [] as Array<StakedNFTInfo>,
      currentlyStakedNFTs: [] as Array<StakedNFTInfo>,
      previouslyStakedNFTs: [] as Array<StakedNFTInfo>,
      nftsOwned: [] as Array<NFTOptionInfo>,
      transactionHash: '' as string,
      selectedNFTsForStaking: {} as Record<string, string[]>,
      collectionAddresses: [] as Array<string>,
      collectionHistories: {} as Record<string, PoolHistory[]>,
    },
    spaces: {
      waitingForApi: false,
      mount: {
        parcel: {} as ParcelSpaceInfo,
        spaceAssets: [] as Array<SpaceAssetInfo>,
      } as MountSpaceInfo,
      ownedInstances: [] as Array<SpaceInstance>,
      ownedAssets: [] as Array<GenericAssetInfo>,
    },
  },
  mutations: {
    setCurrentUrlPath(state: any, payload: string) {
      state.currentUrlPath = payload;
    },
    setMetamask(state: any, payload: any) {
      state.metamask = payload;
    },
    setUser(state: any, payload: any) {
      state.user = payload;
    },
    setInfluencerWizardData(state: any, payload: any) {
      state.user.influencerWizardData = payload;
    },
    setTwitterData(state: any, payload: any) {
      state.user.twitterData = payload;
    },
    setKeypadCollectionData(state: any, payload: any) {
      state.user.keypadCollectionData = payload;
    },
    setKeypadOwnCollectionTradesData(state: any, payload: any) {
      state.user.keypadOwnCollectionTradesData = payload;
    },
    setVestingBeneficiary(state: any, payload: any) {
      state.user.vestingBeneficiary = payload;
    },
    setKeypadTradesBoughSoldData(state: any, payload: any) {
      state.user.keypadTradesBoughSoldData = payload;
    },
    setDystoBuyTransactionsData(state: any, payload: any) {
      state.user.dystoBuyTransactionsData = payload;
    },
    setAllParcelsData(state: any, payload: any) {
      state.user.allParcelsData = payload;
    },
    setKeypadTopFiveInfluencers(state: any, payload: any) {
      state.keypadTopFiveInfluencers = payload;
    },
    setFreeLandWhitelisted(state: any, payload: any) {
      state.user.freeLandWhitelisted = payload;
    },
    setFreeLandReservedOrMinted(state: any, payload: any) {
      state.user.freeLandReservedOrMinted = payload;
    },
    setKeypadCollectionsData(state: any, payload: any) {
      state.keypadCollections = payload;
    },
    setUserNonce(state: any, payload: string) {
      state.user.nonce = payload;
    },
    setWallet(state: any, payload: any) {
      localStorage.setItem('metamask_address', payload || '');
      state.wallet = payload;
    },
    setRequestingToSign(state: any, payload: boolean) {
      state.requestingToSign = payload;
    },
    setWeb3(state: any, payload: any) {
      state.web3 = payload;
      state.web3.eth.transactionBlockTimeout = 200;
    },
    setChainId(state: any, payload: number) {
      state.chainId = payload;
    },
    setDystoWorldContractsFromChain(state: any, payload: number) {
      if (!SUPPORTED_CHAIN_IDS.includes(payload)) {
        state.dystoWorldTokenContract = null;
        state.dystoWorldPortalContract = null;
        state.dystoWorldSpacesContract = null;
        state.dystoWorldLandlordStakingContract = null;
        state.dystoWorldPublicSaleContract = null;
        return;
      }

      const network = getNetworkByChainId(payload);
      state.dystoWorldTokenContract = new state.web3.eth.Contract(
        DYSTO_WORLD_TOKEN_ABI,
        network.DYSTO_WORLD_TOKEN_CONTRACT_ADDRESS
      );
      state.dystoWorldPortalContract = new state.web3.eth.Contract(
        DYSTO_WORLD_PORTAL_CONTRACT_ABI,
        network.DYSTO_WORLD_PORTAL_CONTRACT_ADDRESS
      );
      state.dystoWorldSpacesContract = new state.web3.eth.Contract(
        DYSTO_WORLD_SPACES_CONTRACT_ABI,
        network.DYSTO_WORLD_SPACES_CONTRACT_ADDRESS
      );
      state.dystoWorldLandlordStakingContract = new state.web3.eth.Contract(
        DYSTO_WORLD_LANDLORD_STAKING_CONTRACT_ABI,
        network.DYSTO_WORLD_LANDLORD_STAKING_CONTRACT_ADDRESS
      );
      state.dystoWorldPublicSaleContract =  new state.web3.eth.Contract(
        DYSTO_WORLD_PUBLIC_SALE_CONTRACT_ABI,
        network.DYSTO_WORLD_PUBLIC_SALE_CONTRACT_ADDRESS
      );
      state.dystoWorldKeypadContract = new state.web3.eth.Contract(
        DYSTO_WORLD_KEYPAD_CONTRACT_ABI,
        network.DYSTO_KEYPAD_CONTRACT_ADDRESS
      )
      state.dystoWorldVestingContract = new state.web3.eth.Contract(
        DYSTO_WORLD_VESTING_CONTRACT_ABI,
        network.DYSTO_VESTING_CONTRACT_ADDRESS
      )
      state.usdtContractAddress = network.USDT_ADDRESS;
      state.usdcContractAddress = network.USDC_ADDRESS;
      state.busdContractAddress = network.BUSD_ADDRESS;
    },
    setClient(state: any, payload: any) {
      state.client = payload;
    },
    setToken(state: any, payload: any) {
      state.token = payload;
    },
    setParcels(state: any, payload: any) {
      state.parcels = Object.entries(payload);
    },
    setFilteredParcels(state: any, payload: any) {
      state.filteredParcels = payload;
    },
    updateParcels(state: any, payload: any) {
      if (payload) {
        if (payload.state === 'RESERVED' && payload.owner === state.wallet) {
          return;
        }

        if (payload.state === 'MINTED' && payload.owner === state.wallet) {
          const id = state.parcels.findIndex(
            (el: any) =>
              el[1].startX === payload.startX && el[1].startY === payload.startY
          );
          state.parcels.splice(id, 1);
          state.parcels.push([null, payload]);
        } else {
          state.parcels.push([null, payload]);
        }
      }
    },
    // keypad
    setKeypadCollections(state: any, payload: string) {
      state.keypad.collections = payload;
    },
    setKeypadCollectionSearchName(state: any, payload: string) {
      state.keypad.collectionSearchName = payload;
    },
    setKeypadCurrentCollection(state: any, payload: any) {
      state.keypad.currentCollection = payload;
    },
    //
    setMintingPhase(state: any, payload: boolean) {
      state.launchpad.mintingPhase = payload;
    },
    setCollectionSearchName(state: any, payload: string) {
      state.launchpad.collectionSearchName = payload;
    },
    setPoolRewardsSearchName(state: any, payload: string) {
      state.landlordStaking.poolRewardsSearchName = payload;
    },
    setCollections(state: any, payload: any) {
      state.launchpad.collections = payload;
    },
    setCollectionsByCategory(state: any, payload: any) {
      state.launchpad.collections[payload.category] = Object.values(
        payload.data
      );
    },
    setCurrentCollection(state: any, payload: any) {
      state.launchpad.currentCollection = payload;
    },
    setHomeCollection(state: any, payload: any) {
      state.home.topCollection = payload?.data;
    },
    setApprovalForTransferOfParcels(state: any, payload: boolean) {
      state.landlordStaking.hasApprovalForTransferOfParcels = payload;
    },
    setStakedParcels(state: any, payload: number[]) {
      state.landlordStaking.stakedParcels = payload;
    },
    setUnstakedParcels(state: any, payload: number[]) {
      state.landlordStaking.unstakedParcels = payload;
    },
    setActivePoolRewards(state: any, payload: PoolReward[]) {
      state.landlordStaking.activePoolRewards = payload;
    },
    setPastPoolRewards(state: any, payload: PoolReward[]) {
      state.landlordStaking.pastPoolRewards = payload;
    },
    setCurrentPoolReward(state: any, payload: string) {
      let slug = payload;

      if (!slug || slug == 'bulk') {
        state.landlordStaking.currentPoolReward = null;
        return;
      }

      if (slug.startsWith('staked-')) {
        slug = slug.replace('staked-', '');
      }

      let foundPoolReward = state.landlordStaking.activePoolRewards.find(
        (poolReward: PoolReward) => poolReward.id === slug
      );

      if (!foundPoolReward) {
        foundPoolReward = state.landlordStaking.pastPoolRewards.find(
          (poolReward: PoolReward) => poolReward.id === slug
        );
      }

      state.landlordStaking.currentPoolReward = foundPoolReward;
    },
    setStakedNFTs(state: any, payload: StakedNFTInfo[]) {
      state.landlordStaking.stakedNFTs = payload;
      state.landlordStaking.currentlyStakedNFTs = payload.filter(
        (nft: StakedNFTInfo) => {
          return (
            nft.stakingHistory[nft.stakingHistory.length - 1]
              .unstakedAtTimestamp === null
          );
        }
      );
      state.landlordStaking.previouslyStakedNFTs = payload.filter(
        (nft: StakedNFTInfo) => {
          return (
            nft.stakingHistory[nft.stakingHistory.length - 1]
              .unstakedAtTimestamp !== null
          );
        }
      );
    },
    setWaitingForApi(state: any, payload: boolean) {
      state.landlordStaking.waitingForApi = payload;
    },
    setWaitingForOpenSeaApi(state: any, payload: boolean) {
      state.landlordStaking.waitingForOpenSeaApi = payload;
    },
    setWaitForConfirmationTrigger(state: any, payload: boolean) {
      state.landlordStaking.waitForConfirmationTrigger = payload;
    },
    setMintingInProgress(state: any, payload: boolean) {
      state.launchpad.mintingInProgress = payload;
    },
    setNFTsOwned(state: any, payload: NFTOptionInfo[]) {
      state.landlordStaking.nftsOwned = payload;
    },
    setMount(state: any, payload: MountSpaceInfo) {
      state.spaces.mount = payload;
    },
    setSpacesWaitingForApi(state: any, payload: boolean) {
      state.spaces.waitingForApi = payload;
    },
    setOwnedInstances(state: any, payload: SpaceInstance[]) {
      state.spaces.ownedInstances = payload;
    },
    setOwnedAssets(state: any, payload: GenericAssetInfo[]) {
      state.spaces.ownedAssets = payload;
    },
    setTransactionHash(state: any, payload: string) {
      state.landlordStaking.transactionHash = payload;
    },
    setSelectedNFTsForStaking(state: any, payload: Record<string, string[]>) {
      state.landlordStaking.selectedNFTsForStaking = payload;
    },
    setCollectionAddresses(state: any, payload: string[]) {
      state.landlordStaking.collectionAddresses = payload;
    },
    setCollectionHistory(
      state: any,
      payload: { collectionAddress: string; history: PoolHistory[] }
    ) {
      state.landlordStaking.collectionHistories[payload.collectionAddress] =
        payload.history;
    },
    clearCollectionHistories(state: any) {
      state.landlordStaking.collectionHistories = {};
    },
  },
  actions: {
    async returnToken({ state, commit, dispatch }: any) {
      const token = localStorage.getItem('auth_token');

      if (!token || token === 'undefined') {
        return await dispatch('sign');
      } else return token;
    },
    async requestChangeOfChain(
      { state, commit, dispatch }: any,
      chainId: number
    ) {
      const chainIdHex = `0x${chainId.toString(16)}`;
      const chainName = getChainNameByChainId(chainId);

      const visibilityChangeHandler = () => {
        if (document.visibilityState === 'hidden') {
          changedToSelectedChain = true;
          document.removeEventListener(
            'visibilitychange',
            visibilityChangeHandler
          );
        }
      };

      let changedToSelectedChain = false;
      while (!changedToSelectedChain) {
        // if visibilityState is hidden, just break out of the while loop directly, we're in a different tab moving throguh chains
        // We don't want to prompt the user to move back just yet.
        if (document.visibilityState === 'hidden') break;
        document.addEventListener('visibilitychange', visibilityChangeHandler);
        try {
          await state.web3.currentProvider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: chainIdHex }],
          });
          changedToSelectedChain = true;
        } catch (err: any) {
          if (
            err.code === undefined ||
            err.code === null ||
            err.code === -32002
          ) {
            // pause for 1 second
            await new Promise((resolve) => setTimeout(resolve, 1000));
          } else {
            console.log(err.code, err.code === undefined, err.code === null);
          }
          if (err.code === 4001) {
            commit(
              'app/alterNotifications',
              {
                text: `Please accept the change to the ${chainName}`,
                type: 'danger',
                add: true,
              },
              { root: true }
            );
          } else if (err.code === 4902) {
            let addChainRetries = 0;
            while (addChainRetries < 3) {
              addChainRetries++;
              try {
                await state.web3.currentProvider.request({
                  method: 'wallet_addEthereumChain',
                  params: getNetworkConnectionParamsByChainId(chainId),
                });
                break;
              } catch (addError) {
                commit(
                  'app/alterNotifications',
                  {
                    text: `Please accept the adding of the ${chainName} network in your wallet provider.`,
                    type: 'danger',
                    add: true,
                  },
                  { root: true }
                );
              }
            }
            changedToSelectedChain = true;
          }
        }
      }
    },
    async metamaskCheckChain(
      { state, commit, dispatch }: any,
      data: { chainId: string; provider: any }
    ) {
      if (state.currentUrlPath.startsWith('/launchpad')) {
        // if (LAUNCHPAD_SUPPORTED_CHAIN_IDS_HEX.includes(data.chainId)) {
        return;
        // }
      } else if (SUPPORTED_CHAIN_IDS_HEX.includes(data.chainId)) {
        return;
      }

      // if not on one of the supported chains, change to the ethereum chain as default
      commit(
        'app/alterNotifications',
        {
          text: `Invalid chainId. Please change to the ${ETHEREUM_NETWORK_NAME}`,
          type: 'danger',
          add: true,
        },
        { root: true }
      );

      let changedToGoodChain = false;

      const visibilityChangeHandler = () => {
        if (document.visibilityState === 'hidden') {
          changedToGoodChain = true;
          document.removeEventListener(
            'visibilitychange',
            visibilityChangeHandler
          );
        }
      };

      while (!changedToGoodChain) {
        // if visibilityState is hidden, just break out of the while loop directly, we're in a different tab moving throguh chains
        // We don't want to prompt the user to move back just yet.
        if (document.visibilityState === 'hidden') break;
        document.addEventListener('visibilitychange', visibilityChangeHandler);
        try {
          await data.provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: ETHEREUM_CHAIN_ID_HEX }],
          });
          changedToGoodChain = true;
        } catch (err: any) {
          if (err.code === 4001) {
            commit(
              'app/alterNotifications',
              {
                text: `Please accept the change to the ${ETHEREUM_NETWORK_NAME}`,
                type: 'danger',
                add: true,
              },
              { root: true }
            );
          } else if (err.code === 4902) {
            let addChainRetries = 0;
            while (addChainRetries < 3) {
              addChainRetries++;
              try {
                await data.provider.request({
                  method: 'wallet_addEthereumChain',
                  params: [
                    {
                      chainId: ETHEREUM_CHAIN_ID_HEX,
                      chainName: 'Goerli Test Network',
                      nativeCurrency: {
                        name: 'GoerliETH',
                        symbol: 'GoerliETH',
                        decimals: 18,
                      },
                      rpcUrls: ['https://goerli.infura.io/v3/'],
                      blockExplorerUrls: ['https://goerli.etherscan.io'],
                    },
                  ],
                });
                break;
              } catch (addError) {
                commit(
                  'app/alterNotifications',
                  {
                    text: `Please accept the adding of the ${ETHEREUM_NETWORK_NAME} in Metamask`,
                    type: 'danger',
                    add: true,
                  },
                  { root: true }
                );
              }
            }
          }
        }
      }
    },
    async metamask({ state, commit, dispatch }: any) {
      const provider = (await detectEthereumProvider({
        mustBeMetaMask: true,
      })) as any;

      if (!provider) {
        window.open('https://metamask.io/download/', '_blank');
        return;
      }

      await dispatch('metamaskCheckChain', {
        chainId: await provider.request({ method: 'eth_chainId' }),
        provider,
      });

      const success = await provider
        .request({ method: 'eth_requestAccounts' })
        .then((e: string[]) => {
          commit('setWallet', e[0]);

          provider.on('accountsChanged', async (accounts: Array<string>) => {
            await dispatch('disconnectWallet');
            eventBus.emit('logout', null);
          });
          provider.on('chainChanged', async (chainIdHex: string) => {
            const chainId = parseInt(chainIdHex, 16);
            commit('setChainId', chainId);
            commit('setDystoWorldContractsFromChain', chainId);
            await dispatch('metamaskCheckChain', {
              chainId: chainIdHex,
              provider,
            });
          });
          return true;
        })
        .catch((e: any) => {
          console.error(e);
          return false;
        });

      if (!success) {
        alert('Nu s-a putut detecta niciun walletprovider.');
        return;
      }

      const web3 = new Web3(provider);
      const chainId = await web3.eth.getChainId();
      commit('setWeb3', web3);
      commit('setChainId', chainId);
      commit('setDystoWorldContractsFromChain', chainId);
      commit('setClient', 'METAMASK');
      dispatch('login');
    },
    async walletconnect({ state, commit, dispatch }: any) {
      const account = localStorage.getItem('metamask_address');
      const provider = new WalletConnectProvider({
        rpc: {
          [ETHEREUM_CHAIN_ID]:
            'https://goerli.infura.io/v3/b95b6ce91b0f46078931624ae24e7669',
        },
      });

      const success = await provider
        .enable()
        .then((e: any) => true)
        .catch((e: any) => false);

      if (!success) {
        console.error('[WALLETCONNECT] Failed');
        return;
      }

      if (provider.chainId !== ETHEREUM_CHAIN_ID) {
        const success = await provider
          .request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: ETHEREUM_CHAIN_ID }],
          })
          .then(() => true)
          .catch(() => false);

        if (!success) {
          commit('setWallet', null);
          commit('setWeb3', null);
          commit('setChainId', 0);
          commit('setDystoWorldContractsFromChain', 0); // 0 no chain - reset contracts
          commit('setClient', null);
          return;
        }
      }

      provider.on('accountsChanged', async (accounts: Array<string>) => {
        await dispatch('disconnectWallet');
        eventBus.emit('logout', null);
      });
      provider.on('chainChanged', async (chainIdHex: string) => {
        const chainId = parseInt(chainIdHex, 16);
        commit('setChainId', chainId);
        commit('setDystoWorldContractsFromChain', chainId);
        await dispatch('metamaskCheckChain', { chainIdHex, provider });
      });

      if (!account) {
        localStorage.setItem('metamask_address', provider.accounts[0]);
      }
      const web3 = new Web3(provider as any);
      const chainId = await web3.eth.getChainId();
      commit('setWallet', provider.accounts[0]);
      commit('setWeb3', web3);
      commit('setChainId', chainId);
      commit('setDystoWorldContractsFromChain', chainId);
      commit('setClient', 'WALLET_CONNECT');
      dispatch('login');
    },
    async resetUserNonce({ state, commit, dispatch }: any) {
      try {
        const response = await fetch(`${API_URL}/auth/login`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ address: state.wallet }),
        });
        const parseRes = await response.json();
        commit('setUserNonce', parseRes.nonce);
      } catch (err) {
        console.log(err);
      }
    },
    async sign({ state, commit, dispatch }: any) {

      console.log('=========');

      if (state.requestingToSign) {
        return;
      }
      commit('setRequestingToSign', true);
      try {
        const address = state.wallet;
        if (!state.user) {
          commit('setRequestingToSign', false);
          return;
        }

        const messageToSign = `Dystoworld wants you to sign this challenge ${state.user.nonce}`;
        const signature = await state.web3.eth.personal
          .sign(messageToSign, address, '')
          .catch((err: any) => {
            if (err.code === 4001) {
              throw 4001;
            }
          });

        if (!signature) {
          commit(
            'app/alterNotifications',
            { text: 'The sign operation failed', type: 'danger', add: true },
            { root: true }
          );
          commit('setRequestingToSign', false);
          return;
        }

        const data = {
          address,
          signature,
        };
        const response = await fetch(`${API_URL}/auth/unlock`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        });

        const parseRes = await validateResponse(response);

        if (parseRes.token === undefined) {
          localStorage.removeItem('auth_token');
          commit('setRequestingToSign', false);
          return;
        }

        localStorage.setItem('auth_token', parseRes.token);
        commit('setToken', parseRes.token);
        commit('setRequestingToSign', false);

        eventBus.emit('matamask-signed', null, null);

        return parseRes.token;
      } catch (err) {
        commit('setRequestingToSign', false);
        await dispatch('resetUserNonce');
        console.log(err);
      }
    },
    async reinit({ dispatch, commit }: any) {
      commit('setUser', null);
      localStorage.removeItem('metamask_address');
      localStorage.removeItem('auth_token');
    },
    async checkConnection({ dispatch, commit }: any) {
      try {
        const account = localStorage.getItem('metamask_address');
        if (!account || account.length === 0) {
          dispatch('reinit');
          return;
        }
        await dispatch('metamask');
      } catch (err) {
        console.log(err);
      }
    },
    disconnectWallet({ dispatch }: any) {
      dispatch('reinit');
      dispatch('checkConnection');
    },
    async login({ commit, state, dispatch }: any) {
      if (!state.wallet) {
        commit('setUser', null);
        return;
      }

      try {
        const data = {
          address: state.wallet,
        };
        const response = await fetch(`${API_URL}/auth/login`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        });
        const parseRes = await response.json();
        commit('setUser', parseRes);
      } catch (err) {
        console.log(err);
      }

      await dispatch('getAllInfluencerPageNeededData');
    },
    async getAllInfluencerPageNeededData({ commit, state, dispatch }: any) {
      await dispatch('getInfluencerWizard');
      await dispatch('getTwitterData');
      await dispatch('getKeypadTrades')
      await dispatch('getOwnKeypadCollection');
      await dispatch('getKeypadTradesBoughSoldByTraderAndChainId');
      await dispatch('checkFreeLandWhitelisted');
      await dispatch('checkFreeLandReservedOrMinted');
      await dispatch('fetchTopFiveInfluencers');
      await dispatch('getKeypadCollections');
      await dispatch('getDystoBuyTransactionsByBuyerAndChainId');
      await dispatch('getDystoSpacesTokens');
      await dispatch('getOwnedInstances');
      await dispatch('getAllParcels');
      await dispatch('getIsVestingBeneficiary');
    },
    async getIsVestingBeneficiary({ commit, state, dispatch }: any) {
      try {
        const isVestingBeneficiaryFetch = await fetch(`${API_URL}/vesting/is_beneficiary/${state.wallet}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
        const isVestingBeneficiaryResponse = await isVestingBeneficiaryFetch.json();
        if (isVestingBeneficiaryResponse && !isVestingBeneficiaryResponse.error) {
          commit('setVestingBeneficiary', isVestingBeneficiaryResponse);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getKeypadTrades({ commit, state, dispatch }: any) {
      try {
        const keypadOwnCollectionTradesDataFetch = await fetch(`${API_URL}/keypad/keypad_trades_by_collection_owner/${state.wallet}/${state.chainId}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
        const keypadOwnCollectionTradesDataResponse = await keypadOwnCollectionTradesDataFetch.json();
        if (keypadOwnCollectionTradesDataResponse && !keypadOwnCollectionTradesDataResponse.error) {
          commit('setKeypadOwnCollectionTradesData', keypadOwnCollectionTradesDataResponse);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getOwnKeypadCollection({ commit, state, dispatch }: any) {
      try {
        const keypadCollectionDataFetch = await fetch(`${API_URL}/keypad/get_keypad_collection/${state.wallet}/${state.chainId}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
        const keypadCollectionDataResponse = await keypadCollectionDataFetch.json();
        if (keypadCollectionDataResponse && !keypadCollectionDataResponse.error) {
          commit('setKeypadCollectionData', keypadCollectionDataResponse);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getTwitterData({ commit, state, dispatch }: any) {
      try {
        const twitterDataFetch = await fetch(`${API_URL}/keypad/get_twitter_data/${state.wallet}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
        const twitterDataResponse = await twitterDataFetch.json();
        if (twitterDataResponse && !twitterDataResponse.error) {
          commit('setTwitterData', twitterDataResponse);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getInfluencerWizard({ commit, state, dispatch }: any) {
      try {
        const influencerWizardFetch = await fetch(`${API_URL}/keypad/influencer_wizard/${state.wallet}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
        const influencerWizardResponse = await influencerWizardFetch.json();
        if (influencerWizardResponse) {
          commit('setInfluencerWizardData', influencerWizardResponse);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getAllParcels({ commit, state, dispatch }: any) {
      try {
        const allParcelsFetch = await fetch(`${API_URL}/keypad/get_all_parcel_ids/${state.wallet}/${state.chainId}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
        const allParcelsResponse = await allParcelsFetch.json();
        if (allParcelsResponse && !allParcelsResponse.error) {
          commit('setAllParcelsData', allParcelsResponse);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getDystoBuyTransactionsByBuyerAndChainId({ commit, state, dispatch }: any) {
      try {
        const dystoBuyTransactionsByBuyerFetch = await fetch(`${API_URL}/keypad/dysto_buy_transactions/${state.wallet}/${state.chainId}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
        const dystoBuyTransactionsByBuyerResponse = await dystoBuyTransactionsByBuyerFetch.json();
        if (dystoBuyTransactionsByBuyerResponse && !dystoBuyTransactionsByBuyerResponse.error) {
          commit('setDystoBuyTransactionsData', dystoBuyTransactionsByBuyerResponse);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getKeypadTradesBoughSoldByTraderAndChainId({ commit, state, dispatch }: any) {
      try {
        const keypadTradesBoughSoldByTraderFetch = await fetch(`${API_URL}/keypad/keypad_trades_bought_sold/${state.wallet}/${state.chainId}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
        const keypadTradesBoughSoldByTraderResponse = await keypadTradesBoughSoldByTraderFetch.json();
        if (keypadTradesBoughSoldByTraderResponse && !keypadTradesBoughSoldByTraderResponse.error) {
          commit('setKeypadTradesBoughSoldData', keypadTradesBoughSoldByTraderResponse);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchTopFiveInfluencers({ commit, state, dispatch }: any) {
      try {
        const topFiveInfluencersFetch = await fetch(`${API_URL}/keypad/top_five_influencers/${state.chainId}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
        const topFiveInfluencersResponse = await topFiveInfluencersFetch.json();
        if (topFiveInfluencersResponse && !topFiveInfluencersResponse.error) {
          commit('setKeypadTopFiveInfluencers', topFiveInfluencersResponse);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async checkFreeLandReservedOrMinted({ commit, state, dispatch }: any) {
      try {
        const checkIfUserHadAlreadyReservedOrMintedFreeLand =
          await fetch(`${API_URL}/keypad/free_land_reserved_or_minted`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${await dispatch('returnToken')}`,
            },
            body: JSON.stringify({
              chainId: state.chainId,
            }),
          });

        const res = await checkIfUserHadAlreadyReservedOrMintedFreeLand.json();
        if (res && !res.error) {
          commit('setFreeLandReservedOrMinted', res.status);
        } else {
          commit('setFreeLandReservedOrMinted', true);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async checkFreeLandWhitelisted({ commit, state, dispatch }: any) {
      try {
        const checkIfUserHadWhitelistedFreeLand =
          await fetch(`${API_URL}/keypad/free_land_is_whitelisted`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${await dispatch('returnToken')}`,
            },
            body: JSON.stringify({
              chainId: state.chainId,
            }),
          });

        const res = await checkIfUserHadWhitelistedFreeLand.json();
        if (res && !res.error) {
          commit('setFreeLandWhitelisted', res.status);
        } else {
          commit('setFreeLandWhitelisted', true);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getParcels({ commit, state }: any) {
      try {
        const response = await fetch(`${API_URL}/parcels`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });

        const parseRes = await response.json();

        // const parcels = parseRes.map((res: any) => {
        //   return {
        //     ...res,
        //     discord: !res.discord ? "" : res.discord,
        //     opensea: !res.opensea ? "" : res.opensea,
        //     lookshare: !res.lookshare ? "" : res.lookshare,
        //   };
        // });

        commit('setParcels', parseRes);
      } catch (err) {
        console.log(err);
      }
    },
    async getFeaturedCollections({ commit, state, dispatch }: any) {
      try {
        const token = await dispatch('returnToken');
        const response = await fetch(
          `${API_URL}/collections/featured?mintingPhase=${this.state.launchpad.mintingPhase}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${token}`,
            },
          }
        );

        const parseRes = await response.json();

        commit('setCollectionsByCategory', {
          category: 'featured',
          data: parseRes,
        });
      } catch (err) {
        console.log(err);
      }
    },
    async getHottestCollections({ commit, state, dispatch }: any) {
      try {
        const token = await dispatch('returnToken');
        const response = await fetch(
          `${API_URL}/collections/hottest?mintingPhase=${this.state.launchpad.mintingPhase}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${token}`,
            },
          }
        );

        const parseRes = await response.json();

        commit('setCollectionsByCategory', {
          category: 'hottest',
          data: parseRes,
        });
      } catch (err) {
        console.log(err);
      }
    },
    async getNewestCollections({ commit, state, dispatch }: any) {
      try {
        const token = await dispatch('returnToken');
        const response = await fetch(
          `${API_URL}/collections/newest?mintingPhase=${this.state.launchpad.mintingPhase}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${token}`,
            },
          }
        );

        const parseRes = await response.json();

        commit('setCollectionsByCategory', {
          category: 'newest',
          data: parseRes,
        });
      } catch (err) {
        console.log(err);
      }
    },
    async getHomeCollection({ commit, state }: any) {
      try {
        const response = await fetch(`${API_URL}/collections/home`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const parseRes = await response.json();

        commit('setHomeCollection', {
          data: parseRes,
        });
      } catch (err) {
        console.log(err);
      }
    },
    async getUpcomingCollections({ commit, state, dispatch }: any) {
      try {
        const token = await dispatch('returnToken');
        const response = await fetch(`${API_URL}/collections/upcoming`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
          },
        });

        const parseRes = await response.json();

        commit('setCollectionsByCategory', {
          category: 'upcoming',
          data: parseRes,
        });
      } catch (err) {
        console.log(err);
      }
    },
    async getSoldOutCollections({ commit, state, dispatch }: any) {
      try {
        const token = await dispatch('returnToken');
        const response = await fetch(`${API_URL}/collections/soldout`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
          },
        });

        const parseRes = await response.json();

        commit('setCollectionsByCategory', {
          category: 'soldout',
          data: parseRes,
        });
      } catch (err) {
        console.log(err);
      }
    },
    async getLandlordStakingInfo({ commit, state, dispatch }: any) {
      commit('setWaitingForApi', true);
      try {
        const token = await dispatch('returnToken');
        const urlParams = new URLSearchParams({ chainId: state.chainId });
        const [parcelsResponse, stakedNFTsInfo] = await Promise.all([
          fetch(`${API_URL}/landlord-staking/parcels?` + urlParams, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${token}`,
            },
          }),
          fetch(`${API_URL}/landlord-staking/nfts?` + urlParams, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${token}`,
            },
          }),
        ]);

        const parseParcelsResponse = await parcelsResponse.json();
        const parseStakedNFTsInfo = await stakedNFTsInfo.json();

        commit('setStakedParcels', parseParcelsResponse.stakedParcels);
        commit('setUnstakedParcels', parseParcelsResponse.unstakedParcels);
        if (parseStakedNFTsInfo?.authenticated !== false)
          commit('setStakedNFTs', parseStakedNFTsInfo);
        commit('setWaitingForApi', false);
      } catch (err) {
        console.error(err);
      }
      commit('setWaitingForApi', false);
    },
    // return a Promise of an object of type
    // {
    //   activePoolRewards: PoolReward[];
    //   pastPoolRewards: PoolReward[];
    // }
    async getPoolRewards({ commit, state, dispatch }: any): Promise<{
      activePoolRewards: PoolReward[];
      pastPoolRewards: PoolReward[];
    }> {
      if (state.chainId === 0)
        return { activePoolRewards: [], pastPoolRewards: [] };
      commit('setWaitingForApi', true);
      try {
        const token = await dispatch('returnToken');
        const urlParams = new URLSearchParams({
          chainId: state.chainId,
          searchName: state.landlordStaking.poolRewardsSearchName,
        });
        const poolRewardsResponse = await fetch(
          `${API_URL}/landlord-staking/pool-rewards?` + urlParams,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${token}`,
            },
          }
        );

        const parsePoolRewardsResponse = await poolRewardsResponse.json();

        if (parsePoolRewardsResponse?.authenticated !== false) {
          commit(
            'setActivePoolRewards',
            parsePoolRewardsResponse.activePoolRewards
          );
          commit(
            'setPastPoolRewards',
            parsePoolRewardsResponse.pastPoolRewards
          );
        }
        commit('setWaitingForApi', false);
        return {
          activePoolRewards: parsePoolRewardsResponse.activePoolRewards,
          pastPoolRewards: parsePoolRewardsResponse.pastPoolRewards,
        };
      } catch (err) {
        console.error(err);
        return {
          activePoolRewards: [],
          pastPoolRewards: [],
        };
      }
    },
    clearLandlordStakingInfo({ commit }: any) {
      commit('setWaitingForApi', true);
      commit('setStakedParcels', []);
      commit('setUnstakedParcels', []);
      commit('setActivePoolRewards', []);
      commit('setPastPoolRewards', []);
      commit('setStakedNFTs', []);
      commit('setCurrentPoolReward', null);
    },
    async getNFTsOwned(
      { commit, state, dispatch }: any,
      collectionAddresses: string[]
    ) {
      commit('setWaitingForOpenSeaApi', true);
      let retries = 0;
      while (retries < 3) {
        retries++;
        try {
          const token = await dispatch('returnToken');
          const params = new URLSearchParams({ chainId: state.chainId });
          collectionAddresses.forEach((collectionAddress) =>
            params.append('asset_contract_addresses', collectionAddress)
          );
          const nftsOwnedResponse = await fetch(
            `${API_URL}/landlord-staking/assets-owned?` + params,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`,
              },
            }
          );

          if (nftsOwnedResponse.status === 401) {
            localStorage.removeItem('auth_token');
            continue;
          }

          const parseNFTsOwnedResponse = await nftsOwnedResponse.json();

          commit('setNFTsOwned', parseNFTsOwnedResponse);
          commit('setWaitingForOpenSeaApi', false);
          return;
        } catch (err) {
          commit('setWaitingForOpenSeaApi', false);
          console.log(err);
        }
      }
    },
    async clearNFTsOwned({ commit }: any) {
      commit('setNFTsOwned', []);
      return;
    },
    async getKeypadCollections({ commit, state, dispatch }: any) {
      try {
        let url = `${API_URL}/keypad/get_keypad_collections/${state.chainId}`;
        if (state.keypad.collectionSearchName && state.keypad.collectionSearchName.trim() !== '') {
          url += `/${encodeURIComponent(state.keypad.collectionSearchName.trim())}`;
        }

        const keypadCollectionsFetch = await fetch(url, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
        const keypadCollectionsDataResponse = await keypadCollectionsFetch.json();

        if (keypadCollectionsDataResponse && !keypadCollectionsDataResponse.error) {
          for (let i = 0; i < keypadCollectionsDataResponse.length; i++) {
            const collection = keypadCollectionsDataResponse[i];
            try {
              const priceForOneShare = await state.dystoWorldKeypadContract.methods.getBuyPriceAfterFee(collection.user_wallet_address, 1).call();
              collection.priceForOneShare = new BigNumber(formatEther(priceForOneShare.toString())).toFixed(3);
            } catch (error) {
              console.log('Error while taking share price balance ', error);
              collection.priceForOneShare = '0';
            }
          }

          commit('setKeypadCollections', keypadCollectionsDataResponse);
          commit('setKeypadCollectionsData', keypadCollectionsDataResponse);
          if (keypadCollectionsDataResponse.length >= 1 && !state.keypad.currentCollection) {
            commit('setKeypadCurrentCollection', keypadCollectionsDataResponse[0]);
          } else {
            const matchingCollection = keypadCollectionsDataResponse.find((collection: { name: any; }) =>
              collection && collection.name === state.keypad?.currentCollection?.name);

            if (matchingCollection) {
              commit('setKeypadCurrentCollection', matchingCollection);
            }
          }
        }
      } catch (err) {
        console.error('Error fetching keypad collections:', err);
      }
    },
    async getCollections({ commit, state, dispatch }: any) {
      try {
        const token = await dispatch('returnToken');
        const [
          featuredCollectionsResponse,
          hottestCollectionsResponse,
          newestCollectionsResponse,
          allCollectionsResponse,
        ] = await Promise.all([
          fetch(
            `${API_URL}/collections/featured?mintingPhase=${this.state.launchpad.mintingPhase}&searchName=${this.state.launchpad.collectionSearchName}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`,
              },
            }
          ),
          fetch(
            `${API_URL}/collections/hottest?mintingPhase=${this.state.launchpad.mintingPhase}&searchName=${this.state.launchpad.collectionSearchName}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`,
              },
            }
          ),
          fetch(
            `${API_URL}/collections/newest?mintingPhase=${this.state.launchpad.mintingPhase}&searchName=${this.state.launchpad.collectionSearchName}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`,
              },
            }
          ),
          fetch(
            `${API_URL}/collections?mintingPhase=${this.state.launchpad.mintingPhase}&searchName=${this.state.launchpad.collectionSearchName}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`,
              },
            }
          ),
        ]);

        const parseFeaturedCollectionsRes =
          await featuredCollectionsResponse.json();
        const parseHottestCollectionsRes =
          await hottestCollectionsResponse.json();
        const parseNewestCollectionsRes =
          await newestCollectionsResponse.json();
        const parseAllCollectionsRes = await allCollectionsResponse.json();

        commit('setCollections', {
          featured: parseFeaturedCollectionsRes,
          hottest: parseHottestCollectionsRes,
          newest: parseNewestCollectionsRes,
          all: parseAllCollectionsRes,
        });
      } catch (err) {
        console.log(err);
      }
    },
    async getMintParamsForLaunchpadCollection(
      { commit, state, dispatch }: any,
      data: any
    ) {
      try {
        const mintParamsResponse = await fetch(
          `${API_URL}/launchpad/get-mint-params?` + new URLSearchParams(data),
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${await dispatch('returnToken')}`,
            },
          }
        );
        if (mintParamsResponse && mintParamsResponse.ok) {
          return await mintParamsResponse.json();
        } else {
          const errorResponse = await mintParamsResponse.json();
          commit(
            'app/alterNotifications',
            { text: errorResponse.message, type: 'danger', add: true },
            { root: true }
          );
          return null;
        }
      } catch (err) {
        console.error(err);
      }
    },
    async getERC20Balance({ commit, state, dispatch }: any, data: any) {
      try {
        commit('setWaitForConfirmationTrigger', true);
        const erc20Contract = new state.web3.eth.Contract(
          GENERIC_ERC20_ABI,
          data.tokenContractAddress
        );

        const balance: BigNumber = await erc20Contract.methods
          .balanceOf(state.wallet)
          .call()
          .then((el: any) => new BigNumber(el))
          .catch((err: any) => {
            console.error(err);
            return new BigNumber(0);
          });

        commit('setWaitForConfirmationTrigger', false);
        return balance;
      } catch (err) {
        commit('setWaitForConfirmationTrigger', false);
        console.error('Error when getting ERC20 balance', err);
        return new BigNumber(0);
      }
    },
    async getERC20Allowance({ commit, state, dispatch }: any, data: any) {
      try {
        commit('setWaitForConfirmationTrigger', true);

        const erc20Contract = new state.web3.eth.Contract(
          GENERIC_ERC20_ABI,
          data.tokenContractAddress
        );
        const allowance: BigNumber = await erc20Contract.methods
          .allowance(state.wallet, data.collectionAddress)
          .call()
          .then((el: any) => new BigNumber(el))
          .catch((err: any) => {
            console.error(err);
            return new BigNumber(0);
          });

        commit('setWaitForConfirmationTrigger', false);
        return allowance;
      } catch (err) {
        commit('setWaitForConfirmationTrigger', false);
        console.error(err);
        return new BigNumber(0);
      }
    },
    async approveTransferOfERC20Tokens(
      { commit, state, dispatch }: any,
      data: any
    ) {
      try {
        commit('setWaitForConfirmationTrigger', true);
        const erc20Contract = new state.web3.eth.Contract(
          GENERIC_ERC20_ABI,
          data.tokenContractAddress
        );
        const approveTxListener = erc20Contract.methods
          .approve(data.collectionAddress, data.price)
          .send({ from: state.wallet }, (err: any, transactionHash: any) => {
            if (err) {
              console.error(err);
              commit('setWaitForConfirmationTrigger', false);
              return;
            }
          })
          .on('confirmation', (confirmationNumber: number, receipt: any) => {
            handleConfirmation(confirmationNumber, receipt);
          });

        const handleConfirmation = async (
          confirmationNumber: number,
          receipt: any
        ): Promise<void> => {
          if (confirmationNumber !== MAX_CONFIRMATIONS) {
            return;
          }
          commit('setWaitForConfirmationTrigger', false);
          approveTxListener.off('confirmation');
        };
      } catch (err) {
        commit('setWaitForConfirmationTrigger', false);
        console.error(err);
      }
    },
    incrementMintForCollection({ commit, state }: any, data: any) {
      const newLaunchpadCollections = { ...state.launchpad.collections };

      let currentCollection = null;
      const { collectionAddress, numTokens, assetId } = data;

      Object.keys(newLaunchpadCollections).forEach((key: any) => {
        newLaunchpadCollections[key] = newLaunchpadCollections[key].map(
          (collection: any) => {
            if (collection.contractAddress === collectionAddress) {
              collection.lastTokenId += numTokens;
              currentCollection = collection;

              collection.assets = collection.assets.map((asset: any) => {
                if (
                  asset.id === assetId &&
                  asset.layoutOptions &&
                  asset.layoutOptions.isWhitelisted === true
                ) {
                  asset.layoutOptions.numTokensOptions =
                    asset.layoutOptions.numTokensOptions.slice(
                      0,
                      Math.max(
                        asset.layoutOptions.numTokensOptions.length - numTokens,
                        0
                      )
                    );

                  if (asset.layoutOptions.numTokensOptions.length === 0) {
                    asset.layoutOptions.numTokensOptions = [0];
                  }

                  asset.layoutOptions.numTokensMinted += numTokens;
                }
                return asset;
              });
            }
            return collection;
          }
        );
      });

      commit(
        'app/alterNotifications',
        {
          text: `Successfully minted ${numTokens} token${
            numTokens > 1 ? 's' : ''
          }`,
          type: 'success',
          add: true,
        },
        { root: true }
      );

      commit('setCollections', newLaunchpadCollections);
      commit('setCurrentCollection', currentCollection);
    },
    async mintTokensForLaunchpadCollection(
      { commit, state, dispatch }: any,
      data: any
    ) {
      try {
        commit('setWaitForConfirmationTrigger', true);
        commit('setMintingInProgress', true);

        const collectionContract = new state.web3.eth.Contract(
          data.functionAbi,
          data.collectionAddress
        );

        const paramNames = data.functionAbi
          .find(
            ({ name }: { name: string }) =>
              name === data.functionNameWithParams.split('(')[0]
          )
          .inputs.map(({ name: paramName }: { name: string }) =>
            paramName.startsWith('_') ? paramName.slice(1) : paramName
          );

        const params = data.mintParams
          .filter(({ key }: { key: string }) => paramNames.includes(key))
          .map((param: any) => param.value);

        const mintTxListener = collectionContract.methods[
          data.functionNameWithParams
        ](...params)
          .send(
            {
              from: state.wallet,
              value:
                data.tokenContractAddress === ADDRESS_ZERO ? data.price : 0,
            },
            (err: any, transactionHash: any) => {
              if (err) {
                console.error(err);
                commit('setWaitForConfirmationTrigger', false);
                commit('setMintingInProgress', false);
                return;
              }
            }
          )
          .on('confirmation', (confirmationNumber: number, receipt: any) => {
            handleConfirmation(confirmationNumber, receipt);
          });

        mintTxListener.on('error', (err: any) => {
          console.error(err);
          commit('setWaitForConfirmationTrigger', false);
          commit('setMintingInProgress', false);
        });

        const handleConfirmation = async (
          confirmationNumber: number,
          receipt: any
        ): Promise<void> => {
          if (confirmationNumber !== MAX_CONFIRMATIONS) {
            return;
          }

          commit('setWaitForConfirmationTrigger', false);
          commit('setMintingInProgress', false);
          mintTxListener.off('confirmation');
          // increment the number of tokens minted
          const numTokens =
            data.mintParams.find(
              ({ key }: { key: string }) => key === 'numTokens'
            )?.value || 1;
          dispatch('incrementMintForCollection', {
            collectionAddress: data.collectionAddress,
            numTokens,
            assetId: data.assetId,
          });
        };
      } catch (err) {
        commit('setWaitForConfirmationTrigger', false);
        commit('setMintingInProgress', false);
        console.error(err);
      }
    },
    async Reserve({ dispatch, state, commit }: any, shouldMintFree: boolean) {
      commit('app/setLoaders', { ...state.app.loaders, reserve: true });
      const details = {
        startX: state.app.parcel_coordinates.x,
        startY: state.app.parcel_coordinates.y,
        size: state.app.mintingParcels.blocks.value,
        owner: state.user.address,
        chainId: state.chainId,
        shouldMintFree: shouldMintFree,
      };

      let retries = 0;
      while (retries < 3) {
        retries++;

        try {
          const token = await dispatch('returnToken');

          if (!token) {
            commit(
              'app/alterNotifications',
              {
                text: 'In order to reserve please sign the prompted message',
                type: 'info',
                add: true,
              },
              { root: true }
            );
            commit('app/setLoaders', { ...state.app.loaders, reserve: false });
            return;
          }

          const response = await fetch(`${API_URL}/parcels`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(details),
          });

          if (!response.ok) {
            if (response.status === 403 || response.status === 401) {
              throw { ...(await response.json()), status: response.status };
            } else throw await response.json();
          }

          const parseRes = await validateResponse(response);

          state.parcels.push([
            null,
            {
              ...parseRes,
              reservationId: parseRes.transactionId,
              state: 'RESERVED',
            },
          ]);
          return;
        } catch (err: any) {
          console.log(err);
          if (err === 4001) {
            console.log(err);
            return;
          }
          if (retries === 2) {
            commit('app/setLoaders', { ...state.app.loaders, reserve: false });
            commit(
              'app/alterNotifications',
              { text: err.message, type: 'danger', add: true },
              { root: true }
            );
          }
          if (err === 'TOKEN' || err.status === 403 || err.status === 401) {
            localStorage.removeItem('auth_token');
            continue;
          }
        }
      }
    },
    async mintParcel({ commit, state, dispatch }: any, parcel: any) {
      commit('app/setLoaders', {
        ...state.app.loaders,
        mint: { id: parcel.reservationId, bool: true },
      });

      let retries = 0;
      while (retries < 3) {
        retries++;

        try {
          const token = await dispatch('returnToken');

          if (!token) {
            commit(
              'app/alterNotifications',
              {
                text: 'In order to mint the parcel please sign the prompted message',
                type: 'info',
                add: true,
              },
              { root: true }
            );
            commit('app/setLoaders', {
              ...state.app.loaders,
              mint: { id: '', bool: false },
            });
            return;
          }

          const response = await fetch(
            `${API_URL}/parcels/reservationdetails?reservationId=${parcel.reservationId}`,
            {
              method: 'GET',
              headers: {
                authorization: `Bearer ${await dispatch('returnToken')}`,
              },
            }
          );

          if (!response.ok) {
            if (response.status === 403 || response.status === 401) {
              throw { ...(await response.json()), status: response.status };
            } else throw await response.json();
          }

          const parseRes = await validateResponse(response);

          const {
            issued,
            expiration,
            size,
            transactionId,
            chainId,
            signature,
          } = parseRes;

          const price: BigNumber | undefined =
            await state.dystoWorldPortalContract.methods
              .price()
              .call()
              .then((el: any) => {
                return new BigNumber(el);
              })
              .catch((err: Error) => {
                console.log(err);
              });

          if (!price) {
            commit(
              'app/alterNotifications',
              {
                text: `In order to mint the parcel please choose the right chain (${ETHEREUM_NETWORK_NAME}).`,
                type: 'danger',
                add: true,
              },
              { root: true }
            );
            return;
          }

          const numberOfParcels = size * size;
          let valueBN: BigNumber = price.multipliedBy(numberOfParcels);

          console.log(1);

          if (numberOfParcels >= 5 && numberOfParcels < 25) {
            valueBN = valueBN.multipliedBy(0.95);
          } else if (numberOfParcels >= 25 && numberOfParcels < 100) {
            valueBN = valueBN.multipliedBy(0.92);
          } else if (numberOfParcels >= 100 && numberOfParcels < 250) {
            valueBN = valueBN.multipliedBy(0.88);
          } else if (numberOfParcels >= 250) {
            valueBN = valueBN.multipliedBy(0.8);
          }

          const value = valueBN.toString();

          const network = getNetworkByChainId(chainId);
          const dystoTokenContract = state.dystoWorldTokenContract;

          const allowance: string = await dystoTokenContract.methods.allowance(state.user.address, network.DYSTO_WORLD_PORTAL_CONTRACT_ADDRESS).call();

          if (new BigNumber(allowance).lt(new BigNumber(value)) && !parcel.shouldMintFree) {
            await dystoTokenContract.methods.approve(network.DYSTO_WORLD_PORTAL_CONTRACT_ADDRESS, value).send({ from: state.wallet });
          }

          state.dystoWorldPortalContract.methods
            .mint(
              issued,
              expiration,
              size * size,
              transactionId,
              chainId,
              parcel.shouldMintFree,
              signature,
            )
            .send(
              {
                from: state.wallet,
              },
              (err: any, transactionHash: any) => {
                if (err) {
                  console.log(err);
                  return;
                }
              }
            );
          // .on("confirmation", (receipt: any) => {});

          return;
        } catch (err: any) {
          if (err === 4001) {
            console.log(err);
            return;
          }
          if (retries === 2) {
            commit(
              'app/alterNotifications',
              { text: err.message, type: 'danger', add: true },
              { root: true }
            );
            commit('app/setLoaders', {
              ...state.app.loaders,
              mint: { id: '', bool: false },
            });
          }
          if (err === 'TOKEN' || err.status === 403 || err.status === 401) {
            localStorage.removeItem('auth_token');
            continue;
          }
        }
      }
    },
    async updateUser({ commit, state, dispatch }: any, details: any) {
      let retries = 0;
      while (retries < 3) {
        retries++;

        try {
          const token = await dispatch('returnToken');

          if (!token) {
            commit(
              'app/alterNotifications',
              {
                text: 'In order to update your profile please sign the prompted message',
                type: 'info',
                add: true,
              },
              { root: true }
            );
            return;
          }

          const form = new FormData();
          let profile = {} as File;
          let banner = {} as File;

          Array.from(details['avatar-img']).forEach((image: any) => {
            profile = image[1];
          });
          Array.from(details['banner-img']).forEach((image: any) => {
            banner = image[1];
          });

          for (const name in details) {
            switch (name) {
              case 'avatar-img':
                form.append(name, profile);
                break;
              case 'banner-img':
                form.append(name, banner);
                break;
              default:
                form.append(name, details[name]);
                break;
            }
          }

          const response = await fetch(
            `${API_URL}/users/${details.address}/uploads`,
            {
              method: 'PUT',
              headers: {
                authorization: `Bearer ${await dispatch('returnToken')}`,
              },
              body: form,
            }
          );

          if (!response.ok) {
            if (response.status === 403 || response.status === 401) {
              throw { ...(await response.json()), status: response.status };
            } else throw await response.json();
          }

          const parseRes = await validateResponse(response);

          commit(
            'app/alterNotifications',
            {
              text: 'You successfully updated your information',
              type: 'success',
              add: true,
            },
            { root: true }
          );

          commit('setUser', parseRes);

          return;
        } catch (err: any) {
          if (err === 4001) {
            console.log(err);
            return;
          }
          if (retries === 2) {
            commit(
              'app/alterNotifications',
              { text: err.message, type: 'danger', add: true },
              { root: true }
            );
          }
          if (err === 'TOKEN' || err.status === 403 || err.status === 401) {
            localStorage.removeItem('auth_token');
            continue;
          }
        }
      }
    },
    async updateParcelMulti({ commit, state, dispatch }: any, details: any) {
      let retries = 0;
      while (retries < 3) {
        retries++;

        try {
          const token = await dispatch('returnToken');

          if (!token) {
            commit(
              'app/alterNotifications',
              {
                text: 'In order to update parcels please sign the prompted message',
                type: 'info',
                add: true,
              },
              { root: true }
            );
            return;
          }

          const form = new FormData();
          let image = {} as File;

          Array.from(details['image']).forEach((_image: any) => {
            image = _image[1];
          });

          for (const name in details) {
            switch (name) {
              case 'image':
                form.append('logo-img', image);
                break;
              default:
                form.append(name, details[name]);
                break;
            }
          }

          const response = await fetch(
            `${API_URL}/parcels/${details.id}/logo`,
            {
              method: 'PUT',
              headers: {
                authorization: `Bearer ${await dispatch('returnToken')}`,
              },
              body: form,
            }
          );

          if (!response.ok) {
            if (response.status === 403 || response.status === 401) {
              throw { ...(await response.json()), status: response.status };
            } else throw await response.json();
          }

          const parseRes = await validateResponse(response);

          commit(
            'app/alterNotifications',
            {
              text: 'You successfully updated the parcel',
              type: 'success',
              add: true,
            },
            { root: true }
          );

          const parcel = {
            ...parseRes,
            state: 'MINTED',
            open: true,
            timestamp: new Date().getTime(),
          };
          commit('updateParcels', parcel);

          return;
        } catch (err: any) {
          if (err === 4001) {
            console.log(err);
            return;
          }
          if (retries === 2) {
            commit(
              'app/alterNotifications',
              { text: err.message, type: 'danger', add: true },
              { root: true }
            );
          }
          if (err === 'TOKEN' || err.status === 403 || err.status === 401) {
            localStorage.removeItem('auth_token');
            continue;
          }
        }
      }
    },
    async updateParcel({ commit, state, dispatch }: any, details: any) {
      let retries = 0;
      while (retries < 3) {
        retries++;

        try {
          const token = await dispatch('returnToken');

          if (!token) {
            commit(
              'app/alterNotifications',
              {
                text: 'In order to update parcels please sign the prompted message',
                type: 'info',
                add: true,
              },
              { root: true }
            );
            return;
          }

          const response = await fetch(`${API_URL}/parcels/${details.id}`, {
            method: 'PUT',
            headers: {
              authorization: `Bearer ${await dispatch('returnToken')}`,
            },
            body: JSON.stringify(details),
          });

          if (!response.ok) {
            if (response.status === 403 || response.status === 401) {
              throw { ...(await response.json()), status: response.status };
            } else throw await response.json();
          }

          const parseRes = await validateResponse(response);

          commit(
            'app/alterNotifications',
            {
              text: 'You successfully updated the parcel',
              type: 'success',
              add: true,
            },
            { root: true }
          );

          const parcel = {
            ...parseRes,
            state: 'MINTED',
            open: true,
            timestamp: new Date().getTime(),
          };
          commit('updateParcels', parcel);

          return;
        } catch (err: any) {
          if (err === 4001) {
            console.log(err);
            return;
          }
          if (retries === 2) {
            commit(
              'app/alterNotifications',
              { text: err.message, type: 'danger', add: true },
              { root: true }
            );
          }
          if (err === 'TOKEN' || err.status === 403 || err.status === 401) {
            localStorage.removeItem('auth_token');
            continue;
          }
        }
      }
    },
    async mergeParcel({ commit, state, dispatch }: any, cell) {
      const data = {
        parcels: cell.CellsIds,
        templateParcel: cell.CellsIds[0],
      };

      let retries = 0;
      while (retries < 3) {
        retries++;

        try {
          const token = await dispatch('returnToken');

          if (!token) {
            commit(
              'app/alterNotifications',
              {
                text: 'In order to merge parcels please sign the prompted message',
                type: 'info',
                add: true,
              },
              { root: true }
            );
            return;
          }

          const response = await fetch(`${API_URL}/parcels/merge`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${await dispatch('returnToken')}`,
            },
            body: JSON.stringify(data),
          });

          if (!response.ok) {
            if (response.status === 403 || response.status === 401) {
              throw { ...(await response.json()), status: response.status };
            } else throw await response.json();
          }

          const parseRes = await validateResponse(response);

          commit(
            'app/alterNotifications',
            {
              text: 'You successfully merged the parcels',
              type: 'success',
              add: true,
            },
            { root: true }
          );

          for (const i in cell.CellsIds) {
            const found = state.parcels.findIndex(
              ([_, parcel]: any) => parcel.id === cell.CellsIds[i]
            );
            state.parcels.splice(found, 1);
          }

          state.parcels.push([
            null,
            { ...parseRes, state: 'MINTED', children: null },
          ]);

          return;
        } catch (err: any) {
          if (err === 4001) {
            console.log(err);
            return;
          }
          if (retries === 2) {
            commit(
              'app/alterNotifications',
              { text: err.message, type: 'danger', add: true },
              { root: true }
            );
          }
          if (err === 'TOKEN' || err.status === 403 || err.status === 401) {
            localStorage.removeItem('auth_token');
            continue;
          }
        }
      }
    },
    async splitParcel({ commit, state, dispatch }: any, cell) {
      const data = {
        id: cell.id,
        targetSize: cell.splitOption.value,
      };

      let retries = 0;
      while (retries < 3) {
        retries++;

        try {
          const token = await dispatch('returnToken');

          if (!token) {
            commit(
              'app/alterNotifications',
              {
                text: 'In order to split parcels please sign the prompted message',
                type: 'info',
                add: true,
              },
              { root: true }
            );
            return;
          }

          const response = await fetch(`${API_URL}/parcels/split`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${await dispatch('returnToken')}`,
            },
            body: JSON.stringify(data),
          });

          if (!response.ok) {
            if (response.status === 403 || response.status === 401) {
              throw { ...(await response.json()), status: response.status };
            } else throw await response.json();
          }

          const parseRes = await validateResponse(response);

          const found = state.parcels.findIndex(
            ([_, parcel]: any) => parcel.id === cell.id
          );
          state.parcels.splice(found, 1);

          commit(
            'app/alterNotifications',
            {
              text: 'You successfully split the parcel',
              type: 'success',
              add: true,
            },
            { root: true }
          );

          for (const parcel of parseRes) {
            state.parcels.push([null, { ...parcel, state: 'MINTED' }]);
          }

          return;
        } catch (err: any) {
          if (err === 4001) {
            console.log(err);
            return;
          }
          if (retries === 2) {
            commit(
              'app/alterNotifications',
              { text: err.message, type: 'danger', add: true },
              { root: true }
            );
          }
          if (err === 'TOKEN' || err.status === 403 || err.status === 401) {
            localStorage.removeItem('auth_token');
            continue;
          }
        }
      }
    },
    async checkApprovalForTransferOfParcels({ commit, state, dispatch }: any) {
      if (!state.dystoWorldPortalContract) return;
      try {
        const hasApproval: boolean =
          await state.dystoWorldPortalContract.methods
            .isApprovedForAll(
              state.wallet,
              state.dystoWorldLandlordStakingContract.options.address
            )
            .call()
            .then((el: any) => {
              return el;
            })
            .catch((err: Error) => {
              console.log(err);
            });

        commit('setApprovalForTransferOfParcels', hasApproval);
      } catch (err) {
        console.log(err);
      }
    },
    async approveTransferOfParcels({ commit, state }: any) {
      try {
        commit('setWaitForConfirmationTrigger', true);
        const getApprovalListener = state.dystoWorldPortalContract.methods
          .setApprovalForAll(
            state.dystoWorldLandlordStakingContract.options.address,
            true
          )
          .send(
            {
              from: state.wallet,
            },
            (err: any, transactionHash: any) => {
              if (err) {
                console.log(err);
                commit('setApprovalForTransferOfParcels', false);
                commit('setWaitForConfirmationTrigger', false);
                return;
              }
            }
          )
          .on('confirmation', (confirmationNumber: number, receipt: any) =>
            handleConfirmation(confirmationNumber, receipt)
          );

        const handleConfirmation = (
          confirmationNumber: number,
          receipt: any
        ): void => {
          if (confirmationNumber !== MAX_CONFIRMATIONS) {
            return;
          }

          commit('setApprovalForTransferOfParcels', true);
          commit('setWaitForConfirmationTrigger', false);
          getApprovalListener.off('confirmation');
        };
      } catch (err) {
        commit('setApprovalForTransferOfParcels', false);
        commit('setWaitForConfirmationTrigger', false);
        console.log(err);
      }
    },
    async stakeParcel({ commit, state, dispatch }: any, parcel: number) {
      try {
        commit('setWaitForConfirmationTrigger', true);

        const stakedTxListener = state.dystoWorldLandlordStakingContract.methods
          .stakeParcel(parcel)
          .send(
            {
              from: state.wallet,
            },
            (err: any, transactionHash: any) => {
              if (err) {
                console.log(err);
                commit('setWaitForConfirmationTrigger', false);
                return;
              }
            }
          )
          .on('confirmation', (confirmationNumber: number, receipt: any) => {
            handleConfirmation(confirmationNumber, receipt);
          });

        const handleConfirmation = async (
          confirmationNumber: number,
          receipt: any
        ): Promise<void> => {
          if (confirmationNumber !== MAX_CONFIRMATIONS) {
            return;
          }

          await dispatch('getLandlordStakingInfo');
          commit('setWaitForConfirmationTrigger', false);

          // remove the listener
          stakedTxListener.off('confirmation');
        };
      } catch (err) {
        console.log(err);
        commit('setWaitForConfirmationTrigger', false);
      }
    },
    async stakeBulkParcels(
      { commit, state, dispatch }: any,
      parcels: number[]
    ) {
      try {
        if (parcels.length === 0) {
          return;
        }
        commit('setWaitForConfirmationTrigger', true);

        const startTokenIds: number[] = [];
        const numTokensArray: number[] = [];

        // sort the parcels by tokenId
        parcels.sort((a: number, b: number) => a - b);
        // create chunks for nearby tokens
        let _startTokenId = parcels[0];
        let _numTokens = 1;
        for (let i = 1; i < parcels.length; i++) {
          if (parcels[i] - parcels[i - 1] === 1) {
            _numTokens++;
          } else {
            startTokenIds.push(_startTokenId);
            numTokensArray.push(_numTokens);
            _startTokenId = parcels[i];
            _numTokens = 1;
          }
        }
        startTokenIds.push(_startTokenId);
        numTokensArray.push(_numTokens);

        const stakeParcelsListener =
          state.dystoWorldLandlordStakingContract.methods
            .stakeBulkParcels(startTokenIds, numTokensArray)
            .send(
              {
                from: state.wallet,
              },
              (err: any, transactionHash: any) => {
                if (err) {
                  console.log(err);
                  commit('setWaitForConfirmationTrigger', false);
                  return;
                }
              }
            )
            .on('confirmation', (confirmationNumber: number, receipt: any) =>
              handleConfirmation(confirmationNumber, receipt)
            );

        const handleConfirmation = async (
          confirmationNumber: number,
          receipt: any
        ): Promise<void> => {
          if (confirmationNumber !== MAX_CONFIRMATIONS) {
            return;
          }

          await dispatch('getLandlordStakingInfo');
          commit('setWaitForConfirmationTrigger', false);

          // remove the listener
          stakeParcelsListener.off('confirmation');
        };
      } catch (err) {
        console.log(err);
        commit('setWaitForConfirmationTrigger', false);
      }
    },
    async unstakeParcel({ commit, state, dispatch }: any, parcel: number) {
      try {
        commit('setWaitForConfirmationTrigger', true);

        const unstakeParcelListener =
          state.dystoWorldLandlordStakingContract.methods
            .unstakeParcel(parcel)
            .send(
              {
                from: state.wallet,
              },
              (err: any, transactionHash: any) => {
                if (err) {
                  console.log(err);
                  commit('setWaitForConfirmationTrigger', false);
                  return;
                }
              }
            )
            .on('confirmation', (confirmationNumber: number, receipt: any) =>
              handleConfirmation(confirmationNumber, receipt)
            );

        const handleConfirmation = async (
          confirmationNumber: number,
          receipt: any
        ): Promise<void> => {
          if (confirmationNumber !== MAX_CONFIRMATIONS) {
            return;
          }

          await dispatch('getLandlordStakingInfo');
          commit('setWaitForConfirmationTrigger', false);

          // remove the listener
          unstakeParcelListener.off('confirmation');
        };
      } catch (err) {
        console.log(err);
        commit('setWaitForConfirmationTrigger', false);
      }
    },
    async unstakeBulkParcels(
      { commit, state, dispatch }: any,
      parcels: number[]
    ) {
      try {
        if (parcels.length === 0) {
          return;
        }
        commit('setWaitForConfirmationTrigger', true);

        const startTokenIds: number[] = [];
        const numTokensArray: number[] = [];

        // sort the parcels by tokenId
        parcels.sort((a: number, b: number) => a - b);
        // create chunks for nearby tokens
        let _startTokenId = parcels[0];
        let _numTokens = 1;
        for (let i = 1; i < parcels.length; i++) {
          if (parcels[i] - parcels[i - 1] === 1) {
            _numTokens++;
          } else {
            startTokenIds.push(_startTokenId);
            numTokensArray.push(_numTokens);
            _startTokenId = parcels[i];
            _numTokens = 1;
          }
        }
        startTokenIds.push(_startTokenId);
        numTokensArray.push(_numTokens);

        const stakeParcelsListener =
          state.dystoWorldLandlordStakingContract.methods
            .unstakeBulkParcels(startTokenIds, numTokensArray)
            .send(
              {
                from: state.wallet,
              },
              (err: any, transactionHash: any) => {
                if (err) {
                  console.log(err);
                  commit('setWaitForConfirmationTrigger', false);
                  return;
                }
              }
            )
            .on('confirmation', (confirmationNumber: number, receipt: any) =>
              handleConfirmation(confirmationNumber, receipt)
            );

        const handleConfirmation = async (
          confirmationNumber: number,
          receipt: any
        ): Promise<void> => {
          if (confirmationNumber !== MAX_CONFIRMATIONS) {
            return;
          }

          await dispatch('getLandlordStakingInfo');
          commit('setWaitForConfirmationTrigger', false);

          // remove the listener
          stakeParcelsListener.off('confirmation');
        };
      } catch (err) {
        console.log(err);
        commit('setWaitForConfirmationTrigger', false);
      }
    },
    async unstakeAllParcels({ commit, state, dispatch }: any) {
      try {
        commit('setWaitForConfirmationTrigger', true);

        const unstakeParcelsListener =
          state.dystoWorldLandlordStakingContract.methods
            .unstakeBulkParcels(0, 0)
            .send(
              {
                from: state.wallet,
              },
              (err: any, transactionHash: any) => {
                if (err) {
                  console.log(err);
                  commit('setWaitForConfirmationTrigger', false);
                  return;
                }
              }
            )
            .on('confirmation', (confirmationNumber: number, receipt: any) =>
              handleConfirmation(confirmationNumber, receipt)
            );

        const handleConfirmation = async (
          confirmationNumber: number,
          receipt: any
        ): Promise<void> => {
          if (confirmationNumber !== MAX_CONFIRMATIONS) {
            return;
          }

          await dispatch('getLandlordStakingInfo');
          commit('setWaitForConfirmationTrigger', false);

          // remove the listener
          unstakeParcelsListener.off('confirmation');
        };
      } catch (err) {
        console.log(err);
        commit('setWaitForConfirmationTrigger', false);
      }
    },
    async onStakeSelectedNFTs({ commit, state, dispatch }: any): Promise<void> {
      const stakingInCollections: PoolReward[] = [];

      const nftsSelectedForStaking = state.landlordStaking
        .selectedNFTsForStaking as Record<string, string[]>;

      const numberOfNFTsSelectedForStaking = Object.values(
        nftsSelectedForStaking
      ).reduce((acc, curr) => acc + curr.length, 0);

      if (numberOfNFTsSelectedForStaking == 0) return;

      const activePoolRewards = state.landlordStaking
        .activePoolRewards as PoolReward[];

      for (const collectionAddress in nftsSelectedForStaking) {
        if (nftsSelectedForStaking[collectionAddress].length > 0) {
          const collection = activePoolRewards.find(
            (collection) => collection.collectionAddress === collectionAddress
          );
          // This is somewhat unnecessary, seeing as to how onCollectionsChange only uses .collectionAddress
          // But just in case we change that in the future, as well as to maintain the type requirement, I will push the entire object
          if (collection) stakingInCollections.push(collection);
        }
      }

      let hasApprovalForAll = true;

      // changed forEach with for so the await takes effect and execution waits for dispatch result

      for (let i = 0; i < stakingInCollections.length; i++) {
        const hasApprovalForTransfer = await dispatch(
          'checkApprovalForTransferOfNFTs',
          stakingInCollections[i].collectionAddress
        );
        hasApprovalForAll = hasApprovalForAll && hasApprovalForTransfer;
      }

      // stakingInCollections.forEach(async (collection) => {
      //   const hasApprovalForTransfer = await dispatch(
      //     'checkApprovalForTransferOfNFTs',
      //     collection.collectionAddress
      //   );
      //   hasApprovalForAll = hasApprovalForAll && hasApprovalForTransfer;
      // });

      if (!hasApprovalForAll) {
        commit('app/setModal', {
          component: 'alertMessage',
          payload: {
            title: 'Missing approval for transfer of NFTs',
            message:
              'You need to approve the transfer for all the collections for which you want to stake NFTs.',
          },
        });
        return;
      }

      const stakedNFTs = state.landlordStaking.stakedNFTs as StakedNFTInfo[];

      const activeStakedNFTs = stakedNFTs.filter((nft: StakedNFTInfo) => {
        return (
          nft.stakingHistory[nft.stakingHistory.length - 1]
            .unstakedAtTimestamp === null
        );
      });

      const stakedParcels = state.landlordStaking.stakedParcels as Parcel[];

      const numberOfStakedParcels = stakedParcels.reduce(
        (acc: number, parcel) => {
          acc += parcel?.childrenTokenIds?.length
            ? parcel.childrenTokenIds.length
            : 1;
          return acc;
        },
        0
      );

      if (
        activeStakedNFTs.length + numberOfNFTsSelectedForStaking >
        numberOfStakedParcels
      ) {
        const numParcelsNeeded =
          activeStakedNFTs.length +
          numberOfNFTsSelectedForStaking -
          numberOfStakedParcels;
        commit('app/setModal', {
          component: 'alertMessage',
          payload: {
            title: 'Not enough staked parcels',
            message: `You need to stake ${numParcelsNeeded} more parcel${
              numParcelsNeeded > 1 ? 's' : ''
            } to stake the token${
              numParcelsNeeded > 1 ? 's' : ''
            } you selected.`,
          },
        });
        return;
      }

      // check if all tokenIds are valid and owned by the user
      for (const collectionAddress in nftsSelectedForStaking) {
        if (collectionAddress === '') {
          commit('app/setModal', {
            component: 'alertMessage',
            payload: {
              title: "Token's collection not selected",
              message:
                'Please select for each token the collection it belongs to.',
            },
          });
          return;
        }
        const nfts = nftsSelectedForStaking[collectionAddress];
        for (const tokenId of nfts) {
          const tokenOwnedByUser = await dispatch('checkTokenOwnedByUser', {
            collectionAddress,
            tokenId,
          });
          if (!tokenOwnedByUser) {
            commit('app/setModal', {
              component: 'alertMessage',
              payload: {
                title: 'Not all the token IDs are owned by you',
                message: `Please enter only token IDs that you own. You don't own the token ${tokenId}!`,
              },
            });
            return;
          }
        }
      }

      if (numberOfNFTsSelectedForStaking === 1) {
        const collectionAddress = Object.keys(nftsSelectedForStaking)[0];
        const tokenId = nftsSelectedForStaking[collectionAddress][0];
        dispatch('stakeNFT', {
          collectionAddress,
          tokenId,
        });
      } else {
        const nftsInfo = Object.keys(nftsSelectedForStaking)
          .map((collectionAddress) => {
            return nftsSelectedForStaking[collectionAddress].map((tokenId) => {
              return {
                collectionAddress,
                tokenId,
              };
            });
          })
          .flat();
        dispatch('stakeBulkNFTs', nftsInfo);
      }
    },
    async stakeNFT({ commit, state, dispatch }: any, nft: NFTInfo) {
      try {
        commit('setWaitForConfirmationTrigger', true);

        const stakeNFTListener = state.dystoWorldLandlordStakingContract.methods
          .stakeNFT(nft.collectionAddress, nft.tokenId)
          .send(
            {
              from: state.wallet,
            },
            (err: any, transactionHash: any) => {
              if (err) {
                console.log(err);
                commit('setWaitForConfirmationTrigger', false);
                return;
              }
              commit('setTransactionHash', transactionHash);
            }
          )
          .on('confirmation', (confirmationNumber: number, receipt: any) =>
            handleConfirmation(confirmationNumber, receipt)
          );

        const handleConfirmation = async (
          confirmationNumber: number,
          receipt: any
        ): Promise<void> => {
          if (confirmationNumber !== MAX_CONFIRMATIONS) {
            return;
          }

          await dispatch('getLandlordStakingInfo');
          await dispatch('getPoolRewards');

          const collectionAddresses = state.landlordStaking.collectionAddresses;

          await dispatch('getNFTsOwned', collectionAddresses);
          commit('setWaitForConfirmationTrigger', false);

          // remove the listener
          stakeNFTListener.off('confirmation');
        };
      } catch (err) {
        console.log(err);
        commit('setWaitForConfirmationTrigger', false);
      }
    },
    async stakeBulkNFTs({ commit, state, dispatch }: any, nfts: NFTInfo[]) {
      try {
        commit('setWaitForConfirmationTrigger', true);

        const stakeNFTsListener =
          state.dystoWorldLandlordStakingContract.methods
            .stakeBulkNFTs(
              nfts.map((nft) => nft.collectionAddress),
              nfts.map((nft) => nft.tokenId)
            )
            .send(
              {
                from: state.wallet,
              },
              (err: any, transactionHash: any) => {
                if (err) {
                  console.log(err);
                  commit('setWaitForConfirmationTrigger', false);
                  return;
                }
              }
            )
            .on('confirmation', (confirmationNumber: number, receipt: any) =>
              handleConfirmation(confirmationNumber, receipt)
            );

        const handleConfirmation = async (
          confirmationNumber: number,
          receipt: any
        ): Promise<void> => {
          if (confirmationNumber !== MAX_CONFIRMATIONS) {
            return;
          }

          await dispatch('getLandlordStakingInfo');
          await dispatch('getPoolRewards');

          const collectionAddresses = state.landlordStaking.collectionAddresses;

          await dispatch('getNFTsOwned', collectionAddresses);
          commit('setWaitForConfirmationTrigger', false);

          // remove the listener
          stakeNFTsListener.off('confirmation');
        };
      } catch (err) {
        console.log(err);
        commit('setWaitForConfirmationTrigger', false);
      }
    },
    async unstakeNFT({ commit, state, dispatch }: any, nft: NFTInfo) {
      try {
        commit('setWaitForConfirmationTrigger', true);

        const unstakeNFTListener =
          state.dystoWorldLandlordStakingContract.methods
            .unstakeNFT(nft.collectionAddress, nft.tokenId)
            .send(
              {
                from: state.wallet,
              },
              (err: any, transactionHash: any) => {
                if (err) {
                  console.log(err);
                  commit('setWaitForConfirmationTrigger', false);
                  return;
                }
              }
            )
            .on('confirmation', (confirmationNumber: number, receipt: any) =>
              handleConfirmation(confirmationNumber, receipt)
            );

        const handleConfirmation = async (
          confirmationNumber: number,
          receipt: any
        ): Promise<void> => {
          if (confirmationNumber !== MAX_CONFIRMATIONS) {
            return;
          }

          await dispatch('getLandlordStakingInfo');
          await dispatch('getPoolRewards');

          const collectionAddresses = state.landlordStaking.collectionAddresses;

          await dispatch('getNFTsOwned', collectionAddresses);
          commit('setWaitForConfirmationTrigger', false);

          // remove the listener
          unstakeNFTListener.off('confirmation');
        };
      } catch (err) {
        console.log(err);
        commit('setWaitForConfirmationTrigger', false);
      }
    },
    async unstakeBulkNFTs({ commit, state, dispatch }: any) {
      try {
        commit('setWaitForConfirmationTrigger', true);

        const unstakeNFTsListener =
          state.dystoWorldLandlordStakingContract.methods
            .unstakeBulkNFTs()
            .send(
              {
                from: state.wallet,
              },
              (err: any, transactionHash: any) => {
                if (err) {
                  console.log(err);
                  commit('setWaitForConfirmationTrigger', false);
                  return;
                }
              }
            )
            .on('confirmation', (confirmationNumber: number, receipt: any) =>
              handleConfirmation(confirmationNumber, receipt)
            );

        const handleConfirmation = async (
          confirmationNumber: number,
          receipt: any
        ): Promise<void> => {
          if (confirmationNumber !== MAX_CONFIRMATIONS) {
            return;
          }

          await dispatch('getLandlordStakingInfo');
          commit('setWaitForConfirmationTrigger', false);

          // remove the listener
          unstakeNFTsListener.off('confirmation');
        };
      } catch (err) {
        console.log(err);
        commit('setWaitForConfirmationTrigger', false);
      }
    },
    async claimRewardNFT({ commit, state, dispatch }: any, nft: NFTInfo) {
      try {
        commit('setWaitForConfirmationTrigger', true);

        const claimRewardNFTListener =
          state.dystoWorldLandlordStakingContract.methods
            .claimRewardNFT(nft.collectionAddress, nft.tokenId)
            .send({
              from: state.wallet,
            })
            .on('confirmation', (confirmationNumber: number, receipt: any) =>
              handleConfirmation(confirmationNumber, receipt)
            )
            .catch((err: any) => {
              if (err) {
                console.log(err);
                commit('setWaitForConfirmationTrigger', false);
                return;
              }
            });

        const handleConfirmation = async (
          confirmationNumber: number,
          receipt: any
        ): Promise<void> => {
          if (confirmationNumber !== MAX_CONFIRMATIONS) {
            return;
          }

          await dispatch('getLandlordStakingInfo');
          commit('setWaitForConfirmationTrigger', false);

          // remove the listener
          claimRewardNFTListener.off('confirmation');
        };
      } catch (err) {
        console.log(err);
        commit('setWaitForConfirmationTrigger', false);
      }
    },
    async claimRewardBulkNFTs({ commit, state, dispatch }: any) {
      try {
        commit('setWaitForConfirmationTrigger', true);

        const claimRewardNFTsListener =
          state.dystoWorldLandlordStakingContract.methods
            .claimBulkRewardsNFTs()
            .send(
              {
                from: state.wallet,
              },
              (err: any, transactionHash: any) => {
                if (err) {
                  console.log(err);
                  commit('setWaitForConfirmationTrigger', false);
                  return;
                }
              }
            )
            .on('confirmation', (confirmationNumber: number, receipt: any) =>
              handleConfirmation(confirmationNumber, receipt)
            );

        const handleConfirmation = async (
          confirmationNumber: number,
          receipt: any
        ): Promise<void> => {
          if (confirmationNumber !== MAX_CONFIRMATIONS) {
            return;
          }

          await dispatch('getLandlordStakingInfo');
          commit('setWaitForConfirmationTrigger', false);

          // remove the listener
          claimRewardNFTsListener.off('confirmation');
        };
      } catch (err) {
        console.log(err);
        commit('setWaitForConfirmationTrigger', false);
      }
    },
    async checkTokenOwnedByUser(
      { commit, state }: any,
      nft: NFTInfo
    ): Promise<boolean> {
      try {
        commit('setWaitForConfirmationTrigger', true);

        const collectionContract = new state.web3.eth.Contract(
          GENERIC_COLLECTION_ABI,
          nft.collectionAddress
        );
        const ownerOfToken: string | null = await collectionContract.methods
          .ownerOf(nft.tokenId)
          .call()
          .then((el: any) => {
            return el;
          })
          .catch((err: Error) => {
            console.log(err);
            return null;
          });

        commit('setWaitForConfirmationTrigger', false);

        return ownerOfToken?.toLocaleLowerCase() === state.wallet;
      } catch (err) {
        console.log(err);
        commit('setWaitForConfirmationTrigger', false);
        return false;
      }
    },
    async checkApprovalForTransferOfNFTs(
      { commit, state, dispatch }: any,
      collectionAddress: string
    ): Promise<boolean> {
      try {
        const collectionContract = new state.web3.eth.Contract(
          GENERIC_COLLECTION_ABI,
          collectionAddress
        );
        const hasApproval: boolean = await collectionContract.methods
          .isApprovedForAll(
            state.wallet,
            state.dystoWorldLandlordStakingContract.options.address
          )
          .call()
          .then((el: any) => {
            return el;
          })
          .catch((err: Error) => {
            console.log(err);
          });

        return hasApproval;
      } catch (err) {
        console.log(err);
        return false;
      }
    },
    async approveTransferOfNFTs(
      { commit, state }: any,
      collectionAddress: string
    ) {
      try {
        commit('setWaitForConfirmationTrigger', true);

        const collectionContract = new state.web3.eth.Contract(
          GENERIC_COLLECTION_ABI,
          collectionAddress
        );
        const getApprovalListener = collectionContract.methods
          .setApprovalForAll(
            state.dystoWorldLandlordStakingContract.options.address,
            true
          )
          .send(
            {
              from: state.wallet,
            },
            (err: any, transactionHash: any) => {
              if (err) {
                console.log(err);
                commit('setWaitForConfirmationTrigger', false);
                return;
              }
              commit('setTransactionHash', transactionHash);
            }
          )
          .on('confirmation', (confirmationNumber: number, receipt: any) =>
            handleConfirmation(confirmationNumber, receipt)
          );

        const handleConfirmation = (
          confirmationNumber: number,
          receipt: any
        ): void => {
          if (confirmationNumber !== MAX_CONFIRMATIONS) {
            return;
          }

          commit('setWaitForConfirmationTrigger', false);
          getApprovalListener.off('confirmation');
        };
      } catch (err) {
        commit('setWaitForConfirmationTrigger', false);
        console.log(err);
      }
    },
    async checkUserHasNFTs(
      { commit, state }: any,
      collectionAddress: string
    ): Promise<boolean> {
      try {
        const collectionContract = new state.web3.eth.Contract(
          GENERIC_COLLECTION_ABI,
          collectionAddress
        );
        const balance: BigNumber = await collectionContract.methods
          .balanceOf(state.wallet)
          .call()
          .then((el: any) => {
            return new BigNumber(el);
          })
          .catch((err: Error) => {
            console.log(err);
            return new BigNumber('0');
          });

        return !balance.eq(new BigNumber('0'));
      } catch (err) {
        console.log(err);
        return false;
      }
    },
    async verify3DModel({ commit, state, dispatch }: any, parcelId: any) {
      let retries = 0;
      while (retries < 3) {
        retries++;
        try {
          const response = await fetch(
            `${API_URL}/spaces/instance/info/${parcelId}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${await dispatch('returnToken')}`,
              },
            }
          );

          if (response.status === 401) {
            localStorage.removeItem('auth_token');
            continue;
          }

          if (response.status === 404) {
            return { data: await response.json(), hasInstanceMounted: false };
          } else {
            return { data: await response.json(), hasInstanceMounted: true };
          }
        } catch (err) {
          console.log(err);
        }
      }
    },
    async getParcel({ commit, state, dispatch }: any, id: string) {
      try {
        commit('setSpacesWaitingForApi', true);
        const response = await fetch(`${API_URL}/parcels/${id}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });

        const parseRes = await response.json();
        const { size, startX, startY } = parseRes;
        commit('setMount', {
          ...state.spaces.mount,
          parcel: {
            id: id,
            size: size ? size + 'x' + size : 'N/A',
            startX,
            startY,
          },
        });
        await dispatch('getDystoSpacesTokens');
      } catch (err) {
        commit('setSpacesWaitingForApi', false);
        console.log(err);
      }
    },
    async getDystoSpacesTokens({ commit, state, dispatch }: any) {
      commit('setSpacesWaitingForApi', true);
      let retries = 0;
      while (retries < 3) {
        retries++;
        try {
          const response = await fetch(`${API_URL}/spaces/space-asset`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${await dispatch('returnToken')}`,
            },
          });
          const parseRes = await response.json();

          commit('setMount', {
            ...state.spaces.mount,
            spaceAssets: parseRes.map((asset: any) => {
              return {
                _id: asset._id,
                name: asset.name,
                imageUrl: asset.image_url,
                owner: asset.owner,
                tokenId: asset.tokenId,
                chainId: asset.chain_id,
                parcelId: asset.parcelId,
                spaceId: asset.attributes[3].value,
                // spaceSize: asset.attributes[0].value,
                spaceSize: asset.attributes[0].value,
              };
            }),
          });
          commit('setSpacesWaitingForApi', false);
          return;
        } catch (err) {
          console.log(err);
        }
      }
      commit('setSpacesWaitingForApi', false);
    },
    async getOwnedInstances({ commit, state, dispatch }: any) {
      try {
        const response = await fetch(`${API_URL}/spaces/instance/owned`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${await dispatch('returnToken')}`,
          },
        });
        if (response.status !== 200) {
          throw new Error(await response.text());
        }
        const parseRes = await response.json();

        commit('setOwnedInstances', parseRes);
      } catch (err) {
        console.log(err);
      }
      commit('setSpacesWaitingForApi', false);
    },
    async createSpaceInstance(
      { commit, state, dispatch }: any,
      spaceAsset: SpaceAssetInfo
    ) {
      try {
        const response = await fetch(`${API_URL}/spaces/instance`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${await dispatch('returnToken')}`,
          },
          body: JSON.stringify({
            placeholders: [],
            ownerAddress: spaceAsset.owner,
            spaceConfigId: spaceAsset.spaceId,
            spaceTokenId: spaceAsset.tokenId,
            spaceChainId: spaceAsset.chainId,
            parcelId: spaceAsset.parcelId,
          }),
        });

        const parseRes = await response.json();
        return parseRes;
      } catch (err) {
        console.log(err);
      }
    },

    async getUserAssets({ commit, state, dispatch }: any, force = false) {
      let retries = 0;
      while (retries < 3) {
        retries++;
        try {
          const token = await dispatch('returnToken');
          const response = await fetch(
            `${API_URL}/spaces/asset?force=${force}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`,
              },
            }
          );

          const parseRes = await response.json();
          commit('setOwnedAssets', parseRes);
          return;
        } catch (err) {
          console.log(err);
        }
      }
    },
    async likeSpacePlaceholderAsset(
      { commit, state, dispatch }: any,
      placeholderAssetId: any
    ) {
      try {
        const response = await fetch(
          `${API_URL}/spaces/placeholder-asset-like/${placeholderAssetId}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${await dispatch('returnToken')}`,
            },
          }
        );
        const parseRes = await response.json();
        return parseRes;
      } catch (err) {
        console.log(err);
      }
    },
    async unlikeSpacePlaceholderAsset(
      { commit, state, dispatch }: any,
      placeholderAssetId: any
    ) {
      try {
        const response = await fetch(
          `${API_URL}/spaces/placeholder-asset-like/${placeholderAssetId}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${await dispatch('returnToken')}`,
            },
          }
        );
        if (response.status === 200) {
          return { error: false };
        }
        const parseRes = await response.json();
        return parseRes;
      } catch (err) {
        console.log(err);
      }
    },
    async mintSpacesTokens({ commit, state, dispatch }: any) {
      try {
        commit('setSpacesWaitingForApi', true);
        const price: BigNumber | undefined =
          await state.dystoWorldSpacesContract.methods
            .price()
            .call()
            .then((el: any) => {
              return new BigNumber(el);
            })
            .catch((err: Error) => {
              console.log(err);
              commit('setSpacesWaitingForApi', false);
            });

        if (!price) {
          commit(
            'app/alterNotifications',
            {
              text: `In order to mint the space please choose the right chain (${ETHEREUM_NETWORK_NAME}).`,
              type: 'danger',
              add: true,
            },
            { root: true }
          );
          commit('setSpacesWaitingForApi', false);
          return;
        }

        let createSpaceAssetMintedEventProtectionSucceeded = false;
        let createSpaceAssetMintedEventProtectionResponse: any = {};

        const shouldMintFree = state.spaces.mount.spaceAssets.length === 0;
        const numTokens = 1;
        const chainId = state.chainId;

        try {
          const createSpaceAssetMintedEventProtection =
            await fetch(`${API_URL}/keypad/create_space_asset_minted_event_protection`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${await dispatch('returnToken')}`,
              },
              body: JSON.stringify({
                chainId: chainId,
                numTokens: numTokens,
                shouldMintFree: shouldMintFree
              }),
            });

          const res = await createSpaceAssetMintedEventProtection.json();
          if (res && !res.error) {
            createSpaceAssetMintedEventProtectionSucceeded = true;
            createSpaceAssetMintedEventProtectionResponse = res;
          }
        } catch (err) {
          console.log(err);
        }

        if (!createSpaceAssetMintedEventProtectionSucceeded) {
          throw new Error('sign failed');
        }

        const issued = createSpaceAssetMintedEventProtectionResponse.issued;
        const expiration = createSpaceAssetMintedEventProtectionResponse.expiration;
        const transactionId = createSpaceAssetMintedEventProtectionResponse.transactionId;
        const signature = createSpaceAssetMintedEventProtectionResponse.signature;

        const network = getNetworkByChainId(chainId);
        const dystoTokenContract = state.dystoWorldTokenContract;

        const allowance: string = await dystoTokenContract.methods.allowance(state.user.address, network.DYSTO_WORLD_SPACES_CONTRACT_ADDRESS).call();

        if (new BigNumber(allowance).lt(price) && !shouldMintFree) {
          await dystoTokenContract.methods.approve(network.DYSTO_WORLD_SPACES_CONTRACT_ADDRESS, price.toString()).send({ from: state.wallet });
        }

        state.dystoWorldSpacesContract.methods
          .mint(
            issued,
            expiration,
            numTokens,
            transactionId,
            chainId,
            shouldMintFree,
            signature,
          )
          .send(
            {
              from: state.wallet,
            },
            (err: any, transactionHash: any) => {
              if (err) {
                console.log(err);
                commit('setSpacesWaitingForApi', false);
                return;
              }
            }
          )
          .on(
            'confirmation',
            async (confirmationNumber: number, receipt: any) =>
              await handleConfirmation(confirmationNumber, receipt)
          );

        const handleConfirmation = async (
          confirmationNumber: number,
          receipt: any
        ): Promise<void> => {
          if (confirmationNumber !== MAX_CONFIRMATIONS) {
            return;
          }

          await delay(5000);
          await dispatch('getDystoSpacesTokens');
        };

        return;
      } catch (err) {
        console.log(err);
        commit('setSpacesWaitingForApi', false);
      }
    },
    async deleteSpaceInstance(
      { commit, state, dispatch }: any,
      { instanceId, tokenId }: any
    ) {
      try {
        const response = await fetch(
          `${API_URL}/spaces/instance/unmount/${instanceId}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${await dispatch('returnToken')}`,
            },
          }
        );
        const parseRes = await response.json();
        if (response.status === 200) {
          commit(
            'setOwnedInstances',
            state.spaces.ownedInstances.filter(
              (instance: any) => instance._id !== instanceId
            )
          );
          const newState = state.spaces.mount.spaceAssets;
          newState.map((asset: any) => {
            if (asset.tokenId === tokenId) {
              asset.parcelId = '';
            }
          });
          commit('setMount', {
            ...state.spaces.mount,
            spaceAssets: newState,
          });
        }
        return parseRes;
      } catch (err) {
        console.log(err);
      }
    },
    async getUserBalance({ commit, state, dispatch }: any) {
      if (!state.user) {
        return;
      }
      const mainnet =
        'https://mainnet.infura.io/v3/1d62d9a9f70e4c848baf33615f0ffe25';
      const web3 = new Web3(new Web3.providers.HttpProvider(mainnet));
      const address = state.user.address;
      const balance = await web3.eth.getBalance(address);
      return web3.utils.fromWei(balance, 'ether');
    },
    async getUserBalanceOfNativeCurrency({ commit, state, dispatch }: any) {
      if (!state.user) {
        return;
      }
      const address = state.user.address;
      const balance = await state.web3.eth.getBalance(address);
      return state.web3.utils.fromWei(balance, 'ether');
    },
    async getUserDystoWorldTokenBalance({ commit, state }: any) {
      if (!state.user) {
        return Web3.utils.fromWei('0', 'ether');
      }

      try {
        const balance = await state.dystoWorldTokenContract.methods
          .balanceOf(state.user.address)
          .call();

        return Web3.utils.fromWei(balance, 'ether');
      } catch (err) {
        console.error(err);
        return Web3.utils.fromWei('0', 'ether');
      }
    },
  },
});

export default store;
