import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "text" }
const _hoisted_3 = { class: "actions flex items-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.message), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
        class: "action center-center"
      }, "Close"),
      _createElementVNode("div", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.actionHandler && _ctx.actionHandler(...args))),
        class: "action center-center"
      }, _toDisplayString(_ctx.textAction), 1)
    ])
  ]))
}