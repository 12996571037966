import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "category disable-select space-y-1 pb-1 lg:pb-0" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "font-bold text-base" }
const _hoisted_4 = {
  key: 0,
  class: "links space-y-1 py-3"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 0,
  class: "w-5 h-5 ml-3"
}
const _hoisted_7 = {
  key: 0,
  class: "flex ml-6 font-medium text-sm bulletpoint"
}
const _hoisted_8 = {
  key: 1,
  class: "flex ml-6 font-medium text-sm bulletpoint"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_SteakIcon = _resolveComponent("SteakIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.poolRewardsSubcategoriesArray, (subcategory, id) => {
      return (_openBlock(), _createElementBlock("div", { key: id }, [
        _createElementVNode("div", {
          class: _normalizeClass(["subcategory rounded-md my-2 max-h-[17.6rem] lg:max-h-fit overflow-y-scroll lg:overflow-y-hidden", {
          'bg-grey-10': subcategory.open && subcategory.links.length,
        }])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["w-full cursor-pointer flex items-center text-white rounded-md px-5 py-3", {
            'bg-grey-2': subcategory.open && subcategory.links.length,
          }]),
            onClick: ($event: any) => (subcategory.open = !subcategory.open)
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: subcategory.icon,
              class: "mr-4 h-6"
            }, null, 8, ["icon"]),
            _createElementVNode("div", _hoisted_3, _toDisplayString(subcategory.label), 1)
          ], 10, _hoisted_2),
          (subcategory.open && subcategory.links.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subcategory.links, (link, id2) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: id2,
                    class: _normalizeClass(["cursor-pointer flex flex-col items-start text-base font-bold rounded-md mx-4 px-4 py-3 text-white space-y-2", {
              'bg-grey-11': link.active,
            }]),
                    onClick: ($event: any) => (_ctx.changeCurrentPoolReward(link.id))
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["flex", {
                'bg-grey-11': link.active,
                'text-highlight-green': link.hasStaked,
                'text-white': !link.hasStaked,
              }])
                    }, [
                      _createTextVNode(_toDisplayString(link.name) + " ", 1),
                      (link.hasStaked)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _createVNode(_component_SteakIcon)
                          ]))
                        : _createCommentVNode("", true)
                    ], 2),
                    (link.subcategory !== 'stakedNFTs')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, " Started on: " + _toDisplayString(link.createdAt), 1))
                      : _createCommentVNode("", true),
                    (link.subcategory !== 'stakedNFTs')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, " Ends on: " + _toDisplayString(link.endsOn), 1))
                      : _createCommentVNode("", true)
                  ], 10, _hoisted_5))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ], 2)
      ]))
    }), 128))
  ]))
}