
import { initGlitch } from '@/utils';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Options, Vue } from 'vue-property-decorator';

@Options({
  components: {
    FontAwesomeIcon,
  },
})
export default class HomeFooter extends Vue {
  setRoute(path: string) {
    this.$router.push(path);
  }

  mounted(): void {
    initGlitch();
  }

  alertComingSoon(): boolean {
    this.$store.commit(
      'app/alterNotifications',
      { text: 'Coming soon!', type: 'success', add: true },
      { root: true }
    );
    return false;
  }
}
