import { FinalCell } from '@/utils/interfaces/canvas';

type AggregatedParcel = {
  startX: number;
  startY: number;
  owner: string;
  state: 'MINTED' | 'RESERVED';
  size: number;
  id: string;
};

export interface ValidateShapeForMerge {
  valid: boolean;
  selectedParcels?: AggregatedParcel[];
}

type BaseParcel = {
  x: number;
  y: number;
  owner: string;
  state: 'MINTED' | 'RESERVED';
  parentId: string;
};

export function validateShapeForMerge(
  cell: FinalCell,
  stateParcels: [string, AggregatedParcel][],
  authenticatedWallet: string
): ValidateShapeForMerge {
  const selectedParcels = getSelectedParcels(cell, stateParcels);
  const selectedBaseParcels = generateBaseParcels(selectedParcels);
  const storedBaseParcels = new Map(
    generateBaseParcels(stateParcels.map(([, parcel]) => parcel)).map((p) => [
      `${p.x}${p.y}`,
      p,
    ])
  );

  if (selectedParcels.length < 4) return { valid: false };
  const calculatedSize = Math.sqrt(selectedBaseParcels.length);
  if (!Number.isInteger(calculatedSize)) return { valid: false };
  const stats = selectedBaseParcels.reduce<{
    minX: number;
    maxX: number;
    sumX: number;
    minY: number;
    maxY: number;
    sumY: number;
  }>(
    (acc, point) => {
      acc.minX = acc.minX > point.x ? point.x : acc.minX;
      acc.minY = acc.minY > point.y ? point.y : acc.minY;
      acc.maxX = acc.maxX < point.x ? point.x : acc.maxX;
      acc.maxY = acc.maxY < point.y ? point.y : acc.maxY;
      acc.sumX += point.x;
      acc.sumY += point.y;
      return acc;
    },
    {
      minX: selectedBaseParcels[0].x,
      maxX: selectedBaseParcels[0].x,
      sumX: 0,
      minY: selectedBaseParcels[0].y,
      maxY: selectedBaseParcels[0].y,
      sumY: 0,
    }
  );
  const isSquare = stats.maxX - stats.minX === stats.maxY - stats.minY;
  if (!isSquare) return { valid: false };
  const sumX = stats.sumX - stats.minX * selectedBaseParcels.length;
  const sumY = stats.sumY - stats.minY * selectedBaseParcels.length;
  const expectedSum =
    (((calculatedSize - 1) * calculatedSize) / 2) * calculatedSize;
  if (sumX !== expectedSum || sumY !== expectedSum) return { valid: false };
  if (
    !selectedBaseParcels
      .map((p) => `${p.x}${p.y}`)
      .every(
        (p) =>
          storedBaseParcels.has(p) &&
          storedBaseParcels.get(p)!.owner === authenticatedWallet &&
          storedBaseParcels.get(p)!.state === 'MINTED'
      )
  )
    return { valid: false };

  return { valid: true, selectedParcels };
}

function generateBaseParcels(
  aggregateParcels: AggregatedParcel[]
): BaseParcel[] {
  return aggregateParcels
    .map((p) =>
      p.size === 1
        ? {
            x: p.startX,
            y: p.startY,
            size: 1,
            owner: p.owner,
            state: p.state,
            parentId: p.id,
          }
        : [...Array(p.size).keys()]
            .map((x) =>
              [...Array(p.size).keys()].map((y) => ({
                x: p.startX + x,
                y: p.startY + y,
                size: 1,
                owner: p.owner,
                state: p.state,
                parentId: p.id,
              }))
            )
            .flat()
    )
    .flat();
}

function getSelectedParcels(
  cell: FinalCell,
  storedParcelsMap: [string, AggregatedParcel][]
): AggregatedParcel[] {
  return storedParcelsMap
    .filter(
      ([_, value]) =>
        value.startX >= cell.startX &&
        value.startX < cell.startX + cell.sizeX &&
        value.startY >= cell.startY &&
        value.startY < cell.startY + cell.sizeY
    )
    .map(([_, parcel]) => parcel);
}
