
import { Vue, Prop, Options } from 'vue-property-decorator';
import BoatBuyIcon from '../icons/boatbuy.vue';
import EyeIcon from '../icons/eye.vue';
import EyeBuyIcon from '../icons/eyebuy.vue';
import StarIcon from '../icons/star.vue';

@Options({
  components: {
    EyeIcon,
    StarIcon,
    BoatBuyIcon,
    EyeBuyIcon,
  },
})
export default class Banner extends Vue {
  @Prop(String) readonly image!: string;
  @Prop(String) readonly source!: string;
  @Prop(String) readonly title!: string;
  @Prop(String) readonly description!: string;
  @Prop({ default: false }) readonly shadow!: boolean;
  @Prop({ default: false }) readonly locked!: boolean;
  @Prop({ default: false }) readonly iconBR!: boolean;
  @Prop({ default: true }) readonly openEye!: boolean;
  @Prop({ default: false }) readonly featured!: boolean;
  @Prop({ default: false }) readonly video!: boolean;
  @Prop(Boolean) readonly soldout!: boolean;
  @Prop({ default: true }) readonly padding!: boolean;
  @Prop(Object) readonly minted!: any;
  @Prop({ default: false }) readonly actions!: any;
  @Prop({ default: true }) readonly local!: any;
  @Prop({ default: false }) readonly fitImage!: boolean;

  descriptionClass = '';

  mounted() {
    const height = this.$refs.height as HTMLElement;
    const video = this.$refs.video as HTMLElement;
    window.addEventListener('resize', (e) => {
      if (video) {
        height.style.height = `${video.clientHeight / 2}px`;
      }
    });
  }

  expandDescription(): void {
    this.descriptionClass = this.descriptionClass === '' ? 'expanded' : '';
  }
}
