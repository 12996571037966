import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "category disable-select space-y-1" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "font-bold text-base" }
const _hoisted_4 = {
  key: 0,
  class: "links space-y-1 py-3"
}
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.collectionsSubcategoriesArray, (subcategory, id) => {
      return (_openBlock(), _createElementBlock("div", { key: id }, [
        _createElementVNode("div", {
          class: _normalizeClass(["subcategory rounded-md my-2", {
          'bg-grey-10': subcategory.open && subcategory.links.length,
        }])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(`w-full cursor-pointer flex items-center text-white rounded-md px-5 py-3 ${
            subcategory.open ? 'bg-grey-2' : ''
          }`),
            onClick: ($event: any) => (subcategory.open = !subcategory.open)
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: subcategory.icon,
              class: "mr-4 h-6"
            }, null, 8, ["icon"]),
            _createElementVNode("div", _hoisted_3, _toDisplayString(subcategory.label), 1)
          ], 10, _hoisted_2),
          (subcategory.open && subcategory.links.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subcategory.links, (link, id2) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: id2,
                    class: _normalizeClass(`cursor-pointer flex justify-between items-center text-sm font-medium rounded-md mx-4 px-4 py-3 text-white ${
              link.active ? 'bg-grey-11' : ''
            }`),
                    onClick: ($event: any) => (_ctx.changeCurrentCollection(link.id))
                  }, _toDisplayString(link.name), 11, _hoisted_5))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ], 2)
      ]))
    }), 128))
  ]))
}