
import { Vue, Options } from 'vue-property-decorator';
import Canvas from '../components/default/canvas.vue';
import Parcels from '../components/default/parcels.vue';
import Notifications from '@/components/shared/notifications.vue';
import MiniPortalCategory from '@/components/shared/miniPortalCategory.vue';

@Options({
  components: {
    Canvas,
    Parcels,
    Notifications,
    MiniPortalCategory,
  },
})
export default class Portal extends Vue {
  get portalFilters() {
    return this.$store.state.app.portalFilters;
  }

  get optionBar() {
    return this.$store.state.app.Option_Bar;
  }

  windowInnerWidth = 0;

  created(): void {
    window.addEventListener('resize', this.windowResizeHandler);
    this.windowInnerWidth = window.innerWidth;
  }

  destroyed(): void {
    window.removeEventListener('resize', this.windowResizeHandler);
  }

  windowResizeHandler(): void {
    this.windowInnerWidth = window.innerWidth;
  }

  toggleFilterOpen(filter: string): void {
    switch (filter) {
      case 'size':
        this.$store.commit('app/setPortalFilters', {
          ...this.portalFilters,
          sizeIsOpen: !this.portalFilters.sizeIsOpen,
        });
        break;
      case 'type':
        this.$store.commit('app/setPortalFilters', {
          ...this.portalFilters,
          typeIsOpen: !this.portalFilters.typeIsOpen,
        });
        break;
      case 'chainId':
        this.$store.commit('app/setPortalFilters', {
          ...this.portalFilters,
          chainIsOpen: !this.portalFilters.chainIsOpen,
        });
        break;
    }
  }

  addFilter(event: number, filter: string): void {
    switch (filter) {
      case 'size': {
        let newSize = this.portalFilters.size;
        if (newSize.includes(event)) {
          newSize = newSize.filter((e: number) => e !== event);
        } else {
          newSize.push(event);
        }

        this.$store.commit('app/setPortalFilters', {
          ...this.portalFilters,
          size: newSize,
        });
        return;
      }
      case 'type': {
        let newType = this.portalFilters.type;
        if (newType.includes(event)) {
          newType = newType.filter((e: number) => e !== event);
        } else {
          newType.push(event);
        }

        this.$store.commit('app/setPortalFilters', {
          ...this.portalFilters,
          type: newType,
        });
        return;
      }
      case 'chainId': {
        let newChainId = this.portalFilters.chainId;
        if (newChainId.includes(event)) {
          newChainId = newChainId.filter((e: number) => e !== event);
        } else {
          newChainId.push(event);
        }

        this.$store.commit('app/setPortalFilters', {
          ...this.portalFilters,
          chainId: newChainId,
        });
        return;
      }
    }
  }
}
