
import { Vue } from 'vue-property-decorator';

import { GENERIC_ERC20_ABI } from '@/config/abi';
import { toast, ToastOptions } from 'vue3-toastify';
export default class buyDysto extends Vue {
  title = '';
  loadingMessage = '';
  selectedCurrencyToBuyDysto = 'ETH';

  tokenContractAddress = '';

  dystoPublicSaleContractAddress = '';

  dystoPrice = 0;
  dystoAmount = 0;

  message = '';

  exitCallback: (() => void) | null = null;

  isLoading = false;

  buySharesTxHash = '';

  beforeMount(): void {
    this.selectedCurrencyToBuyDysto = this.$store.state.app.modal.payload.selectedCurrencyToBuyDysto;

    this.tokenContractAddress = this.$store.state.app.modal.payload.tokenContractAddress;
    this.dystoPublicSaleContractAddress = this.$store.state.app.modal.payload.dystoPublicSaleContractAddress;

    this.dystoAmount = this.$store.state.app.modal.payload.dystoAmount;
    this.dystoPrice = this.$store.state.app.modal.payload.dystoPrice;

    this.title = `Buy DYSTO with ${this.selectedCurrencyToBuyDysto}`;
    this.message = this.$store.state.app.modal.payload.message;

    this.exitCallback = this.$store.state.app.modal.payload.exitCallback;

    this.loadingMessage = (this.selectedCurrencyToBuyDysto === 'ETH') ?
        'Processing your DYSTO purchase. Please wait, this might take a few moments.'
      : 'Processing your DYSTO purchase. Please wait, this might take a few moments. Keep an eye on MetaMask for approval requests, possibly twice for both approval and purchase.';
  }

  close(): void {
    this.$store.commit('app/setModal', { component: '', payload: null });
  }

  async buy() {

    this.buySharesTxHash = '';

    if (this.selectedCurrencyToBuyDysto === 'ETH') {
      await this.handleBuyDystoWithEth();
    } else {
      await this.handleBuyDystoWithStablecoin();
    }
  }

  async handleBuyDystoWithStablecoin() {
    try {
      this.isLoading = true;

      const stableCoinContract = new this.$store.state.web3.eth.Contract(GENERIC_ERC20_ABI, this.tokenContractAddress);

      let decimals = await stableCoinContract.methods.decimals().call();

      let amountToSpend: string;
      if (decimals == 18) {
        amountToSpend = this.$store.state.web3.utils.toWei((this.dystoAmount * this.dystoPrice).toFixed(), 'ether');
      } else {
        // Adjust the multiplication factor for tokens with different decimals
        const factor = Math.pow(10, decimals);
        amountToSpend = (this.dystoAmount * this.dystoPrice * factor).toFixed();
      }

      await stableCoinContract.methods
        .approve(this.dystoPublicSaleContractAddress, amountToSpend)
        .send({ from: this.$store.state.user.address });

      await new Promise((resolve, reject) => {
        this.$store.state.dystoWorldPublicSaleContract.methods
                  .buyDYSTOWithStablecoin(this.tokenContractAddress, amountToSpend)
                  .send({ from: this.$store.state.user.address })
          .on('transactionHash', (hash: string) => {
            this.buySharesTxHash = hash;
          })
          .on('receipt', (receipt: unknown) => {
            console.log(receipt);
            resolve(receipt);
          })
          .on('error', (error: any) => {
            console.error('Transaction error:', error);
            reject(error);
          });
        });

      this.purchaseSuccessfulToast();
      this.$store.commit('app/setModal', { component: '', payload: null });
      if (this.exitCallback) {
        this.exitCallback();
      }
    } catch (error) {
      this.purchaseFailedToast();
      console.error('Transaction failed:', error);
    } finally {
      this.isLoading = false;
    }
  }

  async handleBuyDystoWithEth() {
    try {
      this.isLoading = true;
      const ethAmountToSpend = this.$store.state.web3.utils.toWei((this.dystoAmount * this.dystoPrice).toString(), 'ether');
      await new Promise((resolve, reject) => {
        this.$store.state.dystoWorldPublicSaleContract.methods.buyDYSTOWithETH()
          .send({
            from: this.$store.state.user.address,
            value: ethAmountToSpend
          })
          .on('transactionHash', (hash: string) => {
            this.buySharesTxHash = hash;
          })
          .on('receipt', (receipt: unknown) => {
            console.log(receipt);
            resolve(receipt);
          })
          .on('error', (error: any) => {
            console.error('Transaction error:', error);
            reject(error);
          });
      });
      this.purchaseSuccessfulToast();
      this.close();
    } catch (error) {
      this.purchaseFailedToast();
      console.error('Transaction failed:', error);
    } finally {
      this.isLoading = false;
    }
  }

  purchaseSuccessfulToast() {
    toast.success('Purchase successful', {
      hideProgressBar: true,
      autoClose: 1000,
      position: toast.POSITION.TOP_RIGHT,
    } as ToastOptions);
  }

  purchaseFailedToast() {
    toast.error('Purchase has failed', {
      hideProgressBar: true,
      autoClose: 1000,
      position: toast.POSITION.TOP_RIGHT,
    } as ToastOptions);
  }

  async copyTxHashToClipboard() {
    try {
      await navigator.clipboard.writeText(this.txHash);
      toast.success('TxHash copied to clipboard!', {
        hideProgressBar: true,
        autoClose: 1000,
        position: toast.POSITION.TOP_RIGHT,
      } as ToastOptions);
    } catch (error) {
      console.error('Failed to copy TxHash:', error);
    }
  }

  get loading() {
    return this.isLoading;
  }

  get txHash() {
    return this.buySharesTxHash;
  }

}
