
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Options, Vue } from 'vue-property-decorator';

@Options({
  components: {
    FontAwesomeIcon,
  },
})
export default class HomeVideo extends Vue {
  time: any = null;
  isPlaying = false;

  mounted() {
    const video = this.$refs.video as any;
    this.time = `0:00 / 0:00`;

    video.addEventListener('timeupdate', () => {
      this.time = `${this.formatTime(video.currentTime)} / ${this.formatTime(
        video.duration
      )}`;
    });

    video.addEventListener('play', () => {
      this.isPlaying = true;
    });

    video.addEventListener('pause', () => {
      this.isPlaying = false;
    });
  }

  formatTime(time: number) {
    if (!time) return '';
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time - minutes * 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  playVideo() {
    const video = this.$refs.video as any;
    video.play();
  }

  pauseVideo() {
    const video = this.$refs.video as any;
    video.pause();
  }
}
