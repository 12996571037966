
import { Vue, Prop } from 'vue-property-decorator';

export default class Switch extends Vue {
  @Prop(String) readonly text!: string;
  @Prop(Boolean) readonly value!: string;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop({ default: '' }) readonly extraClasses!: string;

  onClick(): void {
    this.$emit('clicked');
  }
}
