
import { Vue } from 'vue-property-decorator';

import { GENERIC_ERC20_ABI } from '@/config/abi';
import { toast, ToastOptions } from 'vue3-toastify';
export default class initializeInVestingContract extends Vue {
  title = '';
  loadingMessage = '';

  signature = '';
  initialRelease = '';
  vestedAmount = '';
  vestingStartTime = 0;
  vestingEndTime = 0;
  monthlyUnlockPercentage = 0;

  message = '';

  exitCallback: (() => void) | null = null;

  isLoading = false;

  initializeTxHash = '';

  beforeMount(): void {

    this.title = `Activate Your Vesting Schedule`;
    this.message = 'Click \'Proceed\' to activate your vesting schedule and unlock your benefits over time.';

    this.exitCallback = this.$store.state.app.modal.payload.exitCallback;

    this.signature = this.$store.state.app.modal.payload.signature;
    this.initialRelease = this.$store.state.app.modal.payload.initialRelease;
    this.vestedAmount = this.$store.state.app.modal.payload.vestedAmount;
    this.vestingStartTime = this.$store.state.app.modal.payload.vestingStartTime;
    this.vestingEndTime = this.$store.state.app.modal.payload.vestingEndTime;
    this.monthlyUnlockPercentage = this.$store.state.app.modal.payload.monthlyUnlockPercentage;

    this.loadingMessage = 'Please wait, this might take a few moments.';
  }

  close(): void {
    this.$store.commit('app/setModal', { component: '', payload: null });
  }

  async initialize() {

    this.initializeTxHash = '';

    try {
      this.isLoading = true;

      await new Promise((resolve, reject) => {
        this.$store.state.dystoWorldVestingContract.methods
          .initializeBeneficiary(this.initialRelease, this.vestedAmount, this.vestingStartTime, this.vestingEndTime, this.monthlyUnlockPercentage, this.signature)
          .send({ from: this.$store.state.user.address })
          .on('transactionHash', (hash: string) => {
            this.initializeTxHash = hash;
          })
          .on('receipt', (receipt: unknown) => {
            console.log(receipt);
            resolve(receipt);
          })
          .on('error', (error: any) => {
            console.error('Transaction error:', error);
            reject(error);
          });
      });

      this.initializeSuccessfulToast();
      this.$store.commit('app/setModal', { component: '', payload: null });
      if (this.exitCallback) {
        this.exitCallback();
      }
    } catch (error) {
      this.initializeFailedToast();
      console.error('Transaction failed:', error);
    } finally {
      this.isLoading = false;
    }
  }

  initializeSuccessfulToast() {
    toast.success('Initialize successful', {
      hideProgressBar: true,
      autoClose: 1000,
      position: toast.POSITION.TOP_RIGHT,
    } as ToastOptions);
  }

  initializeFailedToast() {
    toast.error('Initialize has failed', {
      hideProgressBar: true,
      autoClose: 1000,
      position: toast.POSITION.TOP_RIGHT,
    } as ToastOptions);
  }

  async copyTxHashToClipboard() {
    try {
      await navigator.clipboard.writeText(this.txHash);
      toast.success('TxHash copied to clipboard!', {
        hideProgressBar: true,
        autoClose: 1000,
        position: toast.POSITION.TOP_RIGHT,
      } as ToastOptions);
    } catch (error) {
      console.error('Failed to copy TxHash:', error);
    }
  }

  get loading() {
    return this.isLoading;
  }

  get txHash() {
    return this.initializeTxHash;
  }

}
