import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StakingNFTs = _resolveComponent("StakingNFTs")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = (event) => event.stopPropagation()),
    class: "min-h-[21rem] max-h-[34rem] option-bar-mobile w-72 bg-[#151720] border-1 border-highlight-green flex justify-center overflow-y-scroll rounded absolute right-0 bottom-1 z-998"
  }, [
    _createVNode(_component_StakingNFTs)
  ]))
}