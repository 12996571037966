
import { Vue, Prop, Options } from 'vue-property-decorator';
import Banner from '@/components/shared/banner.vue';
import LinkedinIcon from '@/components/icons/linkedin.vue';
import MediumIcon from '@/components/icons/medium.vue';
import TelegramIcon from '@/components/icons/telegram.vue';
import TwitterIcon from '@/components/icons/twitter.vue';
import TitleBasic from '@/components/basic/title.vue';

@Options({
  components: {
    Banner,
    TelegramIcon,
    TwitterIcon,
    LinkedinIcon,
    MediumIcon,
    TitleBasic,
  },
})
export default class Footer extends Vue {
  @Prop({ default: true }) readonly banner!: boolean;

  accessLink(url: string): void {
    window.open(url, '_blank');
  }
}
