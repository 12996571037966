
import { Vue, Options, Prop, Watch } from 'vue-property-decorator';
import { NFTOptionInfo, StakedNFTInfo } from '@/store/types/landlordStaking';
import Title from '@/components/basic/title.vue';
import AccordionTitle from '@/components/shared/accordionTitle.vue';
import Loader from '@/components/icons/loader.vue';

@Options({ components: { Title, AccordionTitle, Loader } })
export default class StakeNFTsMenu extends Vue {
  @Prop() collectionAddresses!: string[];
  @Prop({ default: false }) mainWindow!: boolean;
  isDisabled = false;
  nftsSelectedForStaking: NFTOptionInfo[] = [];
  collectionAddressesForApproval: any[] = [];
  showLoader = true;
  showLoaderForApproval = false;
  forceRefreshNFTs = false;
  onRefreshTx = false;
  onStakeTx = false;
  listOpen = true;

  async beforeMount(): Promise<void> {
    this.showLoader = true;
    if (this.collectionAddresses.length !== 0) {
      await this.$store.dispatch('getNFTsOwned', this.collectionAddresses);
    }
    this.showLoader = false;
  }

  selectNFTForStaking(nft: NFTOptionInfo): void {
    if (this.nftsSelectedForStaking.includes(nft)) {
      this.nftsSelectedForStaking = this.nftsSelectedForStaking.filter(
        (nftOption) => nftOption !== nft
      );
    } else {
      this.nftsSelectedForStaking.push(nft);
    }
  }

  @Watch('collectionAddresses', { deep: true })
  async onCollectionAddressesChange(
    oldValue: string[],
    newValue: string[]
  ): Promise<void> {
    if (
      !this.forceRefreshNFTs &&
      oldValue.length === newValue.length &&
      oldValue.every((address) => newValue.includes(address))
    ) {
      return;
    }

    this.showLoader = true;
    if (this.collectionAddresses.length !== 0) {
      await this.$store.dispatch('getNFTsOwned', this.collectionAddresses);
    }
    this.showLoader = false;
  }

  get waitingForConfirmation(): boolean {
    return this.$store.state.landlordStaking.waitForConfirmationTrigger;
  }

  @Watch('waitingForConfirmation')
  async onWaitingForConfirmationChange(waiting: boolean): Promise<void> {
    if (!waiting && this.onRefreshTx) {
      await this.checkApprovalForTransferOfNFTs();
      this.onRefreshTx = false;
    }
    if (!waiting && this.onStakeTx) {
      this.onStakeTx = false;
      this.forceRefreshNFTs = true;
    }
    this.isDisabled = this.waitingForConfirmation;
  }

  get nftsOwned(): NFTOptionInfo[] {
    return this.$store.state.landlordStaking.nftsOwned;
  }

  @Watch('nftsSelectedForStaking', { deep: true })
  async onNFTsSelectedForStakingChanged(): Promise<void> {
    await this.checkApprovalForTransferOfNFTs();
  }

  async checkApprovalForTransferOfNFTs(): Promise<void> {
    this.showLoaderForApproval = true;
    const newCollectionsForApproval: any[] = [];
    const uniqueCollectionAddresses = [
      ...new Set(
        this.nftsSelectedForStaking.map((token) => token.collectionAddress)
      ),
    ];
    for (const collectionAddress of uniqueCollectionAddresses) {
      if (collectionAddress === '') {
        continue;
      }

      const hasApprovalForTransfer = await this.$store.dispatch(
        'checkApprovalForTransferOfNFTs',
        collectionAddress
      );
      const collectionName = this.nftsSelectedForStaking.find(
        (nft) => nft.collectionAddress === collectionAddress
      )?.collectionName;

      if (!hasApprovalForTransfer) {
        if (
          !newCollectionsForApproval.find(
            (collection) => collection.address === collectionAddress
          )
        ) {
          newCollectionsForApproval.push({
            address: collectionAddress,
            name: collectionName,
          });
        }
      }
    }
    this.collectionAddressesForApproval = newCollectionsForApproval;
    this.showLoaderForApproval = false;
  }

  async approveCollection(collection: any): Promise<void> {
    this.onRefreshTx = true;
    await this.$store.dispatch('approveTransferOfNFTs', collection.address);
  }

  get activeStakedNFTs(): StakedNFTInfo[] {
    return this.$store.state.landlordStaking.stakedNFTs.filter(
      (nft: StakedNFTInfo) => {
        return (
          nft.stakingHistory[nft.stakingHistory.length - 1]
            .unstakedAtTimestamp === null
        );
      }
    );
  }

  async stakeNFTs(): Promise<void> {
    await this.checkApprovalForTransferOfNFTs();
    if (this.collectionAddressesForApproval.length > 0) {
      this.$store.commit('app/setModal', {
        component: 'alertMessage',
        payload: {
          title: 'Missing approval for transfer of NFTs',
          message:
            'You need to approve the transfer for all the collections for which you want to stake NFTs.',
        },
      });
      return;
    }

    if (
      this.activeStakedNFTs.length + this.nftsSelectedForStaking.length >
      this.$store.state.landlordStaking.stakedParcels.length
    ) {
      const numParcelsNeeded =
        this.activeStakedNFTs.length +
        this.nftsSelectedForStaking.length -
        this.$store.state.landlordStaking.stakedParcels.length;
      this.$store.commit('app/setModal', {
        component: 'alertMessage',
        payload: {
          title: 'Not enough staked parcels',
          message: `You need to stake ${numParcelsNeeded} more parcel${
            numParcelsNeeded > 1 ? 's' : ''
          } to stake the token${numParcelsNeeded > 1 ? 's' : ''} you selected.`,
        },
      });
      return;
    }

    // check if all tokenIds are valid and owned by the user
    for (const token of this.nftsSelectedForStaking) {
      if (token.collectionAddress === '') {
        this.$store.commit('app/setModal', {
          component: 'alertMessage',
          payload: {
            title: "Token's collection not selected",
            message:
              'Please select for each token the collection it belongs to.',
          },
        });
        return;
      }

      const tokenOwnedByUser = await this.$store.dispatch(
        'checkTokenOwnedByUser',
        {
          collectionAddress: token.collectionAddress,
          tokenId: token.id,
        }
      );
      if (!tokenOwnedByUser) {
        this.$store.commit('app/setModal', {
          component: 'alertMessage',
          payload: {
            title: 'Not all the token IDs are owned by you',
            message: `Please enter only token IDs that you own. You don't own the token ${token.id}!`,
          },
        });
        return;
      }
    }

    this.onStakeTx = true;
    if (this.nftsSelectedForStaking.length === 1) {
      this.$store.dispatch('stakeNFT', {
        collectionAddress: this.nftsSelectedForStaking[0].collectionAddress,
        tokenId: this.nftsSelectedForStaking[0].id,
      });
    } else {
      const nftsInfo = this.nftsSelectedForStaking.map((token) => {
        return {
          collectionAddress: token.collectionAddress,
          tokenId: token.id,
        };
      });
      this.$store.dispatch('stakeBulkNFTs', nftsInfo);
    }
  }
}
