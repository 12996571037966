
import { Vue, Options, Watch } from 'vue-property-decorator';
import {
  API_URL,
  DYSTO_SPACES_ASSETS_URL,
  DYSTO_PROXY_URL,
} from '@/config/index';

import { throttle } from 'lodash';
import {
  SineEase,
  EasingFunction,
  Vector3,
  SpotLight,
  SceneLoader,
  Animation,
  ShadowGenerator,
  UniversalCamera,
  Ray,
  StandardMaterial,
  Texture,
  BoundingBoxGizmo,
  Color3,
  PositionGizmo,
  UtilityLayerRenderer,
  MeshBuilder,
  ExecuteCodeAction,
  VideoTexture,
  ActionManager,
  AbstractMesh,
  CubeTexture,
  SceneOptimizerOptions,
  SceneOptimizer,
  LensFlaresOptimization,
  PostProcessesOptimization,
  ParticlesOptimization,
  TextureOptimization,
  HighlightLayer,
  HardwareScalingOptimization,
} from '@babylonjs/core';
import '@babylonjs/loaders/glTF';
import * as CANNON from 'cannon';
// import GUI from "babylonjs-gui";
import {
  createTextureOnSceneRendering,
  createHightlightLayer,
  createDecal,
  calculateSizeToMaintainRatio,
  createHightlightLayerForStakedNFTs,
  createDecalForStaking, formatEther,
} from '@/utils/index';
import { Engine, Scene } from '@babylonjs/core';
import {
  SpacePlaceholder,
  SpacePlaceholderAssetLikeInfo,
} from '@/store/types/spaces';
import Notifications from '@/components/shared/notifications.vue';
import InstanceDetails from '@/components/shared/instanceDetails.vue';
import PreviewPlaceholderAsset from '@/components/shared/previewPlaceholderAsset.vue';
import EditPlaceholderAsset from '@/components/shared/editPlaceholderAsset.vue';
import { CustomLoadingScreen } from '@/utils/interfaces/customLoadingScreenBabylon';
import eventBus from '@/eventBus';
// import("@babylonjs/inspector");

@Options({
  components: {
    InstanceDetails,
    PreviewPlaceholderAsset,
    EditPlaceholderAsset,
    Notifications,
  },
})
export default class Instance extends Vue {
  engine!: Engine;
  scene!: Scene;
  canvas!: HTMLCanvasElement;

  instance = {} as any;
  assetLikes: SpacePlaceholderAssetLikeInfo[] = [];

  loadingScreen!: CustomLoadingScreen;

  showComp = false;
  showEditPage = false;
  showDetailsComp = false;
  loading = true;
  instanceId = '';
  currentMesh = null;
  currentMeshName = null;
  assetsPlaced: any[] = [];
  activeMesh!: any;
  modelMeshes!: AbstractMesh[];
  userETHBalance!: number;
  meshesOfEligibleNftForStaking: any[] = [];

  userKeypadCollectionShares = 0;
  ownCollection = false;
  priceForOneShare = '';
  keypadCollection: any = null;
  keypadCollectionName = "";

  instanceImageUrl = 'https://dystoworld.com/assets/img/dystoworld/gallery.png';

  isFetchingKeypadData = false;

  @Watch('showComp')
  @Watch('showEditPage')
  onShowCompOrEditPageChange(): void {
    this.$store.commit(
      'app/setForceBigNavbarLogo',
      !(this.showComp || this.showEditPage)
    );
    // maybe next feature - auto pointerlock
    // if (!(this.showComp || this.showEditPage) && this.engine) {
    //   this.engine.enterPointerlock();
    // }
  }
  async beforeMount(): Promise<void> {
    await this.$store.dispatch('getLandlordStakingInfo');
  }

  get userAddress() {
    return this.$store.state?.user?.address;
  }
  get landlordStakingInfo() {
    return this.$store.state.landlordStaking;
  }

  get modal() {
    return this.$store.state.app.modal;
  }

  setModal(modal: any, payload: any) {
    this.$store.commit('app/setModal', { component: modal, payload });
  }

  get user(): any {
    return this.$store.state.user;
  }

  @Watch('user', { deep: true })
  async onUserChanged(): Promise<void> {
    await this.load();
  }

  beforeDestroy() {
    eventBus.detach('logout', this.handleLogout);
    eventBus.detach('matamask-signed', this.handleMetamaskSign);
  }

  handleLogout() {
    location.reload();
  }

  handleMetamaskSign() {
    location.reload();
  }

  async mounted(): Promise<(() => void) | void> {
    eventBus.on('logout', this.handleLogout);
    eventBus.on('matamask-signed', this.handleMetamaskSign);
    this.onShowCompOrEditPageChange();
    await this.load();
    this.createCustomLoadingScreen();
    window.CANNON = CANNON;

    this.userETHBalance = await this.$store.dispatch('getUserBalance');
    let divFps = document.getElementById('fps')!;
    this.canvas = document.getElementById('sceneCanvas') as HTMLCanvasElement;
    if (this.canvas) {
      this.engine = new Engine(
        this.canvas,
        true,
        { doNotHandleContextLost: true, stencil: true },
        true
      );
      this.scene = this.createScene();
      this.engine.runRenderLoop(() => {
        this.scene.render();
        divFps.innerHTML = this.engine.getFps().toFixed() + ' fps';
      });
      const resize = () => {
        this.scene.getEngine().resize();
      };
      if (window) {
        window.addEventListener('resize', resize);
      }
      this.engine.loadingScreen = this.loadingScreen;
      this.engine.setStencilBuffer(true);
      this.engine.enableOfflineSupport = false;
      return (): void => {
        this.scene.getEngine().dispose();
        if (window) {
          window.removeEventListener('resize', resize);
        }
      };
    }
  }

  createCustomLoadingScreen() {
    const loadingBar = document.getElementById('loadingBar') as HTMLElement;
    const percentLoaded = document.getElementById(
      'percentLoaded'
    ) as HTMLElement;
    const loader = document.getElementById('loader') as HTMLElement;
    this.loadingScreen = new CustomLoadingScreen(
      loadingBar,
      percentLoaded,
      loader
    );
  }

  created(): void {
    this.instanceId = this.$route.params.id as string;
  }

  isOwner(): boolean {
    return !!(this.userAddress && this.userAddress.toUpperCase() === this.instance.ownerAddress.toUpperCase());
  }

  async goToKeypadToBuyKey() {
    if (this.keypadCollection) {
      this.$store.commit('setKeypadCurrentCollection', this.keypadCollection);
      await this.$router.push('/keypad');
    }
  }

  async fetchKeypadRelatedData() {
    if (this.isFetchingKeypadData) {
      return;
    }

    this.isFetchingKeypadData = true;

    try {
      do {
        await(new Promise(resolve => setTimeout(resolve, 500)));
      } while (!this.$store.state.dystoWorldKeypadContract);

      this.userKeypadCollectionShares =
          await this.$store.state.dystoWorldKeypadContract.methods.sharesBalance(this.instance.ownerAddress, this.$store.state.user.address).call();

      this.ownCollection = this.instance?.ownerAddress?.toLowerCase() == this.$store.state.user?.address?.toLowerCase();

      await this.$store.dispatch('getKeypadCollections');

      this.keypadCollection = this.$store.state.keypad.collections?.find(
        (collection: { user_wallet_address: string; }) => collection.user_wallet_address.toLowerCase() === this.instance.ownerAddress.toLowerCase());

      if (this.keypadCollection) {
        this.keypadCollectionName = this.keypadCollection?.name;
      }

      try {
        this.priceForOneShare = await this.$store.state.dystoWorldKeypadContract.methods.getBuyPriceAfterFee(this.instance.ownerAddress.toLowerCase(), 1).call();
        this.priceForOneShare = formatEther(this.priceForOneShare.toString());
      } catch (error) {
        console.log('Error while taking share price balance ', error);
      }

      try {
        const getImagesFetch = await fetch(`${API_URL}/spaces/space-asset/get_images/${this.instance.ownerAddress}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${await this.$store.dispatch('returnToken')}`,
          },
        });
        const getImagesResponse = await getImagesFetch.json();
        if (getImagesResponse.imageUrl) {
          this.instanceImageUrl = getImagesResponse.imageUrl;
        }
      } catch (err) {
        console.log(err);
      }
    } catch (error) {
      console.log('Error while taking user keypad data', error);
    } finally {
      setTimeout(() => {
        this.isFetchingKeypadData = false;
      }, 2000);
    }
  }

  async load(): Promise<void> {
    try {
      this.loading = true;
      let result = await fetch(
        `${API_URL}/spaces/instance/${this.instanceId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${await this.$store.dispatch(
              'returnToken'
            )}`,
          },
        }
      );
      if (!(result && result.ok)) {
        throw new Error(await result.text());
      }

      let parseRes = await result.json();
      this.instance = parseRes.instance;

      await this.fetchKeypadRelatedData();

      let likesResult = await fetch(
        `${API_URL}/spaces/placeholder-asset-like/${this.instance.ownerAddress}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${await this.$store.dispatch(
              'returnToken'
            )}`,
          },
        }
      );
      if (!(likesResult && likesResult.ok) && likesResult.status !== 401) {
        throw new Error(await likesResult.text());
      }
      let likesParseRes: SpacePlaceholderAssetLikeInfo[] =
        await likesResult.json();
      this.assetLikes = likesParseRes;

      this.instance.placeholders.map((placeholder: any) => {
        const placeholderLikeInfo = likesParseRes.find(
          (like: SpacePlaceholderAssetLikeInfo) =>
            placeholder.assetContract === like.assetContract &&
            placeholder.tokenId === like.tokenId
        );

        const isStaked = this.landlordStakingInfo.currentlyStakedNFTs.find(
          (stakedNft: any) =>
            placeholder.assetContract.toUpperCase() ===
              stakedNft.collectionAddress.toUpperCase() &&
            placeholder.tokenId.toString() === stakedNft.tokenId.toString()
        );

        const isEligibleForStaking =
          this.landlordStakingInfo.activePoolRewards.find(
            (activePoolCollection: any) =>
              activePoolCollection.collectionAddress.toUpperCase() ===
                placeholder.assetContract.toUpperCase() &&
              this.landlordStakingInfo.stakedParcels.length >
                this.landlordStakingInfo.currentlyStakedNFTs.length &&
              !isStaked
          );
        if (
          isEligibleForStaking &&
          !this.meshesOfEligibleNftForStaking.includes(placeholder.meshName)
        ) {
          this.meshesOfEligibleNftForStaking.push(placeholder.meshName);
        }
        placeholder.likeCount = placeholderLikeInfo?.likeCount ?? 0;
        placeholder.likedAt = placeholderLikeInfo?.likedAt ?? null;
        placeholder.likeHistoryId = placeholderLikeInfo?._id ?? null;
        placeholder.isStaked = isStaked ? true : false;
        // placeholder.isStaked = true;
        placeholder.isEligibleForStaking = isEligibleForStaking ? true : false;
      });

      this.assetsPlaced = this.instance.placeholders.map(
        (place: SpacePlaceholder) => {
          return {
            id: place._id,
            meshName: place.meshName,
            assetContract: place.assetContract,
            tokenId: place.tokenId,
          };
        }
      );

      this.loading = false;
    } catch (err) {
      console.error(err);
    }
  }

  async updateAsset(asset: any, meshPlaceholder: any): Promise<void> {
    this.createActions(meshPlaceholder, this.scene);
    const id =
      asset.placeholderId !== undefined ? asset.placeholderId : asset._id;
    if (id) {
      const result = await fetch(`${API_URL}/spaces/placeholder/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${await this.$store.dispatch('returnToken')}`,
        },
        body: JSON.stringify({
          ...asset,
          _id: id,
          meshPosition: {
            position: meshPlaceholder.position,
            meshNormal: new Vector3(0, 0, 0),
          },
          meshScaling: meshPlaceholder._scaling,
        }),
      });
      const parseRes = await result.json();
      if (result.status === 200) {
        const index = this.assetsPlaced.findIndex(
          (assetPlaced) => assetPlaced.meshName === asset.meshName
        );
        this.assetsPlaced[index].assetContract = asset.assetContract;
        this.assetsPlaced[index].tokenId = asset.tokenId;
        this.assetsPlaced[index].id = id;
        this.$store.commit(
          'app/alterNotifications',
          { text: 'Succesfully updated asset', type: 'info', add: true },
          { root: true }
        );
      } else {
        this.$store.commit(
          'app/alterNotifications',
          { text: parseRes.message, type: 'danger', add: true },
          { root: true }
        );
      }
      const assetLikeInfo = this.assetLikes.find(
        (assetLikeInfo: SpacePlaceholderAssetLikeInfo) =>
          assetLikeInfo.assetContract === asset.assetContract &&
          assetLikeInfo.tokenId === asset.tokenId
      );
      const isStaked = this.landlordStakingInfo.currentlyStakedNFTs.find(
        (stakedNft: any) =>
          asset.assetContract.toUpperCase() ===
            stakedNft.collectionAddress.toUpperCase() &&
          asset.tokenId.toString() === stakedNft.tokenId.toString()
      );

      const isEligibleForStaking =
        this.landlordStakingInfo.activePoolRewards.find(
          (activePoolCollection: any) =>
            activePoolCollection.collectionAddress.toUpperCase() ===
              asset.assetContract.toUpperCase() &&
            this.landlordStakingInfo.stakedParcels.length >
              this.landlordStakingInfo.currentlyStakedNFTs.length &&
            !isStaked
        );
      if (
        isEligibleForStaking &&
        !this.meshesOfEligibleNftForStaking.includes(asset.meshName)
      ) {
        this.meshesOfEligibleNftForStaking.push(asset.meshName);
      }
      meshPlaceholder.metadata = {
        ...asset,
        likeCount: assetLikeInfo?.likeCount ?? 0,
        likedAt: assetLikeInfo?.likedAt ?? null,
        likeHistoryId: assetLikeInfo?._id ?? null,
        isStaked: isStaked ? true : false,
        isEligibleForStaking: isEligibleForStaking ? true : false,
        meshScaling: meshPlaceholder.scaling,
        meshPosition: meshPlaceholder.position,
      };

      this.scene
        .getHighlightLayerByName(meshPlaceholder.name + 'like')
        ?.dispose();
      this.scene
        .getHighlightLayerByName(meshPlaceholder.name + 'staked')
        ?.dispose();
      this.scene.getMeshByName(meshPlaceholder.name + '-staked')?.dispose();

      if (isStaked || isEligibleForStaking) {
        createHightlightLayerForStakedNFTs(
          meshPlaceholder,
          meshPlaceholder.metadata,
          this.scene
        );
        createDecalForStaking(meshPlaceholder, this.scene);
        this.meshesOfEligibleNftForStaking.map(
          (meshName: string, index: number) => {
            if (meshName === meshPlaceholder.name) {
              this.meshesOfEligibleNftForStaking.splice(index, 1);
            }
          }
        );
      } else {
        createHightlightLayer(
          meshPlaceholder,
          meshPlaceholder.metadata,
          this.scene
        );
      }
    } else {
      const result = await fetch(`${API_URL}/spaces/placeholder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${await this.$store.dispatch('returnToken')}`,
        },
        body: JSON.stringify({
          ...asset,
          meshPosition: {
            position: meshPlaceholder.position,
            meshNormal: new Vector3(0, 0, 0),
          },
          meshScaling: meshPlaceholder.scaling,
        }),
      });
      const parseRes = await result.json();

      if (result.status === 201) {
        this.assetsPlaced.push({
          id: parseRes.id,
          meshName: asset.meshName,
          assetContract: asset.assetContract,
          tokenId: asset.tokenId,
        });
        this.$store.commit(
          'app/alterNotifications',
          { text: 'Succesfully added asset', type: 'info', add: true },
          { root: true }
        );
      } else {
        this.$store.commit(
          'app/alterNotifications',
          { text: parseRes.message, type: 'danger', add: true },
          { root: true }
        );
      }
      const assetLikeInfo = this.assetLikes.find(
        (assetLikeInfo: SpacePlaceholderAssetLikeInfo) =>
          assetLikeInfo.assetContract === asset.assetContract &&
          assetLikeInfo.tokenId === asset.tokenId
      );
      const isStaked = this.landlordStakingInfo.currentlyStakedNFTs.find(
        (stakedNft: any) =>
          asset.assetContract.toUpperCase() ===
            stakedNft.collectionAddress.toUpperCase() &&
          asset.tokenId.toString() === stakedNft.tokenId.toString()
      );

      const isEligibleForStaking =
        this.landlordStakingInfo.activePoolRewards.find(
          (activePoolCollection: any) =>
            activePoolCollection.collectionAddress.toUpperCase() ===
              asset.assetContract.toUpperCase() &&
            this.landlordStakingInfo.stakedParcels.length >
              this.landlordStakingInfo.currentlyStakedNFTs.length &&
            !isStaked
        );
      if (isEligibleForStaking) {
        this.meshesOfEligibleNftForStaking.push(asset.meshName);
      }
      meshPlaceholder.metadata = {
        ...asset,
        _id: id,
        placeholderId: parseRes.id,
        likeCount: assetLikeInfo?.likeCount ?? 0,
        likedAt: assetLikeInfo?.likedAt ?? null,
        likeHistoryId: assetLikeInfo?._id ?? null,
        isStaked: isStaked ? true : false,
        isEligibleForStaking: isEligibleForStaking ? true : false,
        meshScaling: meshPlaceholder.scaling,
        meshPosition: meshPlaceholder.position,
      };

      if (isStaked || isEligibleForStaking) {
        createHightlightLayerForStakedNFTs(
          meshPlaceholder,
          meshPlaceholder.metadata,
          this.scene
        );
        isStaked && createDecalForStaking(meshPlaceholder, this.scene);
        this.meshesOfEligibleNftForStaking.map(
          (meshName: string, index: number) => {
            if (meshName === meshPlaceholder.name) {
              this.meshesOfEligibleNftForStaking.splice(index, 1);
            }
          }
        );
      } else {
        if (meshPlaceholder.name !== 'placeholder_6') {
          createHightlightLayer(
            meshPlaceholder,
            meshPlaceholder.metadata,
            this.scene
          );
        }
      }
    }
  }
  async createActions(mesh: any, scene: any): Promise<void> {
    mesh.actionManager = new ActionManager(scene);
    mesh.actionManager.registerAction(
      new ExecuteCodeAction(
        ActionManager.OnPickDownTrigger,
        function (): void {}
      )
    );
  }

  createScene(): Scene {
    const scene: any = new Scene(this.engine);
    //tracks if the user can double jump
    var doubleJump = 1;
    scene.autoClear = false; // Color buffer
    // scene.debugLayer.show();
    const skyTex: CubeTexture = new CubeTexture('../../environment/1', scene);
    scene.environmentTexture = skyTex;
    scene.createDefaultSkybox(skyTex, false);

    let meshPlaceholder = undefined as any;
    let loadingTexture = new Texture('../../Loading2.jpg', scene, false, true);
    const activeMeshMaterial = new StandardMaterial('activeMesh', scene);
    activeMeshMaterial.emissiveColor = Color3.Green();
    activeMeshMaterial.disableLighting = true;
    const defaultMeshMaterial = new StandardMaterial('defaultMesh', scene);
    defaultMeshMaterial.emissiveColor = Color3.White();
    defaultMeshMaterial.disableLighting = true;

    var utilLayer = new UtilityLayerRenderer(scene);
    let gizmo = new BoundingBoxGizmo(
      Color3.FromHexString('#FF2473'),
      utilLayer
    );
    var gizmoPosition = new PositionGizmo(utilLayer);
    gizmo.attachedMesh = meshPlaceholder;
    gizmo.setEnabledRotationAxis('');
    gizmo.setEnabledScaling(true, true);
    gizmoPosition.updateScale = true;
    gizmoPosition.zGizmo.dispose();

    // event listener for changing the texture without rerendering
    window.addEventListener('updatePlaceholder', async (event: any) => {
      const asset = event.detail;
      const isAssetPlaced = this.assetsPlaced.find(
        (assetPlaced: any) =>
          asset.assetContract === assetPlaced.assetContract &&
          asset.tokenId === assetPlaced.tokenId
      );
      if (isAssetPlaced) {
        this.$store.commit(
          'app/alterNotifications',
          {
            text: 'Asset already placed in this space!',
            type: 'danger',
            add: true,
          },
          { root: true }
        );
        return;
      }
      const splitResult = (asset.animationUrl || '')
        .split('.')
        .filter((e: any) => e);
      if (
        splitResult.length &&
        (splitResult[splitResult.length - 1] === 'glb' ||
          splitResult[splitResult.length - 1] === 'gltf')
      ) {
        const position = scene.getMeshByName(asset.meshName)?.getBoundingInfo()
          .boundingBox.centerWorld;
        scene.getMeshByName(asset.meshName)?.dispose();
        SceneLoader.ImportMesh(
          '',
          asset.animationUrl,
          '',
          scene,
          (newMeshes) => {
            meshPlaceholder = MeshBuilder.CreateBox(
              asset.meshName,
              { width: 1, height: 1.7, depth: 0.7 },
              scene
            );
            const materialFor3D = new StandardMaterial('3dmaterial', scene);
            materialFor3D.alpha = 0;
            meshPlaceholder.material = materialFor3D;
            newMeshes[0].parent = meshPlaceholder;
            newMeshes[0].scaling = new Vector3(0.04, 0.04, 0.04);
            meshPlaceholder.position = new Vector3(
              position?.x,
              position?.y,
              position?.z
            );
            meshPlaceholder.rotation.y = 3;
            gizmo.setEnabledRotationAxis('y');
            gizmo.attachedMesh = meshPlaceholder;
            gizmoPosition.attachedMesh = meshPlaceholder;
            meshPlaceholder.metadata = {
              asset,
            };
            this.createActions(meshPlaceholder, scene);
            this.updateAsset(asset, meshPlaceholder);
          }
        );
      } else {
        if (asset.meshName === 'placeholder_6') {
          this.$store.commit(
            'app/alterNotifications',
            {
              text: 'The placeholder supports only 3D assets',
              type: 'danger',
              add: true,
            },
            { root: true }
          );
          return;
        }
        scene.getMeshByName(asset.meshName)?.dispose();
        scene.getHighlightLayerByName(asset.meshName + 'like')?.dispose();
        let result;
        if (this.instance.spaceConfig.placeholderGltfRoot !== undefined) {
          result = await SceneLoader.ImportMeshAsync(
            asset.meshName,
            this.instance.spaceConfig.rootUrl !== undefined
              ? this.instance.spaceConfig.rootUrl
              : `${DYSTO_SPACES_ASSETS_URL}/spaces/${this.instance.spaceConfigId}/`,
            this.instance.spaceConfig.placeholderGltfRoot,
            scene
          );
        } else {
          result = await SceneLoader.ImportMeshAsync(
            event.detail.meshName,
            this.instance.spaceConfig.rootUrl !== undefined
              ? this.instance.spaceConfig.rootUrl
              : `${DYSTO_SPACES_ASSETS_URL}/spaces/${this.instance.spaceConfigId}/`,
            this.instance.spaceConfig.gltfRoot,
            scene
          );
        }
        meshPlaceholder = result.meshes[1];
        if (
          splitResult.length &&
          (splitResult[splitResult.length - 1] !== 'glb' ||
            splitResult[splitResult.length - 1] !== 'gif' ||
            splitResult[splitResult.length - 1] !== 'gltf')
        ) {
          const material = new StandardMaterial('mat', scene);
          material.emissiveTexture = this.createVideoTexture(
            asset.animationUrl,
            scene
          );
          material.disableLighting = true;
          meshPlaceholder.material = material;
          this.updateAsset(asset, meshPlaceholder);
        } else {
          scene.getMaterialByName('defaultMesh')?.dispose();
          const newMaterial = new StandardMaterial(
            meshPlaceholder.name + 'material',
            scene
          );
          newMaterial.disableLighting = true;
          const optimisedTextureUrl =
            asset.imageUrl.split('?')[0] + '?w=1000&auto=format';
          const newTexture = new Texture(
            `${DYSTO_PROXY_URL}${optimisedTextureUrl}`,
            scene,
            false,
            true,
            1,
            () => {
              const size = newTexture.getSize();
              calculateSizeToMaintainRatio(
                size.width,
                size.height,
                meshPlaceholder
              );
              newMaterial.emissiveTexture = newTexture;
              newMaterial.opacityTexture = newTexture;
              newMaterial.disableLighting = true;
              newMaterial.opacityTexture.hasAlpha = true;
              meshPlaceholder.material = newMaterial;
              gizmoPosition.attachedMesh = meshPlaceholder;
              this.updateAsset(asset, meshPlaceholder);
            },
            () => {4
              newTexture.updateURL(asset.imageUrl, undefined, () => {
                const size = newTexture.getSize();
                calculateSizeToMaintainRatio(
                  size.width,
                  size.height,
                  meshPlaceholder
                );
              });
              newMaterial.emissiveTexture = newTexture;
              newMaterial.opacityTexture = newTexture;
              newMaterial.disableLighting = true;
              meshPlaceholder.material = newMaterial;
              this.updateAsset(asset, meshPlaceholder);

              this.$store.commit(
                'app/alterNotifications',
                {
                  text: 'Image was not loaded at maximum quality.',
                  type: 'info',
                  add: true,
                },
                { root: true }
              );
            }
          );
          gizmo.attachedMesh = meshPlaceholder;
          newMaterial.emissiveTexture = loadingTexture;
          newMaterial.disableLighting = true;
          meshPlaceholder.material = newMaterial;
        }
      }
    });

    window.addEventListener('cancel', async (event: any) => {
      if (event.detail.placeholderId && this.instanceId) {
        const result = await fetch(
          this.instance.spaceConfig.rootUrl !== undefined
            ? this.instance.spaceConfig.rootUrl
            : `${API_URL}/spaces/placeholder/${this.instance._id}/${event.detail.placeholderId}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${await this.$store.dispatch(
                'returnToken'
              )}`,
            },
          }
        );

        if (result.status === 200) {
          const index = this.assetsPlaced.findIndex(
            (assetPlaced) => assetPlaced.id === event.detail.placeholderId
          );
          this.assetsPlaced.splice(index, 1);
          scene.getMeshByName(event.detail.meshName)?.dispose();
          gizmoPosition.attachedMesh = null;
          gizmo.attachedMesh = null;
          const meshLoaded: any = await SceneLoader.ImportMeshAsync(
            event.detail.meshName,
            `${DYSTO_SPACES_ASSETS_URL}/spaces/${this.instance.spaceConfigId}/`,
            this.instance.spaceConfig.placeholderGltfRoot,
            scene
          );
          if (meshLoaded.meshes[1].name === 'placeholder_6') {
            const material = scene.getMaterialByName('for_placeholder_3d');
            meshLoaded.meshes[1].scaling.x += 20;
            meshLoaded.meshes[1].scaling.y += 20;
            meshLoaded.meshes[1].material = material;
          }
          this.currentMesh = meshLoaded.meshes[1].metadata;
          this.currentMeshName = meshLoaded.meshes[1].name;
          meshPlaceholder = meshLoaded.meshes[1];
          this.createActions(meshLoaded.meshes[1], scene);

          this.$store.commit(
            'app/alterNotifications',
            { text: 'Succesfully removed asset!', type: 'info', add: true },
            { root: true }
          );
        } else {
          this.$store.commit(
            'app/alterNotifications',
            { text: 'Something went wrong!', type: 'danger', add: true },
            { root: true }
          );
        }
      }
    });

    scene.onPointerDown = (evt: any, pickInfo: any) => {
      if (
        evt.button === 0 &&
        !this.engine.isPointerLock &&
        pickInfo.hit &&
        pickInfo.pickedMesh.name.includes('placeholder') &&
        this.isOwner()
      ) {
        if (meshPlaceholder && !meshPlaceholder.metadata.name) {
          meshPlaceholder.material = defaultMeshMaterial;
        } else if (
          meshPlaceholder &&
          meshPlaceholder.metadata.name &&
          gizmo.attachedMesh === meshPlaceholder &&
          gizmoPosition.attachedMesh === meshPlaceholder
        ) {
          gizmo.attachedMesh = null;
          gizmoPosition.attachedMesh = null;
        }
        this.showEditPage = true;
        meshPlaceholder = pickInfo.pickedMesh;
        this.currentMeshName = pickInfo.pickedMesh.name;
        this.currentMesh = pickInfo.pickedMesh.metadata;

        if (meshPlaceholder.metadata.name) {
          gizmoPosition.attachedMesh = meshPlaceholder;
          gizmo.attachedMesh = meshPlaceholder;
        } else {
          meshPlaceholder.material = activeMeshMaterial;
        }
      } else if (
        evt.button === 0 &&
        !this.engine.isPointerLock &&
        pickInfo.hit &&
        !pickInfo.pickedMesh.name.includes('placeholder') &&
        this.isOwner()
      ) {
        this.showEditPage = false;
        gizmo.attachedMesh = null;
        gizmoPosition.attachedMesh = null;
        if (meshPlaceholder !== undefined && !meshPlaceholder.metadata.name) {
          meshPlaceholder.material = defaultMeshMaterial;
        }
        setTimeout(() => {
          this.engine.enterPointerlock();
        }, 700);
      } else {
        setTimeout(() => {
          this.engine.enterPointerlock();
        }, 700);
      }
    };

    //here starts the grid system

    let topLines: any; //the grid lines that need to be drawn on the top
    let centerLines: any; //the grid lines that need to be drawn on center
    let bottomLines: any; //the grid lines that need to be drawn on the bottom
    let closestNft; //the closest nft to the one selected

    //throttle the grid draw function so it triggers only once
    const throttledYHandler = throttle(() => {
      //detect the closest nft
      var selectedNftArray = meshPlaceholder.name.split('_');

      //get the nft on the righ and the nft on the left(from the selected one)
      var leftNftNameString = 'placeholder_' + (selectedNftArray[1] - 1);
      var rightNftNameString =
        'placeholder_' + (parseFloat(selectedNftArray[1]) + parseFloat('1'));

      var leftNft = scene.getMeshByName(leftNftNameString);
      var rightNft = scene.getMeshByName(rightNftNameString);

      //find which one is closer by calculating the minimal distance
      if (leftNft === null && rightNft === null) {
        closestNft = meshPlaceholder;
      } else if (leftNft === null) {
        closestNft = rightNft;
      } else if (rightNft === null) {
        closestNft = leftNft;
      } else {
        var leftToNftDist = Math.abs(
          leftNft.position.z - meshPlaceholder.position.z
        );
        var rightToNftDist = Math.abs(
          rightNft.position.z - meshPlaceholder.position.z
        );
        if (leftToNftDist < rightToNftDist) {
          closestNft = leftNft;
        } else {
          closestNft = rightNft;
        }
      }

      //get the 3 important Y coordinates from where a grid should start
      var centerNftCoordY = meshPlaceholder
        .getBoundingInfo()
        .boundingBox.centerWorld.y.toFixed(2);
      var bottomNftCoordY = meshPlaceholder.position.y.toFixed(2);
      var topNftCoordY =
        2 * parseFloat(centerNftCoordY) - parseFloat(bottomNftCoordY);
      topNftCoordY = parseFloat(topNftCoordY.toFixed(2));

      //get the 3 important Y coordinats from the closest nft
      var centerClosestNftCoordY = closestNft
        .getBoundingInfo()
        .boundingBox.centerWorld.y.toFixed(1);
      var bottomClosestNftCoordY = closestNft.position.y.toFixed(2);
      var topClosestNftCoordY =
        2 * parseFloat(centerClosestNftCoordY) -
        parseFloat(bottomClosestNftCoordY);
      topClosestNftCoordY = parseFloat(topClosestNftCoordY.toFixed(2));

      //if the top Y need to be aligned to the grid
      if (topNftCoordY === topClosestNftCoordY) {
        var myPointsTop = [
          new Vector3(
            -meshPlaceholder.position.x,
            topNftCoordY,
            meshPlaceholder.position.z
          ),
          new Vector3(
            -closestNft.position.x,
            topClosestNftCoordY,
            closestNft.position.z
          ),
        ];

        topLines =
          topLines || MeshBuilder.CreateLines('lines', { points: myPointsTop });
        topLines.color = new Color3(1, 0, 0); //red color
      } else {
        if (topLines) {
          topLines.dispose();
          topLines = undefined;
        }
      }

      //if the center Y need to be aligned to the grid
      if (centerNftCoordY === centerClosestNftCoordY) {
        var myPointsCenter = [
          new Vector3(
            -meshPlaceholder.position.x,
            centerNftCoordY,
            meshPlaceholder.position.z
          ),
          new Vector3(
            -closestNft.position.x,
            centerClosestNftCoordY,
            closestNft.position.z
          ),
        ];

        centerLines =
          centerLines ||
          MeshBuilder.CreateLines('lines', { points: myPointsCenter });
        centerLines.color = new Color3(1, 0, 0); //red color
      } else {
        if (centerLines) {
          centerLines.dispose();
          centerLines = undefined;
        }
      }

      //if the bottom Y need to be aligned to the grid
      if (bottomNftCoordY === bottomClosestNftCoordY) {
        var myPointsBottom = [
          new Vector3(
            -meshPlaceholder.position.x,
            bottomNftCoordY,
            meshPlaceholder.position.z
          ),
          new Vector3(
            -closestNft.position.x,
            bottomClosestNftCoordY,
            closestNft.position.z
          ),
        ];

        bottomLines =
          bottomLines ||
          MeshBuilder.CreateLines('lines', { points: myPointsBottom });
        bottomLines.color = new Color3(1, 0, 0); //red color
      } else {
        if (bottomLines) {
          bottomLines.dispose();
          bottomLines = undefined;
        }
      }
    }, 100);

    // make the event trigger only once
    gizmoPosition.yGizmo.dragBehavior.onDragObservable.add(throttledYHandler);

    gizmoPosition.onDragEndObservable.add(() => {
      //dispose of all the grid lines when the drag ends
      if (topLines !== undefined) {
        topLines.dispose();
      }
      if (centerLines !== undefined) {
        centerLines.dispose();
      }
      if (bottomLines !== undefined) {
        bottomLines.dispose();
      }
      scene
        .getMeshByName(meshPlaceholder.metadata.meshName + '-staked')
        ?.dispose();

      this.updateAsset(meshPlaceholder.metadata, meshPlaceholder);
    });

    gizmo.onScaleBoxDragEndObservable.add(() => {
      scene
        .getMeshByName(meshPlaceholder.metadata.meshName + '-staked')
        ?.dispose();
      this.updateAsset(meshPlaceholder.metadata, meshPlaceholder);
    });

    //here ends the grid system
    const framesPerSecond = 50;
    const gravity = -9.81;
    const roomSettingsGravity =
      this.instance.spaceConfig?.roomSettings?.gravity;
    scene.gravity = new Vector3(0, gravity / framesPerSecond, 0);
    scene.collisionsEnabled = true;

    const position = this.instance.spaceConfig?.roomSettings?.startingPosition;
    const ellipsoidSize =
      this.instance.spaceConfig?.roomSettings?.ellipsoidSize;

    const camera = new UniversalCamera(
      'universal_camera_1',
      new Vector3(
        Number(position?.x),
        Number(position?.y),
        Number(position?.z)
      ),
      scene
    );
    camera.attachControl(this.canvas, true);
    camera.speed = Number(this.instance.spaceConfig.roomSettings?.speed);
    camera.angularSensibility = Number(
      this.instance.spaceConfig.roomSettings?.cameraSensitivity
    );

    //Then apply collisions and gravity to the active camera
    camera.checkCollisions = true;
    camera.applyGravity = true;
    camera.minZ = 0.1;
    camera.inertia = 0.65;
    //Set the ellipsoid around the camera (e.g. your player's size)

    camera.ellipsoid = new Vector3(
      Number(ellipsoidSize?.x),
      Number(ellipsoidSize?.y),
      Number(ellipsoidSize?.z)
    );

    camera.keysUp = [87];
    camera.keysDown = [83]; // S
    camera.keysLeft = [65]; // A
    camera.keysRight = [68]; // D
    //elegant jump
    window.addEventListener('keydown', (e): void => {
      if (camera.position.y < Number(position.y) + 0.5) {
        doubleJump = 1;
      }
      if (e.key === ' ') {
        if (doubleJump > 0) {
          jump();
        }
        doubleJump -= 1;
      }
    });

    //actual jump animation
    function jump() {
      var animation = new Animation(
        'jump',
        'position.y',
        30,
        Animation.ANIMATIONTYPE_FLOAT,
        Animation.ANIMATIONLOOPMODE_CYCLE
      );
      var keys = [
        {
          frame: 0,
          value: camera.position.y,
        },
        {
          frame: 30,
          value: camera.position.y + 1,
        },
      ];
      animation.setKeys(keys);

      var easingFunction = new SineEase();
      easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEOUT);
      animation.setEasingFunction(easingFunction);

      camera.animations.push(animation);
      scene.beginAnimation(camera, 0, 30, false, 2);
    }

    // if character falls under the map respawn it
    scene.onBeforeRenderObservable.add(() => {
      if (camera.position.y < 0) {
        camera.position.set(1.5, 1.5, 1.5);
      }
    });
    //load the gltf court
    const loadModels = (async () => {
      const { meshes } = await SceneLoader.AppendAsync(
        `${DYSTO_SPACES_ASSETS_URL}/spaces/${this.instance.spaceConfigId}/`,
        this.instance.spaceConfig.gltfRoot,
        scene,
        (loaded: any) => {
          const loadingStatus = (
            (loaded.loaded * 100) /
            loaded.total
          ).toFixed();
          this.loadingScreen.updateLoadStatus(loadingStatus);
        }
      );
      this.modelMeshes = meshes;
      this.modelMeshes.map((mesh: AbstractMesh) => {
        if (
          !mesh.name.toUpperCase().includes('COLLIDER') ||
          !mesh.name.toUpperCase().includes('PLACEHOLDER')
        ) {
          mesh.receiveShadows = true;
        }
      });

      if (this.instance.spaceConfig.placeholderGltfRoot !== undefined) {
        SceneLoader.AppendAsync(
          this.instance.spaceConfig.rootUrl !== undefined
            ? this.instance.spaceConfig.rootUrl
            : `${DYSTO_SPACES_ASSETS_URL}/spaces/${this.instance.spaceConfigId}/`,
          this.instance.spaceConfig.placeholderGltfRoot,
          scene
        );
      }
      const options = new SceneOptimizerOptions(50, 2000);
      const textOpt = new TextureOptimization(1, 512, 0.25);
      const postProcessOpt = new PostProcessesOptimization(1);
      const particleOpt = new ParticlesOptimization(1);
      const hardwareOpt = new HardwareScalingOptimization(2, 1.5, 0.1);
      options.addOptimization(textOpt);
      options.addOptimization(new LensFlaresOptimization(0));
      options.addOptimization(postProcessOpt);
      options.addOptimization(particleOpt);
      options.addOptimization(hardwareOpt);

      SceneOptimizer.OptimizeAsync(
        scene,
        options,
        function () {},
        function () {}
      );
    })();

    scene.executeWhenReady(() => {
      for (let i = 0; i < scene.materials.length; ++i) {
        scene.materials[i].maxSimultaneousLights = 10;
      }
      if (this.instance.spaceConfig.lights !== undefined) {
        this.instance.spaceConfig.lights.forEach((light: any) => {
          const spotLight = new SpotLight(
            light.name,
            new Vector3(
              Number(light.position.x),
              Number(light.position.y),
              Number(light.position.z)
            ),
            new Vector3(
              Number(light.direction.x),
              Number(light.direction.y),
              Number(light.direction.z)
            ),
            light.angle,
            light.exponent,
            scene
          );
          spotLight.shadowEnabled = true;
          spotLight.intensity = Number(light.intensity);
          spotLight.shadowMinZ = Number(light.shadows.nearPlane);
          spotLight.shadowMaxZ = Number(light.shadows.farPlane);
          spotLight.diffuse = new Color3(
            Number(light.diffuse.r),
            Number(light.diffuse.g),
            Number(light.diffuse.b)
          );
          const shadowGenerator = new ShadowGenerator(256, spotLight);
          shadowGenerator.bias = Number(light.shadows.bias);
          shadowGenerator.useBlurCloseExponentialShadowMap = true;
          shadowGenerator.blurBoxOffset = Number(light.shadows.blurBoxOffset);

          this.modelMeshes.map((mesh: AbstractMesh) => {
            if (
              !mesh.name.toUpperCase().includes('PLACEHOLDER') ||
              !mesh.name.toUpperCase().includes('COLLIDER') ||
              !mesh.name.toUpperCase().includes('APA')
            ) {
              shadowGenerator.addShadowCaster(mesh, false);
            }
          });
        });
      }
      for (var i = 0; i < scene.meshes.length; i++) {
        if (
          scene.meshes[i].name.toUpperCase().includes('COLLISION') ||
          scene.meshes[i].name.toUpperCase().includes('COLLIDER') ||
          scene.meshes[i].name.toUpperCase().includes('INVISIBLE') ||
          scene.meshes[i].name.toUpperCase().includes('SLIDER')
        ) {
          scene.meshes[i].isVisible = false;
          scene.meshes[i].isPickable = false;
          scene.meshes[i].checkCollisions = true;
        }
        if (scene.meshes[i].name.toUpperCase().includes('PLACEHOLDER')) {
          if (scene.meshes[i].name === 'placeholder_6') {
            scene.meshes[i].scaling.x += 20;
            scene.meshes[i].scaling.y += 20;
            const materialforPlace = new StandardMaterial(
              'for_placeholder_3d',
              scene
            );
            materialforPlace.alpha = 0;
            scene.meshes[i].material = materialforPlace;
          }
          this.createActions(scene.meshes[i], scene);
        }
        if (
          scene.meshes[i].name === 'zid albastru' ||
          scene.meshes[i].name.includes('Sphere')
        ) {
          if (scene.meshes[i].material?.ambientTexture !== null)
            scene.meshes[i].material.ambientTexture.level = 0;
        }
      }
      camera.target = new Vector3(0, 5, 0);
      createTextureOnSceneRendering(this.instance.placeholders, scene);
    });

    const vecToLocal = (vector: any, mesh: any) => {
      const m = mesh.getWorldMatrix();
      const v = Vector3.TransformCoordinates(vector, m);
      return v;
    };

    window.addEventListener('keydown', async (evt: any): Promise<void> => {
      if (evt.key === 'e' && this.activeMesh) {
        if (this.activeMesh.metadata.name && !this.showEditPage) {
          this.showComp = true;
          document.exitPointerLock();
        } else if (this.activeMesh && !this.showEditPage && this.isOwner()) {
          this.showEditPage = true;
          document.exitPointerLock();
        }
      }
      if (
        evt.key === 'l' &&
        this.activeMesh &&
        this.activeMesh.metadata.likedAt === null &&
        !this.showComp &&
        !this.showEditPage
      ) {
        this.likeAssetHandler();
      }
      if (
        evt.key === 'u' &&
        this.activeMesh &&
        this.activeMesh.metadata.likedAt !== null &&
        !this.showComp &&
        !this.showEditPage
      ) {
        this.unlikeAssetHandler();
      }
      if (
        evt.key === 'x' &&
        this.activeMesh &&
        this.activeMesh.metadata.isEligibleForStaking &&
        !this.showComp &&
        !this.showEditPage
      ) {
        this.stakeNFT(this.activeMesh.metadata);
        document.exitPointerLock();
        this.activeMesh = null;
      }
    });

    const castRay = () => {
      const origin = camera.position;
      let forward = new Vector3(0, 0, 1);
      forward = vecToLocal(forward, camera);

      let direction = forward.subtract(origin);
      direction = Vector3.Normalize(direction);
      const length = 2.5;
      const ray = new Ray(origin, direction, length);

      const predicate = (mesh: any) => {
        if (mesh.name.includes('placeholder')) {
          return true;
        }
        return false;
      };

      const hiter = scene.pickWithRay(ray, predicate, true);

      if (hiter?.pickedMesh) {
        this.showDetailsComp = true;
        this.currentMesh = hiter.pickedMesh.metadata;
        this.currentMeshName = hiter.pickedMesh.name;
        this.activeMesh = hiter.pickedMesh;
        if (hiter.pickedMesh.material?.emissiveTexture?.video) {
          hiter.pickedMesh.material.emissiveTexture.video.muted = false;
          this.activeMesh.material.emissiveTexture.video.play();
        }
      } else {
        if (
          this.activeMesh &&
          this.activeMesh.material?.emissiveTexture?.video
        ) {
          this.activeMesh.material.emissiveTexture.video.muted = true;
          this.activeMesh.material.emissiveTexture.video.pause();
        }
        this.showDetailsComp = false;
        this.activeMesh = null;
      }
    };

    let alpha = 0;
    scene.registerBeforeRender(() => {
      castRay();
      this.meshesOfEligibleNftForStaking.forEach((mesh: any) => {
        if (scene.getHighlightLayerByName(mesh + 'staked')) {
          let hl: HighlightLayer = scene.getHighlightLayerByName(
            mesh + 'staked'
          );
          alpha += 0.03;
          hl.blurHorizontalSize = 0.6 + Math.cos(alpha) * 0.6 + 0.6;
          hl.blurVerticalSize = 0.6 + Math.cos(alpha) * 0.6 + 0.6;
        }
      });
    });

    return scene;
  }

  async likeAssetHandler(): Promise<void> {

    const response = await this.$store.dispatch(
      'likeSpacePlaceholderAsset',
      this.activeMesh.metadata.likeHistoryId
    );
    if (!response.authenticated) {
      this.$store.commit(
        'app/alterNotifications',
        { text: response.message, type: 'danger', add: true },
        { root: true }
      );
      return;
    }
    this.activeMesh.metadata.likeCount = response.likeCount;
    this.activeMesh.metadata.likedAt = response.likedAt;
    createDecal(this.activeMesh, this.scene);
    this.$store.commit(
      'app/alterNotifications',
      { text: 'You just liked this asset', type: 'success', add: true },
      { root: true }
    );
    localStorage.setItem(
      this.activeMesh.name,
      JSON.stringify(
        this.scene.getMeshByName(this.activeMesh.name + '-decal')?.position
      )
    );
  }

  async unlikeAssetHandler(): Promise<void> {
    const response = await this.$store.dispatch(
      'unlikeSpacePlaceholderAsset',
      this.activeMesh.metadata.likeHistoryId
    );

    if (response.error) {
      this.$store.commit(
        'app/alterNotifications',
        { text: response.message, type: 'danger', add: true },
        { root: true }
      );
      return;
    }

    this.activeMesh.metadata.likedAt = null;
    this.activeMesh.metadata.likeCount -= 1;
    this.scene.getMeshByName(this.activeMesh.name + '-decal')?.dispose();

    this.$store.commit(
      'app/alterNotifications',
      { text: 'You just unliked this asset', type: 'success', add: true },
      { root: true }
    );
  }

  createVideoTexture(animationUrl: string, scene: Scene): Texture {
    const videoTexture = new VideoTexture(
      'video',
      animationUrl,
      scene,
      false,
      false,
      1,
      {
        autoPlay: true,
        muted: true,
        loop: false,
      },
      (message) => {}
    );
    return videoTexture;
  }

  async stakeNFT(asset: any): Promise<void> {
    this.setModal('stakeNftFromSpace', {
      asset: asset,
      onStakingSucces: this.onStakingSucces,
    });
  }

  onStakingSucces(): void {
    const mesh: any = this.scene.getMeshByName(this.activeMesh.name);
    mesh.metadata.isEligibleForStaking = false;
    mesh.metadata.isStaked = true;
    this.instance.placeholders.map((place: any) => {
      if (place.meshName === mesh.name) {
        place.isEligibleForStaking = false;
        place.isStaked = true;
      }
    });
    this.meshesOfEligibleNftForStaking.map(
      (meshName: string, index: number) => {
        if (meshName === mesh.name) {
          this.meshesOfEligibleNftForStaking.splice(index, 1);
        }
      }
    );
    createDecalForStaking(mesh, this.scene);
  }
}
