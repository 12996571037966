import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "category disable-select" }
const _hoisted_2 = { class: "subcategory" }
const _hoisted_3 = { class: "font-bold text-base" }
const _hoisted_4 = {
  key: 0,
  class: "links"
}
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category, id) => {
      return _withDirectives((_openBlock(), _createElementBlock("div", { key: id }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(`w-full cursor-pointer flex items-center text-white rounded-md px-5 py-3 mb-3 ${
            _ctx.isOpen ? 'bg-grey-2' : ''
          }`),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleOpen && _ctx.toggleOpen(...args)))
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: category.icon,
              class: "mr-4 h-6"
            }, null, 8, ["icon"]),
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.formatLabel(category.label)), 1)
          ], 2),
          (_ctx.isOpen)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.links, (link, id2) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: id2,
                    onClick: ($event: any) => (_ctx.selectedOption(link)),
                    class: _normalizeClass(`cursor-pointer flex justify-between items-center text-sm font-medium rounded-md my-1 px-4 py-3 ml-11 text-white ${
              link.active || _ctx.selected.includes(link.value) ? 'bg-grey-2' : ''
            }`)
                  }, [
                    _createTextVNode(_toDisplayString(link.name) + " ", 1),
                    (link.active || _ctx.selected.includes(link.value))
                      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                          key: 0,
                          icon: "fa-solid fa-x",
                          class: ""
                        }))
                      : _createCommentVNode("", true)
                  ], 10, _hoisted_5))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ])
      ])), [
        [_vShow, category.label === _ctx.title]
      ])
    }), 128))
  ]))
}