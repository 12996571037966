
import SignalStreamIcon from '@/components/icons/signalStream.vue';
import SteakIcon from '@/components/icons/steak.vue';
import {
  PoolHistory,
  PoolReward,
  StakedNFTInfo,
} from '@/store/types/landlordStaking';
import {
  formatEther,
  formatTimestampInSecondsToDateInterval,
  getPoolRewardName,
  isNFTEligibleForPoolReward,
  MIN_STAKED_TIME,
} from '@/utils';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Options, Prop, Vue, Watch } from 'vue-property-decorator';

@Options({
  components: {
    FontAwesomeIcon,
    SteakIcon,
    SignalStreamIcon,
  },
})
export default class NFTCard extends Vue {
  isOpen = false;
  eligiblePoolRewards: PoolReward[] = [];
  presentInMilis = Date.now() / 1000;
  @Prop() nftOption!: StakedNFTInfo;
  @Prop() disabled!: boolean;
  @Prop() poolReward!: PoolReward;
  @Prop() poolHistory!: PoolHistory[];

  get activePoolRewards(): PoolReward[] {
    return this.$store.state.landlordStaking.activePoolRewards;
  }

  get pastPoolRewards(): PoolReward[] {
    return this.$store.state.landlordStaking.pastPoolRewards;
  }

  get rewardsClaimed(): string {
    let result = 0;
    let count = 0;

    this.nftOption.claimedRewardHistory.forEach(
      ({ poolRewardIndex, reward }) => {
        if (
          poolRewardIndex === this.poolReward.index &&
          this.nftOption.collectionAddress === this.poolReward.collectionAddress
        )
          result += parseInt(reward, 10);
        count++;
      }
    );

    return `${formatEther(result)} $DYSTO (${count} entries)`;
  }

  mounted(): void {
    this.computeEligiblePoolRewards();

    setInterval(() => {
      this.presentInMilis = Date.now() / 1000;
    }, 1000);
  }

  @Watch('activePoolRewards')
  @Watch('pastPoolRewards')
  computeEligiblePoolRewards(): void {
    const poolRewards: PoolReward[] = [];
    for (const poolReward of this.pastPoolRewards) {
      if (isNFTEligibleForPoolReward(this.nftOption, poolReward)) {
        poolRewards.push(poolReward);
      }
    }
    for (const poolReward of this.activePoolRewards) {
      if (isNFTEligibleForPoolReward(this.nftOption, poolReward)) {
        poolRewards.push(poolReward);
      }
    }

    this.eligiblePoolRewards = poolRewards;
  }

  toggleOpen(): void {
    this.isOpen = !this.isOpen;
  }

  timestampToDate(timestamp: number): string {
    const endDate = new Date(timestamp * 1000);
    return endDate.toLocaleString();
  }

  get poolRewardName(): string {
    return getPoolRewardName(this.poolReward);
  }

  get canClaimRewards(): boolean {
    // token needs to be at staking
    if (
      this.nftOption.stakingHistory[this.nftOption.stakingHistory.length - 1]
        .unstakedAtTimestamp !== null
    ) {
      return false;
    }

    // token needs to be staked for at least 30 days
    const stakedAt =
      this.nftOption.stakingHistory[this.nftOption.stakingHistory.length - 1]
        .stakedAtTimestamp;
    const now = Math.floor(Date.now() / 1000);
    if (now - stakedAt < MIN_STAKED_TIME) {
      return false;
    }

    this.computeEligiblePoolRewards();

    for (const poolReward of this.eligiblePoolRewards) {
      // can claim reward if the pool reward has ended
      if (poolReward.endTimestamp > now) {
        continue;
      }

      // check if user has claimed reward for this token
      if (
        this.nftOption.claimedRewardHistory.findIndex(
          (claimedReward) => claimedReward.poolRewardIndex === poolReward.index
        ) === -1
      ) {
        return true;
      }
    }

    return false;
  }

  get stakedFor(): string {
    return formatTimestampInSecondsToDateInterval(
      this.presentInMilis -
        this.nftOption.stakingHistory[this.nftOption.stakingHistory.length - 1]
          .stakedAtTimestamp
    );
  }

  get canUnstakeNFT(): boolean {
    if (this.nftOption.stakingHistory.length === 0) {
      return false;
    } else {
      return !this.nftOption.stakingHistory[
        this.nftOption.stakingHistory.length - 1
      ].unstakedAtTimestamp;
    }
  }

  onClaimRewards(event: MouseEvent): void {
    event.stopPropagation();
    if (!this.canClaimRewards) return;

    if (
      this.nftOption.claimedRewardHistory.length >=
      this.eligiblePoolRewards.length
    ) {
      this.$store.commit('app/setModal', {
        component: 'alertMessage',
        payload: {
          title: 'All rewards claimed',
          message: "You can't claim any more rewards for this NFT",
        },
      });
      return;
    }

    let stakedTime = 0;
    const stakedAtTimestamp =
      this.nftOption.stakingHistory[this.nftOption.stakingHistory.length - 1]
        .stakedAtTimestamp;
    const unstakedAtTimestamp =
      this.nftOption.stakingHistory[this.nftOption.stakingHistory.length - 1]
        .unstakedAtTimestamp || Math.floor(Date.now() / 1000);
    stakedTime += unstakedAtTimestamp - stakedAtTimestamp;

    if (
      stakedTime -
        (this.nftOption.claimedRewardHistory.length + 1) * MIN_STAKED_TIME <=
      0
    ) {
      this.$store.commit('app/setModal', {
        component: 'alertMessage',
        payload: {
          title: 'Not enough time staked',
          message:
            'You need to keep the token staked for longer before claiming the next reward',
        },
      });
      return;
    }

    this.$store.dispatch('claimRewardNFT', {
      collectionAddress: this.nftOption.collectionAddress,
      tokenId: this.nftOption.tokenId,
    });
  }

  onUnstakeNFT(event: MouseEvent): void {
    event.stopPropagation();
    if (!this.canUnstakeNFT) return;

    this.computeEligiblePoolRewards();

    if (
      Date.now() / 1000 -
        this.nftOption.stakingHistory[this.nftOption.stakingHistory.length - 1]
          .stakedAtTimestamp <
      MIN_STAKED_TIME
    ) {
      this.$store.commit('app/setModal', {
        component: 'alertMessage',
        payload: {
          title: 'Unstaking Warning',
          message:
            "You didn't keep this token staked for the minimum required time of <b>30 days</b>.<br /> <br /> By unstaking now you won't be eligible for any pool rewards.",
          okMessage: 'Proceed',
          okAction: () =>
            this.$store.dispatch('unstakeNFT', {
              collectionAddress: this.nftOption.collectionAddress,
              tokenId: this.nftOption.tokenId,
            }),
        },
      });
      return;
    } else if (
      this.nftOption.claimedRewardHistory.length <
      this.eligiblePoolRewards.length
    ) {
      for (
        let i = this.nftOption.claimedRewardHistory.length;
        i <= this.eligiblePoolRewards.length - 1;
        i++
      ) {
        const timeDiff =
          this.eligiblePoolRewards[i].endTimestamp - Date.now() / 1000;
        if (timeDiff > 0) {
          this.$store.commit('app/setModal', {
            component: 'alertMessage',
            payload: {
              title: 'Unstaking Warning',
              message: `If you unstake this token now, you won't be eligible for the pool reward <b>${getPoolRewardName(
                this.eligiblePoolRewards[i]
              )}</b> of <b>${formatEther(
                this.eligiblePoolRewards[i].dystoReward
              )} $DYST</b>. <br /> <br /> If you wish to receive a part of the reward you need to keep the token staked for <b>${formatTimestampInSecondsToDateInterval(
                timeDiff
              )}</b>.`,
              okMessage: 'Proceed',
              okAction: () =>
                this.$store.dispatch('unstakeNFT', {
                  collectionAddress: this.nftOption.collectionAddress,
                  tokenId: this.nftOption.tokenId,
                }),
            },
          });
          return;
        }
      }
    }

    this.$store.commit('app/setModal', {
      component: 'alertMessage',
      payload: {
        title: 'Unstaking Warning',
        message: `Are you sure you want to unstake the token #${this.nftOption.tokenId}?`,
        okMessage: 'Proceed',
        okAction: () =>
          this.$store.dispatch('unstakeNFT', {
            collectionAddress: this.nftOption.collectionAddress,
            tokenId: this.nftOption.tokenId,
          }),
      },
    });
  }
}
