
import { Options, Prop, Vue, Watch } from 'vue-property-decorator';
import { NFTOptionInfo, PoolReward } from '@/store/types/landlordStaking';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import NFTCard from './nftCard.vue';
import Loader from '@/components/icons/loader.vue';

@Options({
  components: {
    FontAwesomeIcon,
    NFTCard,
    Loader,
  },
})
export default class PoolStakeAssets extends Vue {
  @Prop() collection!: PoolReward;
  @Prop() title!: string;
  @Prop() hasApproval!: boolean | undefined;
  collections: PoolReward[] = [];

  get nftsSelectedForStaking(): Record<string, string[]> {
    return this.$store.state.landlordStaking.selectedNFTsForStaking;
  }

  set nftsSelectedForStaking(newValue: Record<string, string[]>) {
    this.$store.commit('setSelectedNFTsForStaking', newValue);
  }

  transferApprovals: Record<string, boolean> = {};
  get titles(): string[] {
    if (this.collections.length == 1)
      return ['Select tokens you wish to stake'];
    return this.collections.map((collection) => collection.name);
  }

  get nftsOwned(): NFTOptionInfo[] {
    return this.$store.state.landlordStaking.nftsOwned;
  }

  get selectedPoolReward(): PoolReward | null {
    return this.$store.state.landlordStaking.currentPoolReward;
  }

  get collectionIsEnded(): boolean {
    if (this.selectedPoolReward)
      return this.selectedPoolReward.endTimestamp < Date.now() / 1000;
    return false;
  }

  @Watch('nftsOwned', { immediate: true })
  onNftsOwnedChange(newValue: NFTOptionInfo[]): void {
    this.nftsSelectedForStaking = {};
    const allCollections: PoolReward[] = [];

    const activePoolRewards = this.$store.state.landlordStaking
      .activePoolRewards as PoolReward[];

    newValue.forEach((nft) => {
      let collection = activePoolRewards.find(
        (pool) =>
          pool.collectionAddress.toLowerCase() ===
          nft.collectionAddress.toLowerCase()
      );

      if (collection) {
        const collectionExists = allCollections.find(
          (pool) =>
            pool.collectionAddress.toLowerCase() ===
            nft.collectionAddress.toLowerCase()
        );

        if (!collectionExists) {
          allCollections.push(collection);
        }
      }
    });

    this.collections = allCollections;
  }

  get numberOfSelectedNFTs(): number {
    return Object.values(this.nftsSelectedForStaking).reduce((acc, curr) => {
      return acc + curr.length;
    }, 0);
  }

  toggleNFT(nftId: string, collectionAddress: string): void {
    const selected = this.nftsSelectedForStaking[collectionAddress] || [];

    const index = selected.indexOf(nftId);
    if (index > -1) {
      selected.splice(index, 1);
    } else {
      selected.push(nftId);
    }

    this.nftsSelectedForStaking[collectionAddress] = selected;
  }

  async onApproveTransfer(): Promise<void> {
    if (!this.collection) return;
    const { collectionAddress } = this.collection;
    await this.$store.dispatch('approveTransferOfNFTs', collectionAddress);

    // I could simply watch setWaitForConfirmationTrigger in the top level component
    // But that will result in unnecessary requests, seeing as to how that is used for multiple things
    // And so instead I will do this, arguably slightly more ghetto solution

    const interval = setInterval(async () => {
      if (this.$store.state.landlordStaking.waitForConfirmationTrigger) {
        return;
      }
      const approvedCollection = this.collections.find(
        (collection) => collection.collectionAddress === collectionAddress
      );

      if (approvedCollection) {
        this.$emit('updateApprovalForCollectionAddress', collectionAddress);
      }
      clearInterval(interval);
    }, 500);
  }

  async onStake(): Promise<void> {
    this.$store.dispatch('onStakeSelectedNFTs');
  }
}
