
import CollapseIcon from '@/components/icons/collapse.vue';
import ExpandIcon from '@/components/icons/expand.vue';
import StarIcon from '@/components/icons/star.vue';
import StickerIcon from '@/components/icons/sticker.vue';
import SteakIcon from '@/components/icons/steak.vue';
import { PoolHistory, PoolReward } from '@/store/types/landlordStaking';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Emit, Options, Vue, Watch } from 'vue-property-decorator';

@Options({
  components: {
    StarIcon,
    StickerIcon,
    SteakIcon,
    CollapseIcon,
    ExpandIcon,
    FontAwesomeIcon,
  },
})
export default class LaunchpadCategory extends Vue {
  get activePoolRewards(): PoolReward[] {
    return this.$store.state.landlordStaking.activePoolRewards;
  }

  get pastPoolRewards(): PoolReward[] {
    return this.$store.state.landlordStaking.pastPoolRewards;
  }

  @Watch('$route', { immediate: true, deep: true })
  @Watch('activePoolRewards', { deep: true, immediate: true })
  @Watch('pastPoolRewards', { deep: true, immediate: true })
  onRouteOrRewardsChange(): void {
    // if route name is StakingParcels, return
    if (
      this.$route.name !== 'StakingNFTs' &&
      this.$route.name !== 'StakingNFTsSlug'
    )
      return;

    const slug = this.$route.params.slug as string;
    const newActivePoolRewardsLinks = this.activePoolRewards.map(
      ({ id, name, endTimestamp, createdAt, assetsStaked }) => {
        const createdAtString = new Date(createdAt).toLocaleDateString();
        const endsOn = new Date(endTimestamp * 1000).toLocaleDateString();
        return {
          id,
          name,
          subcategory: 'active',
          active: slug && slug === id,
          hasStaked: assetsStaked > 0,
          createdAt: createdAtString,
          endsOn,
        };
      }
    );

    const newPastPoolRewardsLinks = this.pastPoolRewards.map(
      ({ id, name, endTimestamp, createdAt, assetsStaked }) => {
        const createdAtString = new Date(createdAt).toLocaleDateString();
        const endsOn = new Date(endTimestamp * 1000).toLocaleDateString();
        return {
          id,
          name,
          subcategory: 'past',
          active: slug && slug === id,
          hasStaked: assetsStaked > 0,
          createdAt: createdAtString,
          endsOn,
        };
      }
    );

    const allCollections: any[] = [];

    this.activePoolRewards.forEach(({ name, id, collectionAddress }) => {
      if (
        !allCollections.find(
          ({ collectionAddress: allCollectionsAddress }) =>
            collectionAddress === allCollectionsAddress
        )
      )
        allCollections.push({
          id: 'staked-' + id,
          name,
          active: slug && slug === 'staked-' + id,
          subcategory: 'stakedNFTs',
          collectionAddress,
        });
    });

    this.pastPoolRewards.forEach(({ name, id, collectionAddress }) => {
      if (
        !allCollections.find(
          ({ collectionAddress: allCollectionsAddress }) =>
            collectionAddress === allCollectionsAddress
        )
      )
        allCollections.push({
          id: 'staked-' + id,
          name,
          active: slug && slug === 'staked-' + id,
          subcategory: 'stakedNFTs',
          collectionAddress,
        });
    });

    this.computeHistories(
      allCollections,
      this.activePoolRewards,
      this.pastPoolRewards
    );

    this.poolRewardsSubcategoriesArray[0].links = newActivePoolRewardsLinks;
    this.poolRewardsSubcategoriesArray[1].links = newPastPoolRewardsLinks;
    this.poolRewardsSubcategoriesArray[2].links = [
      {
        id: 'staked-all',
        name: 'All',
        active: slug && slug === 'staked-all',
        subcategory: 'stakedNFTs',
        collectionAddress: 'all',
      },
      ...allCollections,
    ];

    if (!slug) {
      if (newActivePoolRewardsLinks.length)
        this.changeCurrentPoolReward(newActivePoolRewardsLinks[0].id);
      else if (newPastPoolRewardsLinks.length)
        this.changeCurrentPoolReward(newPastPoolRewardsLinks[0].id);
    } else {
      this.$store.commit('setCurrentPoolReward', slug);
    }
  }

  computeHistories(
    allCollections: any[],
    activePoolRewards: PoolReward[],
    pastPoolRewards: PoolReward[]
  ): void {
    allCollections.forEach((collection) => {
      const allPoolsforCollection = this.findAllPoolsForCollection(
        collection,
        activePoolRewards,
        pastPoolRewards
      );

      let history: PoolHistory[] = [];

      allPoolsforCollection.forEach((pool) => {
        history = this.sortCollectionHistory(
          history,
          pool.createdAtTimestamp,
          pool.endTimestamp,
          pool.assetsStaked
        );
      });

      this.$store.commit('setCollectionHistory', {
        collectionAddress: collection.collectionAddress,
        history,
      });
    });
  }

  unmounted(): void {
    this.$store.commit('clearCollectionHistories');
  }

  findAllPoolsForCollection = (
    collection: PoolReward,
    activePoolRewards: PoolReward[],
    pastPoolRewards: PoolReward[]
  ): PoolReward[] => {
    const allPools: PoolReward[] = [];

    activePoolRewards.forEach((pool) => {
      if (
        pool.collectionAddress.toLowerCase() ===
        collection.collectionAddress.toLowerCase()
      ) {
        allPools.push(pool);
      }
    });

    pastPoolRewards.forEach((pool) => {
      if (
        pool.collectionAddress.toLowerCase() ===
        collection.collectionAddress.toLowerCase()
      ) {
        allPools.push(pool);
      }
    });

    return allPools;
  };

  sortCollectionHistory(
    history: PoolHistory[] | undefined,
    newCollectionCreatedAtTimestamp: number,
    newCollectionEndTimestamp: number,
    assetsStaked: number
  ): PoolHistory[] {
    if (!history || history.length === 0)
      return [
        {
          startTimestamp: newCollectionCreatedAtTimestamp,
          endTimestamp: newCollectionEndTimestamp,
          assetsStaked,
        },
      ];

    for (let i = 0; i < history.length; i++) {
      if (history[i].endTimestamp < newCollectionEndTimestamp) {
        history.splice(i, 0, {
          startTimestamp: newCollectionCreatedAtTimestamp,
          endTimestamp: newCollectionEndTimestamp,
          assetsStaked,
        });
        break;
      }
    }
    return history;
  }

  changeCurrentPoolReward(newId: string): void {
    // change route
    this.$router.push(`/landlord-staking/nfts/${newId}`);
  }

  poolRewardsSubcategoriesArray: any = [
    {
      icon: 'fa-solid fa-fire-flame-curved',
      label: 'Live Pool Rewards',
      name: 'active',
      open: true,
      links: [],
    },
    {
      icon: 'fa-solid fa-clock-rotate-left',
      label: 'Past Pool Rewards',
      name: 'past',
      open: false,
      links: [],
    },
    {
      icon: 'fa-solid fa-layer-group',
      label: 'My Staked NFTs',
      name: 'stakedNFTs',
      open: false,
      links: [],
    },
  ];
}
