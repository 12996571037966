
import { Vue, Options, Watch } from 'vue-property-decorator';
import {
  NFTStakingHistory,
  PoolReward,
  StakedNFTInfo,
} from '@/store/types/landlordStaking';
import Title from '@/components/basic/title.vue';
import AccordionTitle from '@/components/shared/accordionTitle.vue';
import Loader from '@/components/icons/loader.vue';
import StakedNFTView from '@/components/shared/stakedNFTView.vue';
import StakeNFTsMenu from '@/components/shared/stakeNFTsMenu.vue';
import { formatEther, getPoolRewardName } from '@/utils';

@Options({
  components: { Title, AccordionTitle, Loader, StakedNFTView, StakeNFTsMenu },
})
export default class PoolRewardInfo extends Vue {
  isDisabled = false;
  userHasTokens = false;
  hasApprovalForTransfer = false;
  onApprovalTx = false;
  onStakeTx = false;
  tokenIdsToStake = [{ value: '' }];
  loaderUserInfoAboutPoolReward = true;
  viewsOpen = {
    description: true,
    activePoolEligibleNFTs: true,
    pastPoolEligibleNFTS: true,
  };

  async beforeMount(): Promise<void> {
    this.refreshUserInfoAboutPoolReward();
  }

  get waitingForConfirmation(): boolean {
    return this.$store.state.landlordStaking.waitForConfirmationTrigger;
  }

  @Watch('waitingForConfirmation')
  async onWaitingForConfirmationChange(): Promise<void> {
    this.isDisabled = this.waitingForConfirmation;
    if (!this.isDisabled && this.onApprovalTx) {
      this.onApprovalTx = false;
      await this.refreshUserInfoAboutPoolReward();
    }
    if (!this.isDisabled && this.onStakeTx) {
      this.onStakeTx = false;
      this.tokenIdsToStake = [{ value: '' }];
    }
  }

  get poolReward(): PoolReward | null {
    return this.$store.state.landlordStaking.currentPoolReward;
  }

  @Watch('poolReward')
  async poolRewardChanged(): Promise<void> {
    this.resetViewsOpen();
    await this.refreshUserInfoAboutPoolReward();
  }

  resetViewsOpen(): void {
    this.viewsOpen.activePoolEligibleNFTs = true;
    this.viewsOpen.pastPoolEligibleNFTS = true;
  }

  async refreshUserInfoAboutPoolReward(): Promise<void> {
    this.loaderUserInfoAboutPoolReward = true;
    this.userHasTokens = await this.$store.dispatch(
      'checkUserHasNFTs',
      this.poolReward?.collectionAddress
    );
    this.hasApprovalForTransfer = await this.$store.dispatch(
      'checkApprovalForTransferOfNFTs',
      this.poolReward?.collectionAddress
    );
    this.loaderUserInfoAboutPoolReward = false;
  }

  get stakedNFTs(): StakedNFTInfo[] {
    return this.$store.state.landlordStaking.stakedNFTs;
  }

  get poolRewardName(): string {
    if (this.poolReward) {
      return getPoolRewardName(this.poolReward);
    }
    return '';
  }

  get poolRewardValue(): string {
    if (this.poolReward) {
      return formatEther(this.poolReward.dystoReward);
    }
    return '';
  }

  timestampToDate(timestamp: number): string {
    const endDate = new Date(timestamp * 1000);
    return endDate.toLocaleString();
  }

  isNumber(event: KeyboardEvent): void {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  }

  addTokenIdToStake(): void {
    this.tokenIdsToStake.push({ value: '' });
  }

  removeTokenIdToStake(index: number): void {
    this.tokenIdsToStake.splice(index, 1);
  }

  get hasPoolRewardEnded(): boolean {
    if (this.poolReward) {
      return this.poolReward.endTimestamp < Date.now() / 1000;
    }
    return false;
  }

  get activeStakedNFTs(): StakedNFTInfo[] {
    if (this.poolReward) {
      return this.stakedNFTs.filter((nft: StakedNFTInfo) => {
        return (
          nft.stakingHistory[nft.stakingHistory.length - 1]
            .unstakedAtTimestamp === null
        );
      });
    }
    return [];
  }

  get eligibleNFTs(): StakedNFTInfo[] {
    if (this.poolReward) {
      return this.stakedNFTs.filter((nft) => {
        if (this.poolReward?.collectionAddress !== nft.collectionAddress) {
          return false;
        }

        const activeOnPoolReward =
          nft.stakingHistory.filter((history: NFTStakingHistory) => {
            if (
              history.stakedAtTimestamp <= this.poolReward!.endTimestamp &&
              (history.unstakedAtTimestamp === null ||
                history.unstakedAtTimestamp >= this.poolReward!.endTimestamp)
            ) {
              return true;
            }
            return false;
          }).length > 0;

        return activeOnPoolReward;
      });
    }
    return [];
  }

  get notEligibleNFTs(): StakedNFTInfo[] {
    if (this.poolReward) {
      const eligibleNFTs = this.eligibleNFTs.map((nft) => nft.tokenId);
      return this.stakedNFTs
        .filter((nft) => {
          if (this.poolReward?.collectionAddress !== nft.collectionAddress) {
            return false;
          }
          return true;
        })
        .filter((nft) => {
          return !eligibleNFTs.includes(nft.tokenId);
        });
    }
    return [];
  }

  approveNFTsTransfer(): void {
    this.$store.dispatch(
      'approveTransferOfNFTs',
      this.poolReward?.collectionAddress
    );
    this.onApprovalTx = true;
  }

  async stakeNFTs(): Promise<void> {
    if (
      this.activeStakedNFTs.length + this.tokenIdsToStake.length >
      this.$store.state.landlordStaking.stakedParcels.length
    ) {
      this.$store.commit('app/setModal', {
        component: 'alertMessage',
        payload: {
          title: 'Not enough staked parcels',
          message: `You need to stake ${
            this.activeStakedNFTs.length +
            this.tokenIdsToStake.length -
            this.$store.state.landlordStaking.stakedParcels.length
          } more parcels to stake the tokens`,
        },
      });
      return;
    }

    // check if all tokenIds are valid and owned by the user
    for (const tokenId of this.tokenIdsToStake) {
      const tokenIdAsNumber = Number(tokenId.value);
      if (tokenId.value === '' || isNaN(tokenIdAsNumber)) {
        this.$store.commit('app/setModal', {
          component: 'alertMessage',
          payload: {
            title: 'Token IDs must be numbers',
            message:
              "Please enter only valid token IDs. Don't leave any field empty, you can remove them.",
          },
        });
        return;
      }

      const tokenOwnedByUser = await this.$store.dispatch(
        'checkTokenOwnedByUser',
        {
          collectionAddress: this.poolReward!.collectionAddress,
          tokenId: tokenId.value,
        }
      );
      if (!tokenOwnedByUser) {
        this.$store.commit('app/setModal', {
          component: 'alertMessage',
          payload: {
            title: 'Not all the token IDs are owned by you',
            message: `Please enter only token IDs that you own. You don't own the token ${tokenIdAsNumber}!`,
          },
        });
        return;
      }
    }

    if (this.tokenIdsToStake.length === 0) {
      return;
    } else if (this.tokenIdsToStake.length === 1) {
      this.$store.dispatch('stakeNFT', {
        collectionAddress: this.poolReward!.collectionAddress,
        tokenId: this.tokenIdsToStake[0].value,
      });
    } else {
      const nftsInfo = this.tokenIdsToStake.map((tokenId) => {
        return {
          collectionAddress: this.poolReward!.collectionAddress,
          tokenId: tokenId.value,
        };
      });
      this.$store.dispatch('stakeBulkNFTs', nftsInfo);
    }
    this.onStakeTx = true;
  }
}
