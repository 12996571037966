
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Options, Vue } from 'vue-property-decorator';

@Options({
  components: {
    FontAwesomeIcon,
  },
})
export default class HomeVideo extends Vue {}
