
import { Vue, Options, Watch } from 'vue-property-decorator';
import Banner from '../../banner.vue';
import Dropdown from '../../dropdown.vue';
import EllipsisIcon from '@/components/icons/ellipsis.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

enum dropdownType {
  CURRENCY = 'CURRENCY',
  BLOCKS = 'BLOCKS',
}

@Options({
  components: {
    Banner,
    Dropdown,
    EllipsisIcon,
    FontAwesomeIcon,
  },
})
export default class editProfile extends Vue {
  editBool = false;
  picture_hover = false;
  copy_parcel = {};
  parcel = {} as any;
  image_parcel_data = '';
  file_parcel_form = {};
  isMulti = false;
  hasInstanceMounted = false;
  link = '';
  isMoreOpen = false;

  options = [] as any;

  availableSizes = [
    {
      label: '1 x 1',
      type: 'BLOCKS',
      value: 1,
    },
    {
      label: '3 x 3',
      type: 'BLOCKS',
      value: 3,
    },
    {
      label: '6 x 6',
      type: 'BLOCKS',
      value: 6,
    },
    {
      label: '12 x 12',
      type: 'BLOCKS',
      value: 12,
    },
  ];
  option_active = null;

  @Watch('parcel', { deep: true })
  watch_parcel(parcel: any) {
    if (parcel) {
      this.options = this.availableSizes.filter(
        (e) =>
          e.value < (this as any).parcel.size && (this as any).parcel.size > 1
      );
      if (this.options.length > 0) {
        this.option_active = this.options[0];
      }
    }
  }

  get wallet() {
    return this.$store.state.wallet;
  }

  updateOption(option: any) {
    switch (option.type) {
      case dropdownType.BLOCKS:
        this.option_active = option;
        // this.$store.commit("app/setMintingParcels", {
        //   blocks: option,
        // });
        break;
    }
  }

  setModal(modal: any) {
    this.$store.commit('app/setModal', { component: modal, payload: null });
  }

  close() {
    this.setModal('');
    Object.assign(this.parcel, this.copy_parcel);
  }

  discard() {
    this.editBool = !this.editBool;
    this.isMulti = false;
    Object.assign(this.parcel, this.copy_parcel);
  }

  edit() {
    this.editBool = !this.editBool;
  }

  save() {
    // if ((this as any).parcel.owner === this.wallet) {
    //   return;
    // }

    const details = {
      ...this.parcel,
      image: this.file_parcel_form || {},
      splitOption: null,
      opensea: '',
    };

    // if (this.isMulti) {
    //   } else {
    //     this.$store.dispatch("updateParcel", details);
    // }
    this.$store.dispatch('updateParcelMulti', details);

    this.setModal('');
  }

  goTo(link: string) {
    if (link) {
      window.open(link, '_blank');
    }
  }

  updateImage(event: any) {
    const files = event.target.files;
    const formData = new FormData();
    const reader = new FileReader();
    this.isMulti = true;

    if (!files.length) return;

    formData.append('image', files[0], files[0].name);

    this.file_parcel_form = formData;

    reader.onload = (e: any) => {
      this.image_parcel_data = e.target.result;
    };

    reader.readAsDataURL(files[0]);
  }

  changeImage() {
    const file = this.$refs.file as HTMLElement;
    file.click();
  }

  unmounted() {
    this.discard();
  }

  clearLastHovered() {
    this.$store.commit('app/setLastHovered', null);
  }

  async verify() {
    try {
      const verify = await this.$store.dispatch(
        'verify3DModel',
        (this as any).parcel.id
      );

      if (!verify) {
        throw new Error();
      }

      this.hasInstanceMounted = verify.hasInstanceMounted;
      this.link = verify.data.link;
    } catch (err) {
      // this.$store.commit("app/alterNotifications", { text: "The mount/edit 3D model operations are not  available", type: "danger", add: true });
    }
  }

  created() {
    this.parcel = Object.assign({}, this.$store.state.app.modal?.payload);
    this.copy_parcel = Object.assign({}, this.$store.state.app.modal?.payload);

    this.verify();
  }

  triggerMore() {
    this.isMoreOpen = !this.isMoreOpen;
  }
}
