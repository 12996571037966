
import { getChainNativeCurrencyNameByChainId } from '@/utils/blockchain';
import { Vue, Options, Prop, Watch } from 'vue-property-decorator';
import CheckIcon from '../icons/check.vue';
import HamburgerIcon from '../icons/hamburger.vue';
import MetamaskIcon from '../icons/metamask.vue';
import WalletConnectIcon from '../icons/walletconnect.vue';
import LogoIcon from '../icons/logo.vue';
import Balance from './balance.vue';
import BarNavigation from './barnavigation.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faRobot } from '@fortawesome/free-solid-svg-icons';
import eventBus from '@/eventBus';

library.add(faRobot);

@Options({
  components: {
    BarNavigation,
    Balance,
    CheckIcon,
    LogoIcon,
    HamburgerIcon,
    MetamaskIcon,
    WalletConnectIcon,
    FontAwesomeIcon,
  },
})
export default class Navbar extends Vue {
  @Prop(String) readonly logoClass!: string;
  address = '0xf2b5656EC7ab88b098defB751B7401B5f6d89946';
  menu = false;
  userMenu = false;
  showUserBalance = false;
  userBalances = {
    $DYST: 0,
    chainNativeCurrency: 0,
  };
  chainNativeCurrencyName = 'ETH';
  previousWindowWidth = window.innerWidth;

  async beforeMount(): Promise<void> {
    if (!this.user) {
      this.showUserBalance = false;
      return;
    }

    await this.onChainIdChanged();
  }

  created(): void {
    window.addEventListener('resize', this.windowResizeHandler);
    this.previousWindowWidth = window.innerWidth;
  }

  destroyed(): void {
    window.removeEventListener('resize', this.windowResizeHandler);
  }

  windowResizeHandler(): void {
    if (
      window.innerWidth > this.previousWindowWidth &&
      window.innerWidth > 768
    ) {
      // this.$store.commit('app/setOptionBar', true);
    }

    this.previousWindowWidth = window.innerWidth;
  }

  get chainId(): number {
    return this.$store.state.chainId;
  }

  @Watch('chainId')
  async onChainIdChanged(): Promise<void> {
    this.chainNativeCurrencyName = getChainNativeCurrencyNameByChainId(
      this.chainId
    );
    await this.updateUserBalances();
  }

  get formatAddress() {
    if (!this.user) {
      return '';
    }
    return (
      this.user.address.substring(0, 6) +
      '...' +
      this.user.address.substring(
        this.user.address.length - 3,
        this.user.address.length
      )
    );
  }

  get layout() {
    return this.$route.meta.layout;
  }

  get metamask() {
    return this.$store.state.metamask;
  }

  get user() {
    return this.$store.state.user;
  }

  @Watch('user', { deep: true })
  async onUserChanged(value: any): Promise<void> {
    if (!value) {
      this.showUserBalance = false;
      return;
    }
    await this.updateUserBalances();
  }

  async updateUserBalances(): Promise<void> {
    this.showUserBalance = false;
    const dystBalance: string = await this.$store.dispatch(
      'getUserDystoWorldTokenBalance'
    );
    const nativeBalance: string = await this.$store.dispatch(
      'getUserBalanceOfNativeCurrency'
    );
    this.userBalances.$DYST = parseFloat(dystBalance || '0');
    this.userBalances.chainNativeCurrency = parseFloat(nativeBalance || '0');
    this.showUserBalance = true;
  }

  setMenu(): void {
    this.menu = !this.menu;
  }

  setUserMenu(): void {
    this.userMenu = !this.userMenu;
  }

  profile(): void {
    this.setModal('editProfile', null);
  }

  goToBecomeInfluencerPage(): void {
    this.$router.push('/influencer');
    this.setUserMenu();
  }

  openConnectWalletModal(): void {
    this.$store.commit('app/setModal', {
      component: 'ConnectWallet',
      payload: {},
    });
  }

  connect(action: string): void {
    this.$store.dispatch(action);
  }

  disconnect(): void {
    // if (this.metamask) {
    this.$store.dispatch('disconnectWallet');
    eventBus.emit('logout', null);
    // }
    this.setMenu();
  }

  setModal(modal: any, payload?: any) {
    this.$store.commit('app/setModal', { component: modal, payload });
    this.setMenu();
  }

  get optionBar() {
    if (this.layout === 'Basic' || this.layout === 'Staking') {
      return this.menu;
    }
    return this.$store.state.app.Option_Bar;
  }

  setOptionBar() {
    if (this.layout === 'Basic' || this.layout === 'Staking') {
      this.setMenu();
      return;
    }
    this.$store.commit('app/setOptionBar', !this.optionBar);
  }

  alertComingSoon(): boolean {
    this.$store.commit(
      'app/alterNotifications',
      { text: 'Coming soon!', type: 'success', add: true },
      { root: true }
    );
    return false;
  }

  stakingButton() {
    this.$router.push('/landlord-staking/parcels');
  }

  aiChatButton() {
    this.$router.push('/landlord-staking/chat');
  }

  vestingButton() {
    if (this.$store.state.user.vestingBeneficiary) {
      this.$router.push('/vesting');
    }
  }
}
