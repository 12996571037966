
import { Options, Vue, Watch } from 'vue-property-decorator';
import { NFTOptionInfo, PoolReward } from '@/store/types/landlordStaking';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import NFTCard from './nftCard.vue';
import Loader from '@/components/icons/loader.vue';
import StakeAssets from './stakeAssets.vue';

@Options({
  components: {
    FontAwesomeIcon,
    NFTCard,
    Loader,
    StakeAssets,
  },
})
export default class PoolsStake extends Vue {
  collections: PoolReward[] = [];
  transferApprovals: Record<string, boolean> = {};
  get titles(): string[] {
    if (this.collections.length == 1)
      return ['Select tokens you wish to stake'];
    return this.collections.map((collection) => collection.name);
  }

  get nftsOwned(): NFTOptionInfo[] {
    return this.$store.state.landlordStaking.nftsOwned;
  }

  get selectedPoolReward(): PoolReward | null {
    return this.$store.state.landlordStaking.currentPoolReward;
  }

  get collectionIsEnded(): boolean {
    if (this.selectedPoolReward)
      return this.selectedPoolReward.endTimestamp < Date.now() / 1000;
    return false;
  }

  @Watch('nftsOwned', { immediate: true })
  onNftsOwnedChange(newValue: NFTOptionInfo[]): void {
    this.$store.commit('setSelectedNFTsForStaking', {});
    const allCollections: PoolReward[] = [];

    const activePoolRewards = this.$store.state.landlordStaking
      .activePoolRewards as PoolReward[];

    newValue.forEach((nft) => {
      ///TODO
      let collection = activePoolRewards.find(
        (pool) =>
          pool.collectionAddress.toLowerCase() ===
          nft.collectionAddress.toLowerCase()
      );

      if (collection) {
        const collectionExists = allCollections.find(
          (pool) =>
            pool.collectionAddress.toLowerCase() ===
            nft.collectionAddress.toLowerCase()
        );

        if (!collectionExists) {
          allCollections.push(collection);
        }
      }
    });

    this.collections = allCollections;
  }

  @Watch('collections', { immediate: true })
  onCollectionsChange(newValue: PoolReward[] | string[]): boolean {
    let result = true;
    newValue.forEach(async (collection: PoolReward | string) => {
      const collectionAddress =
        typeof collection === 'string'
          ? collection
          : collection.collectionAddress;
      const hasApprovalForTransfer = await this.$store.dispatch(
        'checkApprovalForTransferOfNFTs',
        collectionAddress
      );
      this.transferApprovals[collectionAddress] = hasApprovalForTransfer;
      result = result && hasApprovalForTransfer;
    });

    return result;
  }
}
