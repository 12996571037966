import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "append-icon center-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeOpen && _ctx.changeOpen(...args))),
    class: "accordion-container flex justify-space-between items-center"
  }, [
    _createVNode(_component_Title, { text: _ctx.title }, null, 8, ["text"]),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.open ? 'CollapseIcon' : 'ExpandIcon')))
    ])
  ]))
}