import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GenericHeader = _resolveComponent("GenericHeader")!

  return (_ctx.user)
    ? (_openBlock(), _createBlock(_component_GenericHeader, {
        key: 0,
        imageUrl: _ctx.imageUrl,
        defaultAvatar: _ctx.isDefaultAvatar,
        layout: _ctx.layout,
        address: _ctx.user.address,
        poolData: _ctx.poolData
      }, null, 8, ["imageUrl", "defaultAvatar", "layout", "address", "poolData"]))
    : _createCommentVNode("", true)
}