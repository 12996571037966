
import Footer from '@/components/shared/footer.vue';
import { Vue, Options } from 'vue-property-decorator';
import TitleBasic from '../components/basic/title.vue';
import Banner from '../components/shared/banner.vue';
import GameItem from '../components/shared/gameItem.vue';
import Property from '../components/shared/property.vue';

@Options({
  components: {
    Banner,
    TitleBasic,
    Property,
    GameItem,
    Footer,
  },
})
export default class Home extends Vue {
  banner = {
    image: 'assets/images/banner_4.png',
    title: 'Dystowarz',
    description:
      'Dystowarz takes place in the year 2145. Since our times, carbon emissions and greenhouse gases rose to critical levels. This rendered the temperature unbearable outside the human-made shelters. Trees are mostly burnt and gamma radiation engulfs our once blue planet. The remaining survivors live in shelters named Tech Stations. Tech Stations, in turn, ally with each other to form Districts, and get a better chance at survival. Inside the Tech Stations, humans synthesise the required resources for survival. The prime matter became a luxury, rather than a given. Scarcity of resources and harsh life bred conflict between existing different Districts.',
    soldout: false,
    minted: {
      progress: 2850,
      total: 5000,
    },
    featured: true,
  };

  properties_1 = [
    {
      image: 'assets/images/home_simple_item_1.png',
      title: 'PLAY TO EARN $DYSTO',
      description: `DystoWorld aims to provide its users with a genuine experience and enable them to climb the power ladder of a dystopian Metaverse in which both their creativity and strategy can manifest. <b>$DYSTO</b> will aid players in monetizing their digital experience and exercise their ownership in the <b>ecosystem governance.</b>`,
    },
    {
      image: 'assets/images/home_simple_item_2.png',
      title: 'BUY NEW ASSETS OR PARCELS USING $DYSTO ',
      description: `DystoWorld Portal will offer incentives in $DYSTO for users who interact with the environment. These rewards will be <b>amplified by owning at least one parcel</b>. Buying digital assets from our Launchpad will also qualify you for some $DYSTO tokens.`,
    },
    {
      image: 'assets/images/home_simple_item_3.png',
      title: 'STAKE $DYSTO FOR BETTER IN-GAME REWARDS',
      description: `Staking $DYSTO yields rewards. Owning PARCELS at the time of staking, as well as at the time of un-staking grants even <b>higher APR</b>. We do not restrict the sales in the Launchpad to $DYSTO only, however, there is a <b>substantial fee reduction</b> when using it to buy/mint.`,
    },
  ];

  properties_2 = [
    {
      image: 'assets/images/home_simple_item_4.png',
      title: 'STAKE $DYSTO AND ACCESS OUR LAUNCHPAD ',
      description: `We recognize the difficulty of promoting and getting together a <b>community</b> to play and support a newly launched game. That’s why, similar to a Launchpad for newly created tokens, we use DystoWorld Launchpad to <b>help game creators</b> sell the digital goods provided by their games to our community, through our platform.`,
    },
    {
      image: 'assets/images/home_simple_item_5.png',
      title: 'SELL YOUR ASSETS LEVERAGING OUR  CONTRACTS',
      description: `Besides multiple minting strategy templates, our smart contracts <b>integrate rewards</b> in $DYSTO with collections or games, <b>aid trading</b> digital goods for $DYSTO, <b>staking</b> digital goods for $DYSTO and provide many other interactions that help games to integrate our Governance Token into their ecosystem.`,
    },
    {
      image: 'assets/images/home_simple_item_6.png',
      title: 'BUILD YOUR DIGITAL WORLD USING OUR METAVERSE PORTAL ',
      description: `Parcels enable owners to <b>host games</b> and to <b>sell assets</b> in the DystoWorld Launchpad. Moreover, parcel owners will be able to customize their parcel aspect in DystoWorld Portal (by uploading a logo or a profile picture), which will then be visible on the 2D map. Also, any <b>glTF</b> compatible <b>model</b> can be mounted.`,
    },
  ];

  parcels = []; /* [
    {
      image: 'assets/images/parcel_1.png',
      title: 'Metascraper',
      subtitle: '24x24 • [20, 215] ',
      parcel: true,
      actionTitle: 'Explore parcel #888',
      minted: {
        progress: 2850,
        total: 5000,
      },
      showOpensea: true,
    },
    {
      image: 'assets/images/parcel_2.png',
      title: 'DeadFellaz',
      subtitle: '24x24 • [20, 15] ',
      parcel: true,
      actionTitle: 'Explore parcel #1237',
      minted: {
        progress: 2850,
        total: 5000,
      },

      showOpensea: true,
    },
    {
      image: 'assets/images/parcel_3.png',
      title: 'Wolf Game',
      subtitle: '24x24 • [185, 22] ',
      parcel: true,
      actionTitle: 'Explore parcel #716',
      minted: {
        progress: 2850,
        total: 5000,
      },
      showOpensea: true,
    },
  ]; */

  gameItems = []; /* [
    {
      image: 'assets/images/banner_6.png',
      title: 'Wolf Game',
      description: `On a quaint farm in the metaverse, a flock of Sheep congregate and produce a healthy supply of $WOOL. They huddle together in a Barn and are sheared regularly by their owners to farm the $WOOL. With more $WOOL, the farmers can purchase more Sheep! But outside lurk dangers the Sheep are terrified of… The Wolves - who are on the hunt for Sheep and their precious $WOOL.`,
      soldout: true,
      iconBR: false,
      actionTitle: 'Learn more',
      popular: true,
      minted: {
        progress: 10000,
        total: 10000,
      },
    },
    {
      image: 'assets/images/banner_7.png',
      title: 'Dystowarz',
      description: `Dystowarz takes place in the year 2145. Since our times, carbon emissions and greenhouse gases rose to critical levels. This rendered the temperature unbearable outside the human-made shelters. The fight for survival is unlike any global-scale conflict and doesn't seem to have an end anytime soon. Will humanity mature and find new perspectives that lead them out of this dystopian universe, or <b>is this it</b>? `,
      soldout: false,
      iconBR: false,
      actionTitle: 'Learn more',
      popular: true,
      minted: {
        progress: 2850,
        total: 5000,
      },
    },
    {
      image: 'assets/images/banner_8.png',
      title: 'Feudalz',
      description: `Feudalz emerged to protect their Peasants. When the system runs smoothly, it leads to peace and prosperity for everyone. 4,444 unique collectible characters with proof of ownership stored on the Ethereum blockchain. All feudalz staked already received the right to claim one landz - Grasslands, Forests, Arid & Snowy. The more landz you have, more goldz you will be able to steal in the future. `,
      soldout: true,
      iconBR: false,
      actionTitle: 'Learn more',
      popular: true,
      minted: {
        progress: 4444,
        total: 4444,
      },
    },
  ]; */

  accessLink(link: string) {
    window.open(link, '_blank');
  }
}
