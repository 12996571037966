import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full h-20 p-3 flex justify-start items-center space-x-4" }
const _hoisted_2 = { class: "h-full w-auto flex justify-center items-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "flex flex-col flex-1 h-full justify-between" }
const _hoisted_6 = { class: "time" }
const _hoisted_7 = { class: "grid grid-cols-3" }
const _hoisted_8 = { class: "text-white text-sm font-semibold" }
const _hoisted_9 = { class: "text-white text-sm font-semibold" }
const _hoisted_10 = { class: "text-white text-sm font-semibold" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.parcel.image && _ctx.parcel.image.length > 0)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.parcel?.image + '_0?lastmod=' + _ctx.parcel.timestamp,
            class: "h-full rounded-md w-auto"
          }, null, 8, _hoisted_3))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: 
          require(`@/assets/images/parcel_size_${
            '' + (_ctx.parcel.size || 'null')
          }.png`)
        ,
            class: "h-full rounded-md w-auto"
          }, null, 8, _hoisted_4))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", {
          class: _normalizeClass(["text-sm font-semibold", 
            _ctx.time.split(' ')[0] === 'Expiring'
              ? 'text-orange-1'
              : _ctx.time.split(' ')[0] === 'Expired'
              ? 'text-pink-1'
              : 'text-yellow-300'
          ])
        }, [
          _createVNode(_component_font_awesome_icon, {
            icon: 
              _ctx.expired ? 'fa-solid fa-circle-exclamation' : 'fa-solid fa-clock'
            
          }, null, 8, ["icon"]),
          _createTextVNode(" " + _toDisplayString(_ctx.time), 1)
        ], 2)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, " x: " + _toDisplayString(_ctx.parcel.startX), 1),
        _createElementVNode("div", _hoisted_9, " y: " + _toDisplayString(_ctx.parcel.startY), 1),
        _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.parcel.size) + "x" + _toDisplayString(_ctx.parcel.size), 1)
      ])
    ]),
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.mint && _ctx.mint(...args))),
      class: _normalizeClass(["animated-button-green w-fit h-fit px-4 py-2 font-semibold text-sm select-none uppercase", {
        'bg-grey-8 text-grey-9': _ctx.expired,
        'animated-button-green cursor-pointer text-black': !_ctx.expired,
      }]),
      disabled: _ctx.loaders.mint.bool && _ctx.loaders.mint.id === _ctx.parcel.reservationId
    }, [
      (_ctx.loaders.mint.bool && _ctx.loaders.mint.id === _ctx.parcel.reservationId)
        ? (_openBlock(), _createElementBlock("span", _hoisted_12, "Confirming..."))
        : (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.computeMintText), 1))
    ], 10, _hoisted_11)
  ]))
}