
import { Vue } from 'vue-property-decorator';

import { toast, ToastOptions } from 'vue3-toastify';
import { formatEther } from '@/utils';
import { getNetworkByChainId } from '@/utils/blockchain';
import BigNumber from 'bignumber.js';

interface Balances {
  [key: string]: number;

  dysto: number;
  eth: number;
}

export default class tradeKeypadShare extends Vue {

  balances: Balances = {
    dysto: 0,
    eth: 0,
  };

  title = '';
  loadingMessage = '';
  isBuy = true;

  selectedCurrencyToBuyShare = 'dysto';

  collectionAddress = '';
  sharesSupply = 0;

  userShares = 0;

  shareAmountToTrade = 1;
  estimatedDystoPricesForShareAmount = '';

  insufficientBalanceToBuy = false;
  cannotSellThatManyShares = false;

  exitCallback: (() => void) | null = null;

  isLoading = false;
  initialLoading = true;

  errorOnInit = false;

  orderTxHash = '';

  async beforeMount(): Promise<void> {
    await this.init();

  }

  async init() {

    try {

      this.balances.dysto = Number(await this.$store.dispatch('getUserDystoWorldTokenBalance'));
      this.balances.eth = Number(await this.$store.dispatch('getUserBalanceOfNativeCurrency'));

      this.isBuy = this.$store.state.app.modal.payload.isBuy;
      this.collectionAddress = this.$store.state.app.modal.payload.collectionAddress;
      this.exitCallback = this.$store.state.app.modal.payload.exitCallback;

      this.sharesSupply = await this.$store.state.dystoWorldKeypadContract.methods.sharesSupply(this.collectionAddress).call();
      this.userShares = await this.$store.state.dystoWorldKeypadContract.methods.sharesBalance(this.collectionAddress, this.$store.state.user.address).call();
      this.title = this.isBuy ? 'Buy Keys' : 'Sell Keys';

      this.loadingMessage = (this.selectedCurrencyToBuyShare === 'ETH') ?
        'Processing your order. Please wait, this might take a few moments.'
        : 'Processing your order. Please wait, this might take a few moments. Keep an eye on MetaMask for approval requests, possibly twice for both approval and purchase.';

      await this.estimateDystoPrice();
    } catch (error) {
      this.errorOnInit = true;
    } finally {
      this.initialLoading = false;
    }
  }

  close(): void {
    this.$store.commit('app/setModal', { component: '', payload: null });
    if (this.exitCallback) {
      this.exitCallback();
    }
  }

  canSellThisAmount() {
    return this.shareAmountToTrade >= 0
      && this.shareAmountToTrade < this.sharesSupply
      && this.shareAmountToTrade <= this.userShares;
  }

  async estimateDystoPrice(): Promise<void> {
    try {
      if ((!this.canSellThisAmount() && !this.isBuy) || this.shareAmountToTrade <= 0) {
        return;
      }

      const keypadContract = this.$store.state.dystoWorldKeypadContract;
      let priceInDysto;

      if (this.isBuy) {
        priceInDysto = await keypadContract.methods.getBuyPriceAfterFee(this.collectionAddress, this.shareAmountToTrade).call();
        this.insufficientBalanceToBuy = this.balances.dysto < parseFloat(formatEther(priceInDysto));
      } else {
        priceInDysto = await keypadContract.methods.getSellPriceAfterFee(this.collectionAddress, this.shareAmountToTrade).call();
      }

      this.estimatedDystoPricesForShareAmount = formatEther(priceInDysto.toString());
    } catch (error) {
      console.log(error);
    }
  }

  async trade() {
    if (this.shouldDisableTradeButton) {
      return;
    }

    this.isLoading = true;
    this.orderTxHash = '';

    if (this.isBuy) {
      await this.buy();
    } else {
      await this.sell();
    }

    this.isLoading = false;

    this.close();
  }

  async buy() {

    if (this.selectedCurrencyToBuyShare === 'ETH') {
      await this.handleBuySharesWithEth();
    } else {
      await this.handleBuySharesWithDysto();
    }
  }

  async handleBuySharesWithDysto() {
    try {
      const dystoTokenContract = this.$store.state.dystoWorldTokenContract;
      const keypadContract = this.$store.state.dystoWorldKeypadContract;

      const userAddress = this.$store.state.user.address;

      const priceInDysto: string = await keypadContract.methods.getBuyPriceAfterFee(this.collectionAddress, this.shareAmountToTrade).call();

      const network = getNetworkByChainId(this.$store.state.chainId);

      const allowance: string = await dystoTokenContract.methods.allowance(userAddress, network.DYSTO_KEYPAD_CONTRACT_ADDRESS).call();

      if (new BigNumber(allowance).lt(new BigNumber(priceInDysto))) {
        // Approve the DYSTO transfer
        await dystoTokenContract.methods.approve(network.DYSTO_KEYPAD_CONTRACT_ADDRESS, priceInDysto).send({ from: userAddress });
      }

      await new Promise((resolve, reject) => {
        keypadContract.methods.buyShares(this.collectionAddress, this.shareAmountToTrade)
          .send({ from: userAddress })
          .on('transactionHash', (hash: string) => {
            this.orderTxHash = hash;
          })
          .on('receipt', (receipt: unknown) => {
            this.successfulToast();
            resolve(receipt);
          })
          .on('error', (error: any) => {
            console.error('Transaction error:', error);
            this.failedOrderToast();
            reject(error);
          });
      });

      return true;
    } catch (error) {
      console.error('Error buying first key:', error);
      return false;
    }
  }

  async sell() {
    try {
      const keypadContract = this.$store.state.dystoWorldKeypadContract;

      const userAddress = this.$store.state.user.address;

      await new Promise((resolve, reject) => {
        keypadContract.methods.sellShares(this.collectionAddress, this.shareAmountToTrade)
          .send({ from: userAddress })
          .on('transactionHash', (hash: string) => {
            this.orderTxHash = hash;
          })
          .on('receipt', (receipt: unknown) => {
            this.successfulToast();
            resolve(receipt);
          })
          .on('error', (error: any) => {
            console.error('Transaction error:', error);
            this.failedOrderToast();
            reject(error);
          });
      });

      return true;
    } catch (error) {
      console.error('Error buying first key:', error);
      return false;
    }
  }

  async handleBuySharesWithEth() {

  }

  successfulToast() {
    toast.success('Order successfully completed', {
      hideProgressBar: true,
      autoClose: 1000,
      position: toast.POSITION.TOP_RIGHT,
    } as ToastOptions);
  }

  failedOrderToast() {
    toast.error('Order has failed', {
      hideProgressBar: true,
      autoClose: 1000,
      position: toast.POSITION.TOP_RIGHT,
    } as ToastOptions);
  }

  validateInput() {
    this.orderTxHash = '';
    this.insufficientBalanceToBuy = false;
    this.estimatedDystoPricesForShareAmount = '';

    if (this.shareAmountToTrade < 0) {
      this.shareAmountToTrade = 0;
    }
    if (this.shareAmountToTrade > 1000) {
      this.shareAmountToTrade = 1000;
    }

    this.cannotSellThatManyShares = !this.isBuy && this.shareAmountToTrade > this.userShares;

    this.estimateDystoPrice();
  }

  get shouldDisableTradeButton() {
    return (this.isBuy && (this.insufficientBalanceToBuy
        || this.estimatedDystoPricesForShareAmount.length === 0
        || this.isLoading)
      || (!this.isBuy && (this.shareAmountToTrade > this.userShares
        || this.estimatedDystoPricesForShareAmount.length === 0
        || this.isLoading))
    );
  }

  async copyTxHashToClipboard() {
    try {
      await navigator.clipboard.writeText(this.txHash);
      toast.success('TxHash copied to clipboard!', {
        hideProgressBar: true,
        autoClose: 1000,
        position: toast.POSITION.TOP_RIGHT,
      } as ToastOptions);
    } catch (error) {
      console.error('Failed to copy TxHash:', error);
    }
  }

  get loading() {
    return this.isLoading;
  }

  get txHash() {
    return this.orderTxHash;
  }

}
