
import Loader from '@/components/icons/loader.vue';
import {
  PoolReward,
  PoolHistory,
  StakedNFTInfo,
} from '@/store/types/landlordStaking';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Options, Prop, Vue, Watch } from 'vue-property-decorator';
import StakedNFTCard from './stakedNFTCard.vue';

@Options({
  components: {
    FontAwesomeIcon,
    StakedNFTCard,
    Loader,
  },
})
export default class PoolStakedAssets extends Vue {
  @Prop() layout!: string;
  @Prop() collection!: PoolReward;
  @Prop() poolHistory!: PoolHistory;
  @Prop() title!: string;
  @Prop() nftsStaked!: StakedNFTInfo[];
  stakedNftsForThisPool: StakedNFTInfo[] = [];
  unstakedNftsForThisPool: StakedNFTInfo[] = [];

  get poolRewardHistories(): Record<string, PoolHistory[]> {
    return this.$store.state.landlordStaking.collectionHistories;
  }

  @Watch('nftsStaked', { immediate: true })
  @Watch('collection', { immediate: true })
  onCollectionChange(): void {
    this.stakedNftsForThisPool = [];
    this.unstakedNftsForThisPool = [];
    const { createdAtTimestamp, endTimestamp, collectionAddress } =
      this.collection;
    this.nftsStaked.forEach((nft) => {
      if (
        nft.collectionAddress.toLowerCase() === collectionAddress.toLowerCase() // same collection
      ) {
        if (this.layout === 'per-pool')
          nft.stakingHistory.forEach((history) => {
            if (
              history.stakedAtTimestamp >= createdAtTimestamp && // staked after pool started
              history.stakedAtTimestamp <= endTimestamp // staked before pool ended
            ) {
              if (this.isNftStaked(nft)) this.stakedNftsForThisPool.push(nft);
              else this.unstakedNftsForThisPool.push(nft);
            }
          });
        else {
          if (this.isNftStaked(nft)) this.stakedNftsForThisPool.push(nft);
          else this.unstakedNftsForThisPool.push(nft);
        }
      }
    });
  }

  isNftStaked(nft: StakedNFTInfo): boolean {
    return (
      nft.stakingHistory[nft.stakingHistory.length - 1].unstakedAtTimestamp ===
      null
    );
  }
}
