import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "staking" }
const _hoisted_2 = { class: "content-padding" }
const _hoisted_3 = { class: "router-container flex-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!
  const _component_WaitingForTransaction = _resolveComponent("WaitingForTransaction")!
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.modal.component.length > 0)
      ? (_openBlock(), _createBlock(_component_Modal, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.showWaitingForTransaction)
      ? (_openBlock(), _createBlock(_component_WaitingForTransaction, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(_component_Navbar),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_view, null, {
          default: _withCtx(({ Component }) => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
          ]),
          _: 1
        })
      ])
    ])
  ]))
}