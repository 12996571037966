
import { isUrl } from '@/utils';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import 'swiper/swiper.min.css';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Options, Prop, Vue } from 'vue-property-decorator';

import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

interface TimeLeft {
  daysLeft: number;
  hoursLeft: number;
  minutesLeft: number;
  secondsLeft: number;
}

@Options({
  components: {
    Swiper,
    SwiperSlide,
    Carousel,
    Slide,
    Pagination,
    Navigation,
    FontAwesomeIcon,
  },
})
export default class UpcomingCarousel extends Vue {
  @Prop({ default: [] }) readonly collections!: any[];
  swiper: any;

  timeLeft: TimeLeft[] = [];

  setTimeLeft(): void {
    const timeLeft: TimeLeft[] = [];

    // Loop through all collections and set time left

    if (this.collections) {
      this.collections.forEach((collection: any) => {
        const timeLeftObj: TimeLeft = {
          daysLeft: 0,
          hoursLeft: 0,
          minutesLeft: 0,
          secondsLeft: 0,
        };

        if (collection.comingAt === null) {
          timeLeft.push(timeLeftObj);
          return;
        }
        const now = new Date();
        const endDate = new Date(collection.comingAt);
        const timeDiff = endDate.getTime() - now.getTime();

        timeLeftObj.daysLeft = Math.floor(timeDiff / (1000 * 3600 * 24));
        timeLeftObj.hoursLeft = Math.floor(
          (timeDiff % (1000 * 3600 * 24)) / (1000 * 3600)
        );
        timeLeftObj.minutesLeft = Math.floor(
          (timeDiff % (1000 * 3600)) / (1000 * 60)
        );
        timeLeftObj.secondsLeft = Math.floor((timeDiff % (1000 * 60)) / 1000);

        timeLeft.push(timeLeftObj);
      });
    }

    this.timeLeft = timeLeft;
  }

  // refresh timeLeft every second
  created(): void {
    setInterval(() => {
      this.setTimeLeft();
    }, 1000);
  }

  onSwiper(swiper: any): void {
    this.swiper = swiper;
  }

  setSlide(index: number): void {
    if (this.swiper) this.swiper.slideTo(index);
  }

  changeSlide(direction: number): void {
    if (this.swiper) this.swiper.slideTo(this.swiper.activeIndex + direction);
  }

  checkLocalImage(image: string): boolean {
    return !isUrl(image);
  }

  beforeDestroy(): void {
    if (this.swiper) this.swiper.destroy();
  }
}
