import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "title text-center" }
const _hoisted_2 = { class: "text text-center" }
const _hoisted_3 = {
  key: 0,
  class: "flex-col"
}
const _hoisted_4 = { class: "center-center margin-box-25" }
const _hoisted_5 = { class: "center-center margin-box-25" }
const _hoisted_6 = ["href"]
const _hoisted_7 = {
  key: 1,
  class: "actions flex items-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.message), 1),
    (_ctx.waitingForConfirmationTrigger)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_Loader)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.linkToEtherscan !== '')
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: "text text-center underline pointer",
            href: _ctx.linkToEtherscan,
            target: "_blank"
          }, "View transaction on Etherscan", 8, _hoisted_6))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.waitingForConfirmationTrigger && _ctx.needActions)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
            class: "action center-center"
          }, "Close"),
          _createElementVNode("div", {
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.actionHandler && _ctx.actionHandler(...args))),
            class: "action center-center"
          }, _toDisplayString(_ctx.textAction), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}