import { INetwork } from '@/store/types/network';

export const API_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://api.dystoworld.ai'
    //: 'http://alexgherghe.go.ro:8678';
    //: 'http://localhost:8678';
    : 'https://api.dystoworld.ai';

export const DYSTO_PROXY_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://proxy.dystoworld.ai/'
    //: 'https://justcors.com/tl_954cf0f/';
    : 'https://proxy.dystoworld.ai/';

export const DYSTO_SPACES_ASSETS_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://dysto.fra1.digitaloceanspaces.com'
    : 'https://dysto.fra1.digitaloceanspaces.com';

export const ETHEREUM_NETWORK: INetwork =
    {
      DYSTO_WORLD_TOKEN_CONTRACT_ADDRESS:
        '0xDe466831fdE62e5141d49BA9d962D3fa3fAe466F',
      DYSTO_WORLD_PORTAL_CONTRACT_ADDRESS:
        '0xfdED0531fAf8AB8DdD52E0d7FBd988F72860b78D',
      DYSTO_WORLD_SPACES_CONTRACT_ADDRESS:
        '0xA1C032E3e014528903abb037eAD56CDc09517A1D',
      DYSTO_WORLD_LANDLORD_STAKING_CONTRACT_ADDRESS:
        '0xAC48cd72cCE52c6546695FFA84da6f546dE86c79',
      DYSTO_WORLD_PUBLIC_SALE_CONTRACT_ADDRESS:
        '0xdFD64E7a6eA83649a8A30cd2E4828fe53Cbdc9Ff',
      DYSTO_KEYPAD_CONTRACT_ADDRESS:
        '0xe062863da497265442e18610D026F3Df59e6DA26',
      USDT_ADDRESS: '0xa8884EAf94ED27FF86567ea31C6B4a730319E2C6',
      USDC_ADDRESS: '0x5239AB6e6c63142585Ef345518cbe8F11283b485',
      BUSD_ADDRESS: '0xa3E7Baf376998b9b629251e22EDb1A3329c7eC98',
      DYSTO_VESTING_CONTRACT_ADDRESS: '0x232ab8c3d361e75eb8bbb115444ebc3ecb2b26b6',
    };

export const ARBITRUM_NETWORK: INetwork =
  process.env.NODE_ENV === 'production'
    ? {
      DYSTO_WORLD_TOKEN_CONTRACT_ADDRESS:
        '0x717b7a773326b08615d951FBfF2268eA1d6fCeC3',
      DYSTO_WORLD_PORTAL_CONTRACT_ADDRESS:
        '0x26a931cfDDC61a2d25DcB7486c51c2Ba3a4eA53c',
      DYSTO_WORLD_SPACES_CONTRACT_ADDRESS:
        '0x58471fe8ADF9aa3dFd8FD8F7028128fD671B43aC',
      DYSTO_WORLD_LANDLORD_STAKING_CONTRACT_ADDRESS:
        '0xbe7725F5Bd30F69b235541b169D5d4BD092d07F2',
      DYSTO_WORLD_PUBLIC_SALE_CONTRACT_ADDRESS:
        '0x13C37E29efa0815e1c9fEd3d0963bdBff9508302',
      DYSTO_KEYPAD_CONTRACT_ADDRESS:
        '0x840532d64e7553D66F6F033cef99e3363EA333F1',
      USDT_ADDRESS: '0x509ee0d083ddf8ac028f2a56731412edd63223b9',
      USDC_ADDRESS: '0x07865c6E87B9F70255377e024ace6630C1Eaa37F',
      BUSD_ADDRESS: '0xb809b9B2dc5e93CB863176Ea2D565425B03c0540',
      DYSTO_VESTING_CONTRACT_ADDRESS: '0x933997546d76AaB867E14debc84373AEBA83beB2',
    }
    : {
      DYSTO_WORLD_TOKEN_CONTRACT_ADDRESS:
        '0x717b7a773326b08615d951FBfF2268eA1d6fCeC3',
      DYSTO_WORLD_PORTAL_CONTRACT_ADDRESS:
        '0xbf0725BF84297ec1Bd9A59DE5346693bCE62D826',
      DYSTO_WORLD_SPACES_CONTRACT_ADDRESS:
        '0x271cA4028114CC1FC1de6EbCDdDd1568E03aA905',
      DYSTO_WORLD_LANDLORD_STAKING_CONTRACT_ADDRESS:
        '0xb37E8975Ea1b3dDca99b7074a678599D70dBa14B',
      DYSTO_WORLD_PUBLIC_SALE_CONTRACT_ADDRESS:
        '0x13C37E29efa0815e1c9fEd3d0963bdBff9508302',
      DYSTO_KEYPAD_CONTRACT_ADDRESS:
        '0x840532d64e7553D66F6F033cef99e3363EA333F1',
      USDT_ADDRESS: '0x509ee0d083ddf8ac028f2a56731412edd63223b9',
      USDC_ADDRESS: '0x07865c6E87B9F70255377e024ace6630C1Eaa37F',
      BUSD_ADDRESS: '0xb809b9B2dc5e93CB863176Ea2D565425B03c0540',
      DYSTO_VESTING_CONTRACT_ADDRESS: '0x933997546d76AaB867E14debc84373AEBA83beB2',
    };

export const ETHEREUM_NETWORK_NAME =
  process.env.NODE_ENV == 'production' ? 'ETH Mainnet' : 'ETH Mainnet';

export const ARBITRUM_NETWORK_NAME =
  process.env.NODE_ENV === 'production' ? 'Arbitrum' : 'Arbitrum Görli';
export const SYSCOIN_NETWORK_NAME =
  process.env.NODE_ENV === 'production' ? 'SYSCOIN Mainnet' : 'SYSCOIN Testnet';

export const ETHEREUM_NATIVE_CURRENCY_NAME =
  process.env.NODE_ENV === 'production' ? 'ETH' : 'ETH';
export const ARBITRUM_NATIVE_CURRENCY_NAME =
  process.env.NODE_ENV === 'production' ? 'AETH' : 'AETH';
export const SYSCOIN_NATIVE_CURRENCY_NAME =
  process.env.NODE_ENV === 'production' ? 'SYS' : 'SYS';

export const ETHEREUM_CHAIN_ID = process.env.NODE_ENV == 'production' ? 1 : 1;

// export const ETHEREUM_MAINNET_CHAIN_ID = 1;
export const ARBITRUM_CHAIN_ID =
  process.env.NODE_ENV === 'production' ? 42161 : 421613;
export const SYSCOIN_CHAIN_ID =
  process.env.NODE_ENV === 'production' ? 57 : 2814;

export const ETHEREUM_CHAIN_ID_HEX =
  process.env.NODE_ENV == 'production' ? '0x1' : '0x1';
// export const ETHEREUM_MAINNET_CHAIN_ID_HEX = '0x1';
export const ARBITRUM_CHAIN_ID_HEX =
  process.env.NODE_ENV === 'production' ? '0xa4b1' : '0x66eed';
export const SYSCOIN_CHAIN_ID_HEX =
  process.env.NODE_ENV === 'production' ? '0x39' : '0xafe';

export const SUPPORTED_CHAIN_IDS = [
  ETHEREUM_CHAIN_ID,
  // ARBITRUM_CHAIN_ID
];
export const SUPPORTED_CHAIN_IDS_HEX = [
  ETHEREUM_CHAIN_ID_HEX,
  // ARBITRUM_CHAIN_ID_HEX,
];

export const LAUNCHPAD_SUPPORTED_CHAIN_IDS = [
  ETHEREUM_CHAIN_ID,
  // ARBITRUM_CHAIN_ID,
  // SYSCOIN_CHAIN_ID,
];
export const LAUNCHPAD_SUPPORTED_CHAIN_IDS_HEX = [
  ETHEREUM_CHAIN_ID_HEX,
  // ARBITRUM_CHAIN_ID_HEX,
  // SYSCOIN_CHAIN_ID_HEX,
];

export const MAX_CONFIRMATIONS = process.env.NODE_ENV === 'production' ? 1 : 1;

export const OPENSEA_API_ASSETS =
  'https://testnets-api.opensea.io/api/v1/assets';
