import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "h-fit flex flex-col space-y-6 items-start flex-grow" }
const _hoisted_3 = { class: "whitespace-nowrap truncate text-black text-xl font-semibold" }
const _hoisted_4 = { class: "flex items-center justify-start flex-wrap w-full" }
const _hoisted_5 = { class: "w-16 bg-white-2 border-2 border-white-3 rounded-md p-3 text-grey-text-6 font-medium flex items-center justify-between mr-4 mt-2" }
const _hoisted_6 = { class: "" }
const _hoisted_7 = { class: "w-20 bg-white-2 border-2 border-white-3 rounded-md p-3 text-grey-text-6 font-medium flex items-center justify-between mr-4 mt-2" }
const _hoisted_8 = { class: "" }
const _hoisted_9 = { class: "bg-white-2 border-2 border-white-3 rounded-md p-3 text-grey-text-6 font-medium flex items-center max-w-fit mt-2" }
const _hoisted_10 = { class: "ml-4 truncate whitespace-nowrap" }
const _hoisted_11 = { class: "flex items-center justify-start flex-wrap w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["h-fit flex justify-start rounded-md border-2 border-white-5 bg-white-4 pr-5 pb-5 mb-6 p-5 flex-col items-center w-fit sm:flex-row sm:items-center sm:w-full lg:flex-col lg:items-center lg:w-fit 2xl:flex-row 2xl:items-center 2xl:w-full", { 'card-disabled': _ctx.disabled, [_ctx.extraClasses]: _ctx.extraClasses.length }])
  }, [
    _createElementVNode("img", {
      class: "w-80 h-full object-cover rounded-md mr-0 sm:mr-8 lg:mr-0 2xl:mr-8",
      src: _ctx.imageUrl,
      alt: "preview"
    }, null, 8, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.spaceAsset.name ? _ctx.spaceAsset.name : 'undefined spaceId'), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-hashtag" }),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.spaceAsset.tokenId), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-expand" }),
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.spaceAsset.spaceSize), 1)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-link" }),
          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.chainName), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.actionHandler && _ctx.actionHandler(...args))),
          class: "w-40 animated-button-green whitespace-nowrap rounded-md py-2 flex items-center justify-evenly text-black font-bold text-base uppercase cursor-pointer mt-2 mr-4"
        }, [
          (_ctx.actionText === 'Edit space')
            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 0,
                icon: "fa-solid fa-pen"
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, _toDisplayString(_ctx.actionText), 1)
        ]),
        (_ctx.spaceAsset.parcelId !== '' && _ctx.spaceAsset.parcelId !== null)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.setModal && _ctx.setModal(...args))),
              class: "w-40 bg-red-1 whitespace-nowrap rounded-md py-2 flex items-center justify-center space-x-2 text-black font-bold text-base uppercase cursor-pointer mt-2"
            }, " Unmount "))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}