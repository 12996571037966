
import { Vue } from 'vue-property-decorator';
import eventBus from '@/eventBus';
import { toast, ToastOptions } from 'vue3-toastify';
import { formatEther } from '../utils';

export default class KeypadOwnCollectionTradesHistory extends Vue {
  trades: any[] = [];
  collectionAddress = '';

  async created() {
    setTimeout(async () => {
      await this.init();
    }, 500);
  }

  async init() {
    try {
      this.collectionAddress = this.$store.state.wallet;
      this.trades = this.$store.state.user.keypadOwnCollectionTradesData;
      eventBus.on('step-clicked', this.handleStepClicked);
    } catch (error) {
      console.log(error);
    }
  }

  beforeDestroy() {
    eventBus.detach('step-clicked', this.handleStepClicked);
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  }

  formatDystoPrice(dystoPrice: number): string {
    return formatEther(dystoPrice);
  }

  async handleStepClicked(eventPayload: any) {
    await this.$router.push({
      path: '/influencer',
      query: { userSelectedStepInputParam: eventPayload },
    });
  }

  async copyTxHashToClipboard(txHash: string) {
    try {
      await navigator.clipboard.writeText(txHash);
      toast.success('TxHash copied to clipboard!', {
        hideProgressBar: true,
        autoClose: 1000,
        position: toast.POSITION.TOP_RIGHT,
      } as ToastOptions);
    } catch (error) {
      console.error('Failed to copy TxHash:', error);
    }
  }
}
