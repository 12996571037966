import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal disable-select" }
const _hoisted_2 = { class: "relative h-0 w-full m-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = () => {
      if (!(this.$store.state.app.modal.fromComponent
            && (this.$store.state.app.modal.fromComponent === 'influencer'
                  || this.$store.state.app.modal.fromComponent === 'keypad'))) {
        _ctx.setModal('');
      }
    }),
      class: "background",
      id: "disable-area"
    }),
    _createElementVNode("div", {
      class: _normalizeClass(["content w-[85%] md:w-[400px]", (this.$store.state.app.modal.fromComponent
                  && (this.$store.state.app.modal.fromComponent === 'influencer'
                        || this.$store.state.app.modal.fromComponent === 'keypad')) ? 'custom-center' : ''])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setModal(''))),
          class: "rounded-md bg-grey-2 w-7 h-7 md:w-9 md:h-9 flex items-center justify-center absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 cursor-pointer"
        }, [
          _createVNode(_component_font_awesome_icon, {
            icon: "fa-solid fa-x",
            class: "text-white"
          })
        ])
      ]),
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.modal.component), { ref: "currentModal" }, null, 512))
    ], 2)
  ]))
}