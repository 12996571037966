import store from '@/store';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import KeypadTradesSharesHistory from '@/views/KeypadTradesSharesHistory.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    meta: { layout: 'Basic' },
    // component: () => import('../views/Home.vue'),
    component: () => import('../views/NewHome.vue'),
  },
  {
    path: '/launchpad',
    name: 'Launchpad',
    meta: { layout: 'Default', title: 'DystoWorld Launchpad' },
    component: () => import('../views/Launchpad.vue'),
  },
  {
    path: '/launchpad/:slug',
    name: 'LaunchpadSlug',
    meta: { layout: 'Default', title: 'DystoWorld Launchpad' },
    component: () => import('../views/Launchpad.vue'),
  },
  {
    path: '/portal',
    name: 'Portal',
    meta: { layout: 'Default', title: 'DystoWorld Portal' },
    component: () => import('../views/Portal.vue'),
    // redirect: { name: 'Home' },
  },
  {
    path: '/landlord-staking',
    name: 'LandlordStaking',
    component: () => import('../views/LandlordStaking.vue'),
    // redirect: { name: 'StakingParcels' },
    //redirect: { name: 'Home' }, // TODO: remove when ready to reenable landlord staking
  },
  {
    path: '/landlord-staking/parcels',
    name: 'StakingParcels',
    meta: { layout: 'Basic' },
    component: () => import('../views/LandlordStaking.vue'),
    //redirect: { name: 'Home' }, // TODO: remove when ready to reenable landlord staking
  },

  {
    path: '/landlord-staking/nfts',
    name: 'StakingNFTs',
    meta: { layout: 'Basic' },
    component: () => import('../views/LandlordStaking.vue'),
    //redirect: { name: 'Home' }, // TODO: remove when ready to reenable landlord staking
  },
  {
    path: '/landlord-staking/nfts/:slug',
    name: 'StakingNFTsSlug',
    meta: { layout: 'Staking' },
    component: () => import('../views/LandlordStaking.vue'),
    //redirect: { name: 'Home' }, // TODO: remove when ready to reenable landlord staking
  },
  {
    path: '/mount/:id',
    name: 'Mount',
    meta: { layout: 'Basic' },
    component: () => import('../views/Mount.vue'),
  },
  {
    path: '/instance/:id',
    name: 'Instance',
    meta: { layout: 'Basic' },
    component: () => import('../views/Instance.vue'),
  },
  {
    path: '/landlord-staking/chat',
    name: 'Chat',
    meta: { layout: 'Staking' },
    component: () => import('../views/Chat.vue'),
  },
  {
    path: '/twitter_oauth_callback',
    name: 'TwitterOAuthCallback',
    component: () => import('../views/TwitterOAuthCallback.vue'),
  },
  {
    path: '/influencer',
    name: 'Influencer',
    component: () => import('../views/Influencer.vue'),
  },
  {
    path: '/keypad_own_collection_trades_history',
    name: 'KeypadOwnCollectionTradesHistory',
    component: () => import('../views/KeypadOwnCollectionTradesHistory.vue'),
  },
  {
    path: '/keypad_bought_sold_trades_history',
    name: 'KeypadTradesSharesHistory',
    component: () => import('../views/KeypadTradesSharesHistory.vue'),
  },
  {
    path: '/keypad',
    name: 'Keypad',
    component: () => import('../views/Keypad.vue'),
  },
  {
    path: '/vesting',
    name: 'Vesting',
    component: () => import('../views/Vesting.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('../views/Portal.vue'),
    //redirect: { name: 'Portal' },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const DEFAULT_TITLE = 'DystoWorld';
router.afterEach(async (to, from) => {
  const title = to.meta.title as string;
  document.title = title || DEFAULT_TITLE;
  store.commit('setCurrentUrlPath', to.fullPath);
  if (store.state.web3) {
    const provider = store.state.web3.givenProvider;
    const chainId = await provider.request({ method: 'eth_chainId' });
    store.dispatch('metamaskCheckChain', { chainId, provider });
  }
});

export default router;
