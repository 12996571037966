import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input-switch flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClick())),
      class: _normalizeClass(`font-medium text-base text-white disable-select flex-1 ${
        _ctx.extraClasses || ''
      }`)
    }, _toDisplayString(_ctx.text), 3),
    _createElementVNode("div", {
      class: _normalizeClass(`flex switch-container items-center relative px-1 transition-all duration-200 ease-in-out ${
        _ctx.value ? 'bg-green-2' : 'bg-grey-3'
      }`),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClick()))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["rounded-full w-5 h-5 transition-all duration-200 ease-in-out absolute left-1/2 transform", _ctx.value ? 'bg-green-1' : 'bg-switch-off -translate-x-full'])
      }, null, 2)
    ], 2)
  ]))
}