
import { Vue, Prop, Options, Emit, Watch } from 'vue-property-decorator';
import ChevronIcon from '../icons/chevron.vue';

@Options({
  components: {
    ChevronIcon,
  },
})
export default class Pagination extends Vue {
  @Prop(Number) readonly active!: number;
  @Prop(Number) readonly pages!: number;

  active_page = this.active;

  @Watch('active')
  onActiveExternalChange(): void {
    this.active_page = this.active;
  }

  @Emit()
  changePage() {
    return this.active_page;
  }

  go(page: number) {
    this.active_page = page;
    this.changePage();
  }

  previous() {
    this.active_page--;
    if (this.active_page === 0) {
      this.active_page = this.pages;
    }
    this.changePage();
  }

  next() {
    this.active_page++;
    if (this.active_page === this.pages + 1) {
      this.active_page = 1;
    }
    this.changePage();
  }
}
