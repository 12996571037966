
import { Vue, Options, Watch } from 'vue-property-decorator';
import StakingParcels from './StakingParcels.vue';
import StakingNFTs from './StakingNFTs.vue';
import { getChainNameByChainId } from '@/utils/blockchain';

@Options({
  components: {
    StakingParcels,
    StakingNFTs,
  },
})
export default class LandlordStaking extends Vue {
  submenu = 'staking-parcels';
  showWaitingForConfirmations = false;

  get component(): string {
    switch (this.active) {
      case 'StakingParcels':
        return 'StakingParcels';

      case 'StakingNFTs':
        return 'StakingNFTs';

      case 'StakingNFTsSlug':
        return 'StakingNFTs';

      default:
        return '';
    }
  }

  async beforeMount(): Promise<void> {
    await this.$store.dispatch('getLandlordStakingInfo');
    await this.$store.dispatch('getPoolRewards');
  }

  get loading(): boolean {
    return (
      this.$store.state.landlordStaking.waitForConfirmationTrigger ||
      this.$store.state.landlordStaking.waitingForApi
    );
  }

  get userWallet(): string {
    return this.$store.state.wallet;
  }

  get user(): any {
    return this.$store.state.user;
  }

  get chainId(): number {
    return this.$store.state.chainId;
  }

  get chainInfo(): string {
    return getChainNameByChainId(this.chainId);
  }

  @Watch('user', { deep: true })
  @Watch('chainId')
  onUserChanged(value: any): void {
    // when user wallet changed, update the landlord staking info after the
    // fetch of the user info is done to have the nonce for signing and get
    // the new token
    if (value) {
      // user can be null
      this.$store.dispatch('clearLandlordStakingInfo');
      this.$store.dispatch('getPoolRewards');
      this.$store.dispatch('getLandlordStakingInfo');
    }
  }

  get waitingForConfirmation(): boolean {
    return this.$store.state.landlordStaking.waitForConfirmationTrigger;
  }

  @Watch('waitingForConfirmation')
  onWaitingForConfirmationChange(): void {
    this.showWaitingForConfirmations = this.waitingForConfirmation;
  }

  get active() {
    return this.$route.name;
  }
}
