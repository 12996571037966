
import { Vue } from 'vue-property-decorator';
import { formatEther } from '@/utils';
import { toast, ToastOptions } from 'vue3-toastify';

interface TradeEvent {
  id: string;
  contractAddress: string;
  chainId: number;
  collectionOwner: string;
  asset: string;
  isBuy: boolean;
  amount: number;
  priceInDysto: number;
  created: string;
  txHash: string;
  trader: string;
}

interface CollectionData {
  history: TradeEvent[];
  currentShares: number;
  name: string;
}

interface CollectionsData {
  [key: string]: CollectionData;
}

export default class KeypadTradesSharesHistory extends Vue {
  collectionsData: CollectionsData = {};
  allTrades: Array<any> = [];
  initialLoading = true;

  async created() {
    setTimeout(async () => {
      await this.init();
      this.initialLoading = false;
    }, 500);
  }

  async init() {
    this.collectionsData = this.$store.state.user?.keypadTradesBoughSoldData;

    if (!this.collectionsData) {
      return;
    }

    const trades: any[] = [];

    Object.entries(this.collectionsData).forEach(([collectionAddress, collectionData]) => {
      collectionData.history.forEach(trade => {
        trades.push({
          ...trade,
          collectionName: collectionData.name, // Add collection name to each trade
        });
      });
    });

    this.allTrades = trades;
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  }

  formatDystoPrice(dystoPrice: number): string {
    return formatEther(dystoPrice);
  }

  async copyTxHashToClipboard(txHash: string) {
    try {
      await navigator.clipboard.writeText(txHash);
      toast.success('TxHash copied to clipboard!', {
        hideProgressBar: true,
        autoClose: 1000,
        position: toast.POSITION.TOP_RIGHT,
      } as ToastOptions);
    } catch (error) {
      console.error('Failed to copy TxHash:', error);
    }
  }
}
