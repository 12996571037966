
import SteakIcon from '@/components/icons/steak.vue';
import NFTIcon from '@/components/icons/nft.vue';
import { PoolData } from '@/store/types/landlordStaking';
import { copyToClipboard } from '@/utils';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Options, Prop, Vue } from 'vue-property-decorator';

@Options({
  components: {
    SteakIcon,
    FontAwesomeIcon,
    NFTIcon,
  },
})
export default class GenericHeader extends Vue {
  @Prop() imageUrl!: string | null;
  @Prop({ default: false }) defaultAvatar!: boolean;
  @Prop() layout!: string;
  @Prop() title!: string | null;
  @Prop() address!: string;
  @Prop() poolData!: PoolData[];

  showCopied = false;

  copyToClipboard(string: string | undefined): void {
    if (!string) return;
    copyToClipboard(string);
    this.showCopied = true;
    setTimeout(() => {
      this.showCopied = false;
    }, 2000);
  }
}
