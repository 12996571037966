
import { Parcel } from '@/utils/interfaces';
import { Emit, Prop, Vue } from 'vue-property-decorator';

export default class ParcelCard extends Vue {
  @Prop() parcel!: Parcel;
  @Prop() isSelected!: boolean;
  @Prop() disabled!: boolean;

  get parcelName(): string {
    return this.parcel.childrenTokenIds
      ? `#${Math.min(...this.parcel.childrenTokenIds)} - #${Math.max(
          ...this.parcel.childrenTokenIds
        )}`
      : '#' + this.parcel.tokenId;
  }

  @Emit('selected')
  toggleParcel(id: string): string {
    return id;
  }
}
