
import CollapseIcon from '@/components/icons/collapse.vue';
import ExpandIcon from '@/components/icons/expand.vue';
import StarIcon from '@/components/icons/star.vue';
import StickerIcon from '@/components/icons/sticker.vue';
import StreakIcon from '@/components/icons/streak.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Options, Vue, Watch } from 'vue-property-decorator';

@Options({
  components: {
    StarIcon,
    StickerIcon,
    StreakIcon,
    CollapseIcon,
    ExpandIcon,
    FontAwesomeIcon,
  },
})
export default class KeypadCategory extends Vue {

  created(): void {
    setTimeout(async () => {
      if (this.$store.state.keypad.currentCollection) {
        await this.changeCurrentCollection(this.$store.state.keypad.currentCollection.user_wallet_address);
      }
    }, 500);
  }

  get collections() {
    return this.$store.state.keypad.collections;
  }

  @Watch('collections', { deep: true, immediate: true })
  collections_watch() {
    const allCollectionsLinks = this.collections.map((collection: any) => {
      return {
        id: collection.user_wallet_address,
        name: collection.name,
        subcategory: 'all',
        active: false,
      };
    });

    // Update links for each subcategory
    this.collectionsSubcategoriesArray.forEach((subcategory: any) => {
      subcategory.links = allCollectionsLinks;
    });
  }

  async changeCurrentCollection(newId: string): Promise<void> {

    this.collectionsSubcategoriesArray.forEach((subcategory: any) => {
      subcategory.links.forEach((link: any) => {
        link.active = newId === link.id;
      });
    });

    const collection = this.collections.find(
      (collection: any) => collection.user_wallet_address === newId
    );

    if (collection) {
      this.$store.commit('setKeypadCurrentCollection', collection);
      if (this.$route.name !== 'Keypad') {
        await this.$router.push('/keypad');
      }
    }
  }

  collectionsSubcategoriesArray: any = [
    {
      icon: 'fa-solid fa-star',
      label: 'Featured',
      name: 'featured',
      open: true,
      links: [],
    },
    {
      icon: 'fa-solid fa-fire-flame-curved',
      label: 'Hottest',
      name: 'hottest',
      open: false,
      links: [],
    },
    {
      icon: 'fa-solid fa-square-plus',
      label: 'Newest',
      name: 'newest',
      open: false,
      links: [],
    },
    {
      icon: 'fa-solid fa-cubes',
      label: 'All collections',
      name: 'all',
      open: false,
      links: [],
    },
  ];
}
