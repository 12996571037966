import * as PIXI from 'pixi.js';
import {
  Pixel,
  Position,
  FinalCell,
  PositionHistory,
  Filters,
} from '@/utils/interfaces/canvas';
import store from '@/store';
import _ from 'lodash';
import { validateShapeForMerge } from '../functions/validateShapeForMerge';
import { SelectedSize, SelectedType } from '../interfaces';

export class CanvasApi {
  canvasContainer = {} as HTMLElement;
  gridSize = [370, 370];
  squareSize = [3, 3];
  zoomLevel = 1;

  isMerge = false;

  mergingClicks = 0;
  mergedArea = {
    startX: 0,
    startY: 0,
    endX: 0,
    endY: 0,
  };

  app = null as any;
  graphics = null as any;
  gridLines = null as any;
  container = null as any;
  containerPics?: PIXI.Container;
  logo = null as any;
  logo2 = null as any;
  logos = [] as any;
  dragging = false as boolean;
  selector = null as any;
  reserved = null as any;
  highlight = null as any;
  mergingLayer = null as any;
  minted = null as any;
  mouseDown = false as boolean;
  start = {} as Position;
  graphicsStart = {} as Position;
  selectedColor = 'FFD449' as string;
  RESERVED_COLOR = { owner: 'FF3969', other: 'F4ACB7' };
  MINTED_COLOR = { owner: '1DD3B0', other: '3A86FF' };
  hoveredColor = '6FB5F9';
  mergingColor = '6FB5F9';
  mergingColorShade2 = '2081E2';
  borderColor = 'FFFFFF' as string;
  zoomed = false as boolean;
  coolCount = 0 as number;
  coolInterval = null as any;
  scale = 1 as number;
  SCALE = { x: 0.7, y: 0.7 } as Position;
  SCALE_FACTOR = 1.1;
  currentlyWriting = '' as string;
  ready = false as boolean;
  LINE_WIDTH = 0.5;
  SQUARE_SIZE = 3;
  sizes = [3, 6, 12, 24];
  cacheCell = null as any;
  cacheCellStatus = false as boolean;
  lastParcelHover = null as any;
  isMovedFromParcels = false as boolean;
  scaling = false;
  lastDistance = null as number | null;

  constructor() {
    this.canvasContainer = document.getElementById('canvas')!;
    this.setupStage();
    this.ready = true;
    this.setZoom();
  }

  destroy() {
    this.containerPics?.children.forEach((el) => el.destroy());
    this.graphics.destroy();
    this.containerPics?.destroy();
    this.container?.destroy();
    store.commit('app/setMintingParcels', null);
  }

  setupStage() {
    while (this.canvasContainer.firstChild) {
      this.canvasContainer.removeChild(this.canvasContainer.firstChild);
    }
    this.app = new PIXI.Application({
      width: window.innerWidth - 260,
      height: window.innerHeight - 50,
      antialias: true,
      backgroundColor: 0xffffff,
    });
    this.app.stage.position.y += 40;
    this.canvasContainer.appendChild(this.app.view);
    this.container = new PIXI.Container();
    this.containerPics = new PIXI.Container();
    this.app.stage.addChild(this.container);
    this.graphics = new PIXI.Graphics();
    this.graphics.beginFill(0xd3d3d3, 1);
    this.graphics.drawRect(
      0,
      0,
      this.gridSize[0] * this.squareSize[0],
      this.gridSize[1] * this.squareSize[1]
    );
    this.graphics.interactive = true;
    this.graphics.on('touchstart', (e: any) => this.onTouchStart(e));
    this.graphics.on('touchmove', (e: any) => this.onTouchMove(e));
    this.graphics.on('touchend', (e: any) => this.onTouchEnd(e));
    this.graphics.on('pointerdown', (e: any) => this.onDown(e));
    this.graphics.on('pointermove', (e: any) => this.onMove(e));

    this.graphics.on('pointerup', (e: any) => this.onUp(e));
    this.graphics.on('pointerupoutside', (e: any) => this.onUp(e));
    this.graphics.position.x = -this.graphics.width / 2 + 50;
    this.graphics.position.y = -this.graphics.height / 2 + 50;
    this.container.addChild(this.graphics);
    this.selector = new PIXI.Graphics();
    this.selector.on('touchstart', (e: any) => this.onTouchStart(e));
    this.selector.on('touchmove', (e: any) => this.onTouchMove(e));
    this.selector.on('touchend', (e: any) => this.onTouchEnd(e));
    this.selector.on('pointerdown', (e: any) => this.onDown(e));
    this.selector.on('pointermove', (e: any) => this.onMove(e));
    this.selector.on('pointerup', (e: any) => this.onUp(e));
    this.selector.on('pointerupoutside', (e: any) => this.onUp(e));
    this.selector.interactive = true;
    this.selector.position.x = -this.graphics.width / 2 + 50;
    this.selector.position.y = -this.graphics.height / 2 + 50;
    this.container.addChild(this.selector);
    this.reserved = new PIXI.Graphics();
    this.reserved.on('touchstart', (e: any) => this.onTouchStart(e));
    this.reserved.on('touchmove', (e: any) => this.onTouchMove(e));
    this.reserved.on('touchend', (e: any) => this.onTouchEnd(e));
    this.reserved.on('pointerdown', (e: any) => this.onDown(e));
    this.reserved.on('pointermove', (e: any) => this.onMove(e));
    this.reserved.on('pointerup', (e: any) => this.onUp(e));
    this.reserved.on('pointerupoutside', (e: any) => this.onUp(e));
    this.reserved.interactive = true;
    this.reserved.position.x = -this.graphics.width / 2 + 50;
    this.reserved.position.y = -this.graphics.height / 2 + 50;
    this.container.addChild(this.reserved);
    this.highlight = new PIXI.Graphics();
    this.highlight.on('touchstart', (e: any) => this.onTouchStart(e));
    this.highlight.on('touchmove', (e: any) => this.onTouchMove(e));
    this.highlight.on('touchend', (e: any) => this.onTouchEnd(e));
    this.highlight.on('pointerdown', (e: any) => this.onDown(e));
    this.highlight.on('pointermove', (e: any) => this.onMove(e));
    this.highlight.on('pointerup', (e: any) => this.onUp(e));
    this.highlight.on('pointerupoutside', (e: any) => this.onUp(e));
    this.highlight.interactive = true;
    this.highlight.position.x = -this.graphics.width / 2 + 50;
    this.highlight.position.y = -this.graphics.height / 2 + 50;
    this.mergingLayer = new PIXI.Graphics();
    this.mergingLayer.on('touchstart', (e: any) => this.onTouchStart(e));
    this.mergingLayer.on('touchmove', (e: any) => this.onTouchMove(e));
    this.mergingLayer.on('touchend', (e: any) => this.onTouchEnd(e));
    this.mergingLayer.on('pointerdown', (e: any) => this.onDown(e));
    this.mergingLayer.on('pointermove', (e: any) => this.onMove(e));
    this.mergingLayer.on('pointerup', (e: any) => this.onUp(e));
    this.mergingLayer.on('pointerupoutside', (e: any) => this.onUp(e));
    this.mergingLayer.interactive = true;
    this.mergingLayer.position.x = -this.graphics.width / 2 + 50;
    this.mergingLayer.position.y = -this.graphics.height / 2 + 50;
    this.gridLines = new PIXI.Graphics();
    this.gridLines.lineStyle(this.LINE_WIDTH, '0x' + this.borderColor, 1);
    this.gridLines.alpha = 1;
    this.gridLines.position.x = this.graphics.position.x;
    this.gridLines.position.y = this.graphics.position.y;

    for (let i = 0; i <= this.gridSize[0]; i++) {
      this.drawLine(
        0,
        i * this.squareSize[0],
        this.gridSize[0] * this.squareSize[0],
        i * this.squareSize[0]
      );
    }

    for (let j = 0; j <= this.gridSize[1]; j++) {
      this.drawLine(
        j * this.squareSize[1],
        0,
        j * this.squareSize[1],
        this.gridSize[1] * this.squareSize[1]
      );
    }

    this.container.addChild(this.gridLines);

    this.minted = new PIXI.Graphics();
    this.minted.on('touchstart', (e: any) => this.onTouchStart(e));
    this.minted.on('touchmove', (e: any) => this.onTouchMove(e));
    this.minted.on('touchend', (e: any) => this.onTouchEnd(e));
    this.minted.on('pointerdown', (e: any) => this.onDown(e));
    this.minted.on('pointermove', (e: any) => this.onMove(e));
    this.minted.on('pointerup', (e: any) => this.onUp(e));
    this.minted.on('pointerupoutside', (e: any) => this.onUp(e));
    this.minted.interactive = true;
    this.minted.position.x = -this.graphics.width / 2 + 50;
    this.minted.position.y = -this.graphics.height / 2 + 50;
    this.container.addChild(this.minted);

    this.container.addChild(this.highlight);
    this.container.addChild(this.mergingLayer);
    this.container.addChild(this.containerPics);

    this.onResize();
    this.container.position.x = window.innerWidth / 2;
    this.container.position.y = window.innerHeight / 2;

    //TOP
    for (let i = 180; i < 190; i++) {
      for (let j = 0; j < 116; j++) {
        this.writePixel(i, j, this.borderColor);
      }
    }
    for (let i = 120; i < 250; i++) {
      for (let j = 115; j < 120; j++) {
        this.writePixel(i, j, this.borderColor);
      }
    }
    //LEFT
    for (let i = 0; i < 116; i++) {
      for (let j = 180; j < 190; j++) {
        this.writePixel(i, j, this.borderColor);
      }
    }
    for (let i = 115; i < 120; i++) {
      for (let j = 115; j < 255; j++) {
        this.writePixel(i, j, this.borderColor);
      }
    }
    //RIGHT
    for (let i = 254; i < 370; i++) {
      for (let j = 180; j < 190; j++) {
        this.writePixel(i, j, this.borderColor);
      }
    }
    for (let i = 250; i < 255; i++) {
      for (let j = 115; j < 255; j++) {
        this.writePixel(i, j, this.borderColor);
      }
    }
    //BOTTOM
    for (let i = 180; i < 190; i++) {
      for (let j = 254; j < 370; j++) {
        this.writePixel(i, j, this.borderColor);
      }
    }
    for (let i = 120; i < 250; i++) {
      for (let j = 250; j < 255; j++) {
        this.writePixel(i, j, this.borderColor);
      }
    }

    this.canvasContainer.addEventListener('mousewheel', (e: any) => {
      this.zoom(e.deltaY, e.offsetX, e.offsetY);
    });
    window.addEventListener('resize', this.onResize);
  }

  onResize(e?: any) {
    if (window.innerWidth < 816 && !store.state.app.optionBar) {
      this.app.renderer?.resize(window.innerWidth, window.innerHeight - 50);
    } else {
      this.app.renderer?.resize(
        window.innerWidth - 260,
        window.innerHeight - 50
      );
    }
  }

  onDown(e: any) {
    if (e.data.global.y < window.innerHeight - 50 && this.ready) {
      this.start = { x: e.data.global.x, y: e.data.global.y };
      this.mouseDown = true;
    }
  }

  pinchMove(touches: any): void {
    // Using Math.hypot, figure out whether the distance between the two touches has increased or decreased
    // Then change this.zoom accordingly (zoom in or out)
    const distance = Math.hypot(
      touches[0].clientX - touches[1].clientX,
      touches[0].clientY - touches[1].clientY
    );

    if (this.lastDistance) {
      const diff = distance - this.lastDistance;
      this.zoom(
        diff / 3000,
        (touches[0].clientX + touches[1].clientX) / 2,
        (touches[0].clientY + touches[1].clientY) / 2,
        diff / 3000
      );
    }

    setTimeout(() => {
      // I swear if this is what fixes it
      this.lastDistance = distance;
    }, 2);
  }

  onTouchStart(e: any): void {
    const {
      data: {
        originalEvent: { touches },
      },
    } = e;
    if (touches && touches.length === 2) {
      this.scaling = true;
      const distance = Math.hypot(
        touches[0].clientX - touches[1].clientX,
        touches[0].clientY - touches[1].clientY
      );

      this.lastDistance = distance;
    }
  }

  onTouchMove(e: any): void {
    const {
      data: {
        originalEvent: { touches },
      },
    } = e;

    if (touches && touches.length === 2 && this.scaling) {
      this.pinchMove(touches);
    }
  }

  onTouchEnd(e: any): void {
    if (this.scaling) {
      this.scaling = false;
      this.lastDistance = null;
    }
  }

  onMove(e: any) {
    if (this.scaling) return;

    if (!this.mouseDown && !this.isMerge && !this.isMovedFromParcels) {
      const position = e.data.getLocalPosition(this.graphics);
      const gapsX = Math.floor(position.x / this.squareSize[0]);
      const gapsY = Math.floor(position.y / this.squareSize[1]);
      const { x, y } = this.translateNoGaps({ x: gapsX, y: gapsY }).to;
      store.commit('app/setCursorCoordinates', {
        x: Math.max(0, x),
        y: Math.max(0, y),
      });
      const underPointerParcel = store.state.filteredParcels
        .filter(
          ([_, value]: any) =>
            x >= value.startX &&
            y >= value.startY &&
            x < value.startX + value.size &&
            y < value.startY + value.size &&
            value.state === 'MINTED'
        )
        .map(([_, parcel]: any) => parcel);

      if (underPointerParcel.length > 0) {
        const parcel = underPointerParcel[0];
        if (this.lastParcelHover?.id === parcel.id) {
          return;
        }
        this.lastParcelHover = parcel;

        if (parcel.owner === store.state.wallet) {
          const element = document.getElementById(parcel.id);
          element?.scrollIntoView();
          store.commit('app/setOwnedParcelsSidebar', true);
        }

        store.commit('app/setLastHovered', { ...parcel, outside: false });
      } else {
        this.lastParcelHover = null;
        store.commit('app/setLastHovered', null);
      }
    }

    if (this.mouseDown) {
      if (!this.dragging) {
        const pos = e.data.global;

        if (
          Math.abs(this.start.x - pos.x) > 5 ||
          Math.abs(this.start.y - pos.y) > 5
        ) {
          this.graphicsStart = {
            x: this.container.position.x,
            y: this.container.position.y,
          };
          this.dragging = true;
        }
      }

      if (this.dragging) {
        this.container.position.x =
          (e.data.global.x - this.start.x) / this.scale + this.graphicsStart.x;
        this.container.position.y =
          (e.data.global.y - this.start.y) / this.scale + this.graphicsStart.y;
      }
    }
  }

  onUp(e: any) {
    if (this.mouseDown && this.ready) {
      this.mouseDown = false;

      if (!this.dragging) {
        const position = e.data.getLocalPosition(this.graphics);
        const posX = Math.floor(position.x / this.squareSize[0]);
        const posY = Math.floor(position.y / this.squareSize[1]);
        this.selector.clear();

        if (this.isMerge) {
          this.mergeZone(posX, posY, this.mergingColor);
        } else {
          const position = e.data.getLocalPosition(this.graphics);
          const gapsX = Math.floor(position.x / this.squareSize[0]);
          const gapsY = Math.floor(position.y / this.squareSize[1]);
          const { x, y } = this.translateNoGaps({ x: gapsX, y: gapsY }).to;
          const underPointerParcel = store.state.filteredParcels
            .filter(
              ([_, value]: any) =>
                x >= value.startX &&
                y >= value.startY &&
                x < value.startX + value.size &&
                y < value.startY + value.size &&
                value.state === 'MINTED'
            )
            .map(([_, parcel]: any) => parcel);

          if (underPointerParcel.length > 0) {
            const parcel = underPointerParcel[0];
            if (parcel.owner === store.state.wallet) {
              if (window.innerWidth > 768) {
                store.commit('app/setOwnedParcelsSidebar', true);
              }
              const element = document.getElementById(parcel.id);
              element?.scrollIntoView();
            }
            if (parcel.owner === store.state.wallet) {
              store.commit('app/setModal', {
                component: 'editParcel',
                payload: parcel,
              });
            } else {
              store.commit('app/setModal', {
                component: 'parcelDetails',
                payload: parcel,
              });
            }
          } else {
            this.selectZone(posX, posY, this.selectedColor, true);
          }
        }
      }
      this.dragging = false;
    }
  }

  zoom(s: any, x: any, y: any, forceScale: number | null = null) {
    const stage = this.app.stage;
    s = s > 0 ? this.SCALE_FACTOR : 1 / this.SCALE_FACTOR;

    if (forceScale !== null) {
      s = forceScale >= 0 ? 1 + forceScale : 1 / (1 + forceScale * -1);
    }

    const worldPos = {
      x: (x - stage.x) / stage.scale.x,
      y: (y - stage.y) / stage.scale.y,
    };
    const newScale = { x: stage.scale.x * s, y: stage.scale.y * s };
    this.SCALE = newScale;

    this.scale = (newScale.x + newScale.y) / 2;

    if (this.scale > 16) {
      this.scale = 16;
      return;
    } else if (this.scale < 0.7) {
      this.scale = 0.7;
      return;
    }

    const newScreenPos = {
      x: worldPos.x * newScale.x + stage.x,
      y: worldPos.y * newScale.y + stage.y,
    };

    stage.x -= newScreenPos.x - x;
    stage.y -= newScreenPos.y - y;
    this.setZoom();
  }

  toggleZoom(offset: Position, forceZoom?: boolean) {
    this.zoomed = forceZoom !== undefined ? forceZoom : !this.zoomed;
  }

  setZoom() {
    this.app.stage.scale.x = this.SCALE.x;
    this.app.stage.scale.y = this.SCALE.y;
  }

  zoomIn() {
    const oldScale = this.scale;
    switch (true) {
      case this.scale <= 1:
        this.SCALE.x *= 2;
        this.SCALE.y *= 2;
        break;
      case this.scale > 1 && this.scale < 16:
        this.SCALE.x = Math.min(this.SCALE.x + 1, 16);
        this.SCALE.y = Math.min(this.SCALE.y + 1, 16);
        break;
    }
    this.scale = (this.SCALE.x + this.SCALE.x) / 2;
    this.app.stage.position.x -=
      (this.app.screen.width / 2) * (this.scale - oldScale);
    this.app.stage.position.y -=
      (this.app.screen.height / 2) * (this.scale - oldScale);
    this.setZoom();
  }

  zoomOut() {
    const oldScale = this.scale;
    switch (true) {
      case this.scale <= 1 && this.scale > 0.7:
        this.SCALE.x = Math.min(this.SCALE.x / 2, 0.7);
        this.SCALE.y = Math.min(this.SCALE.y / 2, 0.7);
        break;
      case this.scale > 1:
        this.SCALE.x -= 1;
        this.SCALE.y -= 1;
        break;
    }
    this.scale = (this.SCALE.x + this.SCALE.x) / 2;
    this.app.stage.position.x +=
      (this.app.screen.width / 2) * (oldScale - this.scale);
    this.app.stage.position.y +=
      (this.app.screen.height / 2) * (oldScale - this.scale);
    this.setZoom();
  }

  drawLine(x: any, y: any, x2: any, y2: any) {
    this.gridLines.moveTo(x, y);
    this.gridLines.lineTo(x2, y2);
  }

  draw({ startX, startY, endX, endY }: any, opacity: number, COLOR: string) {
    this.mergingLayer.beginFill(parseInt('0x' + COLOR), opacity ? opacity : 1);
    this.mergingLayer.drawRect(startX, startY, endX, endY);
  }

  selectZone(x: number, y: number, color: string, isClick?: boolean) {
    const squareSize = store.state.app.mintingParcels
      ? store.state.app.mintingParcels?.blocks.value
      : 1;
    this.selector.clear();
    const pos: Position = this.computeCoordinates(x, y, squareSize, isClick);

    this.selector.beginFill(parseInt('0x' + color), 1);
    this.selector.drawRect(
      pos.x * this.squareSize[0],
      pos.y * this.squareSize[1],
      this.squareSize[0] * squareSize,
      this.squareSize[1] * squareSize
    );
  }

  mergeZone(x: number, y: number, color: string) {
    const squareSize = 1;
    this.mergingClicks++;

    if (this.mergingClicks >= 3) {
      this.mergingClicks = 0;
      this.mergingLayer.clear();
      store.commit('app/setMergedParcel', { selected: null, variable: null });
      return;
    }

    const pos: Position = this.computeCoordinates(x, y, squareSize);
    //de verificat
    const calculateENDS = (start: number, end: number, squareSize: number) => {
      if (start < end) {
        return squareSize * (end - start + 1);
      } else return squareSize * (end - start - 1);
    };
    const calculateSTARTS = (
      start: number,
      end: number,
      squareSize: number
    ) => {
      if (start < end) {
        return squareSize * start;
      } else return squareSize * (start + 1);
    };

    if (this.mergingClicks === 1) {
      this.draw(
        {
          startX: pos.x * this.squareSize[0],
          startY: pos.y * this.squareSize[1],
          endX: this.squareSize[0] * squareSize,
          endY: this.squareSize[1] * squareSize,
        },
        1,
        this.mergingColorShade2
      );
      this.mergedArea = {
        startX: x,
        startY: y,
        endX: 0,
        endY: 0,
      };
    } else if (this.mergingClicks === 2) {
      this.mergingLayer.clear();
      const processedAreaCells = {
        startX: this.mergedArea.startX,
        startY: this.mergedArea.startY,
        endX: x,
        endY: y,
        sizeX:
          calculateENDS(this.mergedArea.startX, x, this.squareSize[0]) /
          this.squareSize[0],
        sizeY:
          calculateENDS(this.mergedArea.startY, y, this.squareSize[1]) /
          this.squareSize[1],
      };

      const mergedArea = {
        startX: calculateSTARTS(this.mergedArea.startX, x, this.squareSize[0]),
        startY: calculateSTARTS(this.mergedArea.startY, y, this.squareSize[1]),
      };

      this.processCell(processedAreaCells, color, {
        x: mergedArea.startX,
        y: mergedArea.startY,
      });

      if (!this.checkAvailability(processedAreaCells, 'SELECTED')) {
        this.drawMergeZone(x, y, calculateSTARTS, calculateENDS, color);
        return;
      }
    }

    this.mergingLayer.on('mousemove', (e: any) => {
      if (this.mergingClicks !== 1 || !this.isMerge) {
        this.cacheCell = null as any;
        this.cacheCellStatus = false as boolean;
        this.mergingClicks = 0;
        this.mergingLayer.clear();
        return;
      }

      const position = e.data.getLocalPosition(this.graphics);
      const x = Math.floor(position.x / this.squareSize[0]);
      const y = Math.floor(position.y / this.squareSize[1]);

      this.drawMergeZone(x, y, calculateSTARTS, calculateENDS, color);
    });
  }

  drawMergeZone(
    x: any,
    y: any,
    calculateSTARTS: any,
    calculateENDS: any,
    color: any
  ) {
    store.commit('app/setMergedParcel', { selected: null, variable: null });

    this.mergingLayer.clear();

    const mergedArea = {
      startX: calculateSTARTS(this.mergedArea.startX, x, this.squareSize[0]),
      startY: calculateSTARTS(this.mergedArea.startY, y, this.squareSize[1]),
      endX: calculateENDS(this.mergedArea.startX, x, this.squareSize[0]),
      endY: calculateENDS(this.mergedArea.startY, y, this.squareSize[1]),
    };

    const processedAreaCells = {
      startX: this.mergedArea.startX,
      startY: this.mergedArea.startY,
      endX: x,
      endY: y,
      sizeX:
        calculateENDS(this.mergedArea.startX, x, this.squareSize[0]) /
        this.squareSize[0],
      sizeY:
        calculateENDS(this.mergedArea.startY, y, this.squareSize[1]) /
        this.squareSize[1],
    };

    this.draw(
      {
        startX: mergedArea.startX,
        startY: mergedArea.startY,
        endX: mergedArea.endX,
        endY: mergedArea.endY,
      },
      0.5,
      color
    );

    this.processCell(processedAreaCells, color, {
      x: mergedArea.startX,
      y: mergedArea.startY,
    });

    this.checkAvailability(processedAreaCells, 'VARIABLE');
  }

  checkAvailability(cell: FinalCell, type: string) {
    const { x, y } = this.translateNoGaps({
      x: cell.startX,
      y: cell.startY,
    }).to;
    cell.startX = x;
    cell.startY = y;
    cell.endX = x + cell.sizeX - 1;
    cell.endY = x + cell.sizeY - 1;

    const cond1 = cell.sizeX === cell.sizeY;
    const cond2 = this.sizes.find((size) => size === cell.sizeX);

    if (type === 'VARIABLE') {
      if (cond1 && cond2) {
        if (
          !_.isEqual(cell, this.cacheCell) &&
          this.cacheCell?.sizeX !== cell.sizeX
        ) {
          this.cacheCell = cell;
          this.cacheCellStatus = validateShapeForMerge(
            cell,
            store.state.parcels,
            store.state.wallet
          ).valid;
        }

        if (this.cacheCellStatus) {
          store.commit('app/setMergedParcel', {
            variable: { ...cell, status: 'AVAILABLE' },
            selected: null,
          });
        } else {
          store.commit('app/setMergedParcel', {
            variable: cell,
            selected: null,
          });
        }
      } else {
        store.commit('app/setMergedParcel', { selected: null, variable: null });
      }
      return;
    } else if (type === 'SELECTED') {
      if (!cond1 && !cond2) {
        store.commit('app/setMergedParcel', { selected: null, variable: null });
        return false;
      }

      const validation = validateShapeForMerge(
        cell,
        store.state.parcels,
        store.state.wallet
      );
      if (!validation.valid) {
        store.commit('app/setMergedParcel', {
          selected: null,
          variable: null,
        });
        return false;
      }
      store.commit('app/setMergedParcel', {
        selected: {
          ...cell,
          status: 'AVAILABLE',
          CellsIds: validation.selectedParcels!.map((parcel) => parcel.id),
        },
        variable: null,
      });
      return true;
    }
  }

  processCell(
    cell: FinalCell,
    color: string,
    coordinates: Position
  ): FinalCell {
    cell.sizeX = Math.abs(cell.sizeX);
    cell.sizeY = Math.abs(cell.sizeY);
    const cond3 =
      cell.sizeX > 2 && cell.sizeY > 2 && (cell.sizeX <= 5 || cell.sizeY <= 5);
    const cond6 =
      cell.sizeX > 5 &&
      cell.sizeY > 5 &&
      (cell.sizeX <= 11 || cell.sizeY <= 11);
    const cond12 =
      cell.sizeX > 11 &&
      cell.sizeY > 11 &&
      (cell.sizeX <= 23 || cell.sizeY <= 23);
    const cond24 = cell.sizeX > 23 && cell.sizeY > 23;

    const setCell = (sizeX: number, sizeY: number) => {
      if (cell.endX < cell.startX && cell.endY < cell.startY) {
        cell.endX = cell.startX;
        cell.endY = cell.startY;
        cell.startX = cell.startX - sizeX + 1;
        cell.startY = cell.startY - sizeY + 1;
        drawRegion(sizeX);
        this.draw(
          {
            startX: coordinates.x - 1 * this.squareSize[0],
            startY: coordinates.y - 1 * this.squareSize[1],
            endX: 1 * this.squareSize[0],
            endY: 1 * this.squareSize[1],
          },
          1,
          this.mergingColorShade2
        );
        this.draw(
          {
            startX: coordinates.x - sizeX * this.squareSize[0],
            startY: coordinates.y - sizeY * this.squareSize[1],
            endX: 1 * this.squareSize[0],
            endY: 1 * this.squareSize[1],
          },
          1,
          this.mergingColorShade2
        );
      } else if (cell.endX < cell.startX) {
        cell.endX = cell.startX;
        cell.startX = cell.startX - sizeX + 1;
        drawRegion(sizeX);
        this.draw(
          {
            startX: coordinates.x - 1 * this.squareSize[0],
            startY: coordinates.y,
            endX: 1 * this.squareSize[0],
            endY: 1 * this.squareSize[1],
          },
          1,
          this.mergingColorShade2
        );
        this.draw(
          {
            startX: coordinates.x - sizeX * this.squareSize[0],
            startY: coordinates.y + (sizeY - 1) * this.squareSize[1],
            endX: 1 * this.squareSize[0],
            endY: 1 * this.squareSize[1],
          },
          1,
          this.mergingColorShade2
        );
      } else if (cell.endY < cell.startY) {
        cell.endY = cell.startY;
        cell.startY = cell.startY - sizeY + 1;
        drawRegion(sizeX);
        this.draw(
          {
            startX: coordinates.x,
            startY: coordinates.y - 1 * this.squareSize[1],
            endX: 1 * this.squareSize[0],
            endY: 1 * this.squareSize[1],
          },
          1,
          this.mergingColorShade2
        );
        this.draw(
          {
            startX: coordinates.x + (sizeX - 1) * this.squareSize[0],
            startY: coordinates.y - sizeY * this.squareSize[1],
            endX: 1 * this.squareSize[0],
            endY: 1 * this.squareSize[1],
          },
          1,
          this.mergingColorShade2
        );
      } else {
        drawRegion(sizeX);
        this.draw(
          {
            startX: coordinates.x,
            startY: coordinates.y,
            endX: 1 * this.squareSize[0],
            endY: 1 * this.squareSize[1],
          },
          1,
          this.mergingColorShade2
        );
        this.draw(
          {
            startX: coordinates.x + (sizeX - 1) * this.squareSize[0],
            startY: coordinates.y + (sizeY - 1) * this.squareSize[1],
            endX: 1 * this.squareSize[0],
            endY: 1 * this.squareSize[1],
          },
          1,
          this.mergingColorShade2
        );
      }

      cell.sizeX = sizeX;
      cell.sizeY = sizeY;
    };

    const drawRegion = (size: number) => {
      this.draw(
        {
          startX: cell.startX * this.squareSize[0],
          startY: cell.startY * this.squareSize[1],
          endX: size * this.squareSize[0],
          endY: size * this.squareSize[1],
        },
        1,
        color
      );
    };

    if (cond3) {
      setCell(3, 3);
    } else if (cond6) {
      setCell(6, 6);
    } else if (cond12) {
      setCell(12, 12);
    } else if (cond24) {
      setCell(24, 24);
    }

    return cell;
  }

  computeCoordinates(
    x: number,
    y: number,
    squareSize: any,
    isClick?: boolean
  ): Position {
    const posNoGaps = this.translateNoGaps({ x, y });
    const finalPosition = this.translateWithGaps(posNoGaps, squareSize);

    if (isClick) {
      store.commit(
        'app/setParcelCoordinates',
        this.translateNoGaps(finalPosition).to
      );
    }

    return finalPosition;
  }

  translateNoGaps({ x, y }: Position): PositionHistory {
    const from = { x, y };
    //centre
    if (x >= 120 && y >= 120 && x <= 249 && y <= 249) {
      x -= 5;
      y -= 5;
    }

    //form top right
    if ((x >= 190 && y <= 114) || (x >= 255 && y <= 179)) {
      x -= 10;
    }

    //form bottom left
    if ((x <= 114 && y >= 190) || (x <= 179 && y >= 255)) {
      y -= 10;
    }
    //form bottom right
    if ((x >= 190 && y >= 255) || (x >= 255 && y >= 190)) {
      x -= 10;
      y -= 10;
    }
    return { from, to: { x, y } };
  }

  translateToGaps({ x, y }: Position): Position {
    //centre
    if (x >= 115 && y >= 115 && x <= 244 && y <= 244) {
      x += 5;
      y += 5;
      return { x, y };
    }
    //form top right
    if ((x >= 180 && y <= 114) || (x >= 245 && y <= 179)) {
      x += 10;
      return { x, y };
    }
    //form bottom left
    if ((x <= 114 && y >= 180) || (x <= 179 && y >= 245)) {
      y += 10;
      return { x, y };
    }
    //form bottom right
    if ((x >= 180 && y >= 245) || (x >= 245 && y >= 180)) {
      x += 10;
      y += 10;
      return { x, y };
    }
    return { x, y };
  }

  translateWithGaps(cell: PositionHistory, squareSize: number): Position {
    let { x, y } = cell.to;

    //centre
    if (
      ((x >= 115 && x < 120 && y >= 115 && y < 255) ||
        (y >= 115 && y < 120 && x >= 115 && x < 255)) &&
      (cell.from.x < 120 || cell.from.y < 120)
    ) {
      if (x >= 115 && x < 120) {
        if (y + squareSize > 249) {
          y = 249 - squareSize + 1;
        }
        x = 120;
      }
      if (y >= 115 && y < 120) {
        if (x + squareSize > 249) {
          x = 249 - squareSize + 1;
        }
        y = 120;
      }
    } else if (
      ((x <= 254 && x > 249 && y <= 254) ||
        (y <= 254 && y > 249 && x <= 254)) &&
      (cell.from.x > 249 || cell.from.y > 249)
    ) {
      if (x <= 254 && x > 249) {
        if (y + squareSize > 249) {
          y = 249 - squareSize + 1;
        }
        x = 249 - squareSize + 1;
      }
      if (y <= 254 && y > 249) {
        if (x + squareSize > 249) {
          x = 249 - squareSize + 1;
        }
        y = 249 - squareSize + 1;
      }
    } else if (
      cell.from.x >= 120 &&
      cell.from.y >= 120 &&
      cell.from.x <= 249 &&
      cell.from.y <= 249
    ) {
      if (cell.from.x + squareSize - 1 > 249) {
        cell.from.x = 249 - squareSize + 1;
      }
      if (cell.from.y + squareSize - 1 > 249) {
        cell.from.y = 249 - squareSize + 1;
      }
      return cell.from;
    }

    // form top left
    if (
      (x > 179 && x <= 184 && y <= 114) ||
      (y > 179 && y <= 184 && x <= 114)
    ) {
      if (x > 179 && x <= 184) {
        if (y + squareSize > 114) {
          y = 114 - squareSize + 1;
        }
        x = 179 - squareSize + 1;
      }
      if (y > 179 && y <= 184) {
        if (x + squareSize > 114) {
          x = 114 - squareSize + 1;
        }
        y = 179 - squareSize + 1;
      }
    } else if (
      (cell.from.x <= 114 && cell.from.y <= 179) ||
      (cell.from.x <= 179 && cell.from.y <= 114)
    ) {
      if (
        x <= 179 &&
        x > 114 - squareSize + 1 &&
        y <= 114 &&
        (x + squareSize > 179 || y + squareSize > 114)
      ) {
        if (x + squareSize > 179) {
          x = 179 - squareSize + 1;
        }
        if (y + squareSize > 114) {
          y = 114 - squareSize + 1;
        }
        return { x, y };
      }
      if (
        x <= 114 &&
        y <= 179 &&
        (x + squareSize > 114 || y + squareSize > 179)
      ) {
        if (x + squareSize > 114) {
          x = 114 - squareSize + 1;
        }
        if (y + squareSize > 179) {
          y = 179 - squareSize + 1;
        }
        return { x, y };
      }
      return cell.from;
    }

    //form top right
    if (
      (cell.from.x >= 185 && cell.from.x < 190 && y <= 114) ||
      (y > 179 && y <= 184 && cell.from.x >= 255)
    ) {
      if (cell.from.x >= 185 && cell.from.x < 190) {
        if (y + squareSize > 114) {
          y = 114 - squareSize + 1;
        }
        x = 190;
      }
      if (y > 179 && y <= 184) {
        if (cell.from.x + squareSize > 369) {
          x = 369 - squareSize + 1;
        }
        y = 179 - squareSize + 1;
      }
    } else if (
      (cell.from.x >= 190 && y <= 114) ||
      (cell.from.x >= 255 && y <= 179)
    ) {
      if (
        cell.from.x >= 190 &&
        cell.from.x < 255 &&
        y <= 114 &&
        y + squareSize > 114
      ) {
        if (y + squareSize > 114) {
          y = 114 - squareSize + 1;
        }
        return { x: cell.from.x, y };
      }
      if (
        cell.from.x >= 255 &&
        y > 114 - squareSize &&
        y <= 179 &&
        (cell.from.x + squareSize > 369 || y + squareSize > 179)
      ) {
        if (y + squareSize > 179) {
          y = 179 - squareSize + 1;
        }
        if (cell.from.x + squareSize > 369) {
          cell.from.x = 369 - squareSize + 1;
        }
        return { x: cell.from.x, y };
      }

      if (cell.from.x + squareSize > 369) {
        cell.from.x = 369 - squareSize + 1;
      }

      return cell.from;
    }

    //form bottom left
    if (
      (cell.from.y >= 185 && cell.from.y < 190 && x <= 114) ||
      (x > 179 && x <= 184 && cell.from.y >= 255)
    ) {
      if (cell.from.y >= 185 && cell.from.y < 190) {
        if (x + squareSize > 114) {
          x = 114 - squareSize + 1;
        }
        y = 190;
      }
      if (x > 179 && x <= 184) {
        if (cell.from.y + squareSize > 369) {
          y = 369 - squareSize + 1;
        }
        x = 179 - squareSize + 1;
      }
    } else if (
      (cell.from.y >= 190 && x <= 114) ||
      (cell.from.y >= 255 && x <= 179)
    ) {
      if (
        cell.from.y >= 190 &&
        cell.from.y < 255 &&
        x <= 114 &&
        x + squareSize > 114
      ) {
        if (x + squareSize > 114) {
          x = 114 - squareSize + 1;
        }
        return { x, y: cell.from.y };
      }
      if (
        cell.from.y >= 255 &&
        x > 114 - squareSize &&
        x <= 179 &&
        (cell.from.y + squareSize > 369 || x + squareSize > 179)
      ) {
        if (x + squareSize > 179) {
          x = 179 - squareSize + 1;
        }
        if (cell.from.y + squareSize > 369) {
          cell.from.y = 369 - squareSize + 1;
        }
        return { x, y: cell.from.y };
      }

      if (cell.from.y + squareSize > 369) {
        cell.from.y = 369 - squareSize + 1;
      }

      return cell.from;
    }

    //form bottom right
    if (
      (cell.from.y >= 185 && cell.from.y < 190 && x >= 255) ||
      (cell.from.x >= 185 && cell.from.x < 190 && y >= 255)
    ) {
      if (cell.from.y >= 185 && cell.from.y < 190) {
        if (cell.from.x + squareSize > 369) {
          cell.from.x = 369 - squareSize + 1;
        }
        cell.from.y = 190;
      }
      if (cell.from.x >= 185 && cell.from.x < 190) {
        if (cell.from.y + squareSize > 369) {
          cell.from.y = 369 - squareSize + 1;
        }
        cell.from.x = 190;
      }
      return cell.from;
    } else if (
      (cell.from.y >= 255 && cell.from.x >= 190) ||
      (cell.from.x >= 255 && cell.from.y >= 190)
    ) {
      if (cell.from.x + squareSize > 369) {
        cell.from.x = 369 - squareSize + 1;
      }
      if (cell.from.y + squareSize > 369) {
        cell.from.y = 369 - squareSize + 1;
      }
      return cell.from;
    }
    return { x, y };
  }

  populateParcels(parcels: any, option?: any) {
    let filters = store.state.app.portalFilters as Filters;
    let minted = [];
    let reserved = [];

    if (option) {
      filters = option;
    } else filters = store.state.app.portalFilters;

    const typeFilter = filters.type.map((el: any) => SelectedType[el]);

    minted = parcels.filter(
      ([_, el]: any) =>
        typeFilter.includes('MINTED') &&
        el.state === 'MINTED' &&
        filters.size.includes(el.size) &&
        (filters.chainId.includes(-1) ||
          filters.chainId.includes(el.chainId)) &&
        (filters.Owned_By_Me && store.state.user
          ? el.owner === store.state.wallet
          : true)
    );

    reserved = parcels.filter(
      ([_, el]: any) =>
        typeFilter.includes('RESERVED') &&
        el.state === 'RESERVED' &&
        filters.size.includes(el.size) &&
        (filters.chainId.includes(-1) ||
          filters.chainId.includes(el.chainId)) &&
        (filters.Owned_By_Me && store.state.user
          ? el.owner === store.state.wallet
          : true)
    );

    this.populateParcelContainer(minted);
    this.minted.clear();
    this.reserved.clear();

    const filteredParcels = minted.concat(reserved);
    store.commit('setFilteredParcels', filteredParcels);

    for (const [_, parcel] of minted) {
      const color =
        store.state.user && parcel.owner === store.state.user.address
          ? this.MINTED_COLOR.owner
          : this.MINTED_COLOR.other;
      const { startX, startY, size } = parcel;
      const { x, y } = this.translateToGaps({ x: startX, y: startY });
      this.printMinted(x, y, size, color);
    }

    for (const [_, parcel] of reserved) {
      const color =
        store.state.user && parcel.owner === store.state.user.address
          ? this.RESERVED_COLOR.owner
          : this.RESERVED_COLOR.other;
      const { startX, startY, size } = parcel;
      const { x, y } = this.translateToGaps({ x: startX, y: startY });
      this.printReserved(x, y, size, color);
    }
  }

  populateParcelContainer(parcels: any) {
    this.containerPics?.children.forEach((el) => el.destroy());
    this.containerPics?.destroy({
      children: true,
      texture: true,
      baseTexture: true,
    });
    this.containerPics = new PIXI.Container();
    this.container.addChild(this.containerPics);
    const loader = new PIXI.Loader();
    for (const [_, array] of Object.entries(
      parcels as { [key: string]: any }
    )) {
      const { image, size, id, startX, startY } = array[1];
      const { x, y } = this.translateToGaps({ x: startX, y: startY });
      if (image) {
        loader.add({
          name: id + new Date().getTime(),
          //TODO! save all images with transparent background no matter what size they have
          url:
            image +
            (size === 1 ? '_0' : size === 3 ? '_0' : size === 6 ? '_0' : '_0') +
            '?lastmod=' +
            new Date().getTime(),
          loadType: PIXI.LoaderResource.LOAD_TYPE.IMAGE,
          xhrType: PIXI.LoaderResource.XHR_RESPONSE_TYPE.BLOB,
          onComplete: (resource) => {
            if (!resource) {
              return;
            }
            const logo = new PIXI.Sprite(resource.texture!);
            logo.anchor.set(0);
            logo.width = size * this.SQUARE_SIZE - this.LINE_WIDTH;
            logo.height = size * this.SQUARE_SIZE - this.LINE_WIDTH;
            logo.position.x =
              this.gridLines.x + x * this.SQUARE_SIZE + this.LINE_WIDTH / 2;
            logo.position.y =
              this.gridLines.y + y * this.SQUARE_SIZE + this.LINE_WIDTH / 2;
            this.containerPics?.addChild(logo);
          },
        });
      }
    }
    loader.load();
  }

  printReserved(x: number, y: number, size: number, color: string) {
    this.reserved.beginFill(parseInt('0x' + color), 1);
    this.reserved.drawRect(
      x * this.squareSize[0],
      y * this.squareSize[1],
      this.squareSize[0] * size,
      this.squareSize[1] * size
    );
  }

  printMinted(x: number, y: number, size: number, color: string) {
    const BORDER_SIZE = 0.25;
    this.minted.beginFill(parseInt('0x' + color), 1);
    this.minted.drawRect(
      x * this.squareSize[0] + BORDER_SIZE,
      y * this.squareSize[1] + BORDER_SIZE,
      this.squareSize[0] * size - BORDER_SIZE * 2,
      this.squareSize[1] * size - BORDER_SIZE * 2
    );
  }

  renderPixel(pos: string, pixel: Pixel) {
    const split = pos.split('x');
    const squareSize = store.state.app.mintingParcels
      ? store.state.app.mintingParcels?.blocks.value
      : 1;
    const x = +split[0];
    const y = +split[1];
    const color = pixel.color;

    this.graphics.beginFill(parseInt('0x' + color), 1);
    this.graphics.drawRect(
      x * this.squareSize[0],
      y * this.squareSize[1],
      this.squareSize[0] * squareSize,
      this.squareSize[1] * squareSize
    );
  }

  writePixel(x: number, y: number, color: string) {
    const data: Pixel = {
      color: color,
    };

    this.currentlyWriting = x + 'x' + y;

    this.renderPixel(this.currentlyWriting, data);
    this.currentlyWriting = '';
  }

  showHovered(parcel: any) {
    if (parcel === null) {
      this.highlight.clear();
      this.lastParcelHover = null;
      this.isMovedFromParcels = false;
      return;
    }

    const { startX, startY, size } = parcel;
    const { x, y } = this.translateToGaps({ x: startX, y: startY });
    const BORDER_SIZE = 0.25;
    this.highlight.clear();

    this.highlight.beginFill(parseInt('0x' + '000000'), 1);
    this.highlight.drawRect(
      x * this.squareSize[0] + BORDER_SIZE - 0.5,
      y * this.squareSize[1] + BORDER_SIZE - 0.5,
      0.5,
      this.squareSize[1] * size - BORDER_SIZE * 2 + 1
    );
    this.highlight.drawRect(
      x * this.squareSize[0] + BORDER_SIZE - 0.5,
      y * this.squareSize[1] + BORDER_SIZE - 0.5,
      this.squareSize[1] * size - BORDER_SIZE * 2 + 1,
      0.5
    );
    this.highlight.drawRect(
      x * this.squareSize[0] + BORDER_SIZE - 0.5,
      y * this.squareSize[1] +
        BORDER_SIZE -
        0.5 +
        (this.squareSize[1] * size - BORDER_SIZE * 2 + 0.5),
      this.squareSize[1] * size - BORDER_SIZE * 2 + 1,
      0.5
    );
    this.highlight.drawRect(
      x * this.squareSize[0] +
        BORDER_SIZE -
        0.5 +
        (this.squareSize[0] * size - BORDER_SIZE * 2 + 0.5),
      y * this.squareSize[1] + BORDER_SIZE - 0.5,
      0.5,
      this.squareSize[0] * size - BORDER_SIZE * 2 + 1
    );
  }
}
