export interface Notification {
  id: number;
  text: string;
  type: string;
}

export enum SelectedSize {
  'ALL' = -1,
  '1 x 1' = 1,
  '3 x 3' = 3,
  '6 x 6' = 6,
  '12 x 12' = 12,
  '24 x 24' = 24,
}
export enum SelectedType {
  'ALL' = -1,
  'RESERVED' = 1,
  'MINTED' = 2,
}

export interface Parcel {
  id: string;
  parentId: string;
  owner: string;
  startX: number;
  startY: number;
  size: number;
  reservationId: number;
  tokenId: number;
  mintblock: number;
  transferblock: number;
  image: string;
  title: string;
  description: string;
  discord: string;
  instagram: string;
  website: string;
  opensea: string;
  looksrare: string;
  isStaked: boolean;
  latestStakingBlockNumber: number;
  chainId: number;
  childrenTokenIds?: number[];
}
