
import Banner from '@/components/shared/banner.vue';
import GameItem from '@/components/shared/gameItem.vue';
import Dropdown from '@/components/shared/newDropdown.vue';
import Notifications from '@/components/shared/notifications.vue';
import Pagination from '@/components/shared/pagination.vue';
import { isUrl } from '@/utils';
import { Options, Vue, Watch } from 'vue-property-decorator';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

@Options({
  components: {
    GameItem,
    Pagination,
    Banner,
    Notifications,
    Dropdown,
    FontAwesomeIcon,
  },
})
export default class Launchpad extends Vue {
  collection: any = null;
  selectedAsset: any = null;
  selectedPaymentMethod: any = null;
  paymentMethodOptions: any = null;
  selectedPieces: any = null;
  piecesOptions: any = null;
  selectedDiscount: any = null;
  discountOptions: any = [
    {
      label: '0% OFF',
      name: 'discount',
      value: 0,
    },
  ];
  page = 0;
  currentSlide = 0;

  pages = {
    total: 10,
    active: 1,
  };

  assets: any = [
    {
      id: 0,

      image: 'assets/images/banner_2.png',
      title: 'Tech Station',
      description: `Tech Stations are buildings that can produce either Workers or Peacemakers. Tech Stations with the War Factory trait can produce Peacemakers, while Tech Stations with the Headquarters trait produce Workers.</br>
      Players have to stake Tech Stations to get a peacemaker or a worker every 7 days.`,
      openEye: false,
      soldout: false,
      iconBR: true,
      minted: {
        progress: 2850,
        total: 5000,
      },
      paymentMethods: [
        {
          value: 0.1,
          tokenName: 'ETH',
        },
      ],
    },
    {
      id: 1,
      image: 'assets/images/banner_3.png',
      title: 'Peacemaker',
      description: `Peacemakers can be staked to gain $WOOD and $METAL as well. When a peacemaker’s staking period ends, it will be burnt, and the owner will lose it. Peacemakers are not capped.</br></br>
      Every minted or produced peacemaker has level 0. Peacemakers can be upgraded up to level 9. Each upgrade has a chance to succeed or fail. If the upgrade fails, you lose your peacemaker. Peacemakers with higher levels grant more $WOOD and $METAL when consumed for staking. Level 9 peacemakers have different staking mechanisms and are not necessarily consumed through staking. </br></br>
      The fight for survival is unlike any global-scale conflict and doesn't seem to have an end anytime soon. Will humanity mature and find new perspectives that lead them out of this dystopian universe, or is this it?<b> Did we reach the great filter?</b>`,
      openEye: true,
      soldout: true,
      iconBR: true,
      minted: {
        progress: 20000,
        total: 20000,
      },
      paymentMethods: [
        {
          value: 0.1,
          tokenName: 'ETH',
        },
      ],
    },
  ];

  get mintingInProgress(): boolean {
    return this.$store.state.launchpad.mintingInProgress;
  }

  get buttonText(): string {
    if (!this.collection || !this.selectedAsset) return '';
    switch (true) {
      case this.collection.isUpcoming:
        return 'Coming Soon';
      case this.selectedAsset && this.selectedAsset.soldout:
        return 'Fully minted';
      case !this.collection.mintingPhase:
        return 'Minting Ended';
      case this.mintingInProgress:
        return 'Minting...';
      case !this.user:
        return 'Not logged in';
      case this.selectedPieces?.value === 0 && this.piecesOptions.length === 1:
        if (this.selectedAsset.layoutOptions?.isLoggedIn === false)
          return 'Request signature';
        return this.selectedAsset.layoutOptions.isWhitelisted
          ? 'Claimed all'
          : 'Not whitelisted';
      default:
        return 'Mint';
    }
  }

  @Watch('selectedAsset', { immediate: true, deep: true })
  onSelectedAssetChange(): void {
    if (
      this.selectedAsset?.chainId &&
      this.selectedAsset.chainId !== this.chainId &&
      this.buttonText &&
      this.buttonText == 'Mint'
    ) {
      this.$store.dispatch('requestChangeOfChain', this.selectedAsset.chainId);
      return;
    }
  }

  getNoOfSlides(): number {
    let perSlide;
    switch (true) {
      case window.innerWidth < 600:
        perSlide = 1;
        break;
      case window.innerWidth < 900:
        perSlide = 2;
        break;
      case window.innerWidth < 1400:
        perSlide = 3;
        break;
      default:
        perSlide = 4;
        break;
    }

    return Math.ceil(this.assets.length / perSlide); // 4 cards per slide
  }

  changeSlide(direction: number): void {
    const noOfSlides = this.getNoOfSlides();
    let newSlide = (this.currentSlide + direction) % noOfSlides;

    if (newSlide < 0) {
      newSlide = noOfSlides + newSlide;
    }

    this.currentSlide = newSlide;
  }

  created(): void {
    window.addEventListener('resize', this.windowResizeHandler);
  }

  mounted(): void {
    this.$nextTick(() => {
      this.windowResizeHandler();
    });
  }

  destroyed(): void {
    window.removeEventListener('resize', this.windowResizeHandler);
  }

  windowResizeHandler(): void {
    const assetSelector = this.$refs.assetSelector as HTMLElement;

    if (!assetSelector) {
      setTimeout(() => {
        this.windowResizeHandler();
      }, 150);
      return;
    }
    if (window.innerWidth < 1600) {
      // Check the parent of assetSelector
      // If it has the id 'selectorBig', then we need to move it to the ref 'selectorSmall'
      if (
        assetSelector?.parentElement &&
        assetSelector?.parentElement.id === 'selectorBig'
      ) {
        const selectorSmall = this.$refs.selectorSmall as HTMLElement;
        selectorSmall.appendChild(assetSelector);
      }
    } else {
      // Check the parent of assetSelector
      // If it has the id 'selectorSmall', then we need to move it to the ref 'selectorBig'
      if (
        assetSelector?.parentElement &&
        assetSelector?.parentElement.id === 'selectorSmall'
      ) {
        const selectorBig = this.$refs.selectorBig as HTMLElement;
        selectorBig.appendChild(assetSelector);
      }
    }
  }

  get currentCollection(): unknown {
    return this.$store.state.launchpad.currentCollection;
  }

  @Watch('currentCollection', { deep: true })
  updateCollection(val: unknown): void {
    this.collection = val;
    this.updatePages();
    this.updateAssets();
    this.updateOptions();
  }

  get chainId(): number {
    return this.$store.state.chainId;
  }

  get user(): any {
    return this.$store.state.user;
  }

  async onMint(): Promise<void> {
    if (!this.user) return;

    if (this.selectedAsset.chainId !== this.chainId) {
      this.$store.dispatch('requestChangeOfChain', this.selectedAsset.chainId);
      return;
    }

    const response = await this.$store.dispatch(
      'getMintParamsForLaunchpadCollection',
      {
        assetId: this.selectedAsset.id,
        paymentMethodId: this.selectedPaymentMethod?.value.id,
        numberOfTokens: this.selectedPieces.value,
        discountThreshold: this.selectedDiscount.value,
        discountPercentage: this.selectedDiscount.value,
      }
    );
    if (!response) {
      this.$store.dispatch('getCollections');
      return;
    }

    this.$store.commit('app/setModal', {
      component: 'mintTokensLaunchpadCollection',
      payload: {
        ...response,
        paymentMethods: this.selectedAsset.paymentMethods,
        collectionAddress: this.selectedAsset.contractAddress,
        chainId: this.selectedAsset.chainId,
        assetId: this.selectedAsset.id,
      },
    });
  }

  async requestSignature(): Promise<void> {
    if (!this.user) return;
    await this.$store.dispatch('resetUserNonce');
    await this.$store.dispatch('sign');
    this.$store.dispatch('getCollections');
  }

  updatePages(): void {
    this.pages.total = Math.ceil(this.collection.assets.length / 2);
    this.pages.active = 1;
  }

  updateAssets(): void {
    this.assets = this.collection.assets
      .map((asset: any) => {
        let mintProgress = 0;
        if (this.collection.lastTokenId >= asset.startIndex) {
          mintProgress = this.collection.lastTokenId - asset.startIndex + 1;
        }
        if (
          asset.endIndex > 0 &&
          this.collection.lastTokenId >= asset.endIndex
        ) {
          mintProgress = asset.endIndex - asset.startIndex + 1;
        }
        return {
          ...asset,
          id: asset.id,
          image: asset.banner,
          title: asset.name,
          collectionId: asset.collectionId,
          contractAddress: this.collection.contractAddress,
          chainId: this.collection.chainId,
          slug: this.collection.slug,
          assetsDiscriminator: this.collection.assetsDiscriminator,
          description: asset.description,
          gallery: asset.gallery,
          order: asset.order,
          openEye: false, // TODO
          soldout: asset.cardinality > 0 && mintProgress >= asset.cardinality,
          iconBR: true, // TODO
          minted: {
            progress: mintProgress,
            total: asset.cardinality,
          },
          paymentMethods: asset.paymentMethods,
          discounts: asset.discounts,
          layoutOptions: asset.layoutOptions,
        };
      })
      .sort((a: any, b: any) => a.order - b.order);
    this.selectedAsset = this.assets[0];
  }

  selectAsset(asset: any): void {
    this.selectedAsset = asset;
    this.updateOptions();
  }

  updateOptions(): void {
    this.paymentMethodOptions = this.selectedAsset.paymentMethods.map(
      (item: any) => {
        return {
          label: item.tokenName,
          name: 'paymentMethod',
          value: item,
        };
      }
    );
    this.selectedPaymentMethod = this.paymentMethodOptions.length
      ? this.paymentMethodOptions[0]
      : null;
    this.piecesOptions = this.selectedAsset.layoutOptions.numTokensOptions.map(
      (item: any) => {
        return {
          label: String(item),
          name: 'pieces',
          value: item,
        };
      }
    );
    this.selectedPieces = this.piecesOptions.length
      ? this.piecesOptions[0]
      : null;
    this.discountOptions = this.discountOptions.concat(
      this.selectedAsset.discounts
        .filter((item: any) => item.threshold <= this.selectedPieces)
        .sort((a: any, b: any) => b.percentage - a.percentage)
        .map((item: any) => {
          return {
            label: `${item.percentage}% OFF`,
            name: 'discount',
            value: item,
          };
        })
    );
    this.selectedDiscount = this.discountOptions[0];
  }

  checkLocalImage(image: string): boolean {
    return !isUrl(image);
  }

  updatePage(page: number): void {
    this.pages.active = page;
  }

  updateOption(option: any): void {
    switch (option.name) {
      case 'paymentMethod':
        this.selectedPaymentMethod = option;
        break;
      case 'pieces':
        this.selectedPieces = option;
        break;
      case 'discount':
        this.selectedDiscount = option;
        break;
    }
  }
}
