
import TitleBasic from '@/components/basic/title.vue';
import Switch from '@/components/default/optionbars/switch.vue';
import Loader from '@/components/icons/loader.vue';
import Banner from '@/components/shared/banner.vue';
import Footer from '@/components/shared/footer.vue';
import Notifications from '@/components/shared/notifications.vue';
import SpaceCard from '@/components/shared/spaceCard.vue';
import { MountSpaceInfo, SpaceAssetInfo } from '@/store/types/spaces';
import { isUrl } from '@/utils';
import { getChainNameByChainId } from '@/utils/blockchain';
import { copyToClipboard } from '@/utils/index';
import { Options, Vue, Watch } from 'vue-property-decorator';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

@Options({
  components: {
    TitleBasic,
    Footer,
    SpaceCard,
    Loader,
    Notifications,
    Switch,
    Banner,
    FontAwesomeIcon,
  },
})
export default class Mount extends Vue {
  parcelIdParam = '';
  checked = false;
  showCopied = false;

  banner = {
    image: 'assets/images/banner_1.png',
    title: 'Mount a 3D space',
    description: `Acquiring a Parcel within the DystoWorld Portal grants you not only ownership of that specific Parcel, but also the opportunity to create a private, customizable 3D space within it. This space, known as a "3D room," is similar to a museum exhibit in which the owner can showcase digital goods and personalize the space to their liking. As the owner of the Parcel, you will have shared ownership of this private exhibit, allowing you to invite others to view and interact with the items on display.`,
  };

  async created(): Promise<void> {
    await this.getMountInfo();
  }

  get user(): any {
    return this.$store.state.user;
  }

  @Watch('user', { deep: true })
  async onUserChanged(): Promise<void> {
    await this.getMountInfo();
  }

  get walletAddress(): string | null {
    return this.$store.state.wallet;
  }

  @Watch('walletAddress')
  async onWalletAddressChange(newAddress: string | null): Promise<void> {
    if (!newAddress) return;
    if (newAddress) {
      await this.$store.dispatch('checkConnection');
    }
    await this.getMountInfo();
  }

  get waitingForApi(): boolean {
    return this.$store.state.spaces.waitingForApi;
  }

  get mount(): MountSpaceInfo {
    return this.$store.state.spaces.mount;
  }

  get modal() {
    return this.$store.state.app.modal;
  }

  get ownedInstances() {
    return this.$store.state.spaces.ownedInstances;
  }

  get chainId(): number {
    return this.$store.state.chainId;
  }

  get chainInfo(): string {
    return getChainNameByChainId(this.chainId);
  }

  get currentMountedInstance(): SpaceAssetInfo[] {
    if (this.checkOwnnedInstanceByParam()) {
      return this.$store.state.spaces.mount.spaceAssets.filter(
        (space: any) => space.parcelId === this.parcelIdParam
      );
    }
    return [];
  }

  get spacesAssets(): SpaceAssetInfo[] {
    if (this.checked) {
      return this.$store.state.spaces.mount.spaceAssets.filter(
        (space: any) =>
          this.convertSpaceSizeToInt(space.spaceSize) <=
            this.convertSpaceSizeToInt(this.mount.parcel.size) &&
          space.parcelId === ''
      );
    }
    if (this.checkOwnnedInstanceByParam()) {
      const moutedSpaceIndex: number =
        this.$store.state.spaces.mount.spaceAssets.findIndex(
          (space: SpaceAssetInfo) => space.parcelId === this.parcelIdParam
        );
      const firstSpaceToDysplay =
        this.$store.state.spaces.mount.spaceAssets[moutedSpaceIndex];
      const spacesFilteredByMounted =
        this.$store.state.spaces.mount.spaceAssets.filter(
          (element: any, index: number) => index !== moutedSpaceIndex
        );
      spacesFilteredByMounted.unshift(firstSpaceToDysplay);
      return spacesFilteredByMounted;
    }
    return this.$store.state.spaces.mount.spaceAssets;
  }

  copyToClipboard(string: string): void {
    copyToClipboard(string);
    this.showCopied = true;
    setTimeout(() => {
      this.showCopied = false;
    }, 2000);
  }

  checkLocalImage(image: string): boolean {
    return !isUrl(image);
  }

  setModal(modal: any, payload: any) {
    this.$store.commit('app/setModal', { component: modal, payload });
  }

  async getMountInfo(): Promise<void> {
    if (!this.walletAddress) return;

    this.parcelIdParam = this.$route.params.id as string;
    await this.$store.dispatch('getOwnedInstances');
    await this.$store.dispatch('getParcel', this.parcelIdParam);
  }

  async spaceActionHandler(spaceAsset: SpaceAssetInfo): Promise<void> {
    if (
      // the space is smaller than the parcel or
      this.convertSpaceSizeToInt(this.mount.parcel.size) <
        this.convertSpaceSizeToInt(spaceAsset.spaceSize) &&
      spaceAsset.parcelId === ''
    ) {
      return;
    }

    if (
      // the space is already mounted to a parcel
      spaceAsset.parcelId !== ''
    ) {
      const response = await this.$store.dispatch(
        'verify3DModel',
        spaceAsset.parcelId
      );
      if (response.error) {
        this.$store.commit(
          'app/alterNotifications',
          { text: response.message, type: 'danger', add: true },
          { root: true }
        );
        return;
      }
      window.location.href = response.data.link;
      return;
    }

    const response = await this.$store.dispatch('createSpaceInstance', {
      ...spaceAsset,
      parcelId: this.mount.parcel.id,
    });
    if (response.error) {
      this.$store.commit(
        'app/alterNotifications',
        { text: response.message, type: 'danger', add: true },
        { root: true }
      );
      return;
    }

    // change the window to the new created instance
    window.location.href = `/instance/${response.id}`;
  }

  async actionHandler(spaceAsset: SpaceAssetInfo) {
    const ownedInstance = this.ownedInstances.filter(
      (instance: any) => instance.spaceTokenId === spaceAsset.tokenId
    );
    const instanceId: string = ownedInstance[0]._id;
    const tokenId: any = spaceAsset.tokenId;
    const response = await this.$store.dispatch('deleteSpaceInstance', {
      instanceId,
      tokenId,
    });
    if (response.error) {
      this.$store.commit(
        'app/alterNotifications',
        { text: response.message, type: 'danger', add: true },
        { root: true }
      );
    }
    this.setModal('', null);
  }

  checkOwnnedInstanceByParam(): boolean {
    if (this.ownedInstances.length > 0) {
      const instanceOwned = this.ownedInstances.filter(
        (instance: any) => instance.parcelId === this.parcelIdParam
      );
      if (instanceOwned.length > 0) return true;
    }
    return false;
  }

  toggleSwitch() {
    this.checked = !this.checked;
  }

  async mintSpaceToken(): Promise<void> {
    await this.$store.dispatch('mintSpacesTokens');
  }

  convertSpaceSizeToInt(stringToConvert: string): number {
    return +stringToConvert.toUpperCase().split('X')[0];
  }
}
