
import {
  ARBITRUM_CHAIN_ID,
  ARBITRUM_NETWORK_NAME,
  ETHEREUM_CHAIN_ID,
  ETHEREUM_NETWORK_NAME,
} from '@/config';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Emit, Options, Prop, Vue } from 'vue-property-decorator';

@Options({
  components: {
    FontAwesomeIcon,
  },
})
export default class MiniPortalCategory extends Vue {
  @Prop(String) readonly title!: string;
  @Prop({ default: [] }) readonly selected!: number[];

  categories = [
    {
      label: 'SIZE',
      icon: 'fa-solid fa-expand',
      links: [
        {
          name: '1 x 1',
          value: 1,
          method: '',
          active: false,
        },
        {
          name: '3 x 3',
          value: 3,
          method: '',
          active: false,
        },
        {
          name: '6 x 6',
          value: 6,
          method: '',
          active: false,
        },
        {
          name: '12 x 12',
          value: 12,
          method: '',
          active: false,
        },
        {
          name: '24 x 24',
          value: 24,
          method: '',
          active: false,
        },
      ],
    },
    {
      label: 'TYPE',
      icon: 'fa-solid fa-layer-group',
      links: [
        {
          name: 'Reserved',
          value: 1,
          method: '',
          active: false,
        },
        {
          name: 'Minted',
          value: 2,
          method: '',
          active: false,
        },
      ],
    },
    // {
    //   label: 'CHAIN',
    //   icon: 'fa-solid fa-cubes',
    //   links: [
    //     { name: 'All', value: -1, method: '', active: false },
    //     {
    //       name: ETHEREUM_NETWORK_NAME,
    //       value: ETHEREUM_CHAIN_ID,
    //       method: '',
    //       active: false,
    //     },
    //     {
    //       name: ARBITRUM_NETWORK_NAME,
    //       value: ARBITRUM_CHAIN_ID,
    //       method: '',
    //       active: false,
    //     },
    //   ],
    // },
  ];

  @Emit('selected')
  selectedOption(option: number): number {
    return option;
  }

  @Emit('toggleOpen')
  onClick(): void {
    return;
  }
}
