import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "property flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "img flex justify-center"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "title"
}
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.image?.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("img", {
            src: require(`@/${_ctx.image}`)
          }, null, 8, _hoisted_3)
        ]))
      : _createCommentVNode("", true),
    (_ctx.title?.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    (_ctx.description?.length > 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          innerHTML: _ctx.description,
          class: "description"
        }, null, 8, _hoisted_5))
      : _createCommentVNode("", true)
  ]))
}