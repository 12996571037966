
import DialsIcon from '@/components/icons/dials.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Options, Prop, Vue } from 'vue-property-decorator';
import PlusMenu from './plusMenu.vue';
import DialsMenu from './dialsMenu.vue';

@Options({
  components: {
    DialsIcon,
    FontAwesomeIcon,
    PlusMenu,
    DialsMenu,
  },
})
export default class MenuOption extends Vue {
  @Prop() icon!: string | undefined;
  @Prop() faIcon!: string | undefined;
  @Prop() component!: string;
  @Prop() isOpen!: boolean;
  @Prop() isOtherOpen!: boolean;
}
