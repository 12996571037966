
import { Vue, Prop, Options } from 'vue-property-decorator';
import { SpaceAssetInfo } from '@/store/types/spaces';
import { getChainNameByChainId } from '@/utils/blockchain';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

@Options({
  components: {
    FontAwesomeIcon,
  },
})
export default class SpaceCard extends Vue {
  @Prop({ required: true }) readonly spaceAsset!: SpaceAssetInfo;
  @Prop({ type: Boolean, default: false }) readonly hasAction!: boolean;
  @Prop({ type: String }) readonly actionText!: string;
  @Prop({ type: Function }) readonly actionHandler!: () => Promise<void>;
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;
  @Prop({ type: String, required: true }) readonly imageUrl!: string;
  @Prop({ type: Function }) readonly setModal!: () => void;
  @Prop({ type: String, default: '' }) readonly extraClasses!: string;

  get chainName(): string {
    return getChainNameByChainId(this.spaceAsset.chainId);
  }
}
