
import SteakIcon from '@/components/icons/steak.vue';
import {
  PoolData,
  PoolHistory,
  PoolReward,
  StakedNFTInfo,
} from '@/store/types/landlordStaking';
import { formatEther } from '@/utils';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Options, Prop, Vue } from 'vue-property-decorator';
import GenericHeader from './genericHeader.vue';
import BigNumber from 'bignumber.js';

@Options({
  components: {
    SteakIcon,
    FontAwesomeIcon,
    GenericHeader,
  },
})
export default class PoolHeader extends Vue {
  @Prop() poolReward!: PoolReward;
  @Prop() layout!: string;

  showCopied = false;

  get poolData(): PoolData[] {
    const poolData = [];
    // Things are done this way to achieve a certain order
    if (this.layout === 'per-pool') {
      poolData.push({
        title: 'Dysto reward shared:',
        value: `${this.poolDystoReward} $DYSTO`,
        icon: 'fa-solid fa-gift',
      });

      poolData.push({
        title: this.collectionIsEnded ? 'Pool ended at:' : 'Pool ends at:',
        value: this.poolEndDate,
        icon: 'fa-solid fa-clock',
      });
    } else {
      poolData.push({
        title: 'Total dysto claimed:',
        value: this.poolDystoRewardClaimed,
        icon: 'fa-solid fa-gift',
      });

      poolData.push({
        title: 'Total pools from this collection:',
        value: `x ${this.poolRewardHistory?.length || 0} lifetime pools`,
        icon: 'fa-solid fa-bars',
      });
    }

    if (this.layout === 'per-pool') {
      poolData.push({
        title: 'Number of staked NFT entries:',
        value: `x ${this.poolReward.assetsStaked} - (You have ${this.poolReward.assetsStaked} entries)`,
        component: 'SteakIcon',
      });
    } else {
      poolData.push({
        title: 'Currently staked NFTs',
        value: `x ${this.totalAssetsStaked} - (You have ${this.totalAssetsStaked} entries)`,
        component: 'SteakIcon',
      });
    }
    if (this.layout === 'per-pool') {
      poolData.push({
        title: 'Total dysto claimed:',
        value: this.poolDystoRewardClaimedPerPool,
        icon: 'fa-solid fa-gift',
      });
    }

    return poolData;
  }

  get poolRewardHistory(): PoolHistory[] {
    return this.$store.state.landlordStaking.collectionHistories[
      this.poolReward.collectionAddress
    ];
  }

  get poolTitle(): string {
    if (this.layout !== 'per-pool') return this.poolReward.name;

    if (!this.poolReward) return '';
    const monthYear = new Date(
      this.poolReward.endTimestamp * 1000
    ).toLocaleString('default', { month: 'long', year: 'numeric' });

    return `${this.poolReward.name} - ${monthYear}`;
  }

  get collectionIsEnded(): boolean {
    if (this.poolReward)
      return this.poolReward.endTimestamp < Date.now() / 1000;
    return false;
  }

  get poolDystoReward(): string {
    if (!this.poolReward) return '0';
    // Convert from wei string to eth number
    return formatEther(this.poolReward.dystoReward);
  }

  get nftsStaked(): StakedNFTInfo[] {
    return this.$store.state.landlordStaking.stakedNFTs;
  }

  get poolDystoRewardClaimed(): string {
    let result = 0;
    let count = 0;

    this.nftsStaked
      .filter(
        (nft) =>
          nft.collectionAddress === this.poolReward.collectionAddress &&
          nft.claimedRewardHistory.length
      )
      .forEach(({ claimedRewardHistory }) => {
        claimedRewardHistory.forEach(({ reward }) => {
          result += parseInt(reward);
          count++;
        });
      });

    return `${formatEther(result)} $DYSTO (${count} entries)`;
  }

  get maxPossibleRewardToClaimPerPool(): string {
    const totalRewardToClaim = new BigNumber(this.poolReward.dystoReward).div(
      this.poolReward.stakedNFTs
    );
    return totalRewardToClaim.toString();
  }

  get poolDystoRewardClaimedPerPool(): string {
    let result = 0;
    let count = 0;

    this.nftsStaked
      .filter(
        (nft) =>
          nft.collectionAddress === this.poolReward.collectionAddress &&
          nft.claimedRewardHistory.length
      )
      .forEach((nft) => {
        nft.claimedRewardHistory.forEach(({ poolRewardIndex, reward }) => {
          if (poolRewardIndex === this.poolReward.index) {
            result += parseInt(reward);
            count++;
          }
        });
      });

    return `${formatEther(result)} $DYSTO`;
  }

  get poolEndDate(): string {
    // get end date from poolReward.endTimestamp
    // in this format 25/01/2023 - 12:28:03 PM UTC +3

    if (!this.poolReward) return '';

    const date = new Date(
      this.poolReward.endTimestamp * 1000
    ).toLocaleDateString('default', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    });

    const time = new Date(
      this.poolReward.endTimestamp * 1000
    ).toLocaleTimeString('default', {
      timeZoneName: 'short',
    });

    return `${date} - ${time}`;
  }

  get totalAssetsStaked(): number {
    if (!this.poolRewardHistory) return 0;

    return this.poolRewardHistory.reduce((acc, pool) => {
      return acc + pool.assetsStaked;
    }, 0);
  }
}
