import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"
import _imports_0 from '../assets/images/profile.png'


const _hoisted_1 = { class: "h-full flex flex-1 chat-container" }
const _hoisted_2 = { class: "grow-1 chatBox md:my-7 md:mx-12 rounded-lg border-grey w-full relative" }
const _hoisted_3 = { class: "flex flex-col justify-between text-white h-full md:h-[84vh] w-full" }
const _hoisted_4 = { class: "h-[80%] m-5 overflow-y-scroll chat-single-message pr-5 w-full" }
const _hoisted_5 = ["id"]
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 1,
  class: "h-auto w-auto max-h-[2.2rem] max-w-[2.2rem] md:max-h-[5.2rem] md:max-w-[5.2rem]",
  alt: "logo",
  src: _imports_0
}
const _hoisted_8 = { class: "text-sm span-title-hour mx-3" }
const _hoisted_9 = { class: "h-16 m-5 input-box rounded-md" }
const _hoisted_10 = {
  type: "submit",
  class: "m-4 w-9 send-icon rounded-md flex justify-center items-center pointer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args))),
        class: "absolute right-0 top-0 border border-red-500/40 text-red-500/80 rounded-tr-lg rounded-bl-lg hover:text-red-500/100 hover:border-red-500/100 font-bold flex items-center justify-center text-4xl font-mono w-12 h-12"
      }, " X "),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chatHistory, (message, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "mb-6",
              id: index.toString()
            }, [
              _createElementVNode("div", {
                class: _normalizeClass([message.role === 'user' ? 'flex-row-reverse' : '', "flex"])
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass([message.role === 'user' ? 'flex-row-reverse' : '', "rounded-md min-w-[2.2rem] md:min-w-[5.2rem] min-h-full flex"])
                }, [
                  (message.role !== 'user')
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        class: "h-auto w-auto max-h-[2.2rem] max-w-[2.2rem] md:max-h-[5.2rem] md:max-w-[5.2rem]",
                        src: _ctx.currentMesh.imageUrl,
                        alt: "logo"
                      }, null, 8, _hoisted_6))
                    : _createCommentVNode("", true),
                  (message.role === 'user')
                    ? (_openBlock(), _createElementBlock("img", _hoisted_7))
                    : _createCommentVNode("", true)
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass([message.role === 'user' ? 'flex-row-reverse' : '', "flex-col"])
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass([message.role === 'user' ? 'flex-row-reverse' : '', "mx-3 font-bold text-lg flex"])
                  }, [
                    _createTextVNode(_toDisplayString(`${message.role === 'user' ? 'You' : _ctx.currentMesh.name}`) + " ", 1),
                    _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.timeFormat()), 1)
                  ], 2),
                  _createElementVNode("div", {
                    class: _normalizeClass([message.role === 'user' ? 'flex-row-reverse' : '', "flex"])
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass([message.role === 'user' ? 'message-sent' : '', "p-3 mx-3 font-semibold message"])
                    }, _toDisplayString(message.content), 3)
                  ], 2)
                ], 2)
              ], 2)
            ], 8, _hoisted_5))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("form", {
            onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onMessageSubmit && _ctx.onMessageSubmit(...args)), ["prevent"])),
            class: "flex justify-between rounded-md"
          }, [
            _withDirectives(_createElementVNode("input", {
              ref: "input-message",
              placeholder: "TYPE YOUR MESSAGE HERE...",
              class: "h-16 w-[90%] input-box py-4 px-6 rounded-md",
              type: "text",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.messageText) = $event))
            }, null, 512), [
              [_vModelText, _ctx.messageText]
            ]),
            _createElementVNode("button", _hoisted_10, [
              _createVNode(_component_font_awesome_icon, {
                icon: "fa-solid fa-paper-plane",
                class: "w-[60%] h-[60%]"
              })
            ])
          ], 32)
        ])
      ])
    ])
  ]))
}