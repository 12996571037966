
import { Vue, Prop, Options } from 'vue-property-decorator';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

@Options({
  components: {
    FontAwesomeIcon,
  },
})
export default class ReservedParcel extends Vue {
  @Prop(Object) readonly parcel!: any;
  time = 'Waiting for timer...';
  expired = false;

  get loaders() {
    return this.$store.state.app.loaders;
  }

  get chainId(): number {
    return this.$store.state.chainId;
  }

  mounted(): void {
    const later = this.parcel.expiration;

    let interval = null as any;
    const intervalFunc = () => {
      const now = Math.floor(new Date().getTime() / 1000);
      if (later < now) {
        this.time = 'Expired';
        this.expired = true;
        this.$store.dispatch('checkFreeLandWhitelisted');
        this.$store.dispatch('checkFreeLandReservedOrMinted');
        clearInterval(interval);
      } else {
        this.time = 'Expiring ' + moment(later * 1000).fromNow();
        this.expired = false;
      }
    };
    interval = setInterval(intervalFunc, 1000);
  }

  shouldMintFree() {
    return this.parcel.size == 1
            && this.$store.state.user.freeLandWhitelisted;
  }

  get computeMintText() {
    if (this.shouldMintFree()) {
      return 'Mint for free';
    }
    return 'Mint';
  }

  mint(): void {
    if (this.expired) return;
    if (this.parcel.chainId !== this.chainId) {
      this.$store.dispatch('requestChangeOfChain', this.parcel.chainId);
    } else {
      this.parcel.shouldMintFree = this.shouldMintFree();
      this.$store.dispatch('mintParcel', this.parcel);
      this.$store.dispatch('checkFreeLandWhitelisted');
      this.$store.dispatch('checkFreeLandReservedOrMinted');
    }
  }
}
