
import { Emit, Options, Vue, Watch } from 'vue-property-decorator';
import Banner from '@/components/shared/banner.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import ParcelCategory from '@/components/shared/landlord-staking/staking-parcels/parcelCategory.vue';
import { Parcel } from '@/utils/interfaces';

@Options({
  components: {
    Banner,
    FontAwesomeIcon,
    ParcelCategory,
  },
})
export default class StakingParcels extends Vue {
  selectedUnstakedParcels: string[] = [];
  selectedStakedParcels: string[] = [];

  banner = {
    image: 'assets/images/banner_1.png',
    title: 'Mount a 3D space',
    description: `You can win rewards in Dysto by staking your NFTS on our platform.
      There will be multiple pool rewards available for you to choose.
      Each pool reward has an end time until you can enter. The total reward for a pool reward will be divided equally to all the nfts staked.
      For each nft you wish to stake you need one Osto Parcel staked.
      A staked NFT can participate in multiple pool rewards, if it's not unstaked before the end time of the pool reward.
      If you
      unstake on NFT before the end time of a pool reward, you can still stake it again before the end of the pool reward and be eligible to receive a reward.`,
  };

  get unstakedParcels(): Parcel[] {
    return this.$store.state.landlordStaking.unstakedParcels || [];
  }

  get stakedParcels(): Parcel[] {
    return this.$store.state.landlordStaking.stakedParcels || [];
  }

  get numberStakedNFTs(): number {
    return this.$store.state.landlordStaking.currentlyStakedNFTs.length;
  }

  beforeMount(): void {
    this.onDystoWorldLandlordStakingContractChange();
  }

  get hasApprovalForTransferOfParcels(): boolean {
    return this.$store.state.landlordStaking.hasApprovalForTransferOfParcels;
  }

  get dystoWorldLandlordStakingContract(): unknown {
    return this.$store.state.dystoWorldLandlordStakingContract;
  }

  get wallet(): string {
    return this.$store.state.wallet;
  }

  get chainId(): number {
    return this.$store.state.chainId;
  }

  get waitingForConfirmation(): boolean {
    return this.$store.state.landlordStaking.waitForConfirmationTrigger;
  }

  @Watch('dystoWorldLandlordStakingContract', { deep: true })
  onDystoWorldLandlordStakingContractChange(): void {
    if (this.$store.state.dystoWorldLandlordStakingContract) {
      this.$store.dispatch('checkApprovalForTransferOfParcels');
    }
  }

  @Watch('wallet', { deep: true })
  @Watch('chainId')
  onWalletChange(): void {
    if (this.$store.state.wallet) {
      this.$store.dispatch('checkApprovalForTransferOfParcels');
    }
  }

  stakeParcels(parcelIds: number[]): void {
    if (parcelIds.length === 0) {
      return;
    } else if (parcelIds.length === 1) {
      this.$store.dispatch('stakeParcel', parcelIds[0]);
    } else {
      this.$store.dispatch('stakeBulkParcels', parcelIds);
    }
  }

  unstakeParcels(parcelIds: number[]): void {
    const numberOfStakedParcels = this.stakedParcels.reduce((acc, parcel) => {
      acc += parcel?.childrenTokenIds?.length
        ? parcel.childrenTokenIds.length
        : 1;
      return acc;
    }, 0);
    if (numberOfStakedParcels - parcelIds.length < this.numberStakedNFTs) {
      this.$store.commit('app/setModal', {
        component: 'alertMessage',
        payload: {
          title: "Can't unstake that many parcels",
          message:
            'Some of the parcels you are trying to unstake are currently staking NFTs. Please unstake the NFTs first before unstaking the parcels.',
        },
      });
      return;
    }
    if (parcelIds.length === 0) {
      return;
    } else if (parcelIds.length === 1) {
      this.$store.dispatch('unstakeParcel', parcelIds[0]);
    } else {
      this.$store.dispatch('unstakeBulkParcels', parcelIds);
    }
  }
}
