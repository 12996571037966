
import { API_URL, ETHEREUM_CHAIN_ID, ETHEREUM_NETWORK_NAME } from '@/config';
import { getChainNameByChainId } from '@/utils/blockchain';
import { Vue, Options, Watch } from 'vue-property-decorator';
import Dropdown from '../shared/dropdown.vue';
import NewDropdown from '../shared/newDropdown.vue';
import OwnedParcel from './ownedParcel.vue';
import ReservedParcelC from './reservedParcel.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

enum dropdownType {
  CHAIN = 'CHAIN',
  CURRENCY = 'CURRENCY',
  BLOCKS = 'BLOCKS',
}

interface Position {
  x: number;
  y: number;
}

interface ReservedParcel {
  owner: string;
  size: number;
  startX: number;
  startY: number;
  state: string;
}

@Options({
  components: {
    Dropdown,
    NewDropdown,
    OwnedParcel,
    ReservedParcel: ReservedParcelC,
    FontAwesomeIcon,
  },
})
export default class Parcels extends Vue {
  x = 0 as number;
  y = 0 as number;
  ReservedParcels = [];
  MintedParcels = [] as any[];

  pricePerDystoInUsdt = '0';
  pricePerDystoInEth = '0';

  get Minting_Parcels() {
    return this.$store.state.app.Minting_Parcels;
  }
  get user() {
    return this.$store.state.user;
  }
  get Owned_Parcels() {
    return this.$store.state.app.Owned_Parcels;
  }
  get Owned_By_Me() {
    return this.$store.state.app.Owned_By_Me;
  }
  get MergedParcel() {
    return this.$store.state.app.MergedParcel;
  }
  get ParcelCoordinates(): Position {
    return this.$store.state.app.parcel_coordinates;
  }
  get wallet() {
    return this.$store.state.wallet;
  }
  get loaders() {
    return this.$store.state.app.loaders;
  }
  get parcels() {
    const parcels = this.$store.state.filteredParcels;
    this.setParcels(parcels);
    return parcels;
  }

  get lastHovered() {
    return this.$store.state.app.lastHovered;
  }

  @Watch('parcels')
  watchParcels(n: any) {
    this.setParcels(n);
  }

  setParcels(parcels: any) {
    this.ReservedParcels = parcels.filter(
      ([_, el]: any) => el.state === 'RESERVED' && el.owner === this.wallet
    );
    this.MintedParcels = parcels.filter(
      ([_, el]: any) => el.state === 'MINTED' && el.owner === this.wallet
    );
  }

  @Watch('x')
  xWatcher(val: number) {
    if (val < 0) {
      this.x = 0;
    }
    if (val > 359) {
      this.x = 359;
    }
    const position = { x: val, y: this.y };
    this.$store.commit('app/setParcelCoordinates', position);
  }
  @Watch('y')
  yWatcher(val: number) {
    if (val < 0) {
      this.y = 0;
    }
    if (val > 359) {
      this.y = 359;
    }
    const position = { x: this.x, y: val };
    this.$store.commit('app/setParcelCoordinates', position);
  }

  @Watch('ParcelCoordinates')
  ParcelCoordinatesWatcher(position: Position) {
    this.x = position.x;
    this.y = position.y;
  }

  d0_currency_options = [
    {
      label: 'ETHEREUM',
      value: ETHEREUM_CHAIN_ID,
      type: 'CHAIN',
    },
    // {
    //   label: 'ARBITRUM',
    //   value: ARBITRUM_CHAIN_ID,
    //   type: 'CHAIN',
    // },
  ];

  d1_options = [
    {
      label: 'DYSTO',
      type: 'CURRENCY',
    },
    // {
    //   label: 'ETH',
    //   type: 'CURRENCY',
    // },
    // {
    //   label: 'USDT',
    //   type: 'CURRENCY',
    // },
    // {
    //   label: 'USDC',
    //   type: 'CURRENCY',
    // },
    // {
    //   label: 'BUSD',
    //   type: 'CURRENCY',
    // },
  ];

  availability = [
    {
      size: '1 x 1',
      price: '50',
      available: '400',
    },
    {
      size: '3 x 3',
      price: '427.5',
      available: '200',
    },
    {
      size: '6 x 6',
      price: '1656',
      available: '100',
    },
    {
      size: '12 x 12',
      price: '6336',
      available: '10',
    },
    {
      size: '24 x 24',
      price: '23040',
      available: '5',
    },
  ];

  d2_options = [
    {
      label: '1 x 1',
      type: 'BLOCKS',
      value: 1,
    },
    {
      label: '3 x 3',
      type: 'BLOCKS',
      value: 3,
    },
    {
      label: '6 x 6',
      type: 'BLOCKS',
      value: 6,
    },
    {
      label: '12 x 12',
      type: 'BLOCKS',
      value: 12,
    },
    {
      label: '24 x 24',
      type: 'BLOCKS',
      value: 24,
    },
  ];

  d0_currency_active = this.d0_currency_options[0];
  d1_active = this.d1_options[0];
  d2_active = this.d2_options[0];

  get chainId(): number {
    return this.$store.state.chainId;
  }

  @Watch('chainId')
  onChainIdChanged(): void {
    // change the active currency on chain changes, if the chain is not a supported one default to etheruem chain
    this.d0_currency_active =
      this.d0_currency_options.find((el) => el.value === this.chainId) ||
      this.d0_currency_options[0];
  }

  get computeReserveButtonText() {
    if (this.shouldMintFree()) {
      return 'Reserve for Free';
    }
    return `Reserve (${this.computedPrice} $${this.d1_active.label})`;
  }

  shouldMintFree() {
    return this.d2_active === this.d2_options[0]
      && this.$store.state.user.freeLandWhitelisted
      && !this.$store.state.user.freeLandReservedOrMinted;
  }

  get computedPrice() {
    const found = this.availability.find(e => e.size === this.d2_active.label);

    if (!found) {
      return 0.0;
    }

    const dystoPrice = found.price;

    switch (this.d1_active.label) {
      case 'DYSTO':
        return dystoPrice;

      case 'ETH':
        return (Number(dystoPrice) * Number(this.pricePerDystoInEth)).toFixed(8);

      case 'USDT':
      case 'USDC':
      case 'BUSD':
        return (Number(dystoPrice) * Number(this.pricePerDystoInUsdt)).toFixed(2);

      default:
        return dystoPrice;
    }
  }

  async mounted() {
    this.$store.commit('app/setMintingParcels', {
      blocks: this.d2_options[0],
    });

    if (window.innerWidth >= 1280) {
      this.toggleBars('Minting_Parcels', true);
      this.toggleBars('Owned_Parcels', true);
    }

    this.onChainIdChanged();

    // setTimeout(async () => {
    //   await this.fetchDystoUsdtEthPrices();
    // }, 500);
  }

  async fetchDystoUsdtEthPrices() {
    try {
      let priceInUsdt = 0;

      const contractDystoUsdtRate = await this.$store.state.dystoWorldPublicSaleContract.methods.RATE().call();
      priceInUsdt = 1 / (contractDystoUsdtRate / 1000);
      this.pricePerDystoInUsdt = priceInUsdt.toFixed(2);

      const getEthUsdtPrice = await fetch(`${API_URL}/keypad/get_eth_usdt_price`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${await this.$store.dispatch('returnToken')}`,
        },
      });
      const getEthUsdtPriceResponse = await getEthUsdtPrice.json();
      if (getEthUsdtPriceResponse && getEthUsdtPriceResponse.usdtEthPrice) {
        // Convert the price of DYSTO from USDT to ETH
        const pricePerEthInUsdt = getEthUsdtPriceResponse.usdtEthPrice;
        this.pricePerDystoInEth = (priceInUsdt / pricePerEthInUsdt).toFixed(8);
      }
    } catch (error) {
      console.log(error);
    }
  }

  updateOption(option: any) {
    switch (option.type) {
      case dropdownType.CHAIN:
        this.d0_currency_active = option;
        this.$store.dispatch('requestChangeOfChain', option.value);
        break;
      case dropdownType.CURRENCY:
        this.d1_active = option;
        break;
      case dropdownType.BLOCKS:
        this.d2_active = option;
        this.$store.commit('app/setMintingParcels', {
          blocks: option,
        });
        break;
    }
  }

  toggleBars(tab: string, bool: boolean): void {
    switch (tab) {
      case 'Minting_Parcels':
        this.$store.commit('app/setMintingParcelsSidebar', bool);
        break;
      case 'Owned_Parcels':
        this.$store.commit('app/setOwnedParcelsSidebar', bool);
        break;
    }
  }

  reserve() {
    if (!this.user || this.loaders.reserve) return;

    const startX = this.$store.state.app.parcel_coordinates.x;
    const startY = this.$store.state.app.parcel_coordinates.y;
    const size = this.$store.state.app.mintingParcels.blocks.value;
    const chainId = this.$store.state.chainId;
    const chainName = getChainNameByChainId(chainId);
    if (chainId !== ETHEREUM_CHAIN_ID) {
      this.$store.commit(
        'app/alterNotifications',
        {
          text: `Invalid chainId. Please change to the ${ETHEREUM_NETWORK_NAME}`,
          type: 'danger',
          add: true,
        },
        { root: true }
      );
      return;
    }
    this.$store.commit('app/setModal', {
      component: 'alertMessage',
      payload: {
        title: 'Reserve Info',
        message: `You are going to reserve ${
          size * size
        } parcels with top left corner at <br />X: ${startX}, Y: ${startY} on chain ${chainName}.`,
        okMessage: 'Proceed',
        okAction: () => { this.$store.dispatch('Reserve', this.shouldMintFree());
                          this.$store.dispatch('checkFreeLandWhitelisted');
                          this.$store.dispatch('checkFreeLandReservedOrMinted'); }
      },
    });
  }

  setLastHovered(parcel: any) {
    this.$store.commit('app/setLastHovered', { ...parcel, outside: true });
  }

  clearLastHovered() {
    this.$store.commit('app/setLastHovered', null);
  }

  merge(parcel: any) {
    this.$store.dispatch('mergeParcel', parcel);
    this.$store.commit('app/setMergedParcel', {
      variable: null,
      selected: null,
    });
  }
}
