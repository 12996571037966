import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "absolute -top-3 -right-0.5 transform -translate-y-full z-40"
}
const _hoisted_2 = {
  key: 2,
  class: "absolute -inset-0.5 bg-black bg-opacity-50 rounded-md"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["w-10 h-10 sm:w-12 sm:h-12 md:w-14 md:h-14 rounded-md bg-grey-2 border sm:border-2 border-highlight-green flex items-center justify-center text-white relative", {
      'cursor-pointer': !_ctx.isOtherOpen,
      'cursor-default': _ctx.isOtherOpen,
    }])
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), { class: "w-6 h-6" })),
    (_ctx.faIcon)
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 0,
          icon: _ctx.faIcon,
          class: "text-xl sm:text-2xl md:text-3xl"
        }, null, 8, ["icon"]))
      : _createCommentVNode("", true),
    (_ctx.isOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component)))
        ]))
      : (_ctx.isOtherOpen)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2))
        : _createCommentVNode("", true)
  ], 2))
}