
import { Vue, Options, Watch } from 'vue-property-decorator';
import BasicLayout from './layouts/Basic.vue';
import DefaultLayout from './layouts/Default.vue';
import StakingLayout from './layouts/Staking.vue';

@Options({
  components: {
    BasicLayout,
    DefaultLayout,
    StakingLayout,
  },
})
export default class App extends Vue {
  get component() {
    return (this.$route.meta.layout || 'Default') + 'Layout';
  }

  get metamask() {
    return this.$store.state.metamask;
  }

  get user(): any {
    return this.$store.state.user;
  }

  @Watch('user', { deep: true })
  async onUserChange(value: any): Promise<void> {
    if (!value) {
      // user not logged in
      document.removeEventListener('visibilitychange', this.onVisibilityChange);
      return;
    }

    document.addEventListener('visibilitychange', this.onVisibilityChange);
  }

  mounted() {
    // this.$store.commit("setMetamask", typeof window.ethereum !== "undefined");
    // if (this.metamask) {
    this.$store.dispatch('checkConnection');
    // }
  }

  async onVisibilityChange(): Promise<void> {
    if (document.visibilityState !== 'visible') {
      return;
    }

    const provider = this.$store.state.web3.givenProvider;
    const chainId = await provider.request({ method: 'eth_chainId' });
    this.$store.dispatch('metamaskCheckChain', { chainId, provider });
  }
}
