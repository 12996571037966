
import { Vue, Options, Watch } from 'vue-property-decorator';
import Loader from '@/components/icons/loaderStakingNftModal.vue';
import { StakedNFTInfo } from '@/store/types/landlordStaking';
@Options({
  components: { Loader },
})
export default class stakeNftFromSpace extends Vue {
  title = '';
  message = '';
  textAction = '';
  actionHandler!: () => void;
  waitingForConfirmationTrigger = false;
  asset: any;
  isError = false;
  needActions = true;
  onStakingSucces!: () => void;
  linkToEtherscan = '';

  get transactionHash(): string {
    return this.$store.state.landlordStaking.transactionHash;
  }

  @Watch('transactionHash', { deep: true })
  async onTransactionHashChange(): Promise<void> {
    this.linkToEtherscan = `https://goerli.etherscan.io/tx/${this.transactionHash}`;
  }

  get waitingForConfirmation(): boolean {
    return this.$store.state.landlordStaking.waitForConfirmationTrigger;
  }

  get activeStakedNFTs(): StakedNFTInfo[] {
    return this.$store.state.landlordStaking.stakedNFTs.filter(
      (nft: StakedNFTInfo) => {
        return (
          nft.stakingHistory[nft.stakingHistory.length - 1]
            .unstakedAtTimestamp === null
        );
      }
    );
  }

  @Watch('waitingForConfirmation')
  async onWaitingForConfirmationChange(): Promise<void> {
    const element: any = document.getElementById('disable-area')!;
    this.waitingForConfirmationTrigger = this.waitingForConfirmation;
    const hasApprovalForTransfer = await this.$store.dispatch(
      'checkApprovalForTransferOfNFTs',
      this.asset.assetContract
    );
    const isStaked = this.$store.state.landlordStaking.currentlyStakedNFTs.find(
      (nft: any) =>
        nft.collectionAddress.toUpperCase() ===
          this.asset.assetContract.toUpperCase() &&
        +nft.tokenId === +this.asset.tokenId
    );
    if (this.waitingForConfirmation && !this.isError) {
      this.title = 'Waiting for confirmation';
      this.message = 'Please, do not close or refresh this page!';
      // this.message = "";
      element.style['pointer-events'] = 'none';
    } else {
      element.style['pointer-events'] = 'auto';
      if (hasApprovalForTransfer && !isStaked && !this.isError) {
        this.title = 'Congratulations! 🎉';
        this.message =
          'Your Nft is ready to be staked in the Landlord Staking program!';
        this.textAction = 'Stake';
        this.actionHandler = this.stakeNFT;
        this.linkToEtherscan = '';
      } else if (hasApprovalForTransfer && isStaked && !this.isError) {
        this.title = 'Congratulations!';
        this.message =
          'Your Nft was successfully staked in the LandlordStaking program!';
        this.textAction = '';
        this.needActions = false;
        this.onStakingSucces();

        setTimeout(() => {
          this.close();
        }, 5000);
      } else if (!hasApprovalForTransfer && !isStaked && !this.isError) {
        this.close();
      }
    }
  }
  async beforeMount(): Promise<void> {
    this.asset = this.$store.state.app.modal.payload.asset;
    this.onStakingSucces = this.$store.state.app.modal.payload.onStakingSucces;
    this.waitingForConfirmationTrigger = true;
    const hasApprovalForTransfer = await this.$store.dispatch(
      'checkApprovalForTransferOfNFTs',
      this.asset.assetContract
    );
    this.waitingForConfirmationTrigger = false;
    if (!hasApprovalForTransfer) {
      this.title = 'Missing approval for transfer of NFTs';
      this.message = `You need to approve the transfer for ${this.asset.collectionName} collection for which you want to stake NFTs.`;
      this.textAction = 'Approve';
      this.actionHandler = () =>
        this.approveCollection(this.asset.assetContract);
    } else {
      this.title = 'Congratulations! 🎉';
      this.message =
        'Your Nft is ready to be staked in the Landlord Staking program!';
      this.textAction = 'Stake';
      this.actionHandler = this.stakeNFT;
    }
  }

  async approveCollection(address: any): Promise<void> {
    const result = await this.$store.dispatch('approveTransferOfNFTs', address);
  }

  async stakeNFT(): Promise<void> {
    if (
      this.activeStakedNFTs.length + 1 >
      this.$store.state.landlordStaking.stakedParcels.length
    ) {
      this.isError = true;
      this.title = 'Not enough staked parcels';
      this.message =
        'You need to stake one more parcel to stake the token you selected.';
      setTimeout(() => {
        this.close();
      }, 5000);
    }
    const tokenOwnedByUser = await this.$store.dispatch(
      'checkTokenOwnedByUser',
      {
        collectionAddress: this.asset.assetContract,
        tokenId: this.asset.tokenId,
      }
    );
    if (!tokenOwnedByUser) {
      this.isError = true;
      this.title = 'This token is not owned by you';
      this.message = `Please choose only tokens that you own!`;
      setTimeout(() => {
        this.close();
      }, 5000);
    }

    await this.$store.dispatch('stakeNFT', {
      collectionAddress: this.asset.assetContract,
      tokenId: this.asset.tokenId,
    });
  }

  close(): void {
    this.$store.commit('app/setModal', { component: '', payload: null });
  }

  beforeUnmount(): void {
    this.$store.commit('setTransactionHash', '');
  }
}
