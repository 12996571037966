import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex justify-center items-center px-4 md:px-8 lg:px-12 xl:px-16 2xl:px-24 partners flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MeterIcon = _resolveComponent("MeterIcon")!
  const _component_Api3Icon = _resolveComponent("Api3Icon")!
  const _component_SyscoinIcon = _resolveComponent("SyscoinIcon")!
  const _component_IqProtocolIcon = _resolveComponent("IqProtocolIcon")!
  const _component_HasuDigitalIcon = _resolveComponent("HasuDigitalIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MeterIcon),
    _createVNode(_component_Api3Icon),
    _createVNode(_component_SyscoinIcon),
    _createVNode(_component_IqProtocolIcon),
    _createVNode(_component_HasuDigitalIcon)
  ]))
}