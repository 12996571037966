import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "portal" }
const _hoisted_2 = {
  key: 0,
  class: "fixed flex flex-row flex-wrap bottom-8 ml-0 md:ml-72 left-12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Canvas = _resolveComponent("Canvas")!
  const _component_Notifications = _resolveComponent("Notifications")!
  const _component_Parcels = _resolveComponent("Parcels")!
  const _component_MiniPortalCategory = _resolveComponent("MiniPortalCategory")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Canvas),
    _createVNode(_component_Notifications),
    _createVNode(_component_Parcels),
    (_ctx.windowInnerWidth > 768 || !_ctx.optionBar || true)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!_ctx.portalFilters.sizeIsOpen && _ctx.portalFilters.size.length > 0)
            ? (_openBlock(), _createBlock(_component_MiniPortalCategory, {
                key: 0,
                title: "SIZE",
                selected: _ctx.portalFilters.size,
                onSelected: _cache[0] || (_cache[0] = (e) => _ctx.addFilter(e, 'size')),
                onToggleOpen: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleFilterOpen('size')))
              }, null, 8, ["selected"]))
            : _createCommentVNode("", true),
          (!_ctx.portalFilters.typeIsOpen && _ctx.portalFilters.type.length > 0)
            ? (_openBlock(), _createBlock(_component_MiniPortalCategory, {
                key: 1,
                title: "TYPE",
                selected: _ctx.portalFilters.type,
                onSelected: _cache[2] || (_cache[2] = (e) => _ctx.addFilter(e, 'type')),
                onToggleOpen: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggleFilterOpen('type')))
              }, null, 8, ["selected"]))
            : _createCommentVNode("", true),
          (!_ctx.portalFilters.chainIsOpen && _ctx.portalFilters.chainId.length > 0)
            ? (_openBlock(), _createBlock(_component_MiniPortalCategory, {
                key: 2,
                title: "CHAIN",
                selected: _ctx.portalFilters.chainId,
                onSelected: _cache[4] || (_cache[4] = (e) => _ctx.addFilter(e, 'chainId')),
                onToggleOpen: _cache[5] || (_cache[5] = ($event: any) => (_ctx.toggleFilterOpen('chainId')))
              }, null, 8, ["selected"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}