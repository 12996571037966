
import { isUrl } from '@/utils';
import { Options, Vue } from 'vue-property-decorator';
import UpcomingCarousel from '../components/shared/upcomingCarousel.vue';
import SpotlightCollections from '../components/shared/spotlightCollections.vue';
import SoldoutCollections from '../components/shared/soldoutCollections.vue';
import HomeVideo from '../components/shared/homeVideo.vue';
import HomePortal from '../components/shared/homePortal.vue';
import HomeFooter from '../components/shared/homeFooter.vue';
import Partners from '../components/shared/partners.vue';
import SignalStreamIcon from '@/components/icons/signalStream.vue';
import Notifications from '@/components/shared/notifications.vue';

@Options({
  components: {
    Partners,
    UpcomingCarousel,
    SpotlightCollections,
    SoldoutCollections,
    HomeVideo,
    HomePortal,
    HomeFooter,
    SignalStreamIcon,
    Notifications,
  },
})
export default class Home extends Vue {
  header = {
    title: 'Transcending digital goods into the NFT world',
    subtitle:
      'DystoWorld is a multi-faceted ecosystem for bringing digital goods into The Metaverse.',
    buttonOne: 'Go to Launchpad',
    buttonTwo: 'Whitepaper',
    // For some reason if I use this.$router or anything else in a function here, it's undefined
    buttonTwoAction: (): void => {
      // open this link in a new tab
      const whitepaperLink =
        'https://dystoworld.com/assets/docs/whitepaper.pdf';
      window.open(whitepaperLink, '_blank');
    },
  };

  beforeCreate(): void {
    this.$store.commit('setCollectionSearchName', '');
    this.$store.commit('setMintingPhase', false);
    this.$store.dispatch('getHomeCollection');
    this.$store.dispatch('getUpcomingCollections');
    this.$store.dispatch('getFeaturedCollections');
    this.$store.dispatch('getSoldOutCollections');

    this.$store.commit('setKeypadCollectionSearchName', '');
    this.$store.dispatch('getKeypadCollections');
  }

  get topCollection(): any {
    return this.$store.state.home.topCollection;
  }

  get upcomingCollections(): any {
    const collections = this.$store.state.launchpad.collections.upcoming || [];
    // sort by comingAt ascending
    return collections.sort((a: any, b: any) => {
      return a.comingAt - b.comingAt;
    });
  }

  get featuredKeypadCollections() {
    const allCollections = this.$store.state.keypad.collections;
    return allCollections.filter((collection: { isFeatured: any; }) => collection.isFeatured);
  }

  get spotlightCollections(): any {
    return this.$store.state.launchpad.collections.featured || [];
  }

  get keypadCollections(): any {
    return this.$store.state.keypad.collections
  }

  checkLocalImage(image: string): boolean {
    return !isUrl(image);
  }
}
