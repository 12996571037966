
import Loader from '@/components/icons/loader.vue';
import {
  PoolReward,
  PoolHistory,
  StakedNFTInfo,
} from '@/store/types/landlordStaking';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Options, Prop, Vue, Watch } from 'vue-property-decorator';
import StakedNFTCard from './stakedNFTCard.vue';
import StakedAssets from './stakedAssets.vue';

@Options({
  components: {
    FontAwesomeIcon,
    StakedNFTCard,
    Loader,
    StakedAssets,
  },
})
export default class PoolsStaked extends Vue {
  @Prop() layout!: string;
  collections: PoolReward[] = [];
  stakedNftsForThisPool: StakedNFTInfo[] = [];
  unstakedNftsForThisPool: StakedNFTInfo[] = [];

  get poolRewardHistories(): Record<string, PoolHistory[]> {
    return this.$store.state.landlordStaking.collectionHistories;
  }

  get titles(): string[] {
    if (this.layout === 'per-pool')
      return this.collections.map(
        (collection) =>
          `${
            this.collectionIsEnded(collection)
              ? 'Eligible NFTs you have staked for this pool reward'
              : 'NFTs staked eligible for rewards'
          } ${this.collections.length > 1 ? '-' + collection.name : ''}`
      );
    else {
      return this.collections.map((collection) =>
        this.collections.length > 1 ? collection.name : ''
      );
    }
  }

  collectionIsEnded(collection: PoolReward): boolean {
    return collection.endTimestamp < Date.now() / 1000;
  }

  get nftsStaked(): StakedNFTInfo[] {
    return this.$store.state.landlordStaking.stakedNFTs;
  }

  isNftStaked(nft: StakedNFTInfo): boolean {
    return (
      nft.stakingHistory[nft.stakingHistory.length - 1].unstakedAtTimestamp ===
      null
    );
  }

  filter = (
    poolAddress: string,
    poolId: string,
    nftCollectionAddress: string,
    selectedCollectionAddress: string | null,
    selectedCollectionId: string | null,
    getAll: boolean
  ): boolean => {
    return (
      poolAddress.toLowerCase() === nftCollectionAddress.toLowerCase() &&
      (getAll ||
        (this.layout === 'per-pool'
          ? poolId === selectedCollectionId
          : poolAddress.toLowerCase() ===
            selectedCollectionAddress?.toLowerCase()))
    );
  };

  @Watch('layout')
  @Watch('$route', { immediate: true, deep: true })
  @Watch('nftsStaked', { immediate: true })
  onNftsStakedChange(): void {
    const slug = this.$route.params.slug as string;
    let getAll = false;
    if (slug === 'bulk') {
      this.collections = [];
      return;
    } else if (slug === 'staked-all') {
      getAll = true;
    }
    const allCollections: PoolReward[] = [];

    const activePoolRewards = this.$store.state.landlordStaking
      .activePoolRewards as PoolReward[];

    const pastPoolRewards = this.$store.state.landlordStaking
      .pastPoolRewards as PoolReward[];

    const selectedPoolReward = this.$store.state.landlordStaking
      .currentPoolReward as PoolReward;

    this.nftsStaked.forEach((nft) => {
      let collection = activePoolRewards.find((pool) =>
        this.filter(
          pool.collectionAddress,
          pool.id,
          nft.collectionAddress,
          selectedPoolReward?.collectionAddress,
          selectedPoolReward?.id,
          getAll
        )
      );

      if (!collection)
        collection = pastPoolRewards.find((pool) =>
          this.filter(
            pool.collectionAddress,
            pool.id,
            nft.collectionAddress,
            selectedPoolReward?.collectionAddress,
            selectedPoolReward?.id,
            getAll
          )
        );

      if (collection) {
        const collectionExists = allCollections.find(
          (oldcollection) =>
            oldcollection.collectionAddress.toLowerCase() ===
            collection?.collectionAddress.toLowerCase()
        );

        if (!collectionExists) {
          allCollections.push(collection);
        }
      }
    });

    this.collections = allCollections;
  }
}
