
import { Emit, Options, Vue, Watch } from 'vue-property-decorator';
import SteakIcon from '@/components/icons/steak.vue';
import OptionBar from '@/components/shared/optionbar.vue';
import { NFTOptionInfo, PoolReward } from '@/store/types/landlordStaking';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import PoolHeader from '@/components/shared/landlord-staking/staking-nfts/poolHeader.vue';
import BulkHeader from '@/components/shared/landlord-staking/staking-nfts/bulkHeader.vue';
import PoolsStake from '@/components/shared/landlord-staking/staking-nfts/poolsStake.vue';
import PoolsStaked from '@/components/shared/landlord-staking/staking-nfts/poolsStaked.vue';
import MenuModal from '@/components/shared/landlord-staking/staking-nfts/menu-modal/menuModal.vue';
import Loader from '@/components/icons/loader.vue';
import DialsIcon from '@/components/icons/dials.vue';

@Options({
  components: {
    SteakIcon,
    OptionBar,
    FontAwesomeIcon,
    PoolHeader,
    BulkHeader,
    PoolsStake,
    PoolsStaked,
    Loader,
    DialsIcon,
    MenuModal,
  },
})
export default class StakingNFTs extends Vue {
  layout = 'per-pool';

  @Watch('$route.params.slug', { immediate: true })
  onRouteChange(): void {
    const slug = this.$route.params.slug as string;

    if (slug && slug.startsWith('staked-')) {
      this.layout = 'per-collection';
    } else {
      this.layout = 'per-pool';
    }
  }

  @Emit('changeSubmenu')
  selectSubmenu(newValue: string): string {
    return newValue;
  }

  get selectedPoolReward(): PoolReward | null {
    return this.$store.state.landlordStaking.currentPoolReward;
  }

  get waitingForOpenSeaApi(): boolean {
    return this.$store.state.landlordStaking.waitingForOpenSeaApi;
  }

  get user(): any {
    return this.$store.state.user;
  }

  get chainId(): number {
    return this.$store.state.chainId;
  }

  get selectedNFTsForStaking(): Record<string, string[]> {
    return this.$store.state.landlordStaking.selectedNFTsForStaking;
  }

  get collectionAddresses(): string[] {
    return this.$store.state.landlordStaking.collectionAddresses;
  }

  set collectionAddresses(newValue: string[]) {
    this.$store.commit('setCollectionAddresses', newValue);
  }

  get numberOfSelectedNFTs(): number {
    return Object.values(this.selectedNFTsForStaking).reduce(
      (acc, curr) => acc + curr.length,
      0
    );
  }

  @Watch('selectedPoolReward', { immediate: true })
  async onSelectedPoolRewardChange(newValue: PoolReward | null): Promise<void> {
    this.$store.dispatch('clearNFTsOwned');
    if (newValue) {
      if (newValue.endTimestamp > Date.now() / 1000)
        this.collectionAddresses = [newValue.collectionAddress];
      else {
        this.collectionAddresses = [];
      }
    } else if (this.$route.params.slug === 'bulk') {
      let activePoolRewards = this.$store.state.landlordStaking
        .activePoolRewards as PoolReward[];

      const newCollectionAddresses: string[] = [];

      activePoolRewards.forEach((poolReward) => {
        if (!newCollectionAddresses.includes(poolReward.collectionAddress)) {
          newCollectionAddresses.push(poolReward.collectionAddress);
        }
      });

      this.collectionAddresses = newCollectionAddresses;
    }
  }

  @Watch('user', { deep: true })
  @Watch('chainId')
  async onUserChange(): Promise<void> {
    if (this.$route.params.slug === 'bulk') {
      let activePoolRewards = this.$store.state.landlordStaking
        .activePoolRewards as PoolReward[];

      if (activePoolRewards.length === 0) {
        const poolRewards = await this.$store.dispatch('getPoolRewards');

        activePoolRewards = poolRewards.activePoolRewards;
      }

      const newCollectionAddresses: string[] = [];

      activePoolRewards.forEach((poolReward) => {
        if (!newCollectionAddresses.includes(poolReward.collectionAddress)) {
          newCollectionAddresses.push(poolReward.collectionAddress);
        }
      });

      this.collectionAddresses = newCollectionAddresses;
    }
  }

  get nftsOwned(): NFTOptionInfo[] {
    return this.$store.state.landlordStaking.nftsOwned;
  }

  @Watch('collectionAddresses', { immediate: true })
  onCollectionAddressesChange(newValue: string[]): void {
    if (newValue.length !== 0 && this.layout === 'per-pool') {
      this.$store.dispatch('getNFTsOwned', newValue);
    } else {
      this.$store.dispatch('clearNFTsOwned');
    }
  }

  onStake(): void {
    this.$store.dispatch('onStakeSelectedNFTs');
  }

  onUnstakeAll(): void {
    this.$store.commit('app/setModal', {
      component: 'alertMessage',
      payload: {
        title: 'Unstaking Warning',
        message:
          'You will unstake all your nfts from every collection. You will receive rewards from eligible pool rewards that have ended if you kept your token staked for at least 30 days, otherwise you will lose those rewards. Are you sure you want to unstake all your nfts?',
        okMessage: 'I understand',
        okAction: () => this.$store.dispatch('unstakeBulkNFTs'),
      },
    });
  }
}
