import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex pagination" }
const _hoisted_2 = {
  key: 0,
  class: "page center-center"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronIcon = _resolveComponent("ChevronIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "prepend center-center pointer",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.prevPage && _ctx.prevPage(...args)))
    }, [
      _createVNode(_component_ChevronIcon)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (page) => {
      return (_openBlock(), _createElementBlock("div", {
        key: page,
        class: _normalizeClass([
        'page center-center',
        page.selected ? 'active disable-pointer' : '',
      ])
      }, [
        (page.breakView)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.breakViewText), 1))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              onClick: ($event: any) => (_ctx.handlePageSelected(page.index + 1)),
              class: "page center-center pointer"
            }, _toDisplayString(page.content), 9, _hoisted_3))
      ], 2))
    }), 128)),
    _createElementVNode("div", {
      class: "append center-center rotate-180 pointer",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.nextPage && _ctx.nextPage(...args)))
    }, [
      _createVNode(_component_ChevronIcon)
    ])
  ]))
}