
import { Options, Vue } from 'vue-property-decorator';

import Api3Icon from '@/components/icons/partners/api3.vue';
import HasuDigitalIcon from '@/components/icons/partners/hasu_digital.vue';
import IqProtocolIcon from '@/components/icons/partners/iq_protocol.vue';
import MeterIcon from '@/components/icons/partners/meter.vue';
import SyscoinIcon from '@/components/icons/partners/syscoin.vue';

@Options({
  components: {
    Api3Icon,
    HasuDigitalIcon,
    IqProtocolIcon,
    MeterIcon,
    SyscoinIcon,
  },
})
export default class Partners extends Vue {}
