
import Banner from '@/components/shared/banner.vue';
import GameItem from '@/components/shared/gameItem.vue';
import Dropdown from '@/components/shared/newDropdown.vue';
import Notifications from '@/components/shared/notifications.vue';
import Pagination from '@/components/shared/pagination.vue';
import { Options, Vue, Watch } from 'vue-property-decorator';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import eventBus from '@/eventBus';
import Dials from '@/components/icons/dials.vue';
import { formatEther } from '@/utils';

@Options({
  components: {
    Dials,
    GameItem,
    Pagination,
    Banner,
    Notifications,
    Dropdown,
    FontAwesomeIcon,
  },
})
export default class Keypad extends Vue {
  collection: any = null;
  sharesSupply = 0;
  userShares = 0;
  priceForOneShare = '0';
  isLoggedIn = false;
  initialLoading = true;

  created(): void {
    setTimeout(async () => {
      await this.init();
      this.initialLoading = false;
    }, 500);
  }

  async init() {
    try {
      eventBus.on('logout', this.handleLogout);
      window.addEventListener('resize', this.windowResizeHandler);

      this.isLoggedIn = (this.$store.state.user !== null);
      if (!this.isLoggedIn) {
        return;
      }

      await this.updateCollection(this.currentCollection);
    } catch (err) {
      console.log(err);
    }
  }

  handleLogout() {
    this.$router.push('/');
    this.isLoggedIn = false;
  }

  mounted(): void {
    this.$nextTick(() => {
      this.windowResizeHandler();
    });
  }

  destroyed(): void {
    window.removeEventListener('resize', this.windowResizeHandler);
  }

  windowResizeHandler(): void {
    const assetSelector = this.$refs.assetSelector as HTMLElement;

    if (!assetSelector) {
      setTimeout(() => {
        this.windowResizeHandler();
      }, 150);
      return;
    }
    if (window.innerWidth < 1600) {
      // Check the parent of assetSelector
      // If it has the id 'selectorBig', then we need to move it to the ref 'selectorSmall'
      if (
        assetSelector?.parentElement &&
        assetSelector?.parentElement.id === 'selectorBig'
      ) {
        const selectorSmall = this.$refs.selectorSmall as HTMLElement;
        selectorSmall.appendChild(assetSelector);
      }
    } else {
      // Check the parent of assetSelector
      // If it has the id 'selectorSmall', then we need to move it to the ref 'selectorBig'
      if (
        assetSelector?.parentElement &&
        assetSelector?.parentElement.id === 'selectorSmall'
      ) {
        const selectorBig = this.$refs.selectorBig as HTMLElement;
        selectorBig.appendChild(assetSelector);
      }
    }
  }

  get currentCollection(): unknown {
    return this.$store.state.keypad.currentCollection;
  }

  tradeShare(isBuy: boolean) {
    this.$store.commit('app/setModal', {
      component: 'tradeKeypadShare',
      fromComponent: 'keypad',
      payload: {
        collectionAddress: this.collection.user_wallet_address,
        isBuy: isBuy,
        exitCallback: async () => {
          await this.$store.dispatch('getKeypadCollections');
          await this.init();
        },
      },
    });
  }

  @Watch('user', { deep: true })
  async onUserChanged(): Promise<void> {
    await this.init();
  }

  @Watch('currentCollection', { deep: true })
  async updateCollection(val: unknown): Promise<void> {
    this.collection = val;

    if (this.collection == null) {
      return;
    }

    try {
      this.sharesSupply = await this.$store.state.dystoWorldKeypadContract.methods.sharesSupply(this.collection?.user_wallet_address).call();
    } catch (error) {
      console.log('Error while taking current supply ', error);
    }

    try {
      this.userShares = await this.$store.state.dystoWorldKeypadContract.methods.sharesBalance(this.collection?.user_wallet_address, this.$store.state.user.address).call();
    } catch (error) {
      console.log('Error while taking user balance ', error);
    }

    try {
      this.priceForOneShare = await this.$store.state.dystoWorldKeypadContract.methods.getBuyPriceAfterFee(this.collection?.user_wallet_address, 1).call();
      this.priceForOneShare = formatEther(this.priceForOneShare.toString());
    } catch (error) {
      console.log('Error while taking share price balance ', error);
    }
  }

  get chainId(): number {
    return this.$store.state.chainId;
  }

  get user(): any {
    return this.$store.state.user;
  }
}
