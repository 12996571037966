
import { Parcel } from '@/utils/interfaces';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Emit, Options, Prop, Vue, Watch } from 'vue-property-decorator';
import ParcelCard from '@/components/shared/landlord-staking/staking-parcels/parcelCard.vue';

@Options({
  components: {
    ParcelCard,
    FontAwesomeIcon,
  },
})
export default class ParcelCategory extends Vue {
  @Prop() parcels!: Parcel[];
  @Prop() type!: 'staked' | 'unstaked';
  @Prop() hasApproved!: boolean;
  selectedParcels: string[] = [];

  get title(): string {
    switch (this.type) {
      case 'staked':
        return 'Staked Parcels';
      case 'unstaked':
        return 'Unstaked Parcels';
      default:
        return '';
    }
  }

  @Watch('parcels')
  onParcelsChange(): void {
    this.selectedParcels = [];
  }

  get buttonText(): string {
    if (!this.hasApproved && this.type == 'unstaked') return 'Approve transfer';

    switch (this.type) {
      case 'staked':
        return this.selectedParcels.length > 0
          ? `Unstake selected parcels (${this.selectedParcels.length})`
          : 'Select parcels to unstake';
      case 'unstaked':
        return this.selectedParcels.length > 0
          ? `Stake selected parcels (${this.selectedParcels.length})`
          : 'Select parcels to stake';
      default:
        return '';
    }
  }

  toggleParcel(id: string): void {
    if (this.selectedParcels.includes(id)) {
      this.selectedParcels = this.selectedParcels.filter(
        (selectedParcelId) => selectedParcelId !== id
      );
    } else {
      this.selectedParcels.push(id);
    }
  }

  toggleAllParcels(): void {
    if (this.selectedParcels.length == this.parcels.length) {
      this.selectedParcels = [];
    } else {
      this.selectedParcels = this.parcels.map((parcel) => parcel.id);
    }
  }

  get selectedParcelTokenIds(): number[] {
    const tokenIds: number[] = [];
    this.parcels
      .filter(({ id }) => this.selectedParcels.includes(id))
      .forEach(({ tokenId, childrenTokenIds }) => {
        if (childrenTokenIds?.length) tokenIds.push(...childrenTokenIds);
        else tokenIds.push(tokenId);
      });

    return tokenIds;
  }

  @Emit('onClick')
  onClick(): number[] {
    return this.selectedParcelTokenIds;
  }

  onApprove(): void {
    this.$store.dispatch('approveTransferOfParcels');
  }
}
