
import { Vue, Options, Watch } from 'vue-property-decorator';
import { CanvasApi } from '@/utils/classes/canvas';
import { Position } from '@/utils/interfaces/canvas';
import QuestionmarkIcon from '../icons/questionmark.vue';
import FilterIcon from '../icons/filter.vue';
import { delay } from '@/utils';

@Options({
  components: {
    QuestionmarkIcon,
    FilterIcon,
  },
})
export default class Canvas extends Vue {
  canvas = {} as CanvasApi;

  get Minting_Parcels() {
    return this.$store.state.app.Minting_Parcels;
  }
  get Merged_Parcel() {
    return this.$store.state.app.MergedParcel;
  }
  get Owned_Parcels() {
    return this.$store.state.app.Owned_Parcels;
  }

  get mintingParcels() {
    return this.$store.state.app.mintingParcels;
  }
  get lastHovered() {
    return this.$store.state.app.lastHovered;
  }

  get user() {
    return this.$store.state.user;
  }

  get ParcelCoordinates(): Position {
    return this.$store.state.app.parcel_coordinates;
  }

  get parcels() {
    const parcels = this.$store.state.parcels;
    return parcels;
  }

  get optionBar() {
    return this.$store.state.app.Option_Bar;
  }

  get portalFilters() {
    return this.$store.state.app.portalFilters;
  }

  beforeCreate() {
    this.$store.dispatch('getParcels');
  }

  @Watch('lastHovered')
  lastHovered_watch(n: any) {
    if (n?.outside) {
      this.canvas.isMovedFromParcels = true;
    }
    this.canvas.showHovered(n);
  }

  @Watch('optionBar')
  optionBar_watch(n: any) {
    this.canvas.onResize();
  }

  @Watch('Minting_Parcels')
  Minting_Parcels_watch(n: any) {
    if (n) {
      this.canvas.isMerge = false;
    }
  }

  @Watch('Merged_Parcel', { deep: true })
  Merged_Parcel_watch(n: any) {
    if (!n.selected && !n.variable) {
      this.canvas.mergingLayer.clear();
    }
  }

  @Watch('Owned_Parcels')
  Owned_Parcels_watch(n: any) {
    if (n) {
      this.canvas.isMerge = false;
    }
  }

  @Watch('parcels', { deep: true })
  async parcels_watch(n: any): Promise<void> {
    if (n !== null) {
      // wait a little bit for the canvas to be rendered and drop the old images on parcels
      await delay(500);
      this.canvas.populateParcelContainer(n);
      this.canvas.populateParcels(n);
    }
  }

  @Watch('portalFilters', { deep: true })
  portal_filters_watch(n: any) {
    if (n !== null) {
      this.canvas.populateParcels(this.parcels, n);
    }
  }

  @Watch('user', { deep: true })
  user_watch(n: any) {
    if (n !== null) {
      this.canvas.populateParcels(this.parcels);
    }
  }

  @Watch('ParcelCoordinates', { deep: true })
  ParcelCoordinatesWatcher(val: Position) {
    const position = this.canvas.translateToGaps({ x: val.x, y: val.y });
    this.canvas.selectZone(position.x, position.y, this.canvas.selectedColor);
  }
  @Watch('mintingParcels', { deep: true })
  mintingParcelsWatcher(val: any) {
    const position = this.canvas.translateToGaps({
      x: this.ParcelCoordinates.x,
      y: this.ParcelCoordinates.y,
    });
    this.canvas.selectZone(position.x, position.y, this.canvas.selectedColor);
  }

  mounted() {
    this.canvas = new CanvasApi();
    const CANVAS = document.getElementById('canvas');
    CANVAS?.addEventListener('contextmenu', (e) => {
      e.preventDefault();
      // this.canvas.isMerge = !this.canvas.isMerge;
      // this.canvas.mergingLayer.clear();
      this.$store.commit('app/setMintingParcelsSidebar', false);
      this.$store.commit('app/setOwnedParcelsSidebar', false);
      this.canvas.isMerge = !this.canvas.isMerge;
      if (!this.canvas.isMerge) {
        if (window.innerWidth > 816) {
          this.$store.commit('app/setMintingParcelsSidebar', true);
          this.$store.commit('app/setOwnedParcelsSidebar', true);
        }
        this.canvas.mergingClicks = 0;
        this.canvas.mergingLayer.clear();
        this.$store.commit('app/setMergedParcel', {
          selected: null,
          variable: null,
        });
      }
    });
  }

  zoomIn() {
    this.canvas.zoomIn();
  }
  zoomOut() {
    this.canvas.zoomOut();
  }

  setModal(modal: any, payload?: any) {
    this.$store.commit('app/setModal', { component: modal, payload });
  }

  merge() {
    this.$store.commit('app/setMintingParcelsSidebar', false);
    this.$store.commit('app/setOwnedParcelsSidebar', false);
    this.canvas.isMerge = !this.canvas.isMerge;
    if (!this.canvas.isMerge) {
      if (window.innerWidth > 816) {
        this.$store.commit('app/setMintingParcelsSidebar', true);
        this.$store.commit('app/setOwnedParcelsSidebar', true);
      }
      this.canvas.mergingClicks = 0;
      this.canvas.mergingLayer.clear();
      this.$store.commit('app/setMergedParcel', {
        selected: null,
        variable: null,
      });
    }
  }

  beforeUnmount() {
    this.canvas.destroy();
  }
}
