
import { Vue, Prop, Watch, Options } from 'vue-property-decorator';
import { API_URL } from '@/config/index';
import Chat from '@/views/Chat.vue';

@Options({ components: { Chat } })
export default class PreviewPlaceholderAsset extends Vue {
  @Prop() readonly currentMesh: any;
  @Prop({ type: Boolean }) readonly showComponent!: boolean;
  @Prop({ type: Function }) readonly setShowComponentToFalse!: () => void;
  @Prop({ type: Function }) readonly setShowEditPageToTrue!: () => void;
  @Prop({ type: Function }) readonly isOwner!: () => boolean;
  @Prop({ type: Function }) readonly likeAssetHandler!: () => boolean;
  @Prop({ type: Function }) readonly unlikeAssetHandler!: () => boolean;

  showingChat = false;

  redirect(): void {
    window.open(`${API_URL}/landlord-staking`, '_blank');
  }

  openAiChat(): void {
    this.showingChat = true;
  }

  closeAiChat(): void {
    this.showingChat = false;
  }
}
