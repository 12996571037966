import { API_URL } from '@/config';
import io from 'socket.io-client';
import store from '@/store';
const socket = io(API_URL, { transports: ['websocket'] });

socket.on('connection', () => {});

socket.on('connect_error', (err: any) => {
  console.log(`connect_error due to ${err.message}`);
});

socket.on('connect', () => {});

socket.on('ParcelReserved', (element: any) => {
  store.commit('app/setLoaders', {
    ...store.state.app.loaders,
    reserve: false,
  });
  store.commit('updateParcels', {
    ...element,
    state: 'RESERVED',
    reservationId: element.transactionId,
  });
  if (element.owner === store.state.wallet) {
    store.commit('app/alterNotifications', {
      text: 'You successfully reserved the parcel.',
      type: 'success',
      add: true,
    });
  }
});

socket.on('ParcelMinted', (element: any) => {
  store.commit('app/setLoaders', {
    ...store.state.app.loaders,
    mint: { id: '', bool: false },
  });
  store.commit('updateParcels', { ...element, state: 'MINTED' });
  if (element.owner === store.state.wallet) {
    store.commit('app/alterNotifications', {
      text: 'You successfully minted the parcel.',
      type: 'success',
      add: true,
    });
  }
});
