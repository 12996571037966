
import { Options, Prop, Vue, Watch } from 'vue-property-decorator';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { isUrl } from '@/utils';

@Options({
  components: {
    FontAwesomeIcon,
  },
})
export default class SpotlightCollections extends Vue {
  @Prop(Array) readonly collections!: any[];
  currentSlide = 0;
  slideInterval: any = null;

  mounted() {
    this.resetInterval();
  }

  changeSlide(direction: number): void {
    let newSlide = (this.currentSlide + direction) % this.collections.length;

    if (newSlide < 0) {
      newSlide = this.collections.length + newSlide;
    }

    this.currentSlide = newSlide;

    this.resetInterval();
  }

  setSlide(index: number): void {
    this.currentSlide = index;

    this.resetInterval();
  }

  resetInterval(): void {
    if (this.slideInterval) clearInterval(this.slideInterval);
    this.slideInterval = setInterval(() => {
      // this.currentSlide = (this.currentSlide + 1) % this.collections.length;
    }, 5000);
  }

  checkLocalImage(image: string): boolean {
    return !isUrl(image);
  }
}
