
import { initGlitch } from '@/utils';
import { Vue } from 'vue-property-decorator';

export default class BarNavigation extends Vue {
  get active() {
    return this.$route.name;
  }

  setRoute(path: string) {
    this.$store.commit('app/setOptionBar', false);
    this.$router.push(path);
  }

  getClasses(isActive: boolean): string {
    return `text-base font-bold text-white px-6 py-2  ${
      isActive
        ? 'rounded-md bg-grey-2 cursor-default'
        : 'cursor-pointer glitch-anchor'
    }`;
  }

  mounted(): void {
    initGlitch();
  }

  alertComingSoon(): boolean {
    this.$store.commit(
      'app/alterNotifications',
      { text: 'Coming soon!', type: 'success', add: true },
      { root: true }
    );
    return false;
  }
}
