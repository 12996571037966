
import { Vue, Options, Watch } from 'vue-property-decorator';
import StakingNFTsCategory from './stakingNFTsCategory.vue';
import Switch from './switch.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

@Options({
  components: {
    StakingNFTsCategory,
    Switch,
    FontAwesomeIcon,
  },
})
export default class LaunchpadBar extends Vue {
  Minting_Phase = false;
  collectionSearchName = '';

  get chainId(): number {
    return this.$store.state.chainId;
  }

  get wallet(): string {
    return this.$store.state.wallet;
  }

  @Watch('collectionSearchName')
  changePoolRewardsSearchString(value: string): void {
    this.$store.commit('setPoolRewardsSearchName', value);
    this.$store.dispatch('getPoolRewards');
  }

  beforeDestroy(): void {
    this.$store.commit('setPoolRewardsSearchName', '');
  }
}
