
import EllipsisIcon from '@/components/icons/ellipsis.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Options, Vue, Watch } from 'vue-property-decorator';
import Banner from '../../banner.vue';
import Dropdown from '../../dropdown.vue';
import { copyToClipboard } from '@/utils/index';
import { API_URL } from '@/config';

enum dropdownType {
  CURRENCY = 'CURRENCY',
  BLOCKS = 'BLOCKS',
}

@Options({
  components: {
    Banner,
    Dropdown,
    EllipsisIcon,
    FontAwesomeIcon,
  },
})
export default class parcelDetails extends Vue {
  copy_parcel = {};
  parcel = {} as any;
  image_parcel_data = '';
  file_parcel_form = {};
  isMulti = false;
  hasInstanceMounted = false;
  link = '';
  isMoreOpen = false;
  descriptionExpanded = false;
  showCopied = false;

  options = [] as any;

  availableSizes = [
    {
      label: '1 x 1',
      type: 'BLOCKS',
      value: 1,
    },
    {
      label: '3 x 3',
      type: 'BLOCKS',
      value: 3,
    },
    {
      label: '6 x 6',
      type: 'BLOCKS',
      value: 6,
    },
    {
      label: '12 x 12',
      type: 'BLOCKS',
      value: 12,
    },
  ];
  option_active = null;

  @Watch('parcel', { deep: true })
  watch_parcel(parcel: any) {
    if (parcel) {
      this.options = this.availableSizes.filter(
        (e) =>
          e.value < (this as any).parcel.size && (this as any).parcel.size > 1
      );
      if (this.options.length > 0) {
        this.option_active = this.options[0];
      }
    }
  }

  get wallet() {
    return this.$store.state.wallet;
  }

  copyToClipboard(string: string): void {
    copyToClipboard(string);
    this.showCopied = true;
    setTimeout(() => {
      this.showCopied = false;
    }, 2000);
  }

  updateOption(option: any) {
    switch (option.type) {
      case dropdownType.BLOCKS:
        this.option_active = option;
        // this.$store.commit("app/setMintingParcels", {
        //   blocks: option,
        // });
        break;
    }
  }

  parcelDescription() {
    if (this.parcel.description) {
      if (this.descriptionExpanded) {
        return this.parcel.description;
      } else {
        return this.parcel.description.substring(0, 100);
      }
    }
    return '';
  }

  setModal(modal: any) {
    this.$store.commit('app/setModal', { component: modal, payload: null });
  }

  goTo(link: string) {
    if (link) {
      window.open(link, '_blank');
    }
  }

  close() {
    this.setModal('');
    Object.assign(this.parcel, this.copy_parcel);
  }
  async verify() {
    try {
      const verify = await this.$store.dispatch(
        'verify3DModel',
        (this as any).parcel.id
      );

      if (!verify) {
        throw new Error();
      }

      this.hasInstanceMounted = verify.hasInstanceMounted;
      if ((this, this.hasInstanceMounted)) {
        this.link = verify.data.link;
      }
    } catch (err) {
      // this.$store.commit("app/alterNotifications", { text: "The mount/edit 3D model operations are not  available", type: "danger", add: true });
    }
  }

  created(): void {
    this.parcel = Object.assign({}, this.$store.state.app.modal?.payload);
    this.copy_parcel = Object.assign({}, this.$store.state.app.modal?.payload);

    fetch(`${API_URL}/users/${this.parcel.owner}/ens`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) =>
        res.json().then((data) => {
          this.parcel.ens = data.ens;
        })
      )
      .catch(() => {
        this.parcel.ens = this.parcel.owner;
      });
    this.verify();
  }
}
