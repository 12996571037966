
import { Vue, Options, Watch } from 'vue-property-decorator';
import LaunchpadCategory from './launchpadCategory.vue';
import Switch from './switch.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

@Options({
  components: {
    LaunchpadCategory,
    Switch,
    FontAwesomeIcon,
  },
})
export default class LaunchpadBar extends Vue {
  Minting_Phase = false;
  collectionSearchName = '';

  beforeCreate() {
    this.$store.dispatch('getCollections');
  }

  get user(): any {
    return this.$store.state.user;
  }

  @Watch('collectionSearchName')
  changeCollectionSearchString(value: string): void {
    this.$store.commit('setCollectionSearchName', value);
    this.$store.dispatch('getCollections');
  }

  @Watch('user', { deep: true })
  changeUser(): void {
    this.$store.dispatch('getCollections');
  }

  toggleSwitch(variable: string): void {
    switch (variable) {
      case 'Minting_Phase':
        this.Minting_Phase = !this.Minting_Phase;
        this.$store.commit('setMintingPhase', this.Minting_Phase);
        this.$store.dispatch('getCollections');
        break;
    }
  }

  beforeDestroy(): void {
    this.$store.commit('setCollectionSearchName', '');
    this.$store.commit('setMintingPhase', false);
    this.$store.dispatch('getCollections');
  }
}
