import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "border-2 border-grey-17 w-full rounded-lg flex flex-col md:flex-row items-center md:items-start p-3 sm:p-4 md:p-8 lg:px-11 lg:py-9 bg-white shadow-lg" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "flex flex-col items-center md:items-start justify-between mt-0 h-full w-full min-w-0" }
const _hoisted_5 = { class: "flex h-fit w-full" }
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "flex flex-col justify-between w-full min-w-0" }
const _hoisted_9 = {
  key: 0,
  class: "font-semibold text-xl md:text-3xl"
}
const _hoisted_10 = { class: "min-w-0 truncate" }
const _hoisted_11 = { class: "flex flex-col md:flex-row md:flex-wrap w-full justify-center md:justify-start mt-0" }
const _hoisted_12 = { class: "bg-white-2 rounded-md border border-grey-21 text-grey-text-8 px-7 py-2 mt-2 flex items-center font-medium w-full" }
const _hoisted_13 = {
  key: 0,
  class: "w-5 h-5 mr-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.imageUrl)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.imageUrl,
          class: "w-52 h-52 rounded-xl mr-12 hidden md:block"
        }, null, 8, _hoisted_2))
      : (_ctx.defaultAvatar)
        ? (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: require('@/assets/images/profile.png'),
            class: "w-52 h-52 rounded-xl mr-12 hidden md:block"
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_ctx.imageUrl)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.imageUrl,
              class: "w-28 h-28 rounded-md mr-3 block md:hidden"
            }, null, 8, _hoisted_6))
          : (_ctx.defaultAvatar)
            ? (_openBlock(), _createElementBlock("img", {
                key: 1,
                src: require('@/assets/images/profile.png'),
                class: "w-28 h-28 rounded-md mr-3 block md:hidden"
              }, null, 8, _hoisted_7))
            : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_8, [
          (_ctx.title)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.title), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: "flex items-center cursor-pointer relative w-fit font-medium text-sm md:text-xl truncate max-w-full bg-white-2 md:bg-opacity-0 rounded-md border border-grey-21 md:border-none text-grey-text-8 md:text-black px-3 md:px-0 py-2 md:py-0",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyToClipboard(_ctx.address)))
          }, [
            _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.address), 1),
            _createVNode(_component_font_awesome_icon, {
              icon: !_ctx.showCopied ? 'fa-regular fa-copy' : 'fa-solid fa-check',
              class: "ml-2 text-grey-3"
            }, null, 8, ["icon"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.poolData, ({ title, value, icon, component }, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "flex flex-col h-fit mr-2 lg:mr-4 xl:mr-6 2xl:mr-8 mt-6 w-full md:w-fit"
          }, [
            _createTextVNode(_toDisplayString(title) + " ", 1),
            _createElementVNode("div", _hoisted_12, [
              component
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(component)))
                  ]))
                : _createCommentVNode("", true),
              icon
                ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                    key: 1,
                    icon: icon,
                    class: "mr-5 text-xl"
                  }, null, 8, ["icon"]))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(value), 1)
            ])
          ]))
        }), 128))
      ])
    ])
  ]))
}