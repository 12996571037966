
import { Vue, Prop, Options, Watch } from 'vue-property-decorator';
import { PoolReward, StakedNFTInfo } from '@/store/types/landlordStaking';
import ImageButton from '@/components/shared/imageButton.vue';
import {
  getPoolRewardName,
  isNFTEligibleForPoolReward,
  MIN_STAKED_TIME,
  formatEther,
  formatTimestampInSecondsToDateInterval,
  canNFTClaimRewardNow,
} from '@/utils';

@Options({ components: { ImageButton } })
export default class StakedNFTView extends Vue {
  @Prop() nft!: StakedNFTInfo;
  @Prop({ default: false }) isDisabled!: boolean;
  @Prop({ default: false }) showCollectionInfo!: boolean;
  @Prop({ default: false }) showClaim!: boolean;
  @Prop({ default: false }) showUnstake!: boolean;
  @Prop({ default: false }) showStakingHistory!: boolean;
  @Prop({ default: false }) showClaimedRewardHistory!: boolean;
  eligiblePoolRewards: PoolReward[] = [];
  stakedTime = 0;
  interval = 0;

  mounted(): void {
    // don't start the interval if the nft is not staked, the total staked time won't change
    if (this.showUnstake) {
      this.interval = window.setInterval(this.updateStakedTime, 1000);
    }
    this.updateStakedTime();
    this.computeEligiblePoolRewards();
  }

  updateStakedTime(): void {
    this.stakedTime = 0;
    const stakedAtTimestamp =
      this.nft.stakingHistory[this.nft.stakingHistory.length - 1]
        .stakedAtTimestamp;
    const unstakedAtTimestamp =
      this.nft.stakingHistory[this.nft.stakingHistory.length - 1]
        .unstakedAtTimestamp || Math.floor(Date.now() / 1000);
    this.stakedTime += unstakedAtTimestamp - stakedAtTimestamp;
  }

  timestampToDate(timestamp: number): string {
    const endDate = new Date(timestamp * 1000);
    return endDate.toLocaleString();
  }

  formatEther(value: number | string): string {
    return formatEther(value);
  }

  formatTotalTime(): string {
    return formatTimestampInSecondsToDateInterval(this.stakedTime);
  }

  get activePoolRewards(): PoolReward[] {
    return this.$store.state.landlordStaking.activePoolRewards;
  }

  get pastPoolRewards(): PoolReward[] {
    return this.$store.state.landlordStaking.pastPoolRewards;
  }

  get windowInnerWidth(): number {
    return window.innerWidth;
  }

  @Watch('activePoolRewards')
  @Watch('pastPoolRewards')
  computeEligiblePoolRewards(): void {
    const poolRewards: PoolReward[] = [];
    for (const poolReward of this.pastPoolRewards) {
      if (isNFTEligibleForPoolReward(this.nft, poolReward)) {
        poolRewards.push(poolReward);
      }
    }
    for (const poolReward of this.activePoolRewards) {
      if (isNFTEligibleForPoolReward(this.nft, poolReward)) {
        poolRewards.push(poolReward);
      }
    }

    this.eligiblePoolRewards = poolRewards;
  }

  get canShowClaim(): boolean {
    return (
      this.showClaim &&
      (canNFTClaimRewardNow(this.nft, this.activePoolRewards) ||
        canNFTClaimRewardNow(this.nft, this.pastPoolRewards))
    );
  }

  get canShowUnstake(): boolean {
    return (
      this.showUnstake &&
      this.nft.stakingHistory[this.nft.stakingHistory.length - 1]
        .unstakedAtTimestamp === null
    );
  }

  getPoolRewardByIndex(index: number): PoolReward | null {
    // index from the contract, not the array index
    return (
      this.$store.state.landlordStaking.activePoolRewards.find(
        (poolReward: PoolReward) =>
          poolReward.collectionAddress === this.nft.collectionAddress &&
          poolReward.index === index
      ) ??
      this.$store.state.landlordStaking.pastPoolRewards.find(
        (poolReward: PoolReward) =>
          poolReward.collectionAddress === this.nft.collectionAddress &&
          poolReward.index === index
      ) ??
      null
    );
  }

  getPoolRewardName(poolReward: PoolReward | null): string {
    return poolReward ? getPoolRewardName(poolReward) : '';
  }

  unstakeNFT(): void {
    if (
      Date.now() / 1000 -
        this.nft.stakingHistory[this.nft.stakingHistory.length - 1]
          .stakedAtTimestamp <
      MIN_STAKED_TIME
    ) {
      this.$store.commit('app/setModal', {
        component: 'alertMessage',
        payload: {
          title: 'Unstaking Warning',
          message:
            "You didn't keep this token staked for the minimum required time of <b>30 days</b>.<br /> <br /> By unstaking now you won't be eligible for any pool rewards.",
          okMessage: 'Proceed',
          okAction: () =>
            this.$store.dispatch('unstakeNFT', {
              collectionAddress: this.nft.collectionAddress,
              tokenId: this.nft.tokenId,
            }),
        },
      });
      return;
    } else if (
      this.nft.claimedRewardHistory.length < this.eligiblePoolRewards.length
    ) {
      for (
        let i = this.nft.claimedRewardHistory.length;
        i <= this.eligiblePoolRewards.length - 1;
        i++
      ) {
        const timeDiff =
          this.eligiblePoolRewards[i].endTimestamp - Date.now() / 1000;
        if (timeDiff > 0) {
          this.$store.commit('app/setModal', {
            component: 'alertMessage',
            payload: {
              title: 'Unstaking Warning',
              message: `If you unstake this token now, you won't be eligible for the pool reward <b>${getPoolRewardName(
                this.eligiblePoolRewards[i]
              )}</b> of <b>${formatEther(
                this.eligiblePoolRewards[i].dystoReward
              )} $DYST</b>. <br /> <br /> If you wish to receive a part of the reward you need to keep the token staked for <b>${formatTimestampInSecondsToDateInterval(
                timeDiff
              )}</b>.`,
              okMessage: 'Proceed',
              okAction: () =>
                this.$store.dispatch('unstakeNFT', {
                  collectionAddress: this.nft.collectionAddress,
                  tokenId: this.nft.tokenId,
                }),
            },
          });
          return;
        }
      }
    }

    this.$store.commit('app/setModal', {
      component: 'alertMessage',
      payload: {
        title: 'Unstaking Warning',
        message: `Are you sure you want to unstake the token #${this.nft.tokenId}?`,
        okMessage: 'Proceed',
        okAction: () =>
          this.$store.dispatch('unstakeNFT', {
            collectionAddress: this.nft.collectionAddress,
            tokenId: this.nft.tokenId,
          }),
      },
    });
  }

  claimRewardNFT(): void {
    if (
      this.nft.claimedRewardHistory.length >= this.eligiblePoolRewards.length
    ) {
      this.$store.commit('app/setModal', {
        component: 'alertMessage',
        payload: {
          title: 'All rewards claimed',
          message: "You can't claim any more rewards for this NFT",
        },
      });
      return;
    }
    if (
      this.stakedTime -
        (this.nft.claimedRewardHistory.length + 1) * MIN_STAKED_TIME <=
      0
    ) {
      this.$store.commit('app/setModal', {
        component: 'alertMessage',
        payload: {
          title: 'Not enough time staked',
          message:
            'You need to keep the token more time at staking before claiming the next reward',
        },
      });
      return;
    }

    this.$store.dispatch('claimRewardNFT', {
      collectionAddress: this.nft.collectionAddress,
      tokenId: this.nft.tokenId,
    });
  }
}
