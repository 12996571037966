
import { Vue } from 'vue-property-decorator';

export default class confirmUnmount extends Vue {
  title = 'title';
  message = 'message';
  textAction = 'Confirm';
  actionHandler!: () => void;

  beforeMount(): void {
    this.title = this.$store.state.app.modal.payload.title;
    this.message = this.$store.state.app.modal.payload.message;
    this.textAction = this.$store.state.app.modal.payload.textAction;
    this.actionHandler = this.$store.state.app.modal.payload.actionHandler;
  }

  close(): void {
    this.$store.commit('app/setModal', { component: '', payload: null });
  }
}
