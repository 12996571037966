
import { Options, Prop, Vue, Watch } from 'vue-property-decorator';
import ExpandIcon from '../icons/expand.vue';
import DropdownOption from './dropdownOption.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

@Options({
  components: {
    ExpandIcon,
    DropdownOption,
    FontAwesomeIcon,
  },
})
export default class Dropdown extends Vue {
  @Prop(Array) readonly options!: any;
  @Prop(Object) readonly selected!: any;
  @Prop({ default: '' }) readonly extraClasses!: string;
  @Prop({ default: false }) readonly disabled!: boolean;
  @Prop({ default: 'dark' }) readonly theme!: string;

  background = 'bg-grey-2';
  selectedBackground = 'bg-grey-5';
  hoverBackground = 'hover:bg-grey-3';
  selectedHoverBackground = 'hover:bg-grey-4';
  disabledBackground = 'bg-grey-2';
  border = '';
  text = 'text-white';

  mounted(): void {
    this.updateTheme(this.theme);
  }

  @Watch('theme')
  onThemeChange(newTheme: string): void {
    this.updateTheme(newTheme);
  }

  updateTheme(newTheme: string): void {
    switch (newTheme) {
      case 'dark':
        this.background = 'bg-grey-2';
        this.selectedBackground = 'bg-grey-5';
        this.hoverBackground = 'hover:bg-grey-3';
        this.selectedHoverBackground = 'hover:bg-grey-4';
        this.disabledBackground = 'bg-grey-2';
        this.border = '';
        this.text = 'text-white';
        break;
      case 'light':
        this.background = 'bg-white-2';
        this.selectedBackground = 'bg-white-1';
        this.selectedHoverBackground = 'hover:bg-white-1';
        this.hoverBackground = 'hover:bg-white-3';
        this.border = 'border-white-3';
        this.text = 'text-black';
        break;
      default:
        this.background = 'bg-grey-2';
        this.selectedBackground = 'bg-grey-5';
        this.hoverBackground = 'hover:bg-grey-3';
        this.selectedHoverBackground = 'hover:bg-grey-4';
        this.disabledBackground = 'bg-grey-2';
        this.border = '';
        this.text = 'text-white';
        break;
    }
  }

  isOpen = false;

  triggerOpen(): void {
    if (this.disabled) return;
    this.isOpen = !this.isOpen;
  }

  selectOption(option: any): void {
    this.isOpen = false;
    this.$emit('selectOption', option);
  }
}
