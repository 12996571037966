export async function validateResponse(response: Response): Promise<any> {
  if (response.status === 401) {
    throw 'TOKEN';
  }

  if (!response.ok) {
    throw new Error('Response not ok');
  }

  const parseRes = await response.json();

  if (!parseRes) {
    throw new Error('Empty object');
  }

  return parseRes;
}
