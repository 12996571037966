import {
  ARBITRUM_CHAIN_ID,
  ARBITRUM_CHAIN_ID_HEX,
  ARBITRUM_NATIVE_CURRENCY_NAME,
  ARBITRUM_NETWORK,
  ARBITRUM_NETWORK_NAME,
  ETHEREUM_CHAIN_ID,
  ETHEREUM_CHAIN_ID_HEX,
  ETHEREUM_NATIVE_CURRENCY_NAME,
  ETHEREUM_NETWORK,
  ETHEREUM_NETWORK_NAME,
  SYSCOIN_CHAIN_ID,
  SYSCOIN_CHAIN_ID_HEX,
  SYSCOIN_NATIVE_CURRENCY_NAME,
  SYSCOIN_NETWORK_NAME,
} from '@/config';
import { INetwork } from '@/store/types/network';

export const ADDRESS_ZERO =
  '0x0000000000000000000000000000000000000000' as const;

export const getChainNameByChainId = (chainId: number): string => {
  switch (chainId) {
    case ETHEREUM_CHAIN_ID:
      return ETHEREUM_NETWORK_NAME;
    case ARBITRUM_CHAIN_ID:
      return ARBITRUM_NETWORK_NAME;
    default:
      return '';
  }
};

export const getChainNameByChainIdHex = (chainIdHex: string): string => {
  return getChainNameByChainId(parseInt(chainIdHex, 16));
};

export const getChainNativeCurrencyNameByChainId = (
  chainId: number
): string => {
  switch (chainId) {
    case ETHEREUM_CHAIN_ID:
      return ETHEREUM_NATIVE_CURRENCY_NAME;
    case ARBITRUM_CHAIN_ID:
      return ARBITRUM_NATIVE_CURRENCY_NAME;
    case SYSCOIN_CHAIN_ID:
      return SYSCOIN_NATIVE_CURRENCY_NAME;
    default:
      return '';
  }
};

export const getChainNativeCurrencyNameByChainIdHex = (
  chainIdHex: string
): string => {
  return getChainNativeCurrencyNameByChainId(parseInt(chainIdHex, 16));
};

export const getNetworkByChainId = (chainId: number): INetwork => {
  switch (chainId) {
    case ETHEREUM_CHAIN_ID:
      return ETHEREUM_NETWORK;
    case ARBITRUM_CHAIN_ID:
      return ARBITRUM_NETWORK;
    default:
      throw new Error(`Unsupported chainId: ${chainId} for web3`);
  }
};

export const getNetworkByChainIdHex = (chainIdHex: string): INetwork => {
  return getNetworkByChainId(parseInt(chainIdHex, 16));
};

export const getNetworkConnectionParamsByChainId = (chainId: number) => {
  switch (chainId) {
    case ETHEREUM_CHAIN_ID:
      return [
        {
          chainId: ETHEREUM_CHAIN_ID_HEX,
          chainName: ETHEREUM_NETWORK_NAME,
          nativeCurrency: {
            name: ETHEREUM_NATIVE_CURRENCY_NAME,
            symbol: ETHEREUM_NATIVE_CURRENCY_NAME,
            decimals: 18,
          },
          rpcUrls: ['https://ethereum-sepolia-rpc.publicnode.com/'],
          blockExplorerUrls: ['https://sepolia.etherscan.io'],
        },
      ];
    case ARBITRUM_CHAIN_ID:
      return [
        {
          chainId: ARBITRUM_CHAIN_ID_HEX,
          chainName: ARBITRUM_NETWORK_NAME,
          nativeCurrency: {
            name: ARBITRUM_NATIVE_CURRENCY_NAME,
            symbol: ARBITRUM_NATIVE_CURRENCY_NAME,
            decimals: 18,
          },
          rpcUrls:
            process.env.NODE_ENV === 'production'
              ? ['https://endpoints.omniatech.io/v1/arbitrum/one/public']
              : ['https://goerli-rollup.arbitrum.io/rpc'],
          blockExplorerUrls:
            process.env.NODE_ENV === 'production'
              ? ['https://arbiscan.io/']
              : ['https://goerli-rollup-explorer.arbitrum.io'],
        },
      ];
    case SYSCOIN_CHAIN_ID:
      return [
        {
          chainId: SYSCOIN_CHAIN_ID_HEX,
          chainName: SYSCOIN_NETWORK_NAME,
          nativeCurrency: {
            name: SYSCOIN_NATIVE_CURRENCY_NAME,
            symbol: SYSCOIN_NATIVE_CURRENCY_NAME,
            decimals: 18,
          },
          rpcUrls: ['https://rpc.syscoin.org'],
          blockExplorerUrls: ['https://explorer.syscoin.org/'],
        },
      ];
    default:
      throw new Error(`Unsupported chainId: ${chainId} for web3`);
  }
};

export const getNetworkConnectionParamsByChainIdHex = (chainIdHex: string) => {
  return getNetworkConnectionParamsByChainId(parseInt(chainIdHex, 16));
};
