
import { Vue, Options, Watch } from 'vue-property-decorator';
import Banner from '../../banner.vue';
import EllipsisIcon from '@/components/icons/ellipsis.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Dropdown from '../../newDropdown.vue';

enum dropdownType {
  CURRENCY = 'CURRENCY',
  BLOCKS = 'BLOCKS',
}

@Options({
  components: {
    Banner,
    Dropdown,
    EllipsisIcon,
    FontAwesomeIcon,
  },
})
export default class splitParcel extends Vue {
  editBool = false;
  picture_hover = false;
  copy_parcel = {};
  parcel = {} as any;
  image_parcel_data = '';
  file_parcel_form = {};
  isMulti = false;
  isSplit = false;
  hasInstanceMounted = false;
  link = '';

  options = [] as any;

  availableSizes = [
    {
      label: '1 x 1',
      value: 1,
    },
    {
      label: '3 x 3',
      value: 3,
    },
    {
      label: '6 x 6',
      value: 6,
    },
    {
      label: '12 x 12',
      value: 12,
    },
  ];
  splitTo = this.availableSizes[0];
  option_active = null;

  @Watch('parcel', { deep: true })
  watch_parcel(parcel: any) {
    if (parcel) {
      this.options = this.availableSizes.filter(
        (e) =>
          e.value < (this as any).parcel.size && (this as any).parcel.size > 1
      );
      if (this.options.length > 0) {
        this.option_active = this.options[0];
      }
    }
  }

  get wallet() {
    return this.$store.state.wallet;
  }

  updateOption(option: any): void {
    this.splitTo = option;
  }

  getSizes(): any[] {
    return this.availableSizes.filter((e) => e.value < this.parcel.size);
  }

  setModal(modal: any): void {
    this.$store.commit('app/setModal', { component: modal, payload: null });
  }

  close() {
    this.setModal('');
    Object.assign(this.parcel, this.copy_parcel);
  }

  save() {
    const details = {
      ...this.parcel,
      image: this.file_parcel_form || {},
      splitOption: null,
      opensea: '',
    };

    details.splitOption = this.splitTo;
    this.$store.dispatch('splitParcel', details);
    this.clearLastHovered();
    this.setModal('');
  }

  updateImage(event: any) {
    const files = event.target.files;
    const formData = new FormData();
    const reader = new FileReader();
    this.isMulti = true;

    if (!files.length) return;

    formData.append('image', files[0], files[0].name);

    this.file_parcel_form = formData;

    reader.onload = (e: any) => {
      this.image_parcel_data = e.target.result;
    };

    reader.readAsDataURL(files[0]);
  }

  changeImage() {
    const file = this.$refs.file as HTMLElement;
    file.click();
  }

  clearLastHovered() {
    this.$store.commit('app/setLastHovered', null);
  }

  async verify() {
    try {
      const verify = await this.$store.dispatch(
        'verify3DModel',
        (this as any).parcel.id
      );

      if (!verify) {
        throw new Error();
      }

      this.hasInstanceMounted = verify.hasInstanceMounted;
      this.link = verify.data.link;
    } catch (err) {
      // this.$store.commit("app/alterNotifications", { text: "The mount/edit 3D model operations are not  available", type: "danger", add: true });
    }
  }

  created() {
    this.parcel = Object.assign({}, this.$store.state.app.modal?.payload);
    this.copy_parcel = Object.assign({}, this.$store.state.app.modal?.payload);

    this.verify();
  }
}
