
import SteakIcon from '@/components/icons/steak.vue';
import { Options, Vue } from 'vue-property-decorator';

@Options({
  components: {
    SteakIcon,
  },
})
export default class PlusMenu extends Vue {
  get selectedNFTsForStaking(): Record<string, string[]> {
    return this.$store.state.landlordStaking.selectedNFTsForStaking;
  }

  get numberOfSelectedNFTs(): number {
    return Object.values(this.selectedNFTsForStaking).reduce(
      (acc, curr) => acc + curr.length,
      0
    );
  }

  onStake(): void {
    this.$store.dispatch('onStakeSelectedNFTs');
  }

  onUnstakeAll(): void {
    this.$store.commit('app/setModal', {
      component: 'alertMessage',
      payload: {
        title: 'Unstaking Warning',
        message:
          'You will unstake all your nfts from every collection. You will receive rewards from eligible pool rewards that have ended if you kept your token staked for at least 30 days, otherwise you will lose those rewards. Are you sure you want to unstake all your nfts?',
        okMessage: 'I understand',
        okAction: () => this.$store.dispatch('unstakeBulkNFTs'),
      },
    });
  }
}
