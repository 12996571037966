
import {
  NFTOptionInfo,
  PoolData,
  StakedNFTInfo,
} from '@/store/types/landlordStaking';
import { formatEther } from '@/utils';
import { Options, Prop, Vue, Watch } from 'vue-property-decorator';
import GenericHeader from './genericHeader.vue';

@Options({
  components: {
    GenericHeader,
  },
})
export default class BulkHeader extends Vue {
  @Prop() layout!: string;
  showCopied = false;
  imageUrl: string | null = null;
  isDefaultAvatar = false;

  @Watch('user', { immediate: true })
  onUserChange(): void {
    if (!this.user) return;
    // TODO: Figure this out
    /* fetch(`${API_URL}/users/${this.user.address}/ens`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }).then((res) =>
      res.json().then((data) => {
        this.ens = data.ens;
      })
    ); */
  }

  get user(): any {
    return this.$store.state.user;
  }

  get poolData(): PoolData[] {
    const poolData = [];
    // Things are done this way to achieve a certain order
    if (this.layout === 'per-pool') {
      poolData.push({
        title: 'Number of NFTs:',
        value: `${this.nftsOwned.length} NFTs collected`,
        component: 'NFTIcon',
      });
    } else {
      poolData.push({
        title: 'Dysto reward claimed:',
        value: this.poolDystoRewardClaimed,
        icon: 'fa-solid fa-gift',
      });
    }

    poolData.push({
      title: 'Number of collections:',
      value: `${this.distinctCollections}
            Collections ${this.layout === 'per-pool' ? 'collected' : 'staked'}`,
      icon: 'fa-solid fa-bars',
    });

    poolData.push({
      title: 'Number of staked NFTs:',
      value: `${this.nftsStaked.length} NFTS staked`,
      icon: 'fa-solid fa-layer-group',
    });

    return poolData;
  }

  @Watch('user', { immediate: true, deep: true })
  getImageUrl(): void {
    if (this.user && this.user.avatar && this.user.avatar.length > 10)
      this.imageUrl = this.user.avatar;
    this.isDefaultAvatar = true;
    this.imageUrl = null;
  }

  get nftsOwned(): NFTOptionInfo[] {
    return this.$store.state.landlordStaking.nftsOwned;
  }

  get nftsStaked(): StakedNFTInfo[] {
    return this.$store.state.landlordStaking.stakedNFTs;
  }

  get distinctCollections(): number {
    const collections: string[] = [];
    this.nftsOwned.forEach((nft) => {
      if (!collections.includes(nft.collectionAddress)) {
        collections.push(nft.collectionAddress);
      }
    });

    this.nftsStaked.forEach((nft) => {
      if (!collections.includes(nft.collectionAddress)) {
        collections.push(nft.collectionAddress);
      }
    });

    return collections.length;
  }

  get poolDystoRewardClaimed(): string {
    let result = 0;
    let count = 0;

    this.nftsStaked
      .filter((nft) => nft.claimedRewardHistory.length)
      .forEach(({ claimedRewardHistory }) => {
        claimedRewardHistory.forEach(({ reward }) => {
          result += parseInt(reward);
          count++;
        });
      });

    return `${formatEther(result)} $DYSTO (${count} entries)`;
  }
}
