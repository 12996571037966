
import { Vue, Options, Prop, Watch } from 'vue-property-decorator';
import Notification from '@/components/shared/notification.vue';

@Options({
  components: {
    Notification,
  },
})
export default class Notifications extends Vue {
  @Prop({ default: true }) leftPadding!: boolean;

  array = null;
  get notifications() {
    return this.$store.state.app.notifications;
  }

  get optionBar() {
    return this.$store.state.app.Option_Bar;
  }

  @Watch('notifications', { deep: true })
  notifications_watcher(n: any) {
    this.$nextTick(() => {
      this.array = n;
    });
    if (
      n.length &&
      n[n.length - 1].expiration <= Math.floor(new Date().getTime() / 1000)
    )
      this.array = null;
  }
}
