
import { Vue } from 'vue-property-decorator';
import eventBus from '@/eventBus';
import { API_URL } from '@/config';

export default class Vesting extends Vue {

  initialLoading = true;

  vestingData: any = {};

  claimableTokens = 0;

  isLoggedIn = false;

  hasUserLinearVesting = false;
  isLinearVestingInitialized = false;

  hasUserEomVesting = false;
  isEomVestingInitialized = false;

  ///////

  get formattedClaimableTokens() {
    // Assuming claimableTokens are in the smallest unit and need to be converted to DYSTO
    return (this.claimableTokens / Math.pow(10, 18)).toFixed(6);
  }

  async created() {
    setTimeout(async () => {
      await this.init();
      this.initialLoading = false;
    }, 500);
  }

  async init() {

    try {
      this.isLoggedIn = (this.$store.state.user !== null);
      if (!this.isLoggedIn) {
        return;
      }

      eventBus.on('logout', this.handleLogout);

      await this.$store.dispatch('getIsVestingBeneficiary');

      this.vestingData = this.$store.state.user.vestingBeneficiary;
      console.log('this.vestingData ', this.vestingData);

      if (this.vestingData && this.vestingData.length > 2) {
        console.log('Vesting data length should not be greater than two');
        return;
      }

      this.hasUserLinearVesting = this.vestingData?.some((vesting: { monthlyUnlockPercentage: number; }) => vesting.monthlyUnlockPercentage == 0);
      console.log('this.hasUserLinearVesting ', this.hasUserLinearVesting);
      if (this.hasUserLinearVesting) {
        this.isLinearVestingInitialized =
          await this.$store.state.dystoWorldVestingContract.methods.isLinearBeneficiaryInitialized(this.$store.state.user.address).call();
        console.log('this.isLinearVestingInitialized ', this.isLinearVestingInitialized);
      }

      this.hasUserEomVesting = this.vestingData?.some((vesting: { monthlyUnlockPercentage: number; }) => vesting.monthlyUnlockPercentage > 0);
      console.log('this.hasUserEomVesting ', this.hasUserEomVesting);
      if (this.hasUserEomVesting) {
        this.isEomVestingInitialized =
          await this.$store.state.dystoWorldVestingContract.methods.isEOMBeneficiaryInitialized(this.$store.state.user.address).call();
        console.log('this.isLinearVestingInitialized ', this.isEomVestingInitialized);
      }

      this.claimableTokens = await this.$store.state.dystoWorldVestingContract.methods.calculateClaimableTokens(this.$store.state.user.address).call();
      console.log('this.claimableTokens ', this.claimableTokens);
    } catch (err) {
      console.log(err);
    }
  }

  beforeDestroy() {
    eventBus.detach('logout', this.handleLogout);
  }

  handleLogout() {
    this.$router.push('/');
    this.isLoggedIn = false;
  }

  async initializeInContract(isLiniar: boolean) {

    console.log('initializeInContract -- isLiniar ' + isLiniar);

    let appendMonthlyUnlockPercentage = '0';
    if (!isLiniar) {
      const ob = this.vestingData.find((vesting: { monthlyUnlockPercentage: number; }) => vesting.monthlyUnlockPercentage > 0) || null;
      if (ob != null) {
        appendMonthlyUnlockPercentage = String(ob.monthlyUnlockPercentage);
      }
    }

    let payload = null;
    try {
      const getSignature = await fetch(`${API_URL}/vesting/create_signature/${this.$store.state.user.address}/${appendMonthlyUnlockPercentage}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${await this.$store.dispatch('returnToken')}`,
        },
      });
      const getSignatureResponse = await getSignature.json();
      if (getSignatureResponse && getSignatureResponse.signature) {
        payload = getSignatureResponse;
      }
    } catch (err) {
      console.log(err);
    }

    if (payload == null) {
      console.warn('Something wrong when creating signature.. Cannot continue');
      return;
    }

    this.$store.commit('app/setModal', {
      component: 'initializeInVestingContract',
      fromComponent: 'influencer',
      payload: {
        signature: payload?.signature,
        initialRelease: payload?.initialRelease,
        vestedAmount: payload?.vestedAmount,
        vestingStartTime: payload?.vestingStartTime,
        vestingEndTime: payload?.vestingEndTime,
        monthlyUnlockPercentage: payload?.monthlyUnlockPercentage,
        exitCallback: async () => {
          await this.init();
        },
      },
    });
  }

  async claimTokens() {
    this.$store.commit('app/setModal', {
      component: 'claimInVestingContract',
      fromComponent: 'influencer',
      payload: {
        exitCallback: async () => {
          await this.init();
        },
      },
    });
  }
}
