
import { Vue } from 'vue-property-decorator';
import eventBus from '@/eventBus';
import { API_URL } from '@/config';

export default class InfluencerBar extends Vue {

  currentProgressStep = 0;
  userSelectedStep = 0;
  dystoBalance = 0;
  sharesSupply = 0;

  freeLandReservedOrMinted = false;

  initialLoading = true;

  rooms = 0;

  onStepClicked(stepNumber: number) {
    this.userSelectedStep = stepNumber;
    eventBus.emit('step-clicked', null, stepNumber);
  }

  async created() {
    setTimeout(async () => {
      await this.init();
      this.initialLoading = false;
    }, 500);
  }

  async init() {
    try {
      await this.$store.dispatch('checkFreeLandReservedOrMinted');
      await this.$store.dispatch('getOwnedInstances');
      await this.$store.dispatch('getDystoSpacesTokens');
      await this.$store.dispatch('getAllParcels');
      this.currentProgressStep = this.$store.state.user?.influencerWizardData?.step;
      this.userSelectedStep = this.currentProgressStep;
      this.dystoBalance = Number(await this.$store.dispatch('getUserDystoWorldTokenBalance'));
      this.sharesSupply = await this.$store.state.dystoWorldKeypadContract.methods.sharesSupply(this.$store.state.user.address).call();
      this.freeLandReservedOrMinted = this.$store.state.user?.freeLandReservedOrMinted;
      this.rooms = this.$store.state.spaces?.mount?.spaceAssets?.length;
    } catch (err) {
      console.log(err);
    }
  }

  get twitterUserData() {
    return this.$store.state.user?.twitterData;
  }
}
