import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "gallery-slideshow-container flex center-center" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image) => {
      return (_openBlock(), _createElementBlock("div", {
        key: image,
        class: "gallery-slideshow-image"
      }, [
        _createElementVNode("img", {
          className: "slide-image",
          src: image
        }, null, 8, _hoisted_2)
      ]))
    }), 128)),
    _createElementVNode("button", {
      class: "button display-left pointer",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addToImageIndex(-1)))
    }, " ❮ "),
    _createElementVNode("button", {
      class: "button display-right pointer",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addToImageIndex(1)))
    }, " ❯ ")
  ]))
}