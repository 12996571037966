
import { Prop, Vue } from 'vue-property-decorator';

export default class DropdownOption extends Vue {
  @Prop(String) readonly text!: string;
  @Prop(Object) readonly icon: any;
  @Prop(Boolean) readonly isSelected!: any;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly isFirst!: boolean;
  @Prop(Boolean) readonly isLast!: boolean;
  @Prop(Object) readonly theme!: any;

  onClick(): void {
    if (this.disabled) return;
    this.$emit('clicked');
  }
}
