
import { Vue } from 'vue-property-decorator';

export default class VestingBar extends Vue {

  async created() {
    setTimeout(async () => {
      await this.init();
    }, 500);
  }

  async init() {

  }

}
