
import { Vue, Options } from 'vue-property-decorator';
import editProfile from './components/editProfile.vue';
import editParcel from './components/editParcel.vue';
import parcelDetails from './components/parcelDetails.vue';
import splitParcel from './components/splitParcel.vue';
import alertMessage from './components/alertMessage.vue';
import CanvasHelp from './components/canvasHelp.vue';
import confirmUnmount from './components/cofirmUnmount.vue';
import ConnectWallet from './components/connectWallet.vue';
import stakeNftFromSpace from './components/stakeNftFromSpace.vue';
import GallerySlideshow from './components/gallerySlideshow.vue';
import mintTokensLaunchpadCollection from './components/mintTokensLaunchpadCollection.vue';
import buyDysto from './components/buyDysto.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import createKeypadCollection from '@/components/shared/modals/components/createKeypadCollection.vue';
import estimateKeyPrice from '@/components/shared/modals/components/estimateKeyPrice.vue';
import keypadOwnCollectionTradesHistory from '@/views/KeypadOwnCollectionTradesHistory.vue';
import tradeKeypadShare from '@/components/shared/modals/components/tradeKeypadShare.vue';
import initializeInVestingContract from '@/components/shared/modals/components/initializeInVestingContract.vue';
import claimInVestingContract from '@/components/shared/modals/components/claimInVestingContract.vue';


@Options({
  components: {
    editProfile,
    editParcel,
    parcelDetails,
    splitParcel,
    alertMessage,
    CanvasHelp,
    confirmUnmount,
    ConnectWallet,
    stakeNftFromSpace,
    GallerySlideshow,
    mintTokensLaunchpadCollection,
    buyDysto,
    initializeInVestingContract,
    claimInVestingContract,
    createKeypadCollection,
    estimateKeyPrice,
    keypadOwnCollectionTradesHistory,
    tradeKeypadShare,
    FontAwesomeIcon,
  },
})
export default class Modal extends Vue {
  get modal() {
    return this.$store.state.app.modal;
  }

  setModal(modal: any) {
    const currentModalComponent: any = this.$refs.currentModal;
    if (modal === '' && currentModalComponent && currentModalComponent.loading) {
      return;
    }
    this.$store.commit('app/setModal', { component: modal, payload: null });
  }
}
