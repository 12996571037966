
import { Vue, Options } from 'vue-property-decorator';
import Banner from '../../banner.vue';

@Options({
  components: {
    Banner,
  },
})
export default class editProfile extends Vue {
  editBool = false;
  copy_user = {};
  user: any = {};
  picture_hover = false;
  banner_hover = false;
  image_profile_data = '';
  banner_profile_data = '';
  file_profile_form = {};
  file_banner_form = {};
  image_component = '';

  setModal(modal: any) {
    this.$store.commit('app/setModal', { component: modal, payload: null });
  }

  close() {
    this.setModal('');
    Object.assign(this.user, this.copy_user);
  }

  discard() {
    this.editBool = !this.editBool;
    Object.assign(this.user, this.copy_user);
  }

  edit() {
    this.editBool = !this.editBool;
  }

  save() {
    const details = {
      ...this.user,
      'avatar-img': this.file_profile_form || '',
      'banner-img': this.file_banner_form || '',
    };

    this.$store.dispatch('updateUser', details);
    this.setModal('');
  }

  updateImage(event: any) {
    const files = event.target.files;
    const formData = new FormData();
    const reader = new FileReader();

    if (!files.length) return;

    formData.append('image', files[0], files[0].name);

    switch (this.image_component) {
      case 'profile':
        this.file_profile_form = formData;
        break;
      case 'banner':
        this.file_banner_form = formData;
        break;
    }

    reader.onload = (e: any) => {
      switch (this.image_component) {
        case 'profile':
          this.image_profile_data = e.target.result;
          break;
        case 'banner':
          this.banner_profile_data = e.target.result;
          break;
      }

      this.image_component = '';
    };

    reader.readAsDataURL(files[0]);
  }

  changeImage(component: string) {
    this.image_component = component;

    const file = this.$refs.file as HTMLElement;
    file.click();
  }

  created() {
    this.user = Object.assign({}, this.$store.state.user);
    this.copy_user = Object.assign({}, this.$store.state.user);
  }
}
