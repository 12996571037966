import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "label flex-1" }
const _hoisted_2 = { class: "icon center-center" }
const _hoisted_3 = { class: "dropdown-list-container" }
const _hoisted_4 = {
  key: 0,
  class: "dropdown-list"
}
const _hoisted_5 = { class: "label flex-1" }
const _hoisted_6 = { class: "icon center-center" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "label flex-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExpandIcon = _resolveComponent("ExpandIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`dropdown disable-select ${_ctx.extraClasses || ''}`)
  }, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setBool())),
      class: "dropdown-button flex items-center"
    }, [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.active_option.label), 1),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_ExpandIcon)
      ])
    ]),
    (_ctx.bool)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setBool())),
          class: "dropdown-list-background"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.bool)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setBool())),
              class: "dropdown-button flex items-center"
            }, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.active_option.label), 1),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_ExpandIcon)
              ])
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, id) => {
              return _withDirectives((_openBlock(), _createElementBlock("div", {
                onClick: ($event: any) => (_ctx.setOption(option)),
                key: id,
                class: "dropdown-list-button flex items-center"
              }, [
                _createElementVNode("div", _hoisted_8, _toDisplayString(option.label), 1)
              ], 8, _hoisted_7)), [
                [_vShow, option.label !== _ctx.active_option.label]
              ])
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}