
import { Vue, Options, Prop, Watch } from 'vue-property-decorator';
import { GenericAssetInfo, SpacePlaceholder } from '@/store/types/spaces';
import { debounce } from 'lodash';
import Dropdown from '@/components/shared/dropdown.vue';
import Paginate from '@/components/shared/paginate.vue';
import { API_URL } from '@/config';
import { toast, ToastOptions } from 'vue3-toastify';

@Options({ components: { Dropdown, Paginate } })
export default class EditPlaceholderAsset extends Vue {
  @Prop(String) readonly currentMeshName!: string;
  @Prop() readonly currentMesh!: any;
  @Prop(Boolean) readonly showEditPage!: boolean;
  @Prop(Function) readonly setShowEditPageToFalse!: () => void;
  @Prop() readonly placeholders!: SpacePlaceholder[];
  @Prop() readonly assetsPlaced!: any[];
  @Prop() readonly instanceOwner!: string;
  @Prop(Function) readonly isOwner!: () => boolean;
  loading = true;
  connected = false;
  filteredAssets: GenericAssetInfo[] | null = null;
  assetValue: GenericAssetInfo | null = null;
  pages = { active: 1, total: 1 };
  assetsPerPage = { label: '10', value: 10 };
  indexOfLastAsset = this.pages.active * this.assetsPerPage.value;
  indexOfFirstAsset = this.indexOfLastAsset - this.assetsPerPage.value;
  aiContext = '';
  aiGreeting = '';

  activeTab = 'nfts';
  uploadedImage = null;
  uploadedImageMimeType = '';

  async beforeMount(): Promise<void> {
    await this.$store.dispatch('getUserAssets');
    this.connected = true;
    this.loading = false;
  }

  @Watch('showEditPage')
  onShowEditPage(): void {
    if (!this.showEditPage) {
      this.changeInputEvent({ target: { value: '' } });
    } else {
      this.aiContext = this.currentMesh.aiContext;
      this.aiGreeting = this.currentMesh.aiGreeting;
      if (this.activeTab === 'context' && !this.currentMesh.name) {
        this.activeTab = 'nfts';
      }
    }
  }

  get user(): any {
    return this.$store.state.user;
  }

  @Watch('user')
  async onUserChanged(value: any): Promise<void> {
    if (value) {
      this.loading = true;
      this.connected = false;
      await this.$store.dispatch('getUserAssets');
      this.connected = true;
      this.loading = false;
    }
  }

  async forceGetAssets(): Promise<void> {
    this.loading = true;
    await this.$store.dispatch('getUserAssets', true);
    this.loading = false;
  }

  get assets(): GenericAssetInfo[] {
    return this.$store.state.spaces.ownedAssets;
  }

  get paginatedAssets(): GenericAssetInfo[] {
    return this.assets.slice(this.indexOfFirstAsset, this.indexOfLastAsset);
  }

  @Watch('assets')
  onAssetsChanged(): void {
    this.assetsPerPageOptions[this.assetsPerPageOptions.length - 1].value =
      this.assets.length;
    this.pages.total = Math.ceil(this.assets.length / this.assetsPerPage.value);
  }

  isAssetPlaced(asset: any): boolean {
    return (
      this.assetsPlaced.findIndex(
        (assetPlaced) =>
          assetPlaced.assetContract === asset.assetContract &&
          assetPlaced.tokenId === asset.tokenId
      ) !== -1
    );
  }

  updatePage(page: number): void {
    this.pages.active = page;
    this.indexOfLastAsset = this.pages.active * this.assetsPerPage.value;
    this.indexOfFirstAsset = this.indexOfLastAsset - this.assetsPerPage.value;
  }

  updateAssetsPerPage(option: any): void {
    this.assetsPerPage = option;
    this.pages.active = 1;
    this.pages.total = Math.ceil(this.assets.length / this.assetsPerPage.value);
    this.indexOfLastAsset = this.pages.active * this.assetsPerPage.value;
    this.indexOfFirstAsset = this.indexOfLastAsset - this.assetsPerPage.value;
  }

  cancelEdit(): void {
    this.assetValue = null;
    const event = new CustomEvent('cancel', {
      detail: {
        imageUrl: this.currentMesh.imageUrl,
        meshName: this.currentMeshName,
        placeholderId: this.currentMesh._id
          ? this.currentMesh._id
          : this.currentMesh.placeholderId
          ? this.currentMesh.placeholderId
          : null,
      },
    });
    window.dispatchEvent(event);
  }

  debouncedChangeInputEvent = debounce(this.changeInputEvent, 300);

  changeInputEvent(event: any): void {
    this.updateFilterOptions(event.target.value);
    if (event.target.value.length === 0) {
      this.filteredAssets = null;
    }
  }

  updateFilterOptions(filterValue: string): void {
    let newOptions = this.assets.filter((option) => {
      return (
        option.name &&
        (option.collectionName
          .toLowerCase()
          .includes(filterValue.toLowerCase()) ||
          option.name.toLowerCase().includes(filterValue.toLowerCase()))
      );
    });
    this.filteredAssets = newOptions;
  }

  onSelectAsset(asset: GenericAssetInfo): void {
    this.assetValue = asset;
    const event = new CustomEvent('updatePlaceholder', {
      detail: {
        name: asset.name,
        animationUrl: asset.animationUrl,
        assetContract: asset.assetContract,
        collectionName: asset.collectionName,
        collectionImageUrl:
          asset.collectionImageUrl !== null
            ? asset.collectionImageUrl
            : asset.previewUrl,
        description: asset.description,
        tokenId: asset.tokenId,
        imageUrl: asset.imageUrl,
        imageOriginalUrl: asset.imageOriginalUrl,
        previewUrl: asset.previewUrl,
        instanceId: window.location.pathname.split('/')[2],
        meshName: this.currentMeshName,
        placeholderId: this.currentMesh._id
          ? this.currentMesh._id
          : this.currentMesh.placeholderId
          ? this.currentMesh.placeholderId
          : null,
        aiContext: this.aiContext,
      },
    });
    window.dispatchEvent(event);
    this.assetValue = null;
  }

  handleFileUpload(event: any) {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      this.uploadedImage = file;
      // Optionally, read and preview the image here
    }
  }

  async submitUpload() {
    if (!this.uploadedImage) {
      alert('No image selected!');
      return;
    }

    let imageUrl = '';

    try {
      const uploadCustomImageRequest =
        await fetch(`${API_URL}/keypad/upload_custom_placeholder_image`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${await this.$store.dispatch('returnToken')}`,
          },
          body: JSON.stringify({
            img: this.uploadedImage,
            imgMimeType: this.uploadedImageMimeType
          }),
        });

      const res = await uploadCustomImageRequest.json();
      if (res && !res.error) {
        imageUrl = res.value[0].location;
      } else {
        toast.error('Oops! Unable to set placeholder. If the issue persists, contact support.', {
          hideProgressBar: true,
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
        } as ToastOptions);
        return;
      }
    } catch (err) {
      console.log(err);
    }

    const event = new CustomEvent('updatePlaceholder', {
      detail: {
        name: 'custom-image-placeholder',
        animationUrl: '',
        assetContract: '0xE169aD75A8DC7EbF8F04111Ca9Db71b415A55Fd8',
        collectionName: '-',
        collectionImageUrl: '-',
        description: '-',
        tokenId: (Math.random().toString(36) + Math.random().toString(36)).slice(-20),
        imageUrl: imageUrl,
        imageOriginalUrl: '-',
        previewUrl: '-',
        instanceId: window.location.pathname.split('/')[2],
        meshName: this.currentMeshName,
        placeholderId: this.currentMesh._id
          ? this.currentMesh._id
          : this.currentMesh.placeholderId
            ? this.currentMesh.placeholderId
            : null,
        aiContext: this.aiContext,
      },
    });
    window.dispatchEvent(event);

    this.uploadedImage = null;
    this.uploadedImageMimeType = '';

    this.setShowEditPageToFalse();
  }

  changeUploadedImage() {
    console.log('changeUploadedImage');
    const file = this.$refs.file as HTMLElement;
    file.click();
  }

  updateImage(event: any) {
    const files = event.target.files;
    const reader = new FileReader();

    if (!files.length) {
      return;
    }

    const sizeInBytes = 4 * 1024 * 1024; // Convert MB to bytes

    if (files[0].size > sizeInBytes) {
      this.$store.commit('app/setModal', {
        component: 'alertMessage',
        fromComponent: 'influencer',
        payload: {
          title: 'Cannot use this image',
          message:
            `Maximum image size is of 4 MB`,
        },
      });
      return;
    }

    this.uploadedImageMimeType = files[0].type;

    reader.onload = (e: any) => {
      this.uploadedImage = e.target.result;
    };

    reader.readAsDataURL(files[0]);
  }

  saveContext() {
    if (!this.currentMesh.name) {
      return;
    }

    if (this.currentMesh.name != "custom-image-placeholder") {
      const event = new CustomEvent('updatePlaceholder', {
        detail: {
          name: this.currentMesh.name,
          animationUrl: this.currentMesh.animationUrl,
          assetContract: this.currentMesh.assetContract,
          collectionName: this.currentMesh.collectionName,
          collectionImageUrl:
            this.currentMesh.collectionImageUrl !== null
              ? this.currentMesh.collectionImageUrl
              : this.currentMesh.previewUrl,
          description: this.currentMesh.description,
          tokenId: this.currentMesh.tokenId,
          imageUrl: this.currentMesh.imageUrl,
          imageOriginalUrl: this.currentMesh.imageOriginalUrl,
          previewUrl: this.currentMesh.previewUrl,
          instanceId: window.location.pathname.split('/')[2],
          meshName: this.currentMeshName,
          placeholderId: this.currentMesh._id
            ? this.currentMesh._id
            : this.currentMesh.placeholderId
              ? this.currentMesh.placeholderId
              : null,
          aiContext: this.aiContext,
          aiGreeting: this.aiGreeting,
        },
      });
      window.dispatchEvent(event);
    } else {
      const event = new CustomEvent('updatePlaceholder', {
        detail: {
          name: 'custom-image-placeholder',
          animationUrl: '',
          assetContract: '0xE169aD75A8DC7EbF8F04111Ca9Db71b415A55Fd8',
          collectionName: '-',
          collectionImageUrl: '-',
          description: '-',
          tokenId: (Math.random().toString(36) + Math.random().toString(36)).slice(-20),
          imageUrl: this.currentMesh.imageUrl,
          imageOriginalUrl: '-',
          previewUrl: '-',
          instanceId: window.location.pathname.split('/')[2],
          meshName: this.currentMeshName,
          placeholderId: this.currentMesh._id
            ? this.currentMesh._id
            : this.currentMesh.placeholderId
              ? this.currentMesh.placeholderId
              : null,
          aiContext: this.aiContext,
          aiGreeting: this.aiGreeting,
        },
      });
      window.dispatchEvent(event);
    }
  }

  assetsPerPageOptions = [
    {
      label: '10',
      value: 10,
    },
    {
      label: '15',
      value: 15,
    },
    {
      label: '20',
      value: 20,
    },
    {
      label: '25',
      value: 25,
    },
    {
      label: 'All',
      value: 0,
    },
  ];
}
