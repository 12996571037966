import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex flex-col absolute bottom-3 right-5 z-10 space-y-3 pointer-events-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuOption = _resolveComponent("MenuOption")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["absolute inset-0 z-20 block lg:hidden", {
      'pointer-events-auto bg-black bg-opacity-50': _ctx.showModal,
      'pointer-events-none': !_ctx.showModal,
    }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal = null))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createBlock(_component_MenuOption, {
          key: option.name,
          name: option.name,
          icon: option.icon,
          faIcon: option.faIcon,
          component: option.component,
          isOpen: _ctx.showModal === option.name,
          isOtherOpen: _ctx.showModal !== null && _ctx.showModal !== option.name,
          onClick: (event) => _ctx.setModal(event, option.name)
        }, null, 8, ["name", "icon", "faIcon", "component", "isOpen", "isOtherOpen", "onClick"]))
      }), 128))
    ])
  ], 2))
}