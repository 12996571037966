
import { Vue, Options } from 'vue-property-decorator';
import StakingNFTs from '../../../../default/optionbars/stakingNFTs.vue';

@Options({
  components: {
    StakingNFTs,
  },
})
export default class LaunchpadBar extends Vue {}
