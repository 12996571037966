
import { Vue, Options, Prop } from 'vue-property-decorator';
import ChevronIcon from '@/components/icons/chevron.vue';

@Options({ components: { ChevronIcon } })
export default class Paginate extends Vue {
  @Prop(Number) readonly value!: number;
  @Prop(Number) readonly pageCount!: number;
  @Prop(Number) readonly forcePage!: number;
  @Prop({
    type: Function,
    default: () => {
      return;
    },
  })
  readonly clickHandler!: (page: number) => void;
  @Prop({ type: Number, default: 3 }) readonly pageRange!: number;
  @Prop({ type: Number, default: 1 }) readonly marginPages!: number;
  @Prop({ type: String, default: '...' }) readonly breakViewText!: string;
  @Prop({ type: Boolean, default: false }) readonly firstLastButton!: boolean;
  @Prop({ type: Boolean, default: false }) readonly hidePrevNext!: boolean;
  innerValue = 1;
  selected = this.value || this.innerValue;

  beforeUpdate(): void {
    if (this.forcePage === undefined) return;
    if (this.forcePage !== this.selected) {
      this.selected = this.forcePage;
    }
  }

  get pages(): any[] {
    let items: any = {};
    if (this.pageCount <= this.pageRange) {
      for (let index = 0; index < this.pageCount; index++) {
        let page = {
          index: index,
          content: index + 1,
          selected: index === this.selected - 1,
        };
        items[index] = page;
      }
    } else {
      const halfPageRange = Math.floor(this.pageRange / 2);

      let setPageItem = (index: any) => {
        let page = {
          index: index,
          content: index + 1,
          selected: index === this.selected - 1,
        };

        items[index] = page;
      };

      let setBreakView = (index: any) => {
        let breakView = {
          disabled: true,
          breakView: true,
        };

        items[index] = breakView;
      };

      for (let i = 0; i < this.marginPages; i++) {
        setPageItem(i);
      }

      let selectedRangeLow = 0;
      if (this.selected - halfPageRange > 0) {
        selectedRangeLow = this.selected - 1 - halfPageRange;
      }

      let selectedRangeHigh = selectedRangeLow + this.pageRange - 1;
      if (selectedRangeHigh >= this.pageCount) {
        selectedRangeHigh = this.pageCount - 1;
        selectedRangeLow = selectedRangeHigh - this.pageRange + 1;
      }

      for (
        let i = selectedRangeLow;
        i <= selectedRangeHigh && i <= this.pageCount - 1;
        i++
      ) {
        setPageItem(i);
      }

      if (selectedRangeLow > this.marginPages) {
        setBreakView(selectedRangeLow - 1);
      }

      if (selectedRangeHigh + 1 < this.pageCount - this.marginPages) {
        setBreakView(selectedRangeHigh + 1);
      }

      for (
        let i = this.pageCount - 1;
        i >= this.pageCount - this.marginPages;
        i--
      ) {
        setPageItem(i);
      }
    }
    return items;
  }

  handlePageSelected(selected: number): void {
    if (this.selected === selected) return;

    this.innerValue = selected;
    this.selected = selected;
    this.clickHandler(selected);
  }

  prevPage(): void {
    if (this.selected <= 1) return;

    this.handlePageSelected(this.selected - 1);
  }

  nextPage(): void {
    if (this.selected >= this.pageCount) return;

    this.handlePageSelected(this.selected + 1);
  }
}
