
import { Emit, Options, Prop, Vue } from 'vue-property-decorator';
import { NFTOptionInfo } from '@/store/types/landlordStaking';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

@Options({
  components: {
    FontAwesomeIcon,
  },
})
export default class NFTCard extends Vue {
  @Prop() nftOption!: NFTOptionInfo;
  @Prop() isSelected!: boolean;
  @Prop() collectionName!: string;
  @Prop() disabled!: boolean;

  @Emit('selected')
  toggleNFT(id: string): string {
    return id;
  }
}
