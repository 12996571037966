
import { Options, Vue } from 'vue-property-decorator';
import MenuOption from './menuOption.vue';
import SteakIcon from '@/components/icons/steak.vue';

@Options({
  components: {
    MenuOption,
    SteakIcon,
  },
})
export default class MenuModal extends Vue {
  options = [
    {
      name: 'Dials',
      icon: 'DialsIcon',
      component: 'DialsMenu',
    },
    {
      name: 'Plus',
      faIcon: 'fa-solid fa-plus',
      component: 'PlusMenu',
    },
  ];

  setModal(event: MouseEvent, modal: string): void {
    event.stopPropagation();
    this.showModal = this.showModal ? null : modal;
  }

  showModal: string | null = null;
}
