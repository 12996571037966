
import { Vue, Options, Watch } from 'vue-property-decorator';
import LaunchpadBar from '../default/optionbars/launchpad.vue';
import PortalBar from '../default/optionbars/portal.vue';
import StakingNFTsBar from '../default/optionbars/stakingNFTs.vue';
import Logo from '../icons/logo.vue';
import BarNavigation from './barnavigation.vue';
import InfluencerBar from '@/components/default/optionbars/influencerBar.vue';
import KeypadBar from '@/components/default/optionbars/keypad.vue';
import VestingBar from '@/components/default/optionbars/vestingBar.vue';

@Options({
  components: {
    LaunchpadBar,
    KeypadBar,
    InfluencerBar,
    PortalBar,
    StakingNFTsBar,
    Logo,
    BarNavigation,
    VestingBar
  },
})
export default class OptionBar extends Vue {
  showOptionBar = false;

  get bar() {
    switch (this.active) {
      case 'Launchpad':
        return 'LaunchpadBar';

      case 'LaunchpadSlug':
        return 'LaunchpadBar';

      case 'Portal':
        return 'PortalBar';

      case 'StakingNFTs':
        return 'StakingNFTsBar';

      case 'StakingNFTsSlug':
        return 'StakingNFTsBar';

      case 'Influencer':
        return 'InfluencerBar';

      case 'KeypadOwnCollectionTradesHistory':
        return 'InfluencerBar';

      case 'KeypadTradesSharesHistory':
        return 'KeypadBar';

      case 'Keypad':
        return 'KeypadBar';

      case 'Vesting':
        return 'VestingBar';

      default:
        return '';
    }

  }

  get optionBar() {
    return this.$store.state.app.Option_Bar;
  }

  get breakpoint() {
    return this.$route.meta.layout !== 'Staking' ? 768 : 1024;
  }

  @Watch('optionBar')
  onOptionBarChange() {
    this.showOptionBar = this.optionBar || window.innerWidth > this.breakpoint;
  }

  created(): void {
    window.addEventListener('resize', this.windowResizeHandler);
    this.showOptionBar = this.optionBar || window.innerWidth > this.breakpoint;
  }

  destroyed(): void {
    window.removeEventListener('resize', this.windowResizeHandler);
  }

  windowResizeHandler(): void {
    this.showOptionBar = this.optionBar || window.innerWidth > this.breakpoint;

    if (window.innerWidth > this.breakpoint) {
      this.$store.commit('app/setOptionBar', false);
    }
  }

  get active() {
    return this.$route.name;
  }
}
