
import { Options, Prop, Vue, Watch } from 'vue-property-decorator';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { isUrl } from '@/utils';

@Options({
  components: {
    FontAwesomeIcon,
  },
})
export default class SpotlightCollections extends Vue {
  @Prop({ default: [] }) readonly collections!: any[];
  currentSlide = 0;

  getCollectionsPerSlide(): number {
    let perSlide;
    switch (true) {
      case window.innerWidth < 600:
        perSlide = 1;
        break;
      case window.innerWidth < 900:
        perSlide = 2;
        break;
      case window.innerWidth < 1400:
        perSlide = 3;
        break;
      default:
        perSlide = 4;
        break;
    }

    return perSlide;
  }

  changeSlide(direction: number): void {
    const perSlide = this.getCollectionsPerSlide();
    let slidesToMove = 0;
    if (direction > 0) {
      slidesToMove = Math.min(
        perSlide,
        this.collections.length - this.currentSlide - perSlide
      );
    } else {
      slidesToMove = Math.min(perSlide, this.currentSlide) * -1;
    }

    if (slidesToMove === 0) {
      this.currentSlide = direction > 0 ? 0 : this.collections.length - perSlide;
      return;
    }

    this.currentSlide += slidesToMove;
  }

  checkLocalImage(image: string): boolean {
    return !isUrl(image);
  }
}
