
import { Vue, Prop } from 'vue-property-decorator';

export default class Notification extends Vue {
  @Prop(Object) readonly notification!: any;
  @Prop(Number) readonly id!: number;

  interval = null as any;
  now = Math.floor(new Date().getTime() / 1000);

  mounted(): void {
    this.interval = setInterval(() => {
      const expiration = this.notification.expiration;
      this.now = Math.floor(new Date().getTime() / 1000);
      if (expiration < this.now) {
        clearInterval(this.interval);
        this.dismissNotification();
      } else {
        this.notification.time = expiration - this.now;
      }
    }, 200);
  }

  dismissNotification(): void {
    this.$store.dispatch('app/dismissNotification', {
      ...this.notification,
      id: this.id,
    });
  }
}
