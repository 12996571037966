import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Notification = _resolveComponent("Notification")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["notifications flex-col", _ctx.leftPadding && _ctx.optionBar ? 'notifications-padding' : ''])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.array, (notification, id) => {
      return (_openBlock(), _createBlock(_component_Notification, {
        key: id,
        id: id,
        notification: notification
      }, null, 8, ["id", "notification"]))
    }), 128))
  ], 2))
}