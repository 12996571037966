import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "title" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", {
      class: "text margin-left-right-0",
      innerHTML: _ctx.message
    }, null, 8, _hoisted_2),
    _createElementVNode("div", {
      class: _normalizeClass(["actions", _ctx.okAction ? 'flex' : ''])
    }, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
        class: "action discard center-center"
      }, "Close"),
      (_ctx.okAction)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onOkAction && _ctx.onOkAction(...args))),
            class: "action edit center-center"
          }, _toDisplayString(_ctx.okMessage), 1))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}