import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_DropdownOption = _resolveComponent("DropdownOption")!

  return (_ctx.options.length)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(`rounded-md flex flex-1 justify-between items-center text-center relative overflow-visible ${
      _ctx.extraClasses || ''
    } ${_ctx.isOpen ? 'rounded-b-none' : ''} ${
      _ctx.disabled ? 'cursor-default' : 'cursor-pointer'
    } ${_ctx.background} ${_ctx.text}`)
      }, [
        _createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.triggerOpen && _ctx.triggerOpen(...args))),
          class: _normalizeClass(["w-full flex items-center justify-between pl-2 h-9", {
        'border-2': _ctx.border.length,
        'rounded-md': !_ctx.isOpen,
        'rounded-t-md border-b-0': _ctx.isOpen,
        [_ctx.hoverBackground]: !_ctx.disabled,
        [_ctx.background]: true,
        [_ctx.border]: _ctx.border.length,
        [_ctx.text]: true,
      }])
        }, [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.selected.icon))),
            _createElementVNode("div", {
              class: _normalizeClass(["text-sm font-semibold select-none mr-2", {
            [_ctx.text]: !_ctx.disabled,
            'text-grey-4': _ctx.disabled,
          }])
            }, _toDisplayString(_ctx.selected.label), 3)
          ]),
          (!_ctx.isOpen)
            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 0,
                icon: "fa-solid fa-chevron-down",
                class: _normalizeClass(["h-1/2 w-auto pr-2", {
          [_ctx.text]: !_ctx.disabled,
          'text-grey-4': _ctx.disabled,
        }])
              }, null, 8, ["class"]))
            : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 1,
                icon: "fa-solid fa-chevron-up",
                class: _normalizeClass(`h-1/2 w-auto pr-2 ${_ctx.text}`)
              }, null, 8, ["class"]))
        ], 2),
        (_ctx.isOpen)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["w-full max-h-64 absolute left-0 right-0 top-0 transform translate-y-9 z-50 overflow-y-auto", {
        'rounded-b-md': true,
        [_ctx.background]: true,
      }])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, id) => {
                return (_openBlock(), _createBlock(_component_DropdownOption, {
                  key: id,
                  text: option.label,
                  icon: option.icon,
                  isSelected: option.value === _ctx.selected.value,
                  onClicked: ($event: any) => (_ctx.selectOption(option)),
                  isFirst: false,
                  isLast: id === _ctx.options.length - 1,
                  theme: {
          background: _ctx.background,
          selectedBackground: _ctx.selectedBackground,
          hoverBackground: _ctx.hoverBackground,
          selectedHoverBackground: _ctx.selectedHoverBackground,
          disabledBackground: _ctx.disabledBackground,
          border: _ctx.border,
          text: _ctx.text,
        }
                }, null, 8, ["text", "icon", "isSelected", "onClicked", "isLast", "theme"]))
              }), 128))
            ], 2))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}