import { createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["disabled", "content"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tippy = _resolveDirective("tippy")!

  return _withDirectives((_openBlock(), _createElementBlock("button", {
    disabled: _ctx.disabled,
    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
    class: "image-button",
    content: _ctx.tooltipText
  }, [
    _createElementVNode("img", {
      src: require(`@/assets/images/${_ctx.imageSrc}`),
      onMouseover: _cache[0] || (_cache[0] = ($event: any) => (!_ctx.disabled ? (_ctx.imageSrc = _ctx.imageNameOnHover) : null)),
      onMouseout: _cache[1] || (_cache[1] = ($event: any) => (_ctx.imageSrc = _ctx.imageName))
    }, null, 40, _hoisted_2)
  ], 8, _hoisted_1)), [
    [_directive_tippy, { placement: 'top' }]
  ])
}