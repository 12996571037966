
import { Vue } from 'vue-property-decorator';

export default class alertMessage extends Vue {
  title = 'title';
  message = 'message';
  okMessage = '';
  okAction: (() => void) | null = null;

  beforeMount(): void {
    this.title = this.$store.state.app.modal.payload.title;
    this.message = this.$store.state.app.modal.payload.message;
    this.okMessage = this.$store.state.app.modal.payload.okMessage;
    this.okAction = this.$store.state.app.modal.payload.okAction;
  }

  close(): void {
    this.$store.commit('app/setModal', { component: '', payload: null });
  }

  onOkAction(): void {
    this.close();
    if (this.okAction) {
      this.okAction();
    }
  }
}
