
import CollapseIcon from '@/components/icons/collapse.vue';
import ExpandIcon from '@/components/icons/expand.vue';
import StarIcon from '@/components/icons/star.vue';
import StickerIcon from '@/components/icons/sticker.vue';
import StreakIcon from '@/components/icons/streak.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Options, Vue, Watch } from 'vue-property-decorator';

@Options({
  components: {
    StarIcon,
    StickerIcon,
    StreakIcon,
    CollapseIcon,
    ExpandIcon,
    FontAwesomeIcon,
  },
})
export default class LaunchpadCategory extends Vue {
  get collections() {
    return this.$store.state.launchpad.collections;
  }

  @Watch('collections', { deep: true, immediate: true })
  collections_watch() {
    this.collectionsSubcategoriesArray.forEach((subcategory: any) => {
      subcategory.links = this.collections[subcategory.name].map(
        (collection: any) => {
          return {
            id: collection.id,
            name: collection.name,
            subcategory: subcategory.name,
            active: false,
          };
        }
      );
    });

    const allCollectionsIndex = this.collectionsSubcategoriesArray.findIndex(
      (subcategory: any) => subcategory.name === 'all'
    );

    if (allCollectionsIndex !== -1) {
      this.collectionsSubcategoriesArray[allCollectionsIndex].links =
        this.collections.all.map((collection: any) => {
          return {
            id: collection.id,
            name: collection.name,
            subcategory: 'all',
            active: false,
          };
        });
    }

    const slug = this.$route.params.slug as string;

    if (slug) {
      const collection = this.collections.all.find(
        (collection: any) => collection.slug === slug
      );
      if (collection) this.changeCurrentCollection(collection.id);
      else if (this.collections.all.length > 0) {
        this.$router.push('/launchpad');
      }
    } else if (this.collections.featured.length > 0) {
      this.changeCurrentCollection(this.collections.featured[0].id);
    }
  }

  changeCurrentCollection(newId: string): void {
    let subcategoryName = '';

    this.collectionsSubcategoriesArray.forEach((subcategory: any) => {
      subcategory.links.forEach((link: any) => {
        link.active = newId === link.id;
        if (link.active) subcategoryName = link.subcategory;
      });
    });

    const collection = this.collections[subcategoryName].find(
      (collection: any) => collection.id === newId
    );
    if (collection) {
      this.$store.commit('setCurrentCollection', collection);
      this.$router.push(`/launchpad/${collection.slug}`);
    }
  }

  collectionsSubcategoriesArray: any = [
    {
      icon: 'fa-solid fa-star',
      label: 'Featured',
      name: 'featured',
      open: true,
      links: [],
    },
    {
      icon: 'fa-solid fa-fire-flame-curved',
      label: 'Hottest',
      name: 'hottest',
      open: false,
      links: [],
    },
    {
      icon: 'fa-solid fa-square-plus',
      label: 'Newest',
      name: 'newest',
      open: false,
      links: [],
    },
    {
      icon: 'fa-solid fa-cubes',
      label: 'All collections',
      name: 'all',
      open: false,
      links: [],
    },
  ];
}
