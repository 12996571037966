
import { Vue, Options, Prop } from 'vue-property-decorator';
import Title from '@/components/basic/title.vue';
import CollapseIcon from '@/components/icons/collapse.vue';
import ExpandIcon from '@/components/icons/expand.vue';

@Options({ components: { Title, CollapseIcon, ExpandIcon } })
export default class AccordionTitle extends Vue {
  @Prop(String) readonly title!: string;
  @Prop(Boolean) readonly open!: boolean;
  @Prop(Function) readonly changeOpen!: () => void;
}
