
import { Vue } from 'vue-property-decorator';

export default class GallerySlideshow extends Vue {
  images = [];
  slideIndex = 1;

  beforeMount(): void {
    this.images = this.$store.state.app.modal.payload.images;
  }

  mounted(): void {
    this.showImageAtSlideIndex(this.slideIndex);
  }

  close(): void {
    this.$store.commit('app/setModal', { component: '', payload: null });
  }

  addToImageIndex(n: number): void {
    this.showImageAtSlideIndex((this.slideIndex += n));
  }

  showImageAtSlideIndex(n: number): void {
    let imagesElements: any;
    imagesElements = document.getElementsByClassName('slide-image');
    if (n > imagesElements.length) {
      this.slideIndex = 1;
    }
    if (n < 1) {
      this.slideIndex = imagesElements.length;
    }
    for (let i = 0; i < imagesElements.length; i++) {
      imagesElements[i].style.display = 'none';
    }
    imagesElements[this.slideIndex - 1].style.display = 'block';
  }
}
