import { Notification } from '@/utils/interfaces';
import store from '..';

export const app = {
  namespaced: true,
  state: {
    modal: {
      component: '',
      payload: null,
    },
    mintingParcels: null,
    Minting_Parcels: false,
    Owned_Parcels: false,
    Option_Bar: false,
    parcel_coordinates: {
      x: 0,
      y: 0,
    },
    lastHovered: null,
    MergedParcel: {
      variable: null,
      selected: null,
    },
    portalFilters: {
      size: [1, 3, 6, 12, 24],
      type: [1, 2],
      chainId: [-1],
      Owned_By_Me: false,
      sizeIsOpen: false,
      typeIsOpen: false,
      chainIsOpen: false,
    },
    notifications: [],
    loaders: {
      reserve: false,
      mint: {
        id: '',
        bool: false,
      },
    },
    forceBigNavbarLogo: false,
  },
  mutations: {
    setModal(state: any, modal: any) {
      state.modal = modal;
    },
    setMintingParcels(state: any, payload: any) {
      state.mintingParcels = payload;
    },
    setMintingParcelsSidebar(state: any, payload: any) {
      state.Minting_Parcels = payload;
    },
    setOwnedParcelsSidebar(state: any, payload: any) {
      state.Owned_Parcels = payload;
    },
    setOptionBar(state: any, payload: any) {
      state.Option_Bar = payload;
    },
    setParcelCoordinates(state: any, payload: any) {
      state.parcel_coordinates = payload;
    },
    setLastHovered(state: any, parcel: any) {
      state.lastHovered = parcel;
    },
    setCursorCoordinates(state: any, payload: any) {
      state.cursorCoordinates = payload;
    },
    setPortalFilters(state: any, filters: any) {
      state.portalFilters = filters;
    },
    setMergedParcel(state: any, { variable, selected }: any) {
      state.MergedParcel.variable = variable;
      state.MergedParcel.selected = selected;
    },
    alterNotifications(state: any, notification: any) {
      if (notification.remove) {
        state.notifications.splice(notification.id, 1);
      } else if (notification.add) {
        const defaultExpiration = Math.floor(
          (new Date().getTime() + 5000) / 1000
        );
        const existingNotification = state.notifications.find(
          (n: Notification) =>
            n.text === notification.text && n.type === notification.type
        );
        if (existingNotification) {
          existingNotification.timestamp = Date.now();
          state.notifications.splice(
            state.notifications.indexOf(existingNotification),
            1
          );
          state.notifications.push({
            ...existingNotification,
            expiration: defaultExpiration,
          });
        } else {
          if (state.notifications.length >= 3) {
            state.notifications.shift();
          }
          state.notifications.push({
            text: notification.text,
            type: notification.type,
            expiration: defaultExpiration,
            time: '',
          });
        }
      }
    },
    setLoaders(state: any, loaders: any) {
      state.loaders = loaders;
    },
    setForceBigNavbarLogo(state: any, payload: boolean) {
      state.forceBigNavbarLogo = payload;
    },
  },
  actions: {
    dismissNotification({ commit }: any, notification: Notification) {
      commit('alterNotifications', { ...notification, remove: true });
    },
  },
};
