
import { Options, Vue } from 'vue-property-decorator';
import Category from './portalCategory.vue';
import Switch from './switch.vue';

@Options({
  components: {
    Category,
    Switch,
  },
})
export default class PortalBar extends Vue {
  get Minting_Parcels() {
    return this.$store.state.app.Minting_Parcels;
  }
  get Owned_Parcels() {
    return this.$store.state.app.Owned_Parcels;
  }
  get Owned_By_Me() {
    return this.portalFilters.Owned_By_Me;
  }
  get portalFilters() {
    return this.$store.state.app.portalFilters;
  }
  get user() {
    return this.$store.state.user;
  }

  get lastHovered() {
    return this.$store.state.app.lastHovered;
  }

  get cursorCoordinates() {
    return this.$store.state.app.cursorCoordinates;
  }

  toggleSwitch(variable: string) {
    switch (variable) {
      case 'Minting_Parcels':
        this.$store.commit(
          'app/setMintingParcelsSidebar',
          !this.Minting_Parcels
        );
        break;
      case 'Owned_Parcels':
        this.$store.commit('app/setOwnedParcelsSidebar', !this.Owned_Parcels);
        break;
      case 'Owned_By_Me':
        this.$store.commit('app/setPortalFilters', {
          ...this.portalFilters,
          Owned_By_Me: !this.Owned_By_Me,
        });
        break;
    }
  }

  toggleFilterOpen(filter: string): void {
    switch (filter) {
      case 'size':
        this.$store.commit('app/setPortalFilters', {
          ...this.portalFilters,
          sizeIsOpen: !this.portalFilters.sizeIsOpen,
        });
        break;
      case 'type':
        this.$store.commit('app/setPortalFilters', {
          ...this.portalFilters,
          typeIsOpen: !this.portalFilters.typeIsOpen,
        });
        break;
      case 'chainId':
        this.$store.commit('app/setPortalFilters', {
          ...this.portalFilters,
          chainIsOpen: !this.portalFilters.chainIsOpen,
        });
        break;
    }
  }

  addFilter(event: number, filter: string): void {
    switch (filter) {
      case 'size': {
        let newSize = this.portalFilters.size;
        if (newSize.includes(event)) {
          newSize = newSize.filter((e: number) => e !== event);
        } else {
          newSize.push(event);
        }

        this.$store.commit('app/setPortalFilters', {
          ...this.portalFilters,
          size: newSize,
        });
        return;
      }
      case 'type': {
        let newType = this.portalFilters.type;
        if (newType.includes(event)) {
          newType = newType.filter((e: number) => e !== event);
        } else {
          newType.push(event);
        }

        this.$store.commit('app/setPortalFilters', {
          ...this.portalFilters,
          type: newType,
        });
        return;
      }
      case 'chainId': {
        let newChainId = this.portalFilters.chainId;
        if (newChainId.includes(event)) {
          newChainId = newChainId.filter((e: number) => e !== event);
        } else {
          newChainId.push(event);
        }

        this.$store.commit('app/setPortalFilters', {
          ...this.portalFilters,
          chainId: newChainId,
        });
        return;
      }
    }
  }
}
