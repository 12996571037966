
import { Vue, Prop, Options } from 'vue-property-decorator';
import CollapseIcon from '../icons/collapse.vue';
import ExpandIcon from '../icons/expand.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

@Options({
  components: {
    CollapseIcon,
    ExpandIcon,
    FontAwesomeIcon,
  },
})
export default class OwnedParcel extends Vue {
  @Prop(Object) readonly parcel!: any;
  @Prop(Number) readonly id!: any;

  hasInstanceMounted: boolean | null = null;
  linkToInstance = '';

  get wallet() {
    return this.$store.state.wallet;
  }

  setModal(modal: any, payload?: any): void {
    this.$store.commit('app/setModal', { component: modal, payload });
  }

  openModal(): void {
    if (this.parcel.owner === this.wallet)
      this.setModal('editParcel', this.parcel);
    else this.setModal('parcelDetails', this.parcel);
  }

  splitParcel(): void {
    if (this.parcel.size == 1) return;
    this.setModal('splitParcel', this.parcel);
  }
}
