
import { def } from '@vue/shared';
import { Vue, Prop, Options, Emit, Watch } from 'vue-property-decorator';
import ExpandIcon from '../icons/expand.vue';

@Options({
  components: {
    ExpandIcon,
  },
})
export default class Dropdown extends Vue {
  @Prop(String) readonly label!: string;
  @Prop(Array) readonly options!: any;
  @Prop(Object) readonly active!: any;
  @Prop(String) readonly extraClasses!: string;

  active_option = this.active;

  bool = false;

  setBool() {
    this.bool = !this.bool;
  }

  @Emit()
  changeOption() {
    this.setBool();
    return this.active_option;
  }

  @Watch('active')
  onActiveChangeExternal() {
    this.active_option = this.active;
  }

  setOption(option: any) {
    this.active_option = option;
    this.changeOption();
  }
}
