
import { Vue, Options, Prop } from 'vue-property-decorator';
import { TippyComponent } from 'vue-tippy';

@Options({ components: { TippyComponent } })
export default class ImageButton extends Vue {
  @Prop(Boolean) disabled!: boolean;
  @Prop(Function) onClick!: () => void;
  @Prop(String) imageName!: string;
  @Prop(String) imageNameOnHover: string = this.imageName;
  @Prop(String) tooltipText!: string;
  imageSrc = this.imageName;
}
