
import { Vue, Options, Watch } from 'vue-property-decorator';
import Loader from '@/components/icons/loaderStakingNftModal.vue';
import Web3 from 'web3';
import BigNumber from 'bignumber.js';
import { ADDRESS_ZERO } from '@/utils';

@Options({
  components: { Loader },
})
export default class mintTokensLaunchpadCollection extends Vue {
  message = '';
  insufficientBalance = false;
  needToApprove = true;
  allowanceTx = false;

  async beforeMount(): Promise<void> {
    await this.initModal();
  }

  get payload(): any {
    return this.$store.state.app.modal.payload;
  }

  get chainId(): number {
    return this.$store.state.chainId;
  }

  get waitingForConfirmation(): boolean {
    return this.$store.state.landlordStaking.waitForConfirmationTrigger;
  }

  @Watch('waitingForConfirmation')
  async onWaitingForConfirmationChange(value: boolean): Promise<void> {
    if (!this.allowanceTx || value) {
      return;
    }

    this.allowanceTx = false;
    await this.initModal();
  }

  async initModal(): Promise<void> {
    const tokenContractAddress = this.payload.mintParams.find(
      (mp: any) => mp.key === 'paymentMethod'
    ).value;
    const price = new BigNumber(
      this.payload.mintParams.find((mp: any) => mp.key === 'price').value
    );

    // check balance & allowance for ERC20 payment method
    if (tokenContractAddress !== ADDRESS_ZERO && price.gt(0)) {
      const balance = await this.$store.dispatch('getERC20Balance', {
        tokenContractAddress: tokenContractAddress,
      });
      if (balance.lt(price)) {
        this.insufficientBalance = true;
        this.computeInsufficientBalance(balance, price);
        return;
      }
      this.insufficientBalance = false;

      const allowance = await this.$store.dispatch('getERC20Allowance', {
        tokenContractAddress: tokenContractAddress,
        collectionAddress: this.payload.collectionAddress,
      });
      if (allowance.lt(price)) {
        this.needToApprove = true;
        this.computeAllowMessage();
        return;
      }
    }

    this.needToApprove = false;
    this.computeMintMessage();
  }

  computeInsufficientBalance(balance: BigNumber, price: BigNumber): void {
    const tokenContractAddress = this.payload.mintParams.find(
      (mp: any) => mp.key === 'paymentMethod'
    ).value;
    const currency = this.payload.paymentMethods.find(
      (pm: any) => pm.tokenContractAddress === tokenContractAddress
    ).tokenName;
    this.message = `You don't have enough ${currency} for mint. The price is ${Web3.utils.fromWei(
      price.toString()
    )} ${currency} and you only have ${Web3.utils.fromWei(
      balance.toString()
    )} ${currency}.`;
  }

  computeAllowMessage(): void {
    const numTokens = this.payload.mintParams.find(
      (mp: any) => mp.key === 'numTokens'
    ).value;
    const price = this.payload.mintParams.find(
      (mp: any) => mp.key === 'price'
    ).value;
    const tokenContractAddress = this.payload.mintParams.find(
      (mp: any) => mp.key === 'paymentMethod'
    ).value;
    const currency = this.payload.paymentMethods.find(
      (pm: any) => pm.tokenContractAddress === tokenContractAddress
    ).tokenName;
    this.message = `Before minting ${numTokens} token${
      numTokens > 1 ? 's' : ''
    } using ${currency}, you need to allow the transfer of ${Web3.utils.fromWei(
      price
    )} ${currency}`;
  }

  computeMintMessage(): void {
    const numTokens = this.payload.mintParams.find(
      (mp: any) => mp.key === 'numTokens'
    ).value;
    const price = this.payload.mintParams.find(
      (mp: any) => mp.key === 'price'
    ).value;
    const tokenContractAddress = this.payload.mintParams.find(
      (mp: any) => mp.key === 'paymentMethod'
    ).value;
    const currency = this.payload.paymentMethods.find(
      (pm: any) => pm.tokenContractAddress === tokenContractAddress
    ).tokenName;
    this.message = `You are about to mint ${numTokens} token${
      numTokens > 1 ? 's' : ''
    } at the price of ${Web3.utils.fromWei(price)} ${currency}`;
  }

  close(): void {
    this.$store.commit('app/setModal', { component: '', payload: null });
  }

  async onProceed(): Promise<void> {
    if (this.needToApprove) {
      await this.onProceedAllow();
    } else {
      await this.onProceedMint();
    }
  }

  async onProceedAllow(): Promise<void> {
    this.allowanceTx = true;
    const tokenContractAddress = this.payload.mintParams.find(
      (mp: any) => mp.key === 'paymentMethod'
    ).value;
    const price = this.payload.mintParams.find(
      (mp: any) => mp.key === 'price'
    ).value;
    await this.$store.dispatch('approveTransferOfERC20Tokens', {
      ...this.payload,
      tokenContractAddress: tokenContractAddress,
      price: price,
    });
  }

  async onProceedMint(): Promise<void> {
    if (this.payload.chainId !== this.chainId) {
      this.$store.dispatch('requestChangeOfChain', this.payload.chainId);
      return;
    }

    const tokenContractAddress = this.payload.mintParams.find(
      (mp: any) => mp.key === 'paymentMethod'
    ).value;
    const price = this.payload.mintParams.find(
      (mp: any) => mp.key === 'price'
    ).value;
    await this.$store.dispatch('mintTokensForLaunchpadCollection', {
      ...this.payload,
      mintParams: this.payload.mintParams,
      tokenContractAddress: tokenContractAddress,
      price: price,
    });
    this.close();
  }
}
