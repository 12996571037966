
import { Options, Vue } from 'vue-property-decorator';

import MetamaskIcon from '@/components/icons/metamask.vue';
import WalletConnectIcon from '@/components/icons/walletconnect.vue';

@Options({
  components: {
    MetamaskIcon,
    WalletConnectIcon,
  },
})
export default class ConnectWallet extends Vue {
  close(): void {
    this.$store.commit('app/setModal', { component: '', payload: null });
  }

  connect(action: string): void {
    this.$store.dispatch(action);
    this.close();
  }
}
