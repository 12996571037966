import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full h-20 p-3 flex justify-start space-x-4 items-center" }
const _hoisted_2 = { class: "h-full w-auto flex justify-center items-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "flex flex-col justify-between flex-1 h-full" }
const _hoisted_6 = { class: "text-highlight-green font-semibold text-base border-b-2 border-highlight-green w-fit leading-4" }
const _hoisted_7 = { class: "text-white font-semibold text-base" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.parcel.image && _ctx.parcel.image.length > 0)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.parcel?.image + '_0?lastmod=' + _ctx.parcel.timestamp,
            class: "h-full rounded-md w-auto"
          }, null, 8, _hoisted_3))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: 
          require(`@/assets/images/parcel_size_${
            '' + (_ctx.parcel.size || 'null')
          }.png`)
        ,
            class: "h-full rounded-md w-auto"
          }, null, 8, _hoisted_4))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.parcel.title), 1),
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.parcel.size) + "x" + _toDisplayString(_ctx.parcel.size) + " - (" + _toDisplayString(_ctx.parcel.startX) + ", " + _toDisplayString(_ctx.parcel.startY) + ") ", 1)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["h-fit p-2 flex items-center justify-center rounded-md text-white", {
        'bg-grey-8 text-grey-9': _ctx.parcel.size == 1,
        'bg-grey-6 cursor-pointer text-white': _ctx.parcel.size > 1,
      }]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.splitParcel && _ctx.splitParcel(...args)))
    }, [
      _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-arrows-split-up-and-left" })
    ], 2),
    _createElementVNode("div", {
      class: "w-fit h-fit px-4 py-2 font-semibold text-sm select-none uppercase bg-grey-6 rounded-md cursor-pointer text-white",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openModal && _ctx.openModal(...args)))
    }, " Edit ")
  ]))
}