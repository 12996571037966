
import { Vue, Options, Watch } from 'vue-property-decorator';
import LaunchpadCategory from './launchpadCategory.vue';
import Switch from './switch.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import KeypadCategory from '@/components/default/optionbars/keypadCategory.vue';

@Options({
  components: {
    KeypadCategory,
    FontAwesomeIcon,
  },
})
export default class KeypadBar extends Vue {
  collectionSearchName = '';
  isLoggedIn = false;

  beforeCreate() {
    this.isLoggedIn = (this.$store.state.user !== null);
    this.$store.dispatch('getKeypadCollections');
  }

  get user(): any {
    return this.$store.state.user;
  }

  @Watch('collectionSearchName')
  changeCollectionSearchString(value: string): void {
    this.$store.commit('setKeypadCollectionSearchName', value);
    this.$store.dispatch('getKeypadCollections');
  }

  @Watch('user', { deep: true })
  changeUser(): void {
    this.$store.dispatch('getKeypadCollections');
    this.isLoggedIn = (this.$store.state.user !== null);
  }

  beforeDestroy(): void {

  }
}
