
import { Vue, Options, Prop, Watch } from 'vue-property-decorator';
import { StakedNFTInfo } from '@/store/types/landlordStaking';
import AccordionTitle from '@/components/shared/accordionTitle.vue';
import StakedNFTView from '@/components/shared/stakedNFTView.vue';

@Options({
  components: {
    AccordionTitle,
    StakedNFTView,
  },
})
export default class StakingNFTsForCollection extends Vue {
  @Prop({ default: false }) isDisabled!: boolean;
  @Prop(String) collectionAddressFilter!: string;
  viewsOpen = {
    currentlyStaked: true,
    previouslyStaked: true,
  };

  @Watch('collectionAddressFilter')
  onCollectionAddressFilterChange(): void {
    this.resetViewsOpen();
  }

  resetViewsOpen(): void {
    this.viewsOpen.currentlyStaked = true;
    this.viewsOpen.previouslyStaked = true;
  }

  get currentlyStakedNFTs(): StakedNFTInfo[] {
    if (this.collectionAddressFilter === 'all') {
      return this.$store.state.landlordStaking.currentlyStakedNFTs;
    } else {
      return this.$store.state.landlordStaking.currentlyStakedNFTs.filter(
        (nft: StakedNFTInfo) =>
          nft.collectionAddress === this.collectionAddressFilter
      );
    }
  }

  get previouslyStakedNFTs(): StakedNFTInfo[] {
    if (this.collectionAddressFilter === 'all') {
      return this.$store.state.landlordStaking.previouslyStakedNFTs;
    } else {
      return this.$store.state.landlordStaking.previouslyStakedNFTs.filter(
        (nft: StakedNFTInfo) =>
          nft.collectionAddress === this.collectionAddressFilter
      );
    }
  }
}
