import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GenericHeader = _resolveComponent("GenericHeader")!

  return (_openBlock(), _createBlock(_component_GenericHeader, {
    title: _ctx.poolTitle,
    imageUrl: _ctx.poolReward.imageUrl,
    defaultAvatar: false,
    layout: _ctx.layout,
    address: _ctx.poolReward?.collectionAddress || '',
    poolData: _ctx.poolData
  }, null, 8, ["title", "imageUrl", "layout", "address", "poolData"]))
}