
import { Vue } from 'vue-property-decorator';
import { toast, ToastOptions } from 'vue3-toastify';
import { getNetworkByChainId } from '@/utils/blockchain';
import { API_URL } from '@/config';
import BigNumber from 'bignumber.js';

export default class createKeypadCollection extends Vue {

  exitCallback: (() => void) | null = null;

  isLoading = false;

  dystoBalance = 0;
  keypadCollection = {name: "", shortDescription: "", longDescription: "", banner: ""}
  message = '';
  buySharesTxHash = '';


  beforeMount(): void {
    this.exitCallback = this.$store.state.app.modal.payload.exitCallback;
    this.dystoBalance = this.$store.state.app.modal.payload.dystoBalance;
    this.keypadCollection = this.$store.state.app.modal.payload.keypadCollection;
    this.message = this.$store.state.app.modal.payload.message;
  }

  close(): void {
    this.$store.commit('app/setModal', { component: '', payload: null });
  }

  async createCollection() {

    try {
      this.isLoading = true;

      this.buySharesTxHash = '';

      const boughtFirstKey = await this.buyFirstKey();
      if (!boughtFirstKey) {
        throw new Error();
      }

      const savedCollectionInBackend = await this.createCollectionInBackend();
      if (!savedCollectionInBackend) {
        throw new Error();
      }

      this.purchaseSuccessfulToast();
      this.close();
      if (this.exitCallback) {
        this.exitCallback();
      }
    } catch (error) {
      this.purchaseFailedToast();
    } finally {
      this.isLoading = false;
    }
  }


  async buyFirstKey() {
    try {
      const dystoTokenContract = this.$store.state.dystoWorldTokenContract;
      const keypadContract = this.$store.state.dystoWorldKeypadContract;

      const userAddress = this.$store.state.user.address;
      const amountToBuy = 1;

      // Get the price for 1 share in DYSTO
      const priceInDysto = await keypadContract.methods.getBuyPriceAfterFee(userAddress, amountToBuy).call();

      const network = getNetworkByChainId(this.$store.state.chainId);

      const allowance: string = await dystoTokenContract.methods.allowance(userAddress, network.DYSTO_KEYPAD_CONTRACT_ADDRESS).call();

      // Approve the DYSTO transfer
      if (new BigNumber(allowance).lt(new BigNumber(priceInDysto))) {
        await dystoTokenContract.methods.approve(network.DYSTO_KEYPAD_CONTRACT_ADDRESS, priceInDysto).send({ from: userAddress });
      }

      await new Promise((resolve, reject) => {
        keypadContract.methods.buyShares(userAddress, amountToBuy)
          .send({ from: userAddress })
          .on('transactionHash', (hash: string) => {
            this.buySharesTxHash = hash;
          })
          .on('receipt', (receipt: unknown) => {
            resolve(receipt);
          })
          .on('error', (error: any) => {
            console.error('Transaction error:', error);
            reject(error);
          });
      });

      return true;
    } catch (error) {
      console.error('Error buying first key:', error);
      return false;
    }
  }

  async createCollectionInBackend() {
    try {
      const keypadCollectionDataResponse =
        await fetch(`${API_URL}/keypad/create_keypad_collection`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${await this.$store.dispatch('returnToken')}`,
          },
          body: JSON.stringify({
            name: this.keypadCollection.name,
            shortDescription: this.keypadCollection.shortDescription,
            longDescription: this.keypadCollection.longDescription,
            banner: this.keypadCollection.banner,
            chainId: this.$store.state.chainId
          }),
        });

      const res = await keypadCollectionDataResponse.json();
      if (res && !res.error) {
        this.$store.commit('setKeypadCollectionData', res);
        return true;
      }
      return false;
    } catch (error) {
      console.log('error ', error);
      return false;
    }
  }

  purchaseSuccessfulToast() {
    toast.success('Collection created', {
      hideProgressBar: true,
      autoClose: 1000,
      position: toast.POSITION.TOP_RIGHT,
    } as ToastOptions);
  }

  purchaseFailedToast() {
    toast.error('Oops! Unable to create the collection. Please check your network connection and try again. If the issue persists, contact support.', {
      hideProgressBar: true,
      autoClose: 1000,
      position: toast.POSITION.TOP_RIGHT,
    } as ToastOptions);
  }


  async copyTxHashToClipboard() {
    try {
      await navigator.clipboard.writeText(this.txHash);
      toast.success('TxHash copied to clipboard!', {
        hideProgressBar: true,
        autoClose: 1000,
        position: toast.POSITION.TOP_RIGHT,
      } as ToastOptions);
    } catch (error) {
      console.error('Failed to copy TxHash:', error);
    }
  }

  get loading() {
    return this.isLoading;
  }

  get txHash() {
    return this.buySharesTxHash;
  }

}
