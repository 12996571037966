
import { Vue, Options, Watch } from 'vue-property-decorator';
import Title from '@/components/basic/title.vue';
import Loader from '@/components/icons/loader.vue';
import ImageButton from '@/components/shared/imageButton.vue';

@Options({ components: { Title, Loader, ImageButton } })
export default class StakingParcels extends Vue {
  viewMoreUnstaked = false;
  viewMoreStaked = false;
  parcelsSelectedForStaking: number[] = [];
  isDisabled = false;
  showLoader = true;

  beforeMount(): void {
    this.onDystoWorldLandlordStakingContractChange();
    this.showLoader = this.waitingForApi;
  }

  get waitingForApi(): boolean {
    return this.$store.state.landlordStaking.waitingForApi;
  }

  @Watch('waitingForApi')
  onWaitingForApiChanged(): void {
    this.showLoader = this.waitingForApi;
  }

  get hasApprovalForTransferOfParcels(): boolean {
    return this.$store.state.landlordStaking.hasApprovalForTransferOfParcels;
  }

  get dystoWorldLandlordStakingContract(): unknown {
    return this.$store.state.dystoWorldLandlordStakingContract;
  }

  get waitingForConfirmation(): boolean {
    return this.$store.state.landlordStaking.waitForConfirmationTrigger;
  }

  @Watch('waitingForConfirmation')
  onWaitingForConfirmationChange(): void {
    this.isDisabled = this.waitingForConfirmation;
  }

  @Watch('dystoWorldLandlordStakingContract', { deep: true })
  onDystoWorldLandlordStakingContractChange(): void {
    if (this.$store.state.dystoWorldLandlordStakingContract) {
      this.$store.dispatch('checkApprovalForTransferOfParcels');
    }
  }

  get unstakedParcels(): number[] {
    return this.$store.state.landlordStaking.unstakedParcels;
  }

  get stakedParcels(): number[] {
    return this.$store.state.landlordStaking.stakedParcels;
  }

  get numberStakedNFTs(): number {
    return this.$store.state.landlordStaking.currentlyStakedNFTs.length;
  }

  approveTransferOfParcels(): void {
    this.$store.dispatch('approveTransferOfParcels');
  }

  toggleViewMore(type: string): void {
    if (type === 'unstaked') {
      this.viewMoreUnstaked = !this.viewMoreUnstaked;
    } else if (type === 'staked') {
      this.viewMoreStaked = !this.viewMoreStaked;
    }
  }

  toggleAllForStaking(): void {
    const selectedState = this.areAllParcelsSelectedForStaking();
    if (selectedState) {
      this.parcelsSelectedForStaking = [];
    } else {
      this.parcelsSelectedForStaking = this.unstakedParcels;
    }
  }

  areAllParcelsSelectedForStaking(): boolean {
    return (
      this.unstakedParcels.length === this.parcelsSelectedForStaking.length
    );
  }

  stakeParcels(): void {
    if (this.parcelsSelectedForStaking.length === 0) {
      return;
    } else if (this.parcelsSelectedForStaking.length === 1) {
      this.$store.dispatch('stakeParcel', this.parcelsSelectedForStaking[0]);
    } else {
      this.$store.dispatch('stakeBulkParcels', this.parcelsSelectedForStaking);
    }
  }

  unstakeParcel(parcelId: number): void {
    if (this.stakedParcels.length === this.numberStakedNFTs) {
      this.$store.commit('app/setModal', {
        component: 'alertMessage',
        payload: {
          title: "Can't unstake used parcel",
          message:
            'The parcel you are trying to unstake is used for staking an NFT. Please unstake an NFT first.',
        },
      });
      return;
    }
    this.$store.dispatch('unstakeParcel', parcelId);
  }

  unstakeParcels(): void {
    if (this.numberStakedNFTs !== 0) {
      this.$store.commit('app/setModal', {
        component: 'alertMessage',
        payload: {
          title: "Can't unstake used parcels",
          message:
            'Before you can unstake all of your parcels, you need to unstake all your NFTs first.',
        },
      });
      return;
    }

    this.$store.commit('app/setModal', {
      component: 'alertMessage',
      payload: {
        title: "Can't unstake used parcels",
        message:
          'Before you can unstake all of your parcels, you need to unstake all your NFTs first.',
        okMessage: 'Yes',
        okAction: () => this.$store.dispatch('unstakeBulkParcels'),
      },
    });
  }
}
