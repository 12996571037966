import { PoolReward, StakedNFTInfo } from '@/store/types/landlordStaking';
import { DecalProperties } from '@/store/types/spaces';
import { DYSTO_PROXY_URL } from '@/config';
import {
  Vector3,
  Texture,
  VideoTexture,
  StandardMaterial,
  SceneLoader,
  MeshBuilder,
  Quaternion,
  HighlightLayer,
  Color3,
  AbstractMesh,
  Scene,
  Mesh,
} from '@babylonjs/core';
import '@babylonjs/loaders/glTF';
// import LoadingTexture from "../assets/images/Loading2.jpg";

export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

// TODO change back to 30 days after testing
// export const MIN_STAKED_TIME = 30 * 24 * 60 * 60; // 30 days
export const MIN_STAKED_TIME = 20 * 60; // 30 mins  TODO this is for dev testing

export const ADDRESS_ZERO =
  '0x0000000000000000000000000000000000000000' as const;

export const initGlitch = (): void => {
  const glitchAnchors = document.querySelectorAll(
    '.glitch-container > .glitch-anchor'
  );
  glitchAnchors.forEach((el) => {
    const icon = el.querySelector('[class*="fa-"]');
    const text = el.querySelector('[class*="glitch-text"]');
    const parent = icon || text;
    if (!parent) return;
    const parentClone = parent.cloneNode(true) as HTMLElement;
    parentClone.classList.add('glitch-layer');
    if (!parentClone.querySelector('.glitch-layer--1')) {
      const parentCloneLayer1 = parentClone.cloneNode(true) as HTMLElement;
      parentCloneLayer1.classList.add('glitch-layer--1');
      el.prepend(parentCloneLayer1);
    }
    if (!parentClone.querySelector('.glitch-layer--2')) {
      const parentCloneLayer2 = parentClone.cloneNode(true) as HTMLElement;
      parentCloneLayer2.classList.add('glitch-layer--2');
      el.prepend(parentCloneLayer2);
    }
  });
};

export const isUrl = (url: string): boolean =>
  /^((?:https?:\/\/|www\d{0,3}[.]|[a-z0-9.-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()[\]{};:'".,<>?«»“”‘’]))$/.test(
    url
  );

export const stringToOpenSeaUrlEncoding = (str: string): string =>
  str.trim().replaceAll(/ /g, '%20');

export const stringToLooksRareUrlEncoding = (str: string): string =>
  str.trim().replaceAll(/ /g, '+');

export const ethTimestampToDate = (timestamp: number): Date =>
  new Date(timestamp * 1000);

export const getMonthName = (date: Date): string => monthNames[date.getMonth()];

export const getPoolRewardName = (poolReward: PoolReward): string =>
  poolReward.name +
  ' - ' +
  getMonthName(ethTimestampToDate(poolReward.endTimestamp)) +
  ' ' +
  ethTimestampToDate(poolReward.endTimestamp).getFullYear();

export const isNFTEligibleForPoolReward = (
  nft: StakedNFTInfo,
  poolReward: PoolReward
): boolean => {
  if (nft.collectionAddress !== poolReward.collectionAddress) {
    return false;
  }

  // an nft is eligible for a pool reward if it was staked before the end of the pool reward and
  // it still hasn't been unstaked or if it was unstaked after the end of the pool reward
  // with a time difference of at least 30 days
  for (const stakingHistory of nft.stakingHistory) {
    if (
      stakingHistory.stakedAtTimestamp <= poolReward.endTimestamp &&
      (stakingHistory.unstakedAtTimestamp === null ||
        (stakingHistory.unstakedAtTimestamp >= poolReward.endTimestamp &&
          stakingHistory.unstakedAtTimestamp -
            stakingHistory.stakedAtTimestamp >=
            MIN_STAKED_TIME))
    ) {
      return true;
    }
  }

  return false;
};

// check if a given nft can claim at least a reward now
export const canNFTClaimRewardNow = (
  nft: StakedNFTInfo,
  poolRewards: PoolReward[]
): boolean => {
  // token needs to be at staking
  if (
    nft.stakingHistory[nft.stakingHistory.length - 1].unstakedAtTimestamp !==
    null
  ) {
    return false;
  }

  // token needs to be staked for at least 30 days
  const stakedAt =
    nft.stakingHistory[nft.stakingHistory.length - 1].stakedAtTimestamp;
  const now = Math.floor(Date.now() / 1000);
  if (now - stakedAt < MIN_STAKED_TIME) {
    return false;
  }

  for (const poolReward of poolRewards) {
    if (!isNFTEligibleForPoolReward(nft, poolReward)) {
      continue;
    }

    // can claim reward if the pool reward has ended
    if (poolReward.endTimestamp > now) {
      continue;
    }

    // check if user has claimed reward for this token
    if (
      nft.claimedRewardHistory.findIndex(
        (claimedReward) => claimedReward.poolRewardIndex === poolReward.index
      ) === -1
    ) {
      return true;
    }
  }

  return false;
};

// convert timestamp to days, hours, minutes, seconds
export const formatTimestampInSecondsToDateInterval = (
  timestamp: number
): string => {
  const days = Math.floor(timestamp / (3600 * 24));
  const hours = Math.floor((timestamp % (3600 * 24)) / 3600);
  const minutes = Math.floor((timestamp % 3600) / 60);
  const seconds = Math.floor(timestamp % 60);
  return `${days}d ${hours}h ${minutes}m ${seconds}s`;
};

export const formatEther = (value: string | number): string => {
  let formattedValue = value.toString().padStart(18, '0');
  if (formattedValue.length <= 18) {
    formattedValue = '0.' + formattedValue;
  } else {
    formattedValue =
      formattedValue.slice(0, formattedValue.length - 18) +
      '.' +
      formattedValue.slice(formattedValue.length - 18);
  }
  formattedValue = formattedValue.replace(/^0+(\d)|(\d)0+$/gm, '$1$2');
  return formattedValue;
};

export const parseJWT = (token: string): any => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
  return JSON.parse(jsonPayload);
};

export const calculateSliderWall = (
  meshPlaceholder: any,
  infoMaximumRoot: any,
  infoMinimumRoot: any,
  initialPosition: any,
  infoMinimumPlaceholder: any,
  infoMaximumPlaceholder: any
) => {
  if (initialPosition.length !== 0) {
    if (
      infoMinimumPlaceholder._y < infoMinimumRoot._y ||
      infoMaximumPlaceholder._y > infoMaximumRoot._y ||
      infoMinimumPlaceholder._x < infoMinimumRoot._x ||
      infoMaximumPlaceholder._x > infoMaximumRoot._x
    ) {
      meshPlaceholder.position = new Vector3(
        initialPosition[0].meshPosition.position._x,
        initialPosition[0].meshPosition.position._y,
        initialPosition[0].meshPosition.position._z
      );
    }
  }
  // else {
  //   // const position = meshPlaceholder.metadata.meshPosition;
  //   if (
  //     infoMinimumPlaceholder._y < infoMinimumRoot._y ||
  //     infoMaximumPlaceholder._y > infoMaximumRoot._y ||
  //     infoMinimumPlaceholder._x < infoMinimumRoot._x ||
  //     infoMaximumPlaceholder._x > infoMaximumRoot._x
  //   ) {
  //     meshPlaceholder.position.x = new Vector3(infoMaximumRoot._x);

  //   }
  // }
};
export const generateColorsForLayer = (numOfLikes: number): Color3 => {
  if (numOfLikes >= 10 && numOfLikes <= 49) {
    return new Color3(61 / 255, 141 / 255, 254 / 255);
  } else if (numOfLikes >= 50 && numOfLikes <= 99) {
    return new Color3(191 / 255, 55 / 255, 233 / 255);
  } else {
    return new Color3(251 / 255, 151 / 255, 23 / 255);
  }
};

export const createHightlightLayer = (
  meshPlaceholder: any,
  placeholder: any,
  scene: any
) => {
  if (placeholder.likeCount >= 10) {
    const hl = new HighlightLayer(placeholder.meshName + 'like', scene);
    hl.addMesh(
      meshPlaceholder,
      generateColorsForLayer(placeholder.likeCount),
      false
    );
    hl.outerGlow = true;
    hl.innerGlow = true;
  }
};

export const createHightlightLayerForStakedNFTs = (
  meshPlaceholder: any,
  placeholder: any,
  scene: any
) => {
  const hl: HighlightLayer = new HighlightLayer(
    placeholder.meshName + 'staked',
    scene
  );
  hl.addMesh(meshPlaceholder, new Color3(159 / 255, 37 / 255, 39 / 255), false);
  hl.outerGlow = true;
  hl.innerGlow = true;
};

export const calculateDecalPositionAndNormal = (
  mesh: AbstractMesh
): DecalProperties => {
  const pointMin: Vector3 = mesh.getBoundingInfo().boundingBox.minimumWorld;
  const pointMax: Vector3 = mesh.getBoundingInfo().boundingBox.maximumWorld;
  const halfSize = 0.095;
  if (pointMax.z < 0 && pointMin.z < 0) {
    return {
      position: new Vector3(
        pointMin.x + halfSize,
        pointMin.y + halfSize,
        pointMax.z
      ),
      normal: Vector3.Forward(),
    };
  }
  if (pointMax.x < 0 && pointMin.x < 0) {
    return {
      position: new Vector3(
        pointMax.x,
        pointMin.y + halfSize,
        pointMax.z - halfSize
      ),
      normal: Vector3.Left(),
    };
  }
  if (pointMax.z - pointMin.z < pointMax.x - pointMin.x) {
    return {
      position: new Vector3(
        pointMax.x - halfSize,
        pointMin.y + halfSize,
        pointMin.z
      ),
      normal: Vector3.Forward(),
    };
  }
  return {
    position: new Vector3(
      pointMin.x,
      pointMin.y + halfSize,
      pointMin.z + halfSize
    ),
    normal: Vector3.Left(),
  };
};

export const createTextureOnSceneRendering = (
  placeholders: any,
  scene: Scene
) => {
  const loadingTexture = new Texture('../../Loading2.jpg', scene, false, true);

  placeholders.forEach(async (placeholder: any) => {
    const meshPlaceholder: AbstractMesh = scene.getMeshByName(
      placeholder.meshName
    )!;

    if (placeholder.isStaked || placeholder.isEligibleForStaking) {
      createHightlightLayerForStakedNFTs(meshPlaceholder, placeholder, scene);
    } else {
      createHightlightLayer(meshPlaceholder, placeholder, scene);
    }

    const splitResult = (placeholder.animationUrl || '')
      .split('.')
      .filter((e: any) => e);

    if (
      splitResult.length &&
      (splitResult[splitResult.length - 1] === 'glb' ||
        splitResult[splitResult.length - 1] === 'gltf')
    ) {
      const { meshes } = await SceneLoader.ImportMeshAsync(
        '',
        placeholder.animationUrl,
        '',
        scene
      );
      if (meshes.length) {
        const plane = MeshBuilder.CreateBox(
          placeholder.meshName,
          { width: 1, height: 1.7, depth: 0.7 },
          scene
        );
        plane.visibility = 0;

        meshes[0].parent = plane;
        scene.registerBeforeRender(() => {
          meshes[0].scaling = new Vector3(0.04, 0.04, 0.04);
        });
        plane.position = new Vector3(
          placeholder.meshPosition.position._x,
          placeholder.meshPosition.position._y,
          placeholder.meshPosition.position._z
        );
        plane.scaling = new Vector3(
          placeholder.meshScaling._x,
          placeholder.meshScaling._y,
          placeholder.meshScaling._z
        );
        plane.metadata = placeholder;
        plane.rotation.y = 3;
        meshes.map((mesh: any, index: number) => {
          if (placeholder.isStaked || placeholder.isEligibleForStaking) {
            createHightlightLayerForStakedNFTs(mesh, placeholder, scene);
          } else {
            createHightlightLayer(mesh, placeholder, scene);
          }
        });
      }
      meshPlaceholder?.dispose();
    } else if (
      splitResult.length &&
      (splitResult[splitResult.length - 1] !== 'glb' ||
        splitResult[splitResult.length - 1] !== 'gif')
    ) {
      const material = new StandardMaterial('mat', scene);
      const videoTexture = new VideoTexture(
        'video',
        placeholder.animationUrl,
        scene,
        false,
        false,
        1,
        {
          autoPlay: true,
          muted: true,
          loop: false,
        }
      );
      material.emissiveTexture = videoTexture;
      material.disableLighting = true;
      meshPlaceholder.material = material;
      meshPlaceholder.metadata = placeholder;
      if (placeholder.likedAt !== null && !placeholder.isStaked) {
        createDecal(meshPlaceholder, scene);
      }
      if (placeholder.isStaked) {
        createDecalForStaking(meshPlaceholder, scene);
      }
    } else {
      const newMaterial = new StandardMaterial('material', scene);
      newMaterial.emissiveTexture = loadingTexture;
      newMaterial.disableLighting = true;
      meshPlaceholder.material = newMaterial;
      meshPlaceholder.scaling = new Vector3(
        placeholder.meshScaling._x,
        placeholder.meshScaling._y,
        placeholder.meshScaling._z
      );
      meshPlaceholder.position = new Vector3(
        placeholder.meshPosition.position._x,
        placeholder.meshPosition.position._y,
        placeholder.meshPosition.position._z
      );
      const newTexture = new Texture(
        `${DYSTO_PROXY_URL}${placeholder.imageUrl}`,
        scene,
        false,
        true,
        1,
        () => {
          newMaterial.emissiveTexture = newTexture;
          newMaterial.opacityTexture = newTexture;
          meshPlaceholder.material = newMaterial;
          meshPlaceholder.metadata = placeholder;
          if (placeholder.likedAt !== null && !placeholder.isStaked) {
            createDecal(meshPlaceholder, scene);
          }
          if (placeholder.isStaked) {
            createDecalForStaking(meshPlaceholder, scene);
          }
        },
        () => {
          meshPlaceholder.scaling = new Vector3(
            placeholder.meshScaling._x,
            placeholder.meshScaling._y,
            placeholder.meshScaling._z
          );
          newTexture.updateURL(placeholder.imageUrl);
          newMaterial.emissiveTexture = newTexture;
          newMaterial.opacityTexture = newTexture;
          newMaterial.disableLighting = true;
          meshPlaceholder.material = newMaterial;
          meshPlaceholder.metadata = placeholder;
          if (placeholder.isStaked) {
            createDecalForStaking(meshPlaceholder, scene);
          }
          if (placeholder.likedAt !== null && !placeholder.isStaked) {
            createDecal(meshPlaceholder, scene);
          }
        }
      );
    }
  });
};

export const createDecal = (mesh: any, scene: Scene): void => {
  const { position, normal } = calculateDecalPositionAndNormal(mesh);
  const decalMaterial = new StandardMaterial('decalMat', scene);
  const decalTexture = new Texture('../../heart.svg', scene, false, true);
  decalMaterial.emissiveTexture = decalTexture;
  decalMaterial.diffuseTexture = decalTexture;
  decalMaterial.disableLighting = true;
  decalMaterial.diffuseTexture.hasAlpha = true;
  decalMaterial.emissiveTexture.hasAlpha = true;
  decalMaterial.zOffset = -2;
  const decal = MeshBuilder.CreateDecal(mesh.name + '-decal', mesh, {
    position: position,
    normal: normal,
    size: new Vector3(0.15, 0.15, 0.00001),
  });
  decal.material = decalMaterial;
  scene
    .getHighlightLayerByName(mesh.name)
    ?.addMesh(decal, generateColorsForLayer(mesh.metadata.likeCount));
};

export const createDecalForStaking = (mesh: any, scene: Scene): void => {
  const { position, normal } = calculateDecalPositionAndNormal(mesh);
  const decalMaterial = new StandardMaterial('decalMat', scene);
  const decalTexture = new Texture('../../meat.png', scene, false, true);
  decalMaterial.emissiveTexture = decalTexture;
  decalMaterial.diffuseTexture = decalTexture;
  decalMaterial.disableLighting = true;
  decalMaterial.diffuseTexture.hasAlpha = true;
  decalMaterial.emissiveTexture.hasAlpha = true;
  decalMaterial.zOffset = -2;
  const decal = MeshBuilder.CreateDecal(mesh.name + '-staked', mesh, {
    position: position,
    normal: normal,
    size: new Vector3(0.15, 0.15, 0.00001),
  });
  decal.material = decalMaterial;
  scene
    .getHighlightLayerByName(mesh.name + 'staked')
    ?.addMesh(decal, new Color3(159 / 255, 37 / 255, 39 / 255));
};

export const calculateSizeToMaintainRatio = (
  width: number,
  height: number,
  mesh: AbstractMesh
): any => {
  if (width > height) {
    const diff = width - height;
    const percentage = Math.floor((diff / width) * 100);
    mesh.scaling.x = mesh.scaling.x + mesh.scaling.x * (percentage / 100);
  } else if (width < height) {
    const diff = height - width;
    const percentage = Math.floor((diff / width) * 100);
    mesh.scaling.y = mesh.scaling.y + mesh.scaling.y * (percentage / 100);
  } else {
    if (mesh.scaling.x > mesh.scaling.y) {
      mesh.scaling.x = mesh.scaling.y;
    } else {
      mesh.scaling.y = mesh.scaling.x;
    }
  }
};

export const copyToClipboard = (text: string): void => {
  const el = document.createElement('textarea');
  el.value = text;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};
