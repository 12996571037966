
import { Options, Vue } from 'vue-property-decorator';
import { API_URL } from '@/config';
import eventBus from '@/eventBus';
import { getNetworkByChainId } from '@/utils/blockchain';
import { toast, ToastOptions } from 'vue3-toastify';
import { formatEther } from '@/utils';

interface Balances {
  [key: string]: number;

  dyst: number;
  eth: number;
  usdt: number;
  usdc: number;
  busd: number;
}
export default class Influencer extends Vue {

  MAX_KEYPAD_COLLECTION_NAME_LENGTH = 30;
  MAX_KEYPAD_COLLECTION_SHORT_DESCRIPTION_LENGTH = 1000;
  MAX_KEYPAD_COLLECTION_LONG_DESCRIPTION_LENGTH = 5000;
  MAX_KEYPAD_COLLECTION_IMAGE_SIZE_MB = 5;

  initialLoading = true;

  user: any = {};
  keypadCollection: any = {};

  currentProgressStep = 0;
  userSelectedStep = 0;

  balances: Balances = {
    dyst: 0,
    eth: 0,
    usdt: 0,
    usdc: 0,
    busd: 0,
  };


  pricePerDystoInUsdt = '0';
  pricePerDystoInEth = '0';

  pricePerEthInUsdt = 0;

  dystoPublicSaleContractAddress = '';

  sharesSupply = 0;

  keypadCollectionEditMode = false;

  freeLandWhitelisted = true;

  freeLandReservedOrMinted = false;

  isLoggedIn = false;


  ///////

  dystoAmountToBuy = 0;
  selectedCurrencyToBuyDysto = 'USDT';
  minDystoAmountToBuy = 0;

  hasDystoBuyTransactions = false;
  shouldSeeDystoBuyTransactionsHistory = false;

  shouldSeeCollectionTradesHistory = false;

  rooms = 0;
  placeholders = 0;
  parcelId = null;

  ///////

  async created() {
    setTimeout(async () => {
      await this.init();
      this.initialLoading = false;
    }, 500);
  }

  adjustForDecimals(rawBalance: any, decimals: number) {
    return Number(rawBalance) / Math.pow(10, decimals);
  }

  async init() {
    await this.$store.dispatch('getAllInfluencerPageNeededData');

    this.user = Object.assign({}, this.$store.state.user);
    this.isLoggedIn = (this.$store.state.user !== null);
    if (!this.isLoggedIn) {
      return;
    }

    if (this.$store.state.user.keypadCollectionData) {
      this.keypadCollection = Object.assign({}, this.$store.state.user.keypadCollectionData);
    } else {
      this.keypadCollection = {name: "", shortDescription: "", longDescription: "", banner: ""};
    }

    this.currentProgressStep = this.$store.state.user?.influencerWizardData?.step;

    const userSelectedStepInputParam = this.$route.query.userSelectedStepInputParam;
    if (userSelectedStepInputParam) {
      this.userSelectedStep = Number(userSelectedStepInputParam);
    } else {
      this.userSelectedStep = this.currentProgressStep;
    }

    this.balances.dyst = Number(await this.$store.dispatch('getUserDystoWorldTokenBalance'));
    if (this.balances.dyst > 0 && this.currentProgressStep && this.currentProgressStep < 1) {
      await this.changeWizardCurrentProgressStep('1');
    }

    this.balances.eth = Number(await this.$store.dispatch('getUserBalanceOfNativeCurrency'));

    try {
      const network = getNetworkByChainId(this.$store.state.chainId);



      // Get raw balances
      // const rawUSDTBalance = await this.$store.dispatch('getERC20Balance', { tokenContractAddress: network.USDT_ADDRESS });
      // const rawUSDCBalance = await this.$store.dispatch('getERC20Balance', { tokenContractAddress: network.USDC_ADDRESS });
      // const rawBUSDBalance = await this.$store.dispatch('getERC20Balance', { tokenContractAddress: network.BUSD_ADDRESS });

      this.balances.usdt = 0;
      this.balances.usdc = 0;
      this.balances.busd = 0;

      this.dystoPublicSaleContractAddress = network.DYSTO_WORLD_PUBLIC_SALE_CONTRACT_ADDRESS;
    } catch (err) {
      console.log(err);
    }

    eventBus.on('step-clicked', this.handleStepClicked);
    eventBus.on('logout', this.handleLogout);

    try {
      this.sharesSupply = await this.$store.state.dystoWorldKeypadContract.methods.sharesSupply(this.$store.state.user.address).call();
      if (this.sharesSupply == 0) {
        this.keypadCollectionEditMode = true;
      }
    } catch (err) {
      console.log(err);
    }

    let priceInUsdt = 0;

    // try {
    //   const contractDystoUsdtRate = await this.$store.state.dystoWorldPublicSaleContract.methods.RATE().call();
    //   priceInUsdt = 1 / (contractDystoUsdtRate / 1000);
    //   this.pricePerDystoInUsdt = priceInUsdt.toFixed(2);
    // } catch (err) {
    //   console.log(err);
    // }

    try {
      const getEthUsdtPrice = await fetch(`${API_URL}/keypad/get_eth_usdt_price`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${await this.$store.dispatch('returnToken')}`,
        },
      });
      const getEthUsdtPriceResponse = await getEthUsdtPrice.json();
      if (getEthUsdtPriceResponse && getEthUsdtPriceResponse.usdtEthPrice) {
        // Convert the price of DYSTO from USDT to ETH
        this.pricePerEthInUsdt = getEthUsdtPriceResponse.usdtEthPrice;
        this.pricePerDystoInEth = (priceInUsdt / this.pricePerEthInUsdt).toFixed(8);

        const minUsdtToSpend = 10;
        this.minDystoAmountToBuy = Math.trunc(minUsdtToSpend / Number(this.pricePerDystoInUsdt));
      }
    } catch (err) {
      console.log(err);
    }

    try {
      this.freeLandWhitelisted =  this.$store.state.user?.freeLandWhitelisted;

      this.freeLandReservedOrMinted = this.$store.state.user?.freeLandReservedOrMinted;

      this.hasDystoBuyTransactions = this.$store.state.user?.dystoBuyTransactionsData?.length > 0;

      if (this.$store.state.user?.allParcelsData?.length > 0) {
        this.parcelId = this.$store.state.user?.allParcelsData[0]?.id;
      }

      this.rooms = this.$store.state.spaces?.mount?.spaceAssets?.length;

      this.placeholders = this.$store.state.spaces?.ownedInstances?.reduce((acc: any, obj: any) => acc + (obj.placeholders?.length || 0), 0) || 0;
    } catch (err) {
      console.log(err);
    }
  }

  beforeDestroy() {
    eventBus.detach('step-clicked', this.handleStepClicked);
    eventBus.detach('logout', this.handleLogout);
  }

  handleStepClicked(eventPayload: any) {
    this.userSelectedStep = eventPayload;
  }

  handleLogout() {
    this.$router.push('/');
    this.isLoggedIn = false;
  }

  async goToParcel() {
    await this.$store.dispatch('getParcel', this.parcelId);
    await this.$router.push(`/mount/${this.parcelId}`);
  }

  get getNumberOfFollowersText() {
    if (!this.twitterUserData || !this.twitterUserData.numberOfFollowers) {
      return '0 Followers';
    }

    // Get the number of followers
    const followers = this.twitterUserData.numberOfFollowers;

    // Check if the number is in thousands
    if (followers < 1000) {
      return `${followers} Followers`;
    } else {
      // Format the number in the thousands (k) format
      const formattedFollowers = (followers / 1000).toFixed(1) + 'k';
      return `${formattedFollowers} Followers`;
    }
  }

  get topFiveInfluencers() {
    const influencers = this.$store.state.keypadTopFiveInfluencers;

    if (!influencers) {
      return [];
    }

    const currentWidth = window.innerWidth;

    let numOfElems = currentWidth > 500 ? 4 : 3;
    if (currentWidth > 1550) {
      numOfElems = 5;
    }

    if (influencers.length >= numOfElems) {
      return influencers.slice(0, numOfElems);
    }

    // const filledArray = influencers.slice();
    // while (filledArray.length < numOfElems) {
    //   const elementToFill = influencers.length > 0 ? influencers[0] : {  logo: '', collectionOwner: '', keysBought: 0, username: '' };
    //   try {
    //     elementToFill.keysBought = elementToFill.keysBought.toLocaleString('en-US');
    //   } catch (error) {
    //     console.log(error);
    //   }
    //   filledArray.push(elementToFill);
    // }

    return influencers;
  }

  async goToClickedCollection(collectionOwner: string) {
    try {
      const collection = this.$store.state.keypad.collections.find(
        (collection: any) => collection.user_wallet_address === collectionOwner
      );

      if (collection) {
        this.$store.commit('setKeypadCurrentCollection', collection);
        if (this.$route.name !== 'Keypad') {
          await this.$router.push('/keypad');
        }
      }
    } catch (err) {
      console.log(err);
    }
  }


  get dystoDisplayPrice() {
    if (this.selectedCurrencyToBuyDysto === 'ETH') {
      return `1 DYSTO = ${this.pricePerDystoInEth} ETH`;
    } else {
      return `1 DYSTO = ${this.pricePerDystoInUsdt} ${this.selectedCurrencyToBuyDysto}`;
    }
  }

  get walletBalance() {
    if (this.selectedCurrencyToBuyDysto === 'ETH') {
      return this.balances.eth.toFixed(8);
    }

    return this.balances[this.selectedCurrencyToBuyDysto.toLowerCase()].toFixed(2);
  }

  isEligibleToClaimFreeLand() {
    return (this.sharesSupply >= 5)
      || (this.twitterUserData
        && this.twitterUserData.numberOfFollowers
        && this.twitterUserData.numberOfFollowers >= 100);
  }


  async buyFirstKeyFromContract() {
    if (this.shouldDisableCreateKeypadCollectionButton()) {
      return;
    }

    this.$store.commit('app/setModal', {
      component: 'createKeypadCollection',
      fromComponent: 'influencer',
      payload: {
        dystoBalance: this.balances.dyst,
        keypadCollection: this.keypadCollection,
        message: `You are going to create your collection`,
        exitCallback: async () => {
          await this.changeWizardCurrentProgressStep('2');
          await this.init();
        },
      },
    });
  }


  async changeWizardCurrentProgressStep(changeToStep: string) {
    try {
      const influencerWizardFetch = await fetch(`${API_URL}/keypad/influencer_wizard_change_current_progress_step/${this.$store.state.user.address}/${changeToStep}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
      const influencerWizardResponse = await influencerWizardFetch.json();
      if (influencerWizardResponse) {
        this.$store.commit('setInfluencerWizardData', influencerWizardResponse);
        this.currentProgressStep = this.$store.state.user?.influencerWizardData?.step;
      }
    } catch (err) {
      console.log(err);
    }
  }

  async claimFreeLand() {
    try {
      const freeLandClaimRequest =
        await fetch(`${API_URL}/keypad/free_land_claim`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${await this.$store.dispatch('returnToken')}`,
          },
          body: JSON.stringify({
            chainId: this.$store.state.chainId,
          }),
        });

      const res = await freeLandClaimRequest.json();
      if (res && !res.error && res?.status === 'backend_whitelisted_parcel') {
        this.freeLandWhitelisted = res.status;
        await this.changeWizardCurrentProgressStep('3');
        await this.$store.dispatch('checkFreeLandWhitelisted');
        await this.$store.dispatch('checkFreeLandReservedOrMinted');
        toast.success('Success', {
          hideProgressBar: true,
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
        } as ToastOptions);
      } else {
        toast.error('Oops! Unable to process your requestIf the issue persists, contact support.', {
          hideProgressBar: true,
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
        } as ToastOptions);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async loginWithTwitter() {
    try {
      const response = await fetch(`${API_URL}/keypad/get_twitter_authorization_url`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
      const res = await response.json();
      if (response.ok && res.url) {
        window.location.href = res.url;
      } else {
        console.error('Error fetching authorization URL');
      }
    } catch (err) {
      console.error('Error:', err);
    }
  }

  getSelectedTokenContractAddress() {
    const network = getNetworkByChainId(this.$store.state.chainId);

    if (this.selectedCurrencyToBuyDysto === 'USDT') {
      return network.USDT_ADDRESS;
    }

    if (this.selectedCurrencyToBuyDysto === 'USDC') {
      return network.USDC_ADDRESS;
    }

    if (this.selectedCurrencyToBuyDysto === 'BUSD') {
      return network.BUSD_ADDRESS;
    }

    return null;
  }

  async seeTradesHistory() {
    try {
      await this.$router.push('/keypad_own_collection_trades_history');
    } catch (err) {
      console.error('Error:', err);
    }
  }

  async estimateCollectionPrice() {
    try {
      this.$store.commit('app/setModal', {
        component: 'estimateKeyPrice',
        fromComponent: 'influencer',
        payload: {
          collectionAddress: this.$store.state.user.address,
          exitCallback: async () => {
          },
        },
      });
    } catch (err) {
      console.error('Error:', err);
    }
  }

  async buyDysto() {
    try {

      if (this.shouldDisableBuyDystoButton) {
        return;
      }

      this.$store.commit('app/setModal', {
        component: 'buyDysto',
        fromComponent: 'influencer',
        payload: {
          selectedCurrencyToBuyDysto: this.selectedCurrencyToBuyDysto,
          tokenContractAddress: this.getSelectedTokenContractAddress(),
          dystoPublicSaleContractAddress: this.dystoPublicSaleContractAddress,
          dystoAmount: this.dystoAmountToBuy,
          dystoPrice: (this.selectedCurrencyToBuyDysto === 'ETH') ? this.pricePerDystoInEth : this.pricePerDystoInUsdt,

          message: `You are going to buy ${this.dystoAmountToBuy} DYSTO with ${this.estimatedCurrencyToSpend}`,

          exitCallback: async () => {
            await this.init();
          },
        },
      });
    } catch (err) {
      console.error('Error:', err);
    }
  }

  updateImage(event: any) {
    const files = event.target.files;
    const formData = new FormData();
    const reader = new FileReader();

    if (!files.length) {
      return;
    }

    const sizeInBytes = this.MAX_KEYPAD_COLLECTION_IMAGE_SIZE_MB * 1024 * 1024; // Convert MB to bytes

    if (files[0].size > sizeInBytes) {
      this.$store.commit('app/setModal', {
        component: 'alertMessage',
        fromComponent: 'influencer',
        payload: {
          title: 'Cannot use this image',
          message:
            `Maximum image size is of ${this.MAX_KEYPAD_COLLECTION_IMAGE_SIZE_MB} MB`,
        },
      });
      return;
    }

    formData.append('image', files[0], files[0].name);
    this.keypadCollection.bannerForm = formData;

    reader.onload = (e: any) => {
      this.keypadCollection.banner = e.target.result;
    };

    reader.readAsDataURL(files[0]);
  }

  changeImage() {
    const file = this.$refs.file as HTMLElement;
    file.click();
  }

  async goToExchange() {
    window.open("https://www.mexc.com/exchange/DYSTO_USDT", '_blank');
  }

  async editKeypadCollectionButtonClicked() {
    try {
      if (this.shouldDisableSaveKeypadCollectionButton()) {
        return;
      }

      if (!this.keypadCollectionEditMode) {
        this.keypadCollectionEditMode = true;
        return;
      }

      this.$store.commit('app/setModal', {
        component: 'alertMessage',
        fromComponent: 'influencer',
        payload: {
          title: 'Confirm',
          message:
            'Are you sure you want to edit your collection?',
          okMessage: 'Proceed',
          okAction: async () => {
            const keypadCollectionDataResponse =
              await fetch(`${API_URL}/keypad/create_keypad_collection`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  authorization: `Bearer ${await this.$store.dispatch('returnToken')}`,
                },
                body: JSON.stringify({
                  name: this.keypadCollection.name,
                  shortDescription: this.keypadCollection.shortDescription,
                  longDescription: this.keypadCollection.longDescription,
                  banner: this.keypadCollection.banner,
                  chainId: this.$store.state.chainId,
                }),
              });

            const res = await keypadCollectionDataResponse.json();
            if (res && !res.error) {
              this.$store.commit('setKeypadCollectionData', res);
              toast.success('Collection edited', {
                hideProgressBar: true,
                autoClose: 1000,
                position: toast.POSITION.TOP_RIGHT,
              } as ToastOptions);
            } else {
              toast.error('Oops! Unable to edit the collection. Please check your network connection and try again. If the issue persists, contact support.', {
                hideProgressBar: true,
                autoClose: 1000,
                position: toast.POSITION.TOP_RIGHT,
              } as ToastOptions);
            }

            this.keypadCollectionEditMode = false;
          },
        },
      });
    } catch (err) {
      console.error('Error:', err);
    }
  }

  async discardChangesKeypadCollectionButtonClicked() {
    try {
      if (!this.isCollectionUnchanged()) {
        this.$store.commit('app/setModal', {
          component: 'alertMessage',
          fromComponent: 'influencer',
          payload: {
            title: 'Confirm',
            message:
              'Are you sure you want to discard collection changes?',
            okMessage: 'Proceed',
            okAction: () => {
              this.keypadCollection = Object.assign({}, this.$store.state.user.keypadCollectionData);
              this.keypadCollectionEditMode = false;
            },
          },
        });
      } else {
        this.keypadCollectionEditMode = false;
      }
    } catch (err) {
      console.error('Error:', err);
    }
  }

  shouldDisableCreateKeypadCollectionButton() {
    if (this.shouldDisableSaveKeypadCollectionButton() || !this.twitterUserData) {
      return true;
    }

    return !(this.keypadCollection.name.length > 0
            && this.keypadCollection.shortDescription.length > 0
            && this.keypadCollection.longDescription.length > 0);
  }

  shouldDisableSaveKeypadCollectionButton() {
    return this.keypadCollection.name.length > this.MAX_KEYPAD_COLLECTION_NAME_LENGTH
      || this.keypadCollection.shortDescription.length > this.MAX_KEYPAD_COLLECTION_SHORT_DESCRIPTION_LENGTH
      || this.keypadCollection.longDescription.length > this.MAX_KEYPAD_COLLECTION_LONG_DESCRIPTION_LENGTH
      || (this.keypadCollectionEditMode && this.isCollectionUnchanged());
  }

  isCollectionUnchanged() {
    return this.keypadCollection.name === this.$store.state.user.keypadCollectionData?.name
      && this.keypadCollection.shortDescription === this.$store.state.user.keypadCollectionData?.shortDescription
      && this.keypadCollection.longDescription === this.$store.state.user.keypadCollectionData?.longDescription
      && this.keypadCollection.banner === this.$store.state.user.keypadCollectionData?.banner;
  }

  get twitterUserData() {
    return this.$store.state.user?.twitterData;
  }

  get currentCollection() {
    return this.$store.state.user?.keypadCollectionData;
  }

  validateDystoAmountToBuyInput() {
    if (this.dystoAmountToBuy < 0) {
      this.dystoAmountToBuy = 0;
    }
    if (this.dystoAmountToBuy > 100000) {
      this.dystoAmountToBuy = 100000;
    }
  }

  get estimatedCurrencyToSpend(): string {
    const dystoPrice = this.selectedCurrencyToBuyDysto === 'ETH' ? this.pricePerDystoInEth : this.pricePerDystoInUsdt;
    const estimatedCurrency = (this.dystoAmountToBuy * Number(dystoPrice)).toFixed(this.selectedCurrencyToBuyDysto === 'ETH' ? 8 : 2);
    return `${estimatedCurrency} ${this.selectedCurrencyToBuyDysto}`;
  }

  get shouldDisableBuyDystoButton() {
    return this.dystoAmountToBuy < this.minDystoAmountToBuy
      || parseFloat(this.estimatedCurrencyToSpend) > parseFloat(this.walletBalance);
  }

  get dystoBuyTransactions() {
    return this.$store.state.user.dystoBuyTransactionsData;
  }

  get collectionTrades() {
    return this.$store.state.user.keypadOwnCollectionTradesData;
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  }

  async copyTxHashToClipboard(txHash: string) {
    try {
      await navigator.clipboard.writeText(txHash);
      toast.success('TxHash copied to clipboard!', {
        hideProgressBar: true,
        autoClose: 1000,
        position: toast.POSITION.TOP_RIGHT,
      } as ToastOptions);
    } catch (error) {
      console.error('Failed to copy TxHash:', error);
    }
  }

  useAllBalanceToBuyDysto(): void {
    const dystoPrice = this.selectedCurrencyToBuyDysto === 'ETH' ? this.pricePerDystoInEth : this.pricePerDystoInUsdt;
    this.dystoAmountToBuy = Math.min(Math.floor(parseFloat(this.walletBalance) / Number(dystoPrice)), 100000);
  }

  formatDystoPrice(dystoPrice: number): string {
    return formatEther(dystoPrice);
  }
}
