
import { Vue, Options, Prop } from 'vue-property-decorator';
import { API_URL } from '@/config';
import MenuModal from '@/components/shared/landlord-staking/staking-nfts/menu-modal/menuModal.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faMicrophone,
  faTrashAlt,
  faVolumeHigh,
  faPause,
  faStop,
  faPlay,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faMicrophone,
  faTrashAlt,
  faVolumeHigh,
  faPause,
  faStop,
  faPlay,
  faPaperPlane
);

interface ChatHistoryItem {
  role: string;
  content: string;
}

@Options({
  components: {
    FontAwesomeIcon,
    MenuModal,
  },
})
export default class Chat extends Vue {
  // Once upon a time, there was some code for text-to-speech (TTS) and sessions here.
  // If you want to recover it, check the git history.

  @Prop() readonly currentMesh!: any;
  @Prop(Function) readonly onClose!: () => void;

  chatHistory: ChatHistoryItem[] = [];
  messageText = '';
  loading = false;

  get user(): any {
    return this.$store.state.user;
  }

  get token(): any {
    return localStorage.getItem('auth_token');
  }

  async mounted(): Promise<void> {
    await this.$store.dispatch('returnToken');
    this.init();
  }

  init(): void {
    if (this.currentMesh.aiGreeting) {
      this.chatHistory.push({
        role: 'assistant',
        content: this.currentMesh.aiGreeting
      });
    }

    (this.$refs['input-message'] as HTMLElement).focus();
  }

  // scrollToTop(): void {
  //   var chat = document.getElementById(
  //     (this.chatHistory.length - 1).toString()
  //   )!;
  //   chat.scrollIntoView({ behavior: 'smooth' });
  // }

  async onMessageSubmit(): Promise<void> {
    if (this.messageText.length || !this.loading) {
      this.loading = true;
      this.chatHistory.push({
        role: 'user',
        content: this.messageText,
      });
      // setTimeout(() => {
      //   this.scrollToTop();
      // }, 100);
      this.messageText = '';
      const response = await fetch(`${API_URL}/openai/chat`, {
        method: 'POST',
        body: JSON.stringify({ messages: this.chatHistory, placeholderId: this.currentMesh._id }),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${await this.$store.dispatch('returnToken')}`,
        },
      });
      if (!response.ok) {
        this.$store.commit(
          'app/alterNotifications',
          { text: 'Something went wrong', type: 'danger', add: true },
          { root: true }
        );
      }
      let parseRes = await response.json();

      this.chatHistory.push({
        role: parseRes.message.choices[0].message.role,
        content: parseRes.message.choices[0].message.content,
      });
      // setTimeout(() => {
      //   this.scrollToTop();
      // }, 100);
      this.loading = false;
    }
  }

  timeFormat(): string {
    var time = new Date();
    const hours = time.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    return hours;
  }
}
