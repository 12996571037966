
import { Vue, Options } from 'vue-property-decorator';
import OptionBar from '@/components/shared/optionbar.vue';
import Navbar from '@/components/shared/navbar.vue';
import Modal from '@/components/shared/modals/index.vue';
import WaitingForTransaction from '@/components/shared/modals/waitingForTransaction.vue';

@Options({
  components: {
    Navbar,
    OptionBar,
    Modal,
    WaitingForTransaction,
  },
})
export default class Default extends Vue {
  get modal() {
    return this.$store.state.app.modal;
  }

  get showWaitingForTransaction(): boolean {
    return this.$store.state.launchpad.mintingInProgress;
  }

  get optionBar() {
    return this.$store.state.app.Option_Bar;
  }
}
