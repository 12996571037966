import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "pagination flex flex-wrap disable-select" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronIcon = _resolveComponent("ChevronIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.previous && _ctx.previous(...args))),
      class: "prepend center-center"
    }, [
      _createVNode(_component_ChevronIcon)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (page, id) => {
      return (_openBlock(), _createElementBlock("div", {
        onClick: ($event: any) => (_ctx.go(page)),
        key: id,
        class: _normalizeClass([_ctx.active_page === page ? 'active disable-pointer' : '', "page center-center"])
      }, _toDisplayString(page), 11, _hoisted_2))
    }), 128)),
    _createElementVNode("div", {
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args))),
      class: "append center-center rotate-180"
    }, [
      _createVNode(_component_ChevronIcon)
    ])
  ]))
}