
import { Vue } from 'vue-property-decorator';

export default class CanvasHelp extends Vue {
  close() {
    this.setModal('');
  }

  setModal(modal: any, payload?: any) {
    this.$store.commit('app/setModal', { component: modal, payload });
  }
}
