
import { Vue, Options, Watch } from 'vue-property-decorator';
import { PoolReward, StakedNFTInfo } from '@/store/types/landlordStaking';
import Loader from '@/components/icons/loader.vue';
import StarIcon from '@/components/icons/star.vue';
import StickerIcon from '@/components/icons/sticker.vue';
import CollapseIcon from '@/components/icons/collapse.vue';
import ExpandIcon from '@/components/icons/expand.vue';
import StakeNFTsMenu from '@/components/shared/stakeNFTsMenu.vue';
import PoolRewardInfo from '@/components/shared/poolRewardInfo.vue';
import StakingNFTsForCollection from '@/components/shared/stakingNFTsForCollection.vue';
import { canNFTClaimRewardNow, getPoolRewardName } from '@/utils';
import FilterIcon from '@/components/icons/filter.vue';

@Options({
  components: {
    Loader,
    StarIcon,
    StickerIcon,
    CollapseIcon,
    ExpandIcon,
    StakeNFTsMenu,
    PoolRewardInfo,
    StakingNFTsForCollection,
    FilterIcon,
  },
})
export default class StakingNFTs extends Vue {
  isDisabled = false;
  showLoader = true;
  showStakeMenu = true;
  showFilter = false;
  collectionAddressFilter = '';

  beforeMount(): void {
    this.updatePoolRewardsOptions();
    this.updateCollectionFilterOptions();
    this.showLoader = this.waitingForApi;
  }

  get waitingForApi(): boolean {
    return this.$store.state.landlordStaking.waitingForApi;
  }

  @Watch('waitingForApi')
  onWaitingForApiChanged(): void {
    this.showStakeMenu = true;
    this.showLoader = this.waitingForApi;
  }

  get waitingForConfirmation(): boolean {
    return this.$store.state.landlordStaking.waitForConfirmationTrigger;
  }

  @Watch('waitingForConfirmation')
  onWaitingForConfirmationChange(): void {
    this.isDisabled = this.waitingForConfirmation;
  }

  get activePoolRewards(): PoolReward[] {
    return this.$store.state.landlordStaking.activePoolRewards;
  }

  get pastPoolRewards(): PoolReward[] {
    return this.$store.state.landlordStaking.pastPoolRewards;
  }

  get currentPoolReward(): PoolReward | null {
    return this.$store.state.landlordStaking.currentPoolReward;
  }

  @Watch('activePoolRewards', { deep: true })
  @Watch('getPastPoolRewards', { deep: true })
  poolRewardsWatcher(): void {
    this.updatePoolRewardsOptions();
  }

  updatePoolRewardsOptions(): void {
    this.poolRewardsOptions[0].links = this.activePoolRewards.map(
      (poolReward: PoolReward) => {
        return {
          id: poolReward.id,
          name: getPoolRewardName(poolReward),
          active: false,
        };
      }
    );
    this.poolRewardsOptions[1].links = this.pastPoolRewards.map(
      (poolReward: PoolReward) => {
        return {
          id: poolReward.id,
          name: getPoolRewardName(poolReward),
          active: false,
        };
      }
    );
  }

  get stakedNFTs(): StakedNFTInfo[] {
    return this.$store.state.landlordStaking.stakedNFTs;
  }

  @Watch('stakedNFTs')
  stakedNFTsWatcher(): void {
    this.updateCollectionFilterOptions();
  }

  updateCollectionFilterOptions(): void {
    this.collectionAddressFilter = '';
    // keep only the all option
    this.collectionFilterOptions[0].links = [
      this.collectionFilterOptions[0].links[0],
    ];
    // set to false in case of set to true
    this.collectionFilterOptions[0].links[0].active = false;
    // add the new collection options
    this.stakedNFTs
      .map((nft: StakedNFTInfo) => {
        return { name: nft.collectionName, address: nft.collectionAddress };
      })
      .forEach((collection) => {
        if (
          !this.collectionFilterOptions[0].links.some(
            (link) =>
              link.name === collection.name &&
              link.address === collection.address
          )
        ) {
          this.collectionFilterOptions[0].links.push({
            name: collection.name,
            address: collection.address,
            active: false,
          });
        }
      });
  }

  changeCurrentPoolReward(newPoolReward: any): void {
    if (this.isDisabled) {
      return;
    }

    this.showStakeMenu = false;
    this.setAllPoolRewardOptionsToFalse();
    this.setAllCollectionFilterOptionsToFalse();

    find_link_loop: for (const subcategory of this.poolRewardsOptions) {
      for (const link of subcategory.links) {
        if (link.id === newPoolReward.id) {
          link.active = true;
          break find_link_loop;
        }
      }
    }

    const poolReward =
      this.activePoolRewards.find(
        (poolReward: PoolReward) => poolReward.id === newPoolReward.id
      ) ??
      this.pastPoolRewards.find(
        (poolReward: PoolReward) => poolReward.id === newPoolReward.id
      );
    this.$store.commit('setCurrentPoolReward', poolReward);
  }

  changeCurrentCollectionFilter(newCollection: any): void {
    if (this.isDisabled) {
      return;
    }

    this.showStakeMenu = false;
    this.setAllPoolRewardOptionsToFalse();
    this.setAllCollectionFilterOptionsToFalse();

    find_link_loop: for (const category of this.collectionFilterOptions) {
      for (const link of category.links) {
        if (
          link.name === newCollection.name &&
          link.address === newCollection.address
        ) {
          link.active = true;
          this.collectionAddressFilter = link.address;
          break find_link_loop;
        }
      }
    }

    this.$store.commit('setCurrentPoolReward', false);
  }

  onShowStakeMenu(): void {
    if (this.isDisabled) {
      return;
    }

    this.showStakeMenu = true;
    this.setAllPoolRewardOptionsToFalse();
    this.setAllCollectionFilterOptionsToFalse();
  }

  setAllPoolRewardOptionsToFalse(): void {
    this.poolRewardsOptions.forEach((subcategory: any) => {
      subcategory.links.forEach((link: any) => {
        link.active = false;
      });
    });
  }

  setAllCollectionFilterOptionsToFalse(): void {
    this.collectionAddressFilter = '';
    this.collectionFilterOptions.forEach((category) => {
      category.links.forEach((link) => {
        link.active = false;
      });
    });
  }

  get canShowStakeMenu(): boolean {
    return this.activePoolRewards.length > 0;
  }

  get currentlyStakedNFTs(): StakedNFTInfo[] {
    return this.$store.state.landlordStaking.currentlyStakedNFTs;
  }

  get canShowClaimBulkRewards(): boolean {
    if (this.currentlyStakedNFTs.length === 0) {
      return false;
    }
    return this.currentlyStakedNFTs.some(
      (stakedNFT: StakedNFTInfo) =>
        canNFTClaimRewardNow(stakedNFT, this.activePoolRewards) ||
        canNFTClaimRewardNow(stakedNFT, this.pastPoolRewards)
    );
  }

  get canShowUnstakeBulkNFTs(): boolean {
    return this.currentlyStakedNFTs.length > 0;
  }

  claimBulkRewards(): void {
    this.$store.dispatch('claimRewardBulkNFTs');
  }

  unstakeBulkNFTs(): void {
    this.$store.commit('app/setModal', {
      component: 'alertMessage',
      payload: {
        title: 'Unstaking Warning',
        message:
          'You will unstake all your nfts from every collection. You will receive rewards from eligible pool rewards that have ended if you kept your token staked for at least 30 days, otherwise you will lose those rewards. Are you sure you want to unstake all your nfts?',
        okMessage: 'I understand',
        okAction: () => this.$store.dispatch('unstakeBulkNFTs'),
      },
    });
  }

  toggleFilter(): void {
    this.showFilter = !this.showFilter;
  }

  poolRewardsOptions = [
    {
      prepend: 'StarIcon',
      label: 'Active Pool Rewards',
      name: 'active',
      open: true,
      links: [
        {
          id: '',
          name: '',
          active: false,
        },
      ],
    },
    {
      prepend: 'StickerIcon',
      label: 'Past Pool Rewards',
      name: 'past',
      open: false,
      links: [
        {
          id: '',
          name: '',
          active: false,
        },
      ],
    },
  ];

  collectionFilterOptions = [
    {
      prepend: 'StarIcon',
      label: 'Filter NFTs by collection',
      open: true,
      links: [
        {
          name: 'All',
          address: 'all',
          active: false,
        },
      ],
    },
  ];
}
