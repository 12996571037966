
import {
  isUrl,
  stringToOpenSeaUrlEncoding,
  stringToLooksRareUrlEncoding,
} from '@/utils';
import { Vue, Prop, Options, Watch } from 'vue-property-decorator';
import BoatBuyIcon from '../icons/boatbuy.vue';
import EyeBuyIcon from '../icons/eyebuy.vue';
import Banner from './banner.vue';
import Dropdown from './dropdown.vue';
import Price from './price.vue';
import Web3 from 'web3';
import BigNumber from 'bignumber.js';

enum dropdownType {
  CURRENCY = 'CURRENCY',
  BLOCKS = 'BLOCKS',
  DISCOUNT = 'DISCOUNT',
}

@Options({
  components: {
    Banner,
    BoatBuyIcon,
    EyeBuyIcon,
    Dropdown,
    Price,
  },
})
export default class GameItem extends Vue {
  @Prop(Boolean) readonly isUpcoming!: boolean;
  @Prop({ default: false }) readonly game!: boolean;
  @Prop({ default: false }) readonly item!: boolean;
  @Prop(Object) readonly options!: any;
  isDisabled = false;

  async beforeMount(): Promise<void> {
    this.d1_currency_options = this.options.paymentMethods.map((item: any) => {
      return {
        label: item.tokenName,
        value: item,
        type: 'CURRENCY',
      };
    });
    this.d1_currency_active =
      this.d1_currency_options.length !== 0
        ? this.d1_currency_options[0]
        : null;
    this.updateNumTokensOptions();
    this.updateDiscountOptions();
  }

  get waitingForConfirmation(): boolean {
    return this.$store.state.landlordStaking.waitForConfirmationTrigger;
  }

  @Watch('waitingForConfirmation')
  async onWaitingForConfirmationChange(value: boolean): Promise<void> {
    this.isDisabled = value;
  }

  @Watch('options', { deep: true })
  onOptionsChange(): void {
    this.d1_currency_options = this.options.paymentMethods.map((item: any) => {
      return {
        label: item.tokenName,
        value: item,
        type: 'CURRENCY',
      };
    });
    this.d1_currency_active =
      this.d1_currency_options.length !== 0
        ? this.d1_currency_options[0]
        : null;
    this.updateNumTokensOptions();
    this.updateDiscountOptions();
  }

  dropdown_type = dropdownType;

  d1_currency_options = [
    {
      label: 'ETH',
      type: 'CURRENCY',
      value: {},
    },
    {
      label: '$MATIC',
      type: 'CURRENCY',
      value: {},
    },
    {
      label: '$DYSTO',
      type: 'CURRENCY',
      value: {},
    },
    {
      label: 'USD',
      type: 'CURRENCY',
      value: {},
    },
  ];

  d2_num_tokens_options = [
    {
      label: '1',
      type: 'BLOCKS',
      value: 1,
    },
    {
      label: '3',
      type: 'BLOCKS',
      value: 3,
    },
    {
      label: '6',
      type: 'BLOCKS',
      value: 6,
    },
    {
      label: '12',
      type: 'BLOCKS',
      value: 12,
    },
    {
      label: '24',
      type: 'BLOCKS',
      value: 24,
    },
  ];

  d3_discounts_options = [
    {
      label: '0% OFF',
      type: 'DISCOUNT',
      value: { threshold: 0, percentage: 0 },
    },
  ];

  d1_currency_active: { [key: string]: any } | null = null;
  d2_num_tokens_active = this.d2_num_tokens_options[0];
  d3_discount_active = this.d3_discounts_options[0];

  updateOption(option: any): void {
    switch (option.type) {
      case dropdownType.CURRENCY:
        this.d1_currency_active = option;
        break;
      case dropdownType.BLOCKS:
        this.d2_num_tokens_active = option;
        this.updateDiscountOptions();
        break;
      case dropdownType.DISCOUNT:
        this.d3_discount_active = option;
        break;
    }
  }

  updateNumTokensOptions(): void {
    this.d2_num_tokens_options =
      this.options.layoutOptions.numTokensOptions.map((value: number) => ({
        label: value.toString(),
        type: 'BLOCKS',
        value: value,
      }));
    this.d2_num_tokens_active = this.d2_num_tokens_options[0];
  }

  updateDiscountOptions(): void {
    this.d3_discounts_options = [
      {
        label: '0% OFF',
        type: 'DISCOUNT',
        value: { threshold: 0, percentage: 0 },
      },
    ];
    this.d3_discounts_options = this.d3_discounts_options.concat(
      this.options.discounts
        .filter(
          (item: any) => item.threshold <= this.d2_num_tokens_active.value
        )
        .sort((a: any, b: any) => b.percentage - a.percentage)
        .map((item: any) => {
          return {
            label: `${item.percentage}% OFF`,
            type: 'DISCOUNT',
            value: item,
          };
        })
    );
    this.d3_discount_active = this.d3_discounts_options[0]; // also reset the discount
  }

  get priceShow(): string {
    if (this.d1_currency_active === null) {
      return '';
    }
    const currency = this.d1_currency_active.value;
    const numTokens = this.d2_num_tokens_active.value;
    const discount = this.d3_discount_active.value.percentage;
    const price = new BigNumber(currency.value)
      .multipliedBy(numTokens * (100 - discount))
      .dividedToIntegerBy(100);
    return `${Web3.utils.fromWei(price.toString(), 'ether')} ${
      currency.tokenName
    }`;
  }

  get chainId(): number {
    return this.$store.state.chainId;
  }

  async onMint(): Promise<void> {
    if (this.options.chainId !== this.chainId) {
      this.$store.dispatch('requestChangeOfChain', this.options.chainId);
      return;
    }

    const response = await this.$store.dispatch(
      'getMintParamsForLaunchpadCollection',
      {
        assetId: this.options.id,
        paymentMethodId: this.d1_currency_active?.value.id,
        numberOfTokens: this.d2_num_tokens_active.value,
        discountThreshold: this.d3_discount_active.value.threshold,
        discountPercentage: this.d3_discount_active.value.percentage,
      }
    );
    if (!response) {
      return;
    }
    this.$store.commit('app/setModal', {
      component: 'mintTokensLaunchpadCollection',
      payload: {
        ...response,
        paymentMethods: this.options.paymentMethods,
        collectionAddress: this.options.contractAddress,
        chainId: this.options.chainId,
      },
    });
  }

  onBuy(market: string): void {
    let stringTraitNameEncoded = '';
    let stringTraitValueEncoded = '';
    let assetUrl = '';
    switch (market) {
      case 'OPENSEA':
        stringTraitNameEncoded = this.options.assetsDiscriminator
          ? stringToOpenSeaUrlEncoding(this.options.assetsDiscriminator)
          : '';
        stringTraitValueEncoded = this.options.title
          ? stringToOpenSeaUrlEncoding(this.options.title)
          : '';
        assetUrl = this.options.assetsDiscriminator
          ? `https://opensea.io/collection/${this.options.slug}?search[stringTraits][0][name]=${stringTraitNameEncoded}&search[stringTraits][0][values][0]=${stringTraitValueEncoded}`
          : `https://opensea.io/collection/${this.options.slug}`;
        break;
      case 'LOOKSRARE':
        stringTraitNameEncoded = this.options.assetsDiscriminator
          ? stringToLooksRareUrlEncoding(this.options.assetsDiscriminator)
          : '';
        stringTraitValueEncoded = this.options.title
          ? stringToLooksRareUrlEncoding(this.options.title)
          : '';
        assetUrl = this.options.assetsDiscriminator
          ? `https://looksrare.org/collections/${this.options.contractAddress}?filters=%7B"attributes"%3A%5B%7B"traitType"%3A"${stringTraitNameEncoded}"%2C"values"%3A%5B"${stringTraitValueEncoded}"%5D%7D%5D%7D`
          : `https://looksrare.org/collections/${this.options.contractAddress}`;
        break;
      default:
        break;
    }

    window.open(assetUrl, '_blank');
  }

  checkLocalImage(image: string): boolean {
    return !isUrl(image);
  }

  openGallerySlideShow(): void {
    this.$store.commit('app/setModal', {
      component: 'gallerySlideshow',
      payload: {
        images: this.options.gallery,
      },
    });
  }
}
