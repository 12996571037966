

import { API_URL } from '@/config';
import { Vue } from 'vue-property-decorator';
import { toast, ToastOptions } from 'vue3-toastify';

export default class TwitterOAuthCallback extends Vue {
  async mounted() {
    await this.$store.dispatch('returnToken');
    const params = this.$route.query;
    if (params.oauth_token && params.oauth_verifier) {
      fetch(`${API_URL}/keypad/twitter_oauth_callback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${await this.$store.dispatch(
            'returnToken',
          )}`,
        },
        body: JSON.stringify({
          oauth_token: params.oauth_token,
          oauth_verifier: params.oauth_verifier,
        }),
      })
        .then(async (response) => {
          const res = await response.json();
          if (res && res.message && res.message == 'Twitter account already connected') {
            toast.warning('Twitter account already connected', {
              hideProgressBar: true,
              autoClose: 1000,
              position: toast.POSITION.TOP_RIGHT,
            } as ToastOptions);
          }
          await this.$store.dispatch('login');
          await this.$router.push('/influencer');
        })
        .catch(err => {
          console.error('Error handling Twitter OAuth callback:', err);
          this.$router.push('/');
        });
    } else {
      console.error('OAuth parameters missing in the callback URL');
      await this.$router.push('/');
    }
  }
}
